import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { NAVIGATION_TOKEN, NavigationProvider, PLATFORM_TOKEN, PlatformProvider } from '@core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { take, tap } from 'rxjs';
import { UpdateTeamCommandHandler } from '../../application/handlers';
import { TitileNavbarComponent } from '@ui-components';
import { NgClass, NgIf } from '@angular/common';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'lib-edit-team',
  templateUrl: './edit-team.component.html',
  styleUrls: ['./edit-team.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TitileNavbarComponent, NgClass, ReactiveFormsModule, NgIf],
  providers: [UpdateTeamCommandHandler]
})
export class EditTeamComponent implements OnInit {
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly navigationProvider: NavigationProvider = inject(NAVIGATION_TOKEN);
  private readonly updateTeamCommandHandler: UpdateTeamCommandHandler = inject(UpdateTeamCommandHandler);

  readonly isiOS: boolean = this.platformProvider.isiOS;

  readonly teamNameForm: FormGroup = new FormGroup({
    id: new FormControl(),
    teamName: new FormControl('', [Validators.required, Validators.maxLength(25)])
  });

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(
        take(1),
        tap((params: Params) =>
          this.teamNameForm.patchValue({
            id: params['id'],
            teamName: params['teamName']
          })
        )
      )
      .subscribe();
  }

  isFieldInvalid(fieldName: string): boolean {
    const control = this.teamNameForm.get(fieldName);
    return control ? control.touched && control.invalid : false;
  }

  get isNameOverAvailableLength() {
    return this.teamNameForm.get('teamName')?.value?.length > 25;
  }

  backToTeamDetails(): void {
    this.navigationProvider.back();
  }

  saveData(): void {
    const id: string = this.teamNameForm?.value?.id;
    const teamName: string = this.teamNameForm?.value?.teamName;

    this.updateTeamCommandHandler
      .update(id, { teamName })
      .pipe(take(1))
      .subscribe(() => this.backToTeamDetails());
  }
}
