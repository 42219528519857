import { BehaviorSubject, map, Observable, take, tap } from 'rxjs';
import { FiltersValues } from '../../application/models';

export class FiltersStorage {
  private startValue: FiltersValues = {} as FiltersValues;

  private filtersSubject: BehaviorSubject<FiltersValues> = new BehaviorSubject<FiltersValues>(this.startValue);

  readonly filters$: Observable<FiltersValues> = this.filtersSubject.asObservable();

  setFilters(data: Partial<FiltersValues>): Observable<void> {
    return this.filtersSubject.asObservable().pipe(
      take(1),
      tap((state) => this.filtersSubject.next({ ...state, ...data })),
      map(() => void 0)
    );
  }
}
