import { trigger, transition, style, animate, AnimationTriggerMetadata } from '@angular/animations';

export const slideInFromBottom: AnimationTriggerMetadata = trigger('slideInFromBottom', [
  transition(':enter', [
    style({ transform: 'translateY(100%)' }),
    animate('1000ms ease-out', style({ transform: 'translateY(0)' }))
  ])
]);

export const slideInFromTop: AnimationTriggerMetadata = trigger('slideInFromTop', [
  transition(':enter', [
    style({ transform: 'translateY(-100%)' }),
    animate('1000ms ease-out', style({ transform: 'translateY(0)' }))
  ])
]);

export const slideInFromLeft: AnimationTriggerMetadata = trigger('slideInFromLeft', [
  transition(':enter', [
    style({ transform: 'translateX(-100%)' }),
    animate('1000ms ease-out', style({ transform: 'translateX(0)' }))
  ])
]);

export const slideInFromRight: AnimationTriggerMetadata = trigger('slideInFromRight', [
  transition(':enter', [
    style({ transform: 'translateX(100%)' }),
    animate('1000ms ease-out', style({ transform: 'translateX(0)' }))
  ])
]);

export const slideOutToLeft: AnimationTriggerMetadata = trigger('slideOutToLeft', [
  transition(':leave', [animate('1000ms ease-out', style({ transform: 'translateX(-100%)' }))])
]);

export const slideInOutFromRight: AnimationTriggerMetadata = trigger('slideInOutFromRight', [
  transition(':enter', [
    style({ transform: 'translateX(100%)' }),
    animate('500ms ease-out', style({ transform: 'translateX(0)' }))
  ]),
  transition(':leave', [animate('500ms ease-out', style({ transform: 'translateX(-100%)' }))])
]);
