import { ActivatedRouteSnapshot, ResolveFn } from "@angular/router";
import { IsAuthedService } from "@core";
import { inject } from "@angular/core";
import { map, take } from "rxjs";
import { RedirectStoragePath } from "@auth";

export const teamInvitationAcceptResolver: ResolveFn<void> = (
  activatedRoute: ActivatedRouteSnapshot
) => {
  const isAuthed: IsAuthedService = inject(IsAuthedService);

  const teamId: string = activatedRoute.params["teamId"];
  const token: string = activatedRoute.params["token"]?.includes("?")
    ? activatedRoute.params["token"].split("?")[0]
    : activatedRoute.params["token"];

  isAuthed.isAuthed$
    .pipe(
      take(1),
      map((isAuthed: boolean) => {
        if (isAuthed)
          return sessionStorage.removeItem(
            RedirectStoragePath.TEAM_INVITATION_ACCEPT
          );

        const data = {
          teamId: teamId,
          token: token,
        };

        return sessionStorage.setItem(
          RedirectStoragePath.TEAM_INVITATION_ACCEPT,
          JSON.stringify(data)
        );
      })
    )
    .subscribe();
};
