import { UserCommentsService } from '../../infrastructure';
import { inject } from '@angular/core';
import { map, Observable, switchMap } from 'rxjs';
import { GroupedComment, GroupedUserCommentModel, SingleComment, UserCommentDTO } from '../interfaces';
import { ActivatedRoute, Params } from '@angular/router';
import { CommentTypeEnum } from '../enums';
import { formatDateToString } from '@core';

export class GetUserCommentQueryHandler {
  private readonly userCommentsService: UserCommentsService = inject(UserCommentsService);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  getComment(): Observable<GroupedUserCommentModel> {
    return this.activatedRoute.params.pipe(
      switchMap((params: Params) => this.userCommentsService.getComment(params['id'])),
      map((userComments: UserCommentDTO) => {
        const groupedComments: Record<CommentTypeEnum, GroupedComment> = userComments?.comments?.reduce(
          (acc, comment: SingleComment) => {
            const { commentType, createdAt, author } = comment;

            return {
              ...acc,
              [commentType]: {
                commentType,
                commentDetails: [
                  ...(acc?.[commentType]?.commentDetails || []),
                  { authorName: author.authorName, createdAt: formatDateToString(createdAt, 'dd.MM.yyyy') }
                ]
              }
            };
          },
          {} as Record<CommentTypeEnum, GroupedComment>
        );

        const groupedCommentsArray: GroupedComment[] = Object.values(groupedComments);

        return {
          totalComments: userComments.comments?.length,
          groupedComments: groupedCommentsArray
        };
      })
    );
  }
}
