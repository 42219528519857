import { UserCommentsService } from "../../infrastructure";
import { inject } from "@angular/core";
import { AddCommentPayloadDTO } from "../interfaces";

export class AddCommentCommandHandler {
  private readonly userCommentsService: UserCommentsService =
    inject(UserCommentsService);

  addComment(paylaod: AddCommentPayloadDTO) {
    return this.userCommentsService.addComment(paylaod);
  }
}
