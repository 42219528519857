import { inject } from '@angular/core';
import { map, Observable } from 'rxjs';
import { DateFormatPipe } from '@ui-components';
import { HOSTED_GAMES_DTO, HostedGamesDTOPort } from '../../infrastructure/http-service';
import { mapToHostedGameQueryUtil } from './utils/map-to-hosted-game-query.util';
import { HostedGameDTO } from '../interfaces';
import { GameDetailsModel, GameDTO } from '@core';

export class GetHostedFutureGamesQueryHandler {
  private readonly hostedGamesDTOPort: HostedGamesDTOPort = inject(HOSTED_GAMES_DTO);
  private readonly dateFormatPipe: DateFormatPipe = inject(DateFormatPipe);

  getGames(): Observable<GameDetailsModel[]> {
    return this.hostedGamesDTOPort
      .getFutureGames()
      .pipe(
        map((games: HostedGameDTO) =>
          games.hostedGames.map((game: GameDTO) => mapToHostedGameQueryUtil(game, this.dateFormatPipe))
        )
      );
  }
}
