import { ChatDtoPort } from './chat.dto-port';
import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { ENV_CONFIG, EnvConfig } from '@core';
import { Observable } from 'rxjs';
import {
  ChannelDetailsDTO,
  ChannelMembersResponseDTO,
  CreateSingleChatResponseDTO
} from '../../application/interfaces';

export class ChatService implements ChatDtoPort {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  channelDetails(channelId: string): Observable<ChannelDetailsDTO> {
    return this.httpClient.get<ChannelDetailsDTO>(`${this.env.apiUrl}/chat/channel/${channelId}`);
  }

  channelDetailsByReference(params: string): Observable<ChannelDetailsDTO> {
    return this.httpClient.get<ChannelDetailsDTO>(`${this.env.apiUrl}/chat/channel/get-by-reference?${params}`);
  }

  channelMembers(channelId: string): Observable<ChannelMembersResponseDTO> {
    return this.httpClient.get<ChannelMembersResponseDTO>(`${this.env.apiUrl}/chat/channel/${channelId}/members`);
  }

  createSingleChat(userId: string): Observable<CreateSingleChatResponseDTO> {
    return this.httpClient.post<CreateSingleChatResponseDTO>(`${this.env.apiUrl}/chat/channel/single`, { userId });
  }

  muteChannel(channelId: string, value: boolean): Observable<void> {
    return this.httpClient.patch<void>(`${this.env.apiUrl}/chat/channel/${channelId}/muted`, { isMuted: value });
  }
}
