import { TeamsService } from "../../infrastructure";
import { inject } from "@angular/core";
import { Observable } from "rxjs";
import { TeamDTO } from "../interfaces/team.dto";
import { CreateTeamDTO } from "../interfaces";

export class CreateTeamCommandHandler {
  private readonly teamsService: TeamsService = inject(TeamsService);

  create(payload: CreateTeamDTO): Observable<TeamDTO> {
    return this.teamsService.createTeam(payload);
  }
}
