import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { cardReasonMapper } from '../../application/utils';
import { CardReason } from '../../application/enums';
import { CardReasonModel } from '../../application/interfaces';
import { IsRedCardActiveQueryHandler } from '../../application/handlers/is-red-card-active.query-handler';
import { Observable } from 'rxjs';
import { AsyncPipe, NgIf } from '@angular/common';
import { PenaltiesService } from '../../infrastructure';

@Component({
  selector: 'lib-red-card-indicator',
  templateUrl: './red-card-indicator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [AsyncPipe, NgIf],
  providers: [IsRedCardActiveQueryHandler, PenaltiesService]
})
export class RedCardIndicatorComponent {
  public cardReason: typeof CardReason = CardReason;
  public cardReasonMapper: Record<CardReason, CardReasonModel> = cardReasonMapper;

  private readonly isRedCardActiveQueryHandler: IsRedCardActiveQueryHandler = new IsRedCardActiveQueryHandler();

  isRedCardActive$: Observable<boolean> = this.isRedCardActiveQueryHandler.isRedCardActive();
}
