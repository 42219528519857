import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { ShareGameDirective, TitileNavbarComponent } from "@ui-components";
import { NgClass } from "@angular/common";
import {
  MODAL_TOKEN,
  ModalProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
} from "@core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { AppRoutes } from "src/app/app-routes.enum";
import { TeamsRoutes } from "../../teams-routes.enum";
import { map, Observable, switchMap, take } from "rxjs";
import { TeamInviteData } from "../../application/models";
import { ShareTeamModalComponent } from "../share-team-modal/share-team-modal.component";

@Component({
  selector: "lib-create-team-finish",
  templateUrl: "./create-team-finish.component.html",
  styleUrls: ["./create-team-finish.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TitileNavbarComponent, NgClass, ShareGameDirective],
})
export class CreateTeamFinishComponent {
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly router: Router = inject(Router);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  readonly isiOS: boolean = this.platformProvider.isiOS;

  readonly teamData$: Observable<TeamInviteData> =
    this.activatedRoute.params.pipe(
      map((params: Params) => ({
        teamId: params["id"],
        token: params["token"],
      }))
    );

  goToDetails(): void {
    this.teamData$
      .pipe(
        take(1),
        switchMap((teamData: TeamInviteData) =>
          this.router.navigateByUrl(
            `${AppRoutes.TEAMS}/${teamData.teamId}/${TeamsRoutes.TEAM_DETAILS}`
          )
        )
      )
      .subscribe();
  }

  invitePlayers(): void {
    this.teamData$
      .pipe(
        take(1),
        switchMap((teamData: TeamInviteData) =>
          this.router.navigateByUrl(
            `${AppRoutes.TEAMS}/${teamData.teamId}/${teamData.token}/${TeamsRoutes.INVITE}`
          )
        )
      )
      .subscribe();
  }

  share(): void {
    this.teamData$
      .pipe(
        take(1),
        switchMap((teamData: TeamInviteData) =>
          this.modalProvider.showModal$({
            component: ShareTeamModalComponent,
            componentProps: { teamData: teamData },
            cssClass: "modal-auto",
            initialBreakpoint: 1,
            breakpoints: [0, 1],
          })
        )
      )
      .subscribe();
  }
}
