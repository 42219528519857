import { TrophyTypeEnum } from '../enums';
import { UserTrophyModel } from '../interfaces';

export const userTrophiesMapper: Record<TrophyTypeEnum, UserTrophyModel> = {
  [TrophyTypeEnum.HOSTED_GAMES_5]: {
    name: 'Entuzjasta Organizowania',
    description: 'Stwórz 5 gierek w Orlikfy',
    icon: 'assets/trophies/hosted-games-5.svg'
  },
  [TrophyTypeEnum.HOSTED_GAMES_25]: {
    name: 'Organizowanie to moja pasja',
    description: 'Stwórz 25 gierek w Orlikfy',
    icon: 'assets/trophies/hosted-games-25.svg'
  },
  [TrophyTypeEnum.HOSTED_GAMES_50]: {
    name: 'Mocarz rozgrywek',
    description: 'Stwórz 50 gierek w Orlikfy',
    icon: 'assets/trophies/hosted-games-50.svg'
  },
  [TrophyTypeEnum.HOSTED_GAMES_100]: {
    name: 'Kocham piłkę, więc organizuje',
    description: 'Stwórz 100 gierek w Orlikfy',
    icon: 'assets/trophies/hosted-games-100.svg'
  },

  [TrophyTypeEnum.PLAYER_GAMES_5]: {
    name: 'Entuzjasta Orlikfy',
    description: 'Zagraj w 5 gierkach w Orlikfy',
    icon: 'assets/trophies/player-games-5.svg'
  },
  [TrophyTypeEnum.PLAYER_GAMES_25]: {
    name: 'Biegam i biegam',
    description: 'Zagraj w 25 gierkach w Orlikfy',
    icon: 'assets/trophies/player-games-25.svg'
  },
  [TrophyTypeEnum.PLAYER_GAMES_50]: {
    name: 'Mocarny Zawodnik',
    description: 'Zagraj w 50 gierkach w Orlikfy',
    icon: 'assets/trophies/player-games-50.svg'
  },
  [TrophyTypeEnum.PLAYER_GAMES_100]: {
    name: 'Piłkarz najlepszy',
    description: 'Zagraj w 100 gierkach w Orlikfy',
    icon: 'assets/trophies/player-games-100.svg'
  },

  [TrophyTypeEnum.HOST_RATE_5_50]: {
    name: 'Robisz to dobrze!',
    description: 'Otrzymaj ocenę 5,0 jako host od 50 graczy',
    icon: 'assets/trophies/host-rate-5-50.svg'
  },
  [TrophyTypeEnum.HOST_RATE_5_100]: {
    name: 'Host na medal!',
    description: 'Otrzymaj ocenę 5,0 jako host od 100 graczy',
    icon: 'assets/trophies/host-rate-5-100.svg'
  },

  [TrophyTypeEnum.PLAYER_COMMENT_FAIR_PLAY_10]: {
    name: 'Specjalista od spraw Fair Play',
    description: 'Otrzymaj komplement “Fair Play” 10 razy',
    icon: 'assets/trophies/player-comment-fair-play-10.svg'
  },
  [TrophyTypeEnum.PLAYER_COMMENT_GOOD_PLAYER_10]: {
    name: 'Gracz najlepszy',
    description: 'Otrzymaj komplement “Wyjątkowo dobry gracz” 10 razy',
    icon: 'assets/trophies/player-comment-good-player-10.svg'
  },
  [TrophyTypeEnum.PLAYER_COMMENT_TEAM_PLAY_10]: {
    name: 'Piłkarz Zespołowy',
    description: 'Otrzymaj komplement “Gra zespołowo” 10 razy',
    icon: 'assets/trophies/player-comment-team-play-10.svg'
  },
  [TrophyTypeEnum.PLAYER_COMMENT_HELPFUL_10]: {
    name: 'Pomaganie jest fajne!',
    description: 'Otrzymaj komplement “Pomocny dla reszty” 10 razy',
    icon: 'assets/trophies/player-comment-helpful-10.svg'
  },
  [TrophyTypeEnum.PLAYER_COMMENT_SPRINTER_10]: {
    name: 'Ngolo Ngolo, Kante!',
    description: 'Otrzymaj komplement “Biega całe spotkanie” 10 razy',
    icon: 'assets/trophies/player-comment-sprinter-10.svg'
  },

  [TrophyTypeEnum.NO_PENALTY_2025]: {
    name: 'Bezkartkowy 2025',
    description: 'Bez kartek przez cały rok',
    icon: 'assets/trophies/no-penalty-2025.svg'
  }
};
