import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { UsersSummaryModel } from '../../../application/models';
import { AsyncPipe, JsonPipe, NgForOf, NgIf, SlicePipe } from '@angular/common';
import { AdminUsersSummaryDataQueryHandler } from '../../../application/handlers';

@Component({
  selector: 'lib-users-summary',
  templateUrl: './users-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, JsonPipe, NgIf, NgForOf, SlicePipe],
  providers: [AdminUsersSummaryDataQueryHandler]
})
export class UsersSummaryComponent {
  public showStats: boolean = false;

  private readonly adminUsersSummaryDataQueryHandler: AdminUsersSummaryDataQueryHandler = inject(
    AdminUsersSummaryDataQueryHandler
  );

  readonly usersSummary$: Observable<UsersSummaryModel> = this.adminUsersSummaryDataQueryHandler.summaryData();

  versionEntries(version: Record<string, number | null>): { key: string; value: number | null }[] {
    return this.adminUsersSummaryDataQueryHandler.versionEntries(version);
  }

  changeVisibility(): void {
    this.showStats = !this.showStats;
  }
}
