import { UsersListDtoPort } from './users-list.dto-port';
import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { ENV_CONFIG, EnvConfig } from '@core';
import { Observable } from 'rxjs';
import { UsersListDTO } from '../../../application/models';

export class UsersListService implements UsersListDtoPort {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  getAllUsers(): Observable<UsersListDTO> {
    return this.httpClient.get<UsersListDTO>(`${this.env.apiUrl}/admin/users`);
  }
}
