import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { NAVIGATION_TOKEN, NavigationProvider } from '@core';
import { AppRoutes } from 'src/app/app-routes.enum';
import { HostedGamesRoutes } from '../../hosted-games.routes.enum';
import { FindGamesRoutes } from '../../../../../find-games/src/lib/find-games.routes.enum';

export const createGameGuard = (route: ActivatedRouteSnapshot): boolean | UrlTree => {
  const router: Router = inject(Router);
  const navigationProvider: NavigationProvider = inject(NAVIGATION_TOKEN);

  const allowRoutes: (string | RegExp)[] = [
    AppRoutes.FIND_GAMES,
    `${AppRoutes.FIND_GAMES}/${FindGamesRoutes.MAP}`,
    AppRoutes.DASHBOARD,
    `${AppRoutes.HOST}/${HostedGamesRoutes.FUTURE}`,
    `${AppRoutes.HOST}/${HostedGamesRoutes.PAST}`,
    new RegExp(`^${AppRoutes.HOST}/${HostedGamesRoutes.FUTURE}/[^/]+$`)
  ];

  const previousPage = navigationProvider.previousPage().slice(1);

  const isAllowed: boolean = allowRoutes.some((route) => {
    if (route instanceof RegExp) {
      return route.test(previousPage);
    }
    return route === previousPage;
  });

  if (isAllowed) return true;

  return router.createUrlTree([AppRoutes.ROOT]);
};
