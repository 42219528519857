import { RankService } from '../../infrastructure';
import { inject } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable, switchMap } from 'rxjs';
import { UserRankDTO } from '../interfaces';

export class GetUserRankQueryHandler {
  private readonly rankService: RankService = inject(RankService);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  getUserRank(): Observable<UserRankDTO> {
    return this.activatedRoute.params.pipe(switchMap((params: Params) => this.rankService.getUserRank(params['id'])));
  }
}
