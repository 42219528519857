<lib-title-navbar title="Edytuj grupkę"></lib-title-navbar>

<div
  [ngClass]="
    isiOS
      ? 'full-height-scrollable-container-ios'
      : 'full-height-scrollable-container'
  ">
  <form [formGroup]="teamNameForm" class="or-d-flex or-flex-col or-justify-content-center or-mx-24-px or-mt-24-px">
    <div class="or-head-xs or-color-secondary-2 or-mb-4-px">Nazwa grupki</div>
    <input
      class="outline-none input-style or-mb-12-px"
      formControlName="teamName"
      [ngClass]="{ 'error-field': isFieldInvalid('teamName') }" />
    <div *ngIf="isNameOverAvailableLength" class="or-mb-12-px or-text-sm or-color-red or-mt-2-px">
      Maksymalna długość to 25 znaków
    </div>

    <div class="or-color-secondary-2 or-note-sm or-text-align-center">
      Nazwa grupki będzie również nazwą Waszego chatu. Znajdziesz go w zakładce “Wiadomości”
    </div>
  </form>
</div>
<div class="or-mx-24-px">
  <div class="or-d-flex or-justify-content-center">
    <button
      [disabled]="!teamNameForm.valid"
      (click)="saveData()"
      class="button-primary-style or-h-48-px or-w-100-pc or-head-md"
      [ngClass]="{
        'disabled-button': !teamNameForm.valid
      }">
      Edytuj grupkę
    </button>
  </div>
</div>
