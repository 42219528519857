export enum GamesRoutes {
  ROOT = "",
  FUTURE = "future",
  PAST = "past",
  SHARED = "shared",
  LOCATION = "location",
  DETAILS = "details",
  GAME_FINISHED = "game-finished",
  LINEUPS = "lineups",
}
