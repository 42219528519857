import { Directive, ElementRef, inject, Input, OnInit, Renderer2 } from '@angular/core';
import { ADS_TOKEN, AdsProvider, PLATFORM_TOKEN, PlatformProvider } from '../tokens';
import { filter, first, tap } from 'rxjs';
import { BannerAdPluginEvents } from '@capacitor-community/admob';

@Directive({
  standalone: true,
  selector: '[fullHeightScrollableContainer]'
})
export class FullHeightScrollableContainerDirective implements OnInit {
  @Input('fullHeightScrollableContainer') height!: number;

  private readonly adMobBannerHeight: number = 70;

  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly elementRef: ElementRef = inject(ElementRef);
  private readonly renderer2: Renderer2 = inject(Renderer2);
  private readonly adsProvider: AdsProvider = inject(ADS_TOKEN);

  ngOnInit(): void {
    this.setStyle();
    this.observeAdBannerEvent();
  }

  setStyle(additionalValue: number = 0): void {
    const element: ElementRef = this.elementRef.nativeElement;
    const calculatedHeight: number = this.platformProvider.isiOS
      ? this.height + 50 + additionalValue
      : this.height + additionalValue;

    this.renderer2.setStyle(element, 'height', `calc(100vh - ${calculatedHeight}px)`);
    this.renderer2.setStyle(element, 'overflow-y', 'auto');
  }

  private observeAdBannerEvent(): void {
    this.adsProvider.bannerEvents$
      .pipe(
        filter((event: BannerAdPluginEvents | null) => event === BannerAdPluginEvents.AdImpression),
        first(),
        tap(() => this.setStyle(this.adMobBannerHeight))
      )
      .subscribe();
  }
}
