import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { IsAuthedService, LocalStorageKey } from '@core';
import { inject } from '@angular/core';
import { map, take } from 'rxjs';
import { ReassignGamePayload } from '../interfaces';

export const reassignGameResolver: ResolveFn<void> = (activatedRoute: ActivatedRouteSnapshot) => {
  const isAuthed: IsAuthedService = inject(IsAuthedService);

  const gameId: string = activatedRoute.params['gameId'];
  const token: string = activatedRoute.queryParamMap.get('token') as string;

  isAuthed.isAuthed$
    .pipe(
      take(1),
      map((isAuthed: boolean) => {
        if (isAuthed) return sessionStorage.removeItem(LocalStorageKey.ReassignGame);

        const data: ReassignGamePayload = {
          gameId: gameId,
          token: token
        };

        return sessionStorage.setItem(LocalStorageKey.ReassignGame, JSON.stringify(data));
      })
    )
    .subscribe();
};
