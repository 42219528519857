import { GameDetailsModel, gameLevelMapper, PaymentType, paymentTypesMapper } from '@core';
import { DateFormatPipe } from '@ui-components';
import { PlayerGameDTO } from '../../interfaces';

export const mapToGameQueryUtil = (game: PlayerGameDTO, dateFormatPipe: DateFormatPipe): GameDetailsModel => {
  return {
    gameName: game.name,
    gameId: game.gameId,
    formattedDate: dateFormatPipe.transform(game.gameDateStart, game.gameDateEnd),
    gameDateStart: game.gameDateStart,
    location: game.location,
    totalSlots: game.totalSlots,
    occupiedSlots: game.occupiedSlots,
    level: gameLevelMapper[game.level],
    priceAmount: +game.priceAmount,
    paymentType: game.paymentTypes?.map((type: PaymentType) => paymentTypesMapper[type]),
    description: game.description ?? '',
    gameStatus: game.currentUserParticipationStatus,
    host: game.host,
    isPaid: game.isPaid,
    players: game.gamePlayers,
    playersApplications: game.playerApplications,
    invitations: game.invitations,
    invitationsRejected: game.invitationsRejected,
    gameContactPhone: game.gameContactPhone,
    visibility: game?.visibility,
    isRecurring: game?.isRecurring,
    channel: game?.channel,
    gameInvitationId: game?.gameInvitationId,
    isHost: game.isCurrentUserHost,
    locationType: game?.locationType
  };
};
