import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AdminPanelRouting } from './admin-panel.routing';
import { provideAdminUsersFilters } from './infrastructure/storages';

@NgModule({
  imports: [RouterModule.forChild(AdminPanelRouting)],
  providers: [provideAdminUsersFilters()]
})
export class AdminPanelModule {}
