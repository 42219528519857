import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { GameDetailsModalComponent } from '../game-details-modal/game-details-modal.component';
import { MODAL_TOKEN, ModalProvider, PLATFORM_TOKEN, PlatformProvider } from '@core';
import { BottomNavbarComponent, DateFormatPipe, TitileNavbarComponent } from '@ui-components';
import { provideLocationDetailsService } from '../../infrastructure/http-service';
import { Observable } from 'rxjs';
import { LocationDetailsModel } from '../../application/interfaces';
import { GetLocationDetailsQueryHandler } from '../../application/handlers';

@Component({
  selector: 'lib-location-details',
  templateUrl: './location-details.component.html',
  styleUrls: ['./location-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, IonicModule, DateFormatPipe, BottomNavbarComponent, TitileNavbarComponent],
  providers: [provideLocationDetailsService(), GetLocationDetailsQueryHandler]
})
export class LocationDetailsComponent {
  private readonly modalToken: ModalProvider = inject(MODAL_TOKEN);
  private readonly getLocationDetailsQueryHandler: GetLocationDetailsQueryHandler =
    inject(GetLocationDetailsQueryHandler);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);

  readonly locationDetails$: Observable<LocationDetailsModel> = this.getLocationDetailsQueryHandler.getDetails();

  readonly isiOS: boolean = this.platformProvider.isiOS;

  openDetails(gameId: string): void {
    this.modalToken.showModal$({
      component: GameDetailsModalComponent,
      componentProps: { gameId: gameId },
      cssClass: 'modal-auto',
      initialBreakpoint: 1,
      breakpoints: [0, 1]
    });
  }
}
