<div (click)="goToDetails()">
  <div
    class="or-d-flex or-justify-content-between or-align-items-center or-mt-8-px"
  >
    <div class="or-head-lg">{{ teamDetails.teamName }}</div>
    <img
      *ngIf="showShareIcon && teamDetails.memberRole !== 'Member'"
      src="assets/icons/share-icon.svg"
      (click)="share($event)"
    />
  </div>

  <div *ngIf="showInfoLabel" class="or-mt-8-px or-text-sm or-color-secondary-2">
    {{ teamDetails.memberRole !== "Member" ? hostInfoText : memberInfoText }}
  </div>

  <div class="or-my-12-px">
    <div class="or-d-flex or-align-items-center">
      <div
        *ngFor="
          let member of teamDetails.members
            | slice : 0 : (teamDetails.members.length === 5 ? 5 : 4)
        "
        class="negative-margin-left-20-px"
      >
        <lib-or-avatar
          [avatarUrl]="member.thumbnail"
          [initials]="member.displayName"
          [showWhiteBorder]="true"
          [avatarSize]="'sm'"
        ></lib-or-avatar>
      </div>
      <div class="or-text-md or-color-secondary-2">
        {{ teamDetails.members?.length }} graczy
      </div>
    </div>
  </div>

  <div class="or-d-flex">
    <div
      (click)="goToChat($event)"
      class="border-shade border-radius-4 or-p-12-px or-mr-8-px or-w-100-pc or-bg-white or-d-flex"
    >
      <img src="assets/icons/team-chat.svg" class="or-size-28-px or-mr-8-px" />
      <div>
        <div class="or-text-md or-my-4-px">Chat grupki</div>
        <div class="or-text-sm or-color-secondary-2">
          Przejdź do chatu grupki
        </div>
      </div>
    </div>

    <div
      *ngIf="teamDetails.memberRole !== 'Member'"
      (click)="goToAutoApprove($event)"
      class="border-shade border-radius-4 or-p-12-px or-w-100-pc or-bg-white or-d-flex"
    >
      <img
        src="assets/icons/bottom-navbar/bottom-host.svg"
        class="or-size-28-px or-mr-8-px"
      />
      <div>
        <div class="or-text-md or-my-4-px">Ulubieni gracze</div>
        <div class="or-text-sm or-color-secondary-2">
          Automatycznie akceptuj graczy
        </div>
      </div>
    </div>
  </div>
</div>
