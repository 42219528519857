<ng-container *ngIf="isVisible$ | async">
  <div (click)="createTeam()" class="or-d-flex or-justify-content-center or-position-relative">
    <img class="or-w-95-pc z-index-1" src="assets/create-team.svg" />
    <div class="or-position-absolute or-left-20-px or-bottom-16-px z-index-2 or-text-sm or-color-white">
      Przenieś swoją grupkę do Orlikfy!
    </div>
    <button
      class="button-primary-style or-position-absolute or-right-20-px or-bottom-12-px z-index-2 or-h-28-px or-head-sm or-color-white">
      Stwórz grupkę
    </button>
  </div>
</ng-container>
