import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { GameDTO, NerabyGamesDTO } from '@core';

export const NEARBY_GAMES_DTO: InjectionToken<NearbyGamesDtoPort> = new InjectionToken<NearbyGamesDtoPort>(
  'NEARBY_GAMES_DTO'
);

export interface NearbyGamesDtoPort {
  getMarkers(params: string): Observable<{ locations: NerabyGamesDTO[] }>;
  getGamesList(params: string, showPrivate: boolean): Observable<{ games: GameDTO[] }>;
}
