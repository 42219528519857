import { ChangeDetectionStrategy, Component, inject, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, switchMap, take, tap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import {
  BottomNavbarComponent,
  CloseAdBannerComponent,
  GameCardV2Component,
  GamesMenuComponent,
  RedirectTypeEnum,
  TitileNavbarComponent
} from '@ui-components';
import { AppRoutes } from 'src/app/app-routes.enum';
import { GamesRoutes } from '../../games-routes.enum';
import { Router } from '@angular/router';
import {
  ADS_TOKEN,
  AdsProvider,
  AdsUnitId,
  FullHeightScrollableContainerDirective,
  GameDetailsModel,
  MODAL_TOKEN,
  ModalProvider,
  PLATFORM_TOKEN,
  PlatformProvider
} from '@core';
import { GetPastGamesQueryHandler } from '../../application/handlers';
import { FeatureFlag, FeatureFlagHandler } from '@feature-flags';
import { BannerAdSize } from '@capacitor-community/admob/dist/esm/banner/banner-ad-size.enum';
import { BannerAdPosition } from '@capacitor-community/admob/dist/esm/banner/banner-ad-position.enum';

@Component({
  selector: 'lib-past-games',
  templateUrl: './past-games.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TitileNavbarComponent,
    BottomNavbarComponent,
    GamesMenuComponent,
    FullHeightScrollableContainerDirective,
    GameCardV2Component,
    CloseAdBannerComponent
  ],
  providers: [GetPastGamesQueryHandler]
})
export class PastGamesComponent implements OnInit {
  @ViewChild(FullHeightScrollableContainerDirective)
  containerDirective!: FullHeightScrollableContainerDirective;

  public redirectType: RedirectTypeEnum = RedirectTypeEnum.GAME;

  private readonly getPastGamesQueryHandler: GetPastGamesQueryHandler = inject(GetPastGamesQueryHandler);
  private readonly router: Router = inject(Router);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly adsProvider: AdsProvider = inject(ADS_TOKEN);
  private readonly featureFlagHandler: FeatureFlagHandler = inject(FeatureFlagHandler);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);

  private showPlaceholderSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly isPageLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  readonly games$: Observable<Map<string, GameDetailsModel[]>> = this.modalProvider.showLoading$().pipe(
    switchMap(() => this.getPastGamesQueryHandler.getGames()),
    tap((games: Map<string, GameDetailsModel[]>) => this.showPlaceholderSubject.next(games?.size === 0)),
    tap(() => this.isPageLoaded$.next(true)),
    tap(() => this.modalProvider.dismissLoading$())
  );

  readonly showPlaceholder$: Observable<boolean> = this.showPlaceholderSubject.asObservable();

  ngOnInit(): void {
    this.initBottomBanner();
  }

  goToFutureGames(): void {
    this.router.navigate([`${AppRoutes.GAMES}/${GamesRoutes.FUTURE}`]);
  }

  goToPastGames(): void {
    this.router.navigate([`${AppRoutes.GAMES}/${GamesRoutes.PAST}`]);
  }

  private initBottomBanner(): void {
    const bottomMargin: number = this.platformProvider.isiOS ? 50 : 70;

    this.featureFlagHandler
      .isEnabled$(FeatureFlag.AD_MOB)
      .pipe(
        take(1),
        tap((isEnabled: boolean) => {
          if (isEnabled)
            return this.adsProvider.showBanner({
              adSize: BannerAdSize.BANNER,
              position: BannerAdPosition.BOTTOM_CENTER,
              adId: AdsUnitId.BannerV1,
              margin: bottomMargin
            });

          return;
        })
      )
      .subscribe();
  }
}
