<lib-title-navbar title="Ustaw autoakceptacje"></lib-title-navbar>

<ng-container *ngIf="teamDetails$ | async as teamDetails">
  <div
    class="or-px-12-px"
    [ngClass]="
      isiOS
        ? 'full-height-scrollable-container-ios'
        : 'full-height-scrollable-container'
    "
  >
    <ng-container *ngIf="teamDetails.members.length > 1; else emptyState">
      <div class="or-color-secondary-2 or-mt-16-px or-text-md or-mx-8-px">
        Ulubieni gracze zaznaczeni przez Ciebie nie będą potrzebować Twojej
        akceptacji. Sami dodadzą się do gierki - pierwszeństwo dla największych
        weteranów!
      </div>
      <div
        class="or-mb-16-px or-mt-24-px or-head-sm or-align-items-center or-mx-8-px"
      >
        <div class="or-color-secondary-2">Lista graczy</div>
      </div>

      <div *ngFor="let player of teamDetails.members; let i = index">
        <div
          *ngIf="player.memberRole !== 'Host'"
          class="or-bg-white or-d-flex or-justify-content-between or-p-8-px or-mb-4-px player-box border-radius-8"
          [ngClass]="
            player.isAutoApproved
              ? 'border-primary or-bg-selected'
              : 'player-box or-bg-white'
          "
        >
          <div class="or-d-flex or-align-items-center or-w-100-pc">
            <ion-checkbox
              [checked]="player.isAutoApproved"
              (ionChange)="
                switchAutoApproved(
                  player.isAutoApproved,
                  player.userId,
                  teamDetails.teamId
                )
              "
              class="or-mr-8-px"
            ></ion-checkbox>
            <lib-or-avatar
              [avatarUrl]="player.thumbnail"
              [initials]="player.displayName"
              [avatarSize]="'sm'"
            ></lib-or-avatar>

            <div class="or-head-sm or-d-flex or-align-items-center">
              {{ i }}.&nbsp;
              <span class="or-truncate-at-line-1">{{
                player.displayName
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-template #emptyState>
  <div
    class="or-text-align-center or-head-md or-h-100-pc or-align-content-center"
  >
    <div>Brak graczy w grupce.</div>
  </div></ng-template
>
