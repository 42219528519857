<div class="or-my-32-px or-mx-24-px">
  <div class="or-d-flex or-justify-content-center or-head-xl or-mb-12-px or-text-align-center">
    Przyznałeś {{ cardName }} kartkę
  </div>

  <div class="or-d-flex or-justify-content-center or-text-align-center or-mb-20-px or-text-lg">
    Kartka będzie widoczna w profilu użytkownika na zawsze w aplikacji.
  </div>

  <div class="or-d-flex or-align-items-center or-justify-content-center or-mb-20-px">
    <lib-or-avatar
      [avatarUrl]="player.userThumbnail"
      [initials]="player.userDisplayName"
      [avatarSize]="'sm'"></lib-or-avatar>

    <div class="or-head-md or-truncate-at-line-1 or-mr-8-px">{{ player.userDisplayName }}</div>

    <img [src]="cardIcon" />
  </div>

  <div class="or-d-flex">
    <button class="button-primary-style or-h-48-px or-w-100-pc or-head-md or-mr-8-px" (click)="closeModal()">
      Wróć do gierki
    </button>
  </div>
</div>
