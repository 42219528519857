<div
  (click)="goToDetails()"
  class="border-radius-8 or-bg-white or-cursor-pointer or-mx-16-px or-pb-12-px or-pt-4-px or-px-12-px z-index-1 card-box-shadow"
  [ngClass]="adjustToChip ? 'or-pt-12-px' : 'or-pt-4-px'"
  *ngIf="game"
>
  <div
    class="or-d-flex or-justify-content-between or-align-items-center or-mt-8-px"
  >
    <div class="or-head-lg">{{ game.gameName }}</div>

    <div *ngIf="showShareButton" class="or-d-flex">
      <img
        src="assets/icons/share-icon.svg"
        [shareGame]="game"
        class="or-cursor-pointer"
      />
    </div>
    <div *ngIf="showAddSimilarGame" class="or-d-flex">
      <div
        class="button-primary-style or-align-items-center or-d-flex or-h-32-px or-head-xs or-px-8-px"
        (click)="similarGameClicked($event)"
      >
        Dodaj ponownie
      </div>
    </div>
  </div>

  <div class="or-d-flex or-align-items-center or-mt-8-px">
    <img src="assets/icons/clock-icon.svg" class="or-mr-8-px" />

    <div>
      <div class="or-head-xs or-color-secondary-2">Data</div>
      <div class="or-text-md">
        {{ game.formattedDate }}
      </div>
    </div>
  </div>

  <div class="or-d-flex or-align-items-center or-mt-8-px">
    <img src="assets/icons/map-pin-icon.svg" class="or-mr-8-px" />

    <div>
      <div class="or-head-xs or-color-secondary-2">Adres</div>
      <div class="or-text-md">
        {{ game.location.address }}, {{ game.location.city }}
      </div>
    </div>
  </div>

  <div *ngIf="showMoreDetails" class="or-d-flex or-mt-12-px">
    <div class="or-d-flex or-align-items-center or-mr-28-px">
      <img src="assets/icons/member-icon.svg" class="or-mr-8-px" />

      <div>
        <div class="or-head-xs or-color-secondary-2">Gracze</div>
        <div class="or-text-md">
          {{ game.occupiedSlots }}/{{ game.totalSlots }}
        </div>
      </div>
    </div>
    <div class="or-d-flex or-align-items-center">
      <img src="assets/icons/price-icon.svg" class="or-mr-8-px" />

      <div>
        <div class="or-head-xs or-color-secondary-2">Cena</div>
        <div *ngIf="game.priceAmount" class="or-text-md">
          {{ game.priceAmount }} zł
        </div>
        <div *ngIf="!game.priceAmount" class="or-text-md">Darmowa</div>
      </div>
    </div>
  </div>

  <div *ngIf="showVisibility" class="or-d-flex or-justify-content-between">
    <div></div>
    <div
      *ngIf="game.visibility === 'PRIVATE'"
      class="border-radius-4 or-align-content-center or-bg-shade or-p-4-px or-text-sm text-no-wrap negative-margin-top-24-px"
    >
      Ukryta na mapie
    </div>
  </div>

  <div *ngIf="showHostInfo" class="or-d-flex or-align-items-center or-mt-16-px">
    <lib-or-avatar
      [avatarUrl]="game.host?.thumbnail"
      [initials]="game.host?.displayName"
      [avatarSize]="'sm'"
    ></lib-or-avatar>
    <div class="or-d-flex or-justify-content-between or-w-100-pc">
      <div class="or-max-w-60-pc">
        <div class="or-head-xs or-color-secondary-2">Organizator</div>
        <div class="or-head-sm">
          {{ game.host?.displayName }}
        </div>
      </div>

      <div class="or-d-flex or-align-items-center">
        <img
          (click)="sendMessage(game.host?.hostId, $event)"
          src="assets/icons/message-host-icon.svg"
          class="or-mr-4-px"
        />

        <button
          *ngIf="showPhoneNumber"
          [copyToClipboard]="game.gameContactPhone"
          class="button-primary-style or-align-items-center or-d-flex or-head-md or-justify-content-between or-px-8-px or-h-32-px or-cursor-pointer"
          [class.disabled-button]="!game.gameContactPhone"
        >
          <img src="assets/icons/phone-white.svg" class="or-mr-4-px" />
          <span
            class="or-color-white or-head-xs text-no-wrap"
            [class.disabled-button]="!game.gameContactPhone"
            >Skopiuj numer
          </span>
        </button>
      </div>
    </div>
  </div>

  <ng-content></ng-content>
</div>
