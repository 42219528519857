import { NgModule } from '@angular/core';
import { NotificationsRoutingModule } from './notifications-routing.module';
import { MarkAsReadNotificationsCommandHandler, NotificationsListQueryHandler } from './application/handlers';
import { provideNotificationsAlertsService } from './infrastructure';

@NgModule({
  imports: [NotificationsRoutingModule],
  providers: [NotificationsListQueryHandler, MarkAsReadNotificationsCommandHandler, provideNotificationsAlertsService()]
})
export class NotificationsModule {}
