export const MAP_API_KEY = 'AIzaSyA3yhZlblc6ojqV5ZZ--z6ydmbtStgh6tY';
export const MAPBOX_TOKEN = 'pk.eyJ1IjoiZG10MTMzNyIsImEiOiJjbHUwa3R5czQwYTg2MnJuMDRlM2xqYTRoIn0.vxPppQ1FcJPPuhrsmrdPDw';

export const CHAT_TOKEN_STAGE = 'gg9t7he8dxyv';
export const CHAT_TOKEN_PROD = 'sfkuy2zhcv3n';

export const AD_MOB_APP_ID = 'ca-app-pub-8749138706409071~8998105382';

export const APPS_FLYER_TOKEN = 'goxiZY5STyHPoxsvqa7Aw9';
export const SEGMENT_TOKEN_PROD = 'wSWCLeZgUO1BH37vijCq4BZeGMQTzxIX';
