import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { Router, RouterOutlet } from "@angular/router";
import { AsyncPipe, NgClass } from "@angular/common";
import { BottomNavbarComponent, HomeNavbarComponent } from "@ui-components";
import {
  IsAuthedService,
  MODAL_TOKEN,
  ModalProvider,
  PresentModalComponent,
} from "@core";
import { AppRoutes } from "src/app/app-routes.enum";
import { HostedGamesRoutes } from "@hosted-games";
import { filter, map, Observable, switchMap, take, tap } from "rxjs";
import {
  NotificationsListQueryHandler,
  NotificationViewModel,
} from "@notifications";
import { FindGamesRoutes } from "../../find-games.routes.enum";
import { FeatureFlag, FeatureFlagHandler } from "@feature-flags";

@Component({
  selector: "lib-find-games-home",
  templateUrl: "./find-games-home.component.html",
  styleUrls: ["./find-games-home.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterOutlet,
    AsyncPipe,
    HomeNavbarComponent,
    BottomNavbarComponent,
    NgClass,
  ],
  providers: [NotificationsListQueryHandler],
})
export class FindGamesHomeComponent {
  private readonly featureFlag: FeatureFlagHandler = inject(FeatureFlagHandler);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly router: Router = inject(Router);
  private readonly notificationsListQueryHandler: NotificationsListQueryHandler =
    inject(NotificationsListQueryHandler);
  private readonly isAuthedService: IsAuthedService = inject(IsAuthedService);

  readonly isAuthed$: Observable<boolean> = this.isAuthedService.isAuthed$;

  readonly isNewNotifications$: Observable<boolean> = this.isAuthed$.pipe(
    filter((isAuthed: boolean) => isAuthed),
    switchMap(() => this.notificationsListQueryHandler.getNotificationsList()),
    map((notifications: NotificationViewModel[]) =>
      notifications.filter(
        (notification: NotificationViewModel) => !notification.isRead
      )
    ),
    map((notifications: NotificationViewModel[]) => !!notifications?.length)
  );

  get isMapActive(): boolean {
    return this.router.url.includes(FindGamesRoutes.MAP);
  }

  goToGameCreator(): void {
    this.router.navigateByUrl(
      `${AppRoutes.HOST}/${HostedGamesRoutes.CREATE_GAME}`
    );
  }

  goToList(): void {
    this.router.navigateByUrl(AppRoutes.FIND_GAMES);
  }

  goToMap(): void {
    this.featureFlag
      .isEnabled$(FeatureFlag.DISABLE_MAP)
      .pipe(
        take(1),
        tap((isEnabled: boolean) => {
          if (isEnabled) return this.mapDisabled();

          return this.router.navigateByUrl(
            `${AppRoutes.FIND_GAMES}/${FindGamesRoutes.MAP}`
          );
        })
      )
      .subscribe();
  }

  private mapDisabled(): void {
    this.modalProvider.showModal$({
      component: PresentModalComponent,
      componentProps: {
        header: "Mapa niedostępna",
        message: "Mapa chwilowo niedostępna. Pracujemy nad tym.",
        btnTxt: "Zamknij",
      },
      cssClass: "present-modal",
    });
  }
}
