import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  BottomNavbarComponent,
  GameDetailsComponent,
  OrAvatarComponent,
  OrLoadingComponent,
  OrLoadingService,
  TitileNavbarComponent
} from '@ui-components';
import { IonicModule } from '@ionic/angular';
import {
  ActionModalComponent,
  CacheKey,
  FullHeightScrollableContainerDirective,
  GameDetailsModel,
  GamePlayersDTO,
  GlobalRefreshService,
  LocalRefreshService,
  MODAL_TOKEN,
  ModalProvider,
  PreferredPosition,
  provideLocalRefreshService,
  RedirectToUserProfileDirective,
  USER_DATA_TOKEN,
  UserDataProvider,
  UserModel
} from '@core';
import { combineLatest, combineLatestWith, map, Observable, shareReplay, switchMap, take, tap } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { provideHostedGameApplicationsService } from '../../infrastructure/http-service';
import {
  ApproveApplicationsCommandHandler,
  GetHostedGameQueryHandler,
  MarkAsPaidCommandHandler,
  RejectApplicationsCommandHandler
} from '../../application/handlers';
import { ReactiveFormsModule } from '@angular/forms';
import { ManagePlayerComponent } from '../manage-player/manage-player.component';
import { ManageHostedGameComponent } from '../manage-hosted-game/manage-hosted-game.component';
import { PaymentConfirmCheckboxComponent } from '../payment-confirm-checkbox/payment-confirm-checkbox.component';
import { HIDE_PAYMENTS_MODAL } from '../../application/utils';
import { InviteOptionsComponent } from '../invite-options/invite-options.component';
import { ChatService, CreateSingleChatCommandHandler } from '@messages';
import { cardReasonMapper } from '@fair-play';

@Component({
  selector: 'lib-hosted-game-details',
  templateUrl: './hosted-game-details.component.html',
  styleUrls: ['./hosted-game-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TitileNavbarComponent,
    IonicModule,
    BottomNavbarComponent,
    ReactiveFormsModule,
    OrAvatarComponent,
    GameDetailsComponent,
    OrLoadingComponent,
    InviteOptionsComponent,
    RedirectToUserProfileDirective,
    FullHeightScrollableContainerDirective
  ],
  providers: [
    MarkAsPaidCommandHandler,
    GetHostedGameQueryHandler,
    ApproveApplicationsCommandHandler,
    provideHostedGameApplicationsService(),
    RejectApplicationsCommandHandler,
    provideLocalRefreshService(),
    OrLoadingService,
    CreateSingleChatCommandHandler,
    ChatService
  ]
})
export class HostedGameDetailsComponent {
  private readonly getHostedGameQueryHandler: GetHostedGameQueryHandler = inject(GetHostedGameQueryHandler);
  private readonly approveApplicationsCommandHandler: ApproveApplicationsCommandHandler = inject(
    ApproveApplicationsCommandHandler
  );
  private readonly rejectApplicationsCommandHandler: RejectApplicationsCommandHandler = inject(
    RejectApplicationsCommandHandler
  );
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly router: Router = inject(Router);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly localRefreshService: LocalRefreshService = inject(LocalRefreshService);
  private readonly globalRefreshService: GlobalRefreshService = inject(GlobalRefreshService);
  private readonly orLoadingService: OrLoadingService = inject(OrLoadingService);
  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);
  private readonly markAsPaidCommandHandler: MarkAsPaidCommandHandler = inject(MarkAsPaidCommandHandler);
  private readonly createSingleChatCommandHandler: CreateSingleChatCommandHandler =
    inject(CreateSingleChatCommandHandler);

  public cardReasonMapper = cardReasonMapper;
  public playersLeftExpanded: boolean = false;
  public invitationsRejectedExpanded: boolean = false;
  public preferredPosition: typeof PreferredPosition = PreferredPosition;

  readonly game$: Observable<GameDetailsModel> = this.localRefreshService.refresh$.pipe(
    switchMap(() => this.globalRefreshService.refresh$),
    switchMap(() => this.activatedRoute.params),
    switchMap((params: Params) => this.getHostedGameQueryHandler.getGame(params['id'])),
    tap(() => this.orLoadingService.hide()),
    tap(() => this.modalProvider.dismissLoading$()),
    shareReplay(1)
  );

  readonly isInviteOptionsVisible$: Observable<boolean> = combineLatest([
    this.game$,
    this.userDataProvider.userData$
  ]).pipe(map(([game, userData]: [GameDetailsModel, UserModel]) => this.caulculateOptionsVisible(game, userData)));

  constructor() {
    this.modalProvider.showLoading$();
  }

  acceptPlayer(gameId: string, gamePlayerApplicationId: string): void {
    this.orLoadingService.show();

    this.approveApplicationsCommandHandler
      .approve(gameId, [gamePlayerApplicationId])
      .pipe(take(1))
      .subscribe({
        next: () => this.refresh(),
        error: () => this.orLoadingService.hide()
      });
  }

  rejectApplication(gameId: string, gamePlayerApplicationId: string): void {
    this.modalProvider.showModal$({
      component: ActionModalComponent,
      cssClass: 'present-modal',
      componentProps: {
        header: 'Usuń gracza z gierki',
        message: 'Czy na pewno chcesz usunąć gracza z gierki?',
        btnOk: 'Tak, usuń gracza',
        btnCancel: 'Anuluj',
        action: () => {
          this.orLoadingService.show();
          this.rejectApplicationsCommandHandler
            .reject(gameId, [gamePlayerApplicationId])
            .pipe(take(1))
            .subscribe({
              next: () => this.refresh(),
              error: () => this.orLoadingService.hide()
            });
        }
      }
    });
  }

  managePlayer(gameId: string, player: GamePlayersDTO, isPlayerInGame: boolean): void {
    this.modalProvider
      .showModal$({
        component: ManagePlayerComponent,
        componentProps: {
          gameId: gameId,
          player: player,
          isPlayerInGame: isPlayerInGame ?? true
        },
        cssClass: 'modal-auto',
        initialBreakpoint: 1,
        breakpoints: [0, 1]
      })
      .subscribe(() => this.refresh());
  }

  manageGame(): void {
    this.game$
      .pipe(
        take(1),
        combineLatestWith(this.userDataProvider.userData$),
        take(1),
        switchMap(([game, userData]: [GameDetailsModel, UserModel]) =>
          this.modalProvider.showModal$({
            component: ManageHostedGameComponent,
            componentProps: {
              game: game,
              hostId: userData.userId
            },
            cssClass: 'modal-auto',
            initialBreakpoint: 1,
            breakpoints: [0, 1]
          })
        )
      )
      .subscribe(() => this.refresh());
  }

  sendMessage(userId: string) {
    if (userId) {
      const redirectToGameFromChatUrl: string = this.router.url;
      sessionStorage.setItem(CacheKey.RedirectToGameFromChatUrl, redirectToGameFromChatUrl);

      this.createSingleChatCommandHandler.create(userId).subscribe();
    }
  }

  changePaymentStatus(userId: string, isPaid: boolean) {
    const header: string[] = ['Oznacz jako zapłacone', 'Oznacz jako niezapłacone'];
    const message: string[] = [
      'Czy na pewno chcesz oznaczyć jako zapłacone?',
      'Czy na pewno chcesz oznaczyć jako niezapłacone?'
    ];

    const hideModal: { isHide: boolean } = JSON.parse(localStorage.getItem(HIDE_PAYMENTS_MODAL) as string);

    if (hideModal?.isHide) {
      this.modalProvider.dismissAllStoredModals();
      return this.markAsPaid(userId, isPaid);
    }

    return this.modalProvider.showModal$({
      component: ActionModalComponent,
      cssClass: 'present-modal',
      componentProps: {
        header: isPaid ? header[1] : header[0],
        message: isPaid ? message[1] : message[0],
        btnOk: 'Tak, potwierdzam',
        btnCancel: 'Anuluj',
        template: PaymentConfirmCheckboxComponent,
        action: () => this.markAsPaid(userId, isPaid)
      }
    });
  }

  refresh(): void {
    this.localRefreshService.emit();
  }

  private markAsPaid(userId: string, isPaid: boolean): void {
    this.game$
      .pipe(
        switchMap((game: GameDetailsModel) =>
          this.markAsPaidCommandHandler.markAsPaid(game.gameId, userId, isPaid).pipe(map(() => game))
        ),
        take(1)
      )
      .subscribe(() => this.refresh());
  }

  private caulculateOptionsVisible(game: GameDetailsModel, userData: UserModel): boolean {
    const isHostInGame: boolean =
      game?.players?.some((player: GamePlayersDTO) => player.userId === userData.userId) || false;

    const playersInGame: number | undefined = isHostInGame ? (game.players?.length || 0) - 1 : game.players?.length;

    return !playersInGame;
  }

  changePlayersLeftVisibility(): void {
    this.playersLeftExpanded = !this.playersLeftExpanded;
  }

  changeInvitationsRejectedVisibility() {
    this.invitationsRejectedExpanded = !this.invitationsRejectedExpanded;
  }

  summaryInvitations(rejected: number | undefined, active: number | undefined) {
    return (rejected || 0) + (active || 0) || 0;
  }
}
