<lib-title-navbar title="Osiągnięcia"></lib-title-navbar>

<div
  class="or-px-20-px or-pt-24-px"
  [class.full-height-scrollable-container]="!isiOS"
  [class.full-height-scrollable-container-ios]="isiOS">
  <div *ngFor="let trophy of allTrophies" class="or-d-flex or-align-items-center or-mb-12-px">
    <img [src]="trophiesMapper[trophy].icon" class="or-d-flex or-align-self-start or-mr-8-px" />
    <div>
      <div class="or-head-sm or-mb-4-px">{{ trophiesMapper[trophy].name }}</div>
      <div class="or-text-sm or-color-secondary-6">{{ trophiesMapper[trophy].description }}</div>
    </div>
  </div>
</div>

<lib-bottom-navbar></lib-bottom-navbar>
