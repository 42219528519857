export enum NotificationType {
  GameApplicationApproved = "game-application-approved",
  GameApplicationRejected = "game-application-rejected",
  GameReminder = "game-reminder",
  HostedGameReminder = "hosted-game-reminder",
  GameCanceled = "game-canceled",
  HostedGameNewApplication = "hosted-game-new-application",
  HostedGamePlayerLeft = "hosted-game-player-left",
  NewGameByAlert = "new-game-from-alert",
  GameFinished = "game-finished",
  HostedGameFinished = "hosted-game-finished",
  GamePlayerRemovedByHost = "game-player-removed-by-host",
  GameInvitationRecived = "game-invitation-received",
  ChatNewMessage = "chat-new-message",
  CustomNotification = "custom-notification",
  HostedGamePlayerAutoApproved = "hosted-game-player-auto-approved",
  TeamMemberAddedByHost = "team-member-added-by-host",
  UserPenaltyReceived = "user-penalty-received",
  GameLineupsCreated = "game-lineups-created",
  NewTrophyReceived = "new-trophy-received",
}
