import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { IsAuthedService } from '@core';
import { inject } from '@angular/core';
import { map, take } from 'rxjs';
import { RedirectStoragePath } from '@auth';

export const sharedGameResolver: ResolveFn<void> = (activatedRoute: ActivatedRouteSnapshot) => {
  const isAuthed: IsAuthedService = inject(IsAuthedService);
  const sharedGameId: string = activatedRoute.params['id'];

  isAuthed.isAuthed$
    .pipe(
      take(1),
      map((isAuthed: boolean) => {
        if (isAuthed) return sessionStorage.removeItem(RedirectStoragePath.SHARED_GAME);

        return sessionStorage.setItem(RedirectStoragePath.SHARED_GAME, sharedGameId);
      })
    )
    .subscribe();
};
