import { map, Observable, switchMap, take } from 'rxjs';
import { LocationDetailsModel } from '../interfaces';
import { inject } from '@angular/core';
import { LOCATION_DETAILS_DTO, LocationDetailsDtoPort } from '../../infrastructure/http-service';
import { ActivatedRoute, Params } from '@angular/router';
import { NearbyGamesGame, NerabyGamesDTO } from '@core';

export class GetLocationDetailsQueryHandler {
  private readonly locationDetailsDtoPort: LocationDetailsDtoPort = inject(LOCATION_DETAILS_DTO);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  getDetails(): Observable<LocationDetailsModel> {
    return this.activatedRoute.params.pipe(
      switchMap((params: Params) =>
        this.locationDetailsDtoPort.getLocationDetails(params['id']).pipe(
          take(1),
          map((data: NerabyGamesDTO) => {
            const today: Date = new Date();

            return {
              id: data.locationId,
              name: data.name,
              address: data.address,
              todayGames: data.games
                .filter((game: NearbyGamesGame) => this.isSameDate(today, new Date(game.gameDate)))
                .map((game: NearbyGamesGame) => {
                  return {
                    gameId: game.gameId,
                    name: game.name,
                    gameDate: game.gameDate,
                    gameDateEnd: game.gameDateEnd
                  };
                }),
              futureGames: data.games
                .filter(
                  (game: NearbyGamesGame) => new Date(game.gameDate).setHours(0, 0, 0, 0) > today.setHours(0, 0, 0, 0)
                )
                .map((game: NearbyGamesGame) => {
                  return {
                    gameId: game.gameId,
                    name: game.name,
                    gameDate: game.gameDate,
                    gameDateEnd: game.gameDateEnd
                  };
                })
            };
          })
        )
      )
    );
  }

  private isSameDate(today: Date, gameDate: Date): boolean {
    return (
      today.getDate() === gameDate.getDate() &&
      today.getMonth() === gameDate.getMonth() &&
      today.getFullYear() === gameDate.getFullYear()
    );
  }
}
