<div
  class="background or-d-flex or-flex-col or-justify-content-center or-align-items-center"
>
  <div
    *ngIf="isPopupVisible"
    class="popup-background or-position-absolute or-top-0 or-mx-20-px border-radius-20 or-mt-20-px or-p-12-px"
  >
    <div class="or-head-sm or-color-white or-mb-8-px or-text-align-center">
      Wchodzisz przez Messenger i masz już pobraną aplikacje?
    </div>
    <div class="or-text-sm or-color-white or-text-align-center">
      Kliknij na 3 kropki i wybierz “Otwórz przez Orlikfy” lub otwórz w
      zewnętrznej przeglądarce
    </div>
  </div>

  <img src="assets/icons/orlikfy-header.svg" class="or-w-200-px" />

  <div class="or-text-xl or-color-white or-py-24-px">
    Pozwól by gierki znajdowały się same!
  </div>

  <div class="or-d-flex or-justify-content-evenly or-w-100-pc or-mb-24-px">
    <img (click)="downloadAndroid()" src="assets/download-google-play.svg" />
    <img (click)="downloadiOS()" src="assets/download-app-store.svg" />
  </div>

  <button
    (click)="redirectToNativeApp()"
    class="button-primary-style or-p-12-px or-head-md or-cursor-pointer or-w-280-px"
  >
    <div class="or-d-flex or-flex-col">
      <span>Przejdź do aplikacji</span>
      <span class="or-color-white or-mt-4-px or-text-sm"
        >Dla osób z pobraną aplikacją</span
      >
    </div>
  </button>
</div>
