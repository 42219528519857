import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule, NgClass } from '@angular/common';

@Component({
  selector: 'lib-games-menu',
  templateUrl: './games-menu.component.html',
  styleUrls: ['./games-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonicModule, NgClass, CommonModule]
})
export class GamesMenuComponent {
  @Input() isActiveFuture: boolean = false;
  @Input() isActivePast: boolean = false;
  @Output() goToFutureEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() goToPastEvent: EventEmitter<void> = new EventEmitter<void>();

  goToFuture(): void {
    return this.goToFutureEvent.emit();
  }

  goToPast(): void {
    return this.goToPastEvent.emit();
  }
}
