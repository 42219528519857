<ion-radio-group (ionChange)="setType($event)" [value]="false">
  <div class="or-d-flex or-justify-content-center or-head-sm or-flex-col">
    <div
      class="or-align-items-center or-d-flex or-justify-content-evenly or-w-100-pc"
      [ngClass]="
        !(isRecurring$ | async)
          ? 'is-active or-bg-focus or-color-primary'
          : 'is-no-active or-bg-white'
      ">
      <ion-radio [value]="false" class="or-mx-20-px" labelPlacement="end" alignment="center">
        <div class="or-d-flex or-flex-col">
          <div
            class="or-head-md or-mb-4-px"
            [ngClass]="
              !(isRecurring$ | async) ? ' or-color-primary' : 'or-color-black'
            ">
            Pojedyncza gierka
          </div>
          <div class="or-note-sm white-space-normal">Wybierz, jeśli chcesz znaleźć paru graczy na jedną gierkę</div>
        </div></ion-radio
      >
    </div>

    <div
      class="or-align-items-center or-d-flex or-justify-content-evenly or-mt-12-px or-w-100-pc"
      [ngClass]="
        (isRecurring$ | async)
          ? 'is-active or-bg-focus or-color-primary'
          : 'is-no-active or-bg-white'
      ">
      <ion-radio [value]="true" class="or-mx-20-px" labelPlacement="end" alignment="center">
        <div class="or-d-flex or-flex-col">
          <div
            class="or-head-md or-mb-4-px"
            [ngClass]="
              (isRecurring$ | async) ? ' or-color-primary' : 'or-color-black'
            ">
            Powtarzalna gierka
          </div>
          <div class="or-note-sm white-space-normal">
            W przypadku chęci organizowania cotygodniowych gier. Oprócz dobrania osób na mapie,
            <span class="or-head-xs">możesz organizować całość gierki w aplikacji</span>
          </div>
        </div></ion-radio
      >
    </div>
  </div>
</ion-radio-group>
