import { TeamsService } from "../../infrastructure";
import { inject } from "@angular/core";

export class RemoveTeamMemberCommandHandler {
  private readonly teamsService: TeamsService = inject(TeamsService);

  remove(teamId: string, memberId: string) {
    return this.teamsService.removeMember(teamId, memberId);
  }
}
