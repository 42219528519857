import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from "@angular/core";
import { BottomNavbarComponent, TitileNavbarComponent } from "@ui-components";
import { IonicModule } from "@ionic/angular";
import { ActivatedRoute, ParamMap, Router, RouterLink } from "@angular/router";
import { StreamChatModule } from "stream-chat-angular";
import { AsyncPipe, NgClass, NgIf } from "@angular/common";
import { BehaviorSubject, Observable, switchMap, take, tap } from "rxjs";
import {
  MODAL_TOKEN,
  ModalProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
} from "@core";
import { ChatInitializer } from "../../application/initializers";
import { AppRoutes } from "src/app/app-routes.enum";
import { MessagesRoutes } from "../../messages-routes.enum";
import { ChannelTypeEnum } from "../../application/enums";
import { UnreadMessagesQueryHandler } from "../../application/handlers";

@Component({
  selector: "lib-chats-list",
  templateUrl: "./chats-list.component.html",
  styleUrls: ["./chats-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TitileNavbarComponent,
    BottomNavbarComponent,
    IonicModule,
    RouterLink,
    StreamChatModule,
    NgIf,
    AsyncPipe,
    NgClass,
  ],
})
export class ChatsListComponent implements OnInit {
  private readonly chatInitializer: ChatInitializer = inject(ChatInitializer);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly router: Router = inject(Router);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly activatedRouter: ActivatedRoute = inject(ActivatedRoute);
  private readonly unreadMessagesQueryHandler: UnreadMessagesQueryHandler =
    inject(UnreadMessagesQueryHandler);

  readonly activeChannelType$: BehaviorSubject<ChannelTypeEnum> =
    new BehaviorSubject<ChannelTypeEnum>(ChannelTypeEnum.SINGLE);

  readonly isChatLoaded$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  readonly isChatAvailable$: Observable<boolean> =
    this.chatInitializer.isChatAvailable$;

  readonly isUnreadMulti$: Observable<boolean> =
    this.unreadMessagesQueryHandler.isUnreadMulti();

  readonly isUnreadSingle$: Observable<boolean> =
    this.unreadMessagesQueryHandler.isUnreadSingle();

  readonly isUnreadTeam$: Observable<boolean> =
    this.unreadMessagesQueryHandler.isUnreadTeam();

  readonly isiOS: boolean = this.platformProvider.isiOS;
  readonly ChannelTypeEnum = ChannelTypeEnum;

  readonly emptyChatText: Record<ChannelTypeEnum, string> = {
    [ChannelTypeEnum.SINGLE]: "Nie masz prywatnych wiadomości.",
    [ChannelTypeEnum.MULTI]:
      "Dołącz do gierki lub stwórz swoją. Chat pojawi się automatycznie.",
    [ChannelTypeEnum.TEAM]:
      "Dołącz do grupki lub stwórz swoją. Chat pojawi się automatycznie.",
  };

  ngOnInit(): void {
    this.modalProvider
      .showLoading$()
      .pipe(
        switchMap(() => this.setChannelType()),
        switchMap(() => this.activeChannelType$),
        switchMap((channelType: ChannelTypeEnum) =>
          this.chatInitializer.init(channelType)
        ),
        tap(() => this.isChatLoaded$.next(true)),
        tap(() => this.modalProvider.dismissLoading$())
      )
      .subscribe();
  }

  openChannelType(channelType: ChannelTypeEnum): void {
    this.activeChannelType$.next(channelType);
  }

  openChannel(): void {
    const qParams: ChannelTypeEnum = this.activeChannelType$.value;

    this.router.navigateByUrl(
      `${AppRoutes.MESSAGES}/${MessagesRoutes.CHAT}?type=${qParams}`
    );
  }

  private setChannelType(): Observable<ParamMap> {
    return this.activatedRouter.queryParamMap.pipe(
      take(1),
      tap((paramMap: ParamMap) => {
        const type: ChannelTypeEnum = paramMap.get("type") as ChannelTypeEnum;
        this.activeChannelType$.next(type || ChannelTypeEnum.MULTI);
      })
    );
  }
}
