import { inject, InjectionToken } from '@angular/core';
import { AdMobService } from '../services';
import { BannerAdOptions, BannerAdPluginEvents } from '@capacitor-community/admob';
import { Observable } from 'rxjs';

export const ADS_TOKEN: InjectionToken<AdsProvider> = new InjectionToken<AdsProvider>('ADS_TOKEN', {
  factory: (): AdsProvider => {
    const adMobService: AdMobService = inject(AdMobService);

    return {
      bannerEvents$: adMobService.bannerEvents$,

      initialize(): void {
        adMobService.initialize();
      },
      showBanner(opts: BannerAdOptions): void {
        adMobService.showBanner(opts);
      },
      showInterstital(opts: BannerAdOptions): void {
        adMobService.showInterstital(opts);
      },
      showRewardVideo(opts: BannerAdOptions): void {
        adMobService.showRewardVideo(opts);
      },
      showRewardInterstitial(opts: BannerAdOptions): void {
        adMobService.showRewardInterstitial(opts);
      },
      resumeBanner(): void {
        adMobService.resumeBanner();
      },
      removeBanner(): void {
        adMobService.removeBanner();
      },
      hideBanner(): void {
        adMobService.hideBanner();
      }
    };
  }
});

export interface AdsProvider {
  initialize(): void;
  showBanner(opts?: BannerAdOptions): void;
  showInterstital(opts?: BannerAdOptions): void;
  showRewardVideo(opts?: BannerAdOptions): void;
  showRewardInterstitial(opts?: BannerAdOptions): void;
  resumeBanner(): void;
  removeBanner(): void;
  hideBanner(): void;
  bannerEvents$: Observable<BannerAdPluginEvents | null>;
}
