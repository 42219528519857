import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { MODAL_TOKEN, ModalProvider } from '@core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { BehaviorSubject, map, Observable, switchMap, take, tap } from 'rxjs';
import { Days } from './days.interface';
import { days } from './days.const';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/app-routes.enum';
import { NotificationsRoutes } from '../../../notifications-routes.enum';

@Component({
  selector: 'lib-specific-days-modal',
  templateUrl: './specific-days-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, IonicModule]
})
export class SpecificDaysModalComponent implements OnInit {
  @Input() selectedDays!: string[];

  private readonly modalToken: ModalProvider = inject(MODAL_TOKEN);
  private readonly router: Router = inject(Router);
  private readonly isNotifications: boolean = true;

  private readonly daysSubject: BehaviorSubject<Days[]> = new BehaviorSubject<Days[]>(days);

  readonly days$: Observable<Days[]> = this.daysSubject.asObservable();

  ngOnInit() {
    if (this.selectedDays?.length) {
      this.daysSubject
        .asObservable()
        .pipe(
          take(1),
          map((values: Days[]) =>
            values.map((v: Days) => ({
              ...v,
              checked: this.selectedDays.includes(v.id)
            }))
          )
        )
        .subscribe((updatedValues) => this.daysSubject.next(updatedValues));
    }
  }

  saveData() {
    this.days$
      .pipe(
        map((days: Days[]) => days.filter((day) => day.checked)),
        map((days: Days[]) => days.map((day) => day.id)),
        switchMap((checkedDays: string[]) => {
          const route = this.isNotifications
            ? [`${AppRoutes.NOTIFICATIONS}/${NotificationsRoutes.ALERTS}`]
            : [`${AppRoutes.FIND_GAMES}`];

          return this.router.navigate(route, {
            queryParams: { day: checkedDays }
          });
        })
      )
      .subscribe(() => this.closeModal());
  }

  closeModal() {
    this.modalToken.dismissModal$();
  }

  checkDays(id: string) {
    this.daysSubject
      .asObservable()
      .pipe(
        take(1),
        map((days: Days[]) =>
          days.map((day: Days) => {
            if (day.id === id) {
              return {
                ...day,
                checked: !day.checked
              };
            }
            return day;
          })
        ),
        tap((updatedDays: Days[]) => this.daysSubject.next(updatedDays))
      )
      .subscribe();
  }
}
