import { ChangeDetectionStrategy, Component, ElementRef, inject, ViewChild } from '@angular/core';
import {
  BlockedWebViewComponent,
  BottomNavbarComponent,
  GameDetailsComponent,
  TitileNavbarComponent
} from '@ui-components';
import { map, Observable, shareReplay, switchMap, tap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MapInitializer } from '../../../../../find-games/src/lib/application/map-initializer';
import { JoinButtonsComponent } from '../join-buttons/join-buttons.component';
import {
  CacheKey,
  FullHeightScrollableContainerDirective,
  GameDetailsModel,
  PLATFORM_TOKEN,
  PlatformProvider,
  USER_DATA_TOKEN,
  UserDataProvider,
  UserRoleEnum
} from '@core';
import { AppRoutes } from 'src/app/app-routes.enum';
import { GamesRoutes } from '../../games-routes.enum';
import { CreateSingleChatCommandHandler } from '@messages';
import { GameDetailsQueryHandler } from '../../application/handlers';

@Component({
  selector: 'lib-shared-game',
  templateUrl: './shared-game.component.html',
  styleUrls: ['./shared-game.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TitileNavbarComponent,
    BottomNavbarComponent,
    CommonModule,
    JoinButtonsComponent,
    GameDetailsComponent,
    BlockedWebViewComponent,
    FullHeightScrollableContainerDirective
  ],
  providers: [CreateSingleChatCommandHandler]
})
export class SharedGameComponent {
  @ViewChild('map') map: ElementRef = {} as ElementRef;

  private readonly gameDetailsQueryHandler: GameDetailsQueryHandler = inject(GameDetailsQueryHandler);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly mapInitializer: MapInitializer = inject(MapInitializer);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly router: Router = inject(Router);
  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);
  private readonly createSingleChatCommandHandler: CreateSingleChatCommandHandler =
    inject(CreateSingleChatCommandHandler);

  readonly allowToView$: Observable<boolean> = this.userDataProvider.userData$.pipe(
    map((userData) => userData.role === UserRoleEnum.ADMIN || this.platformProvider.isNativeRun)
  );

  readonly gameDetails$: Observable<GameDetailsModel> = this.activatedRoute.params.pipe(
    switchMap((params: Params) => this.gameDetailsQueryHandler.getGameDetails(params['id'])),
    tap((game: GameDetailsModel) => {
      this.mapInitializer.initMap({
        mapContainerName: this.map.nativeElement,
        startCoordinates: game.location.coordinates,
        zoom: 15
      });
      this.mapInitializer.addMarker({
        coordinates: game.location.coordinates,
        showCounter: false,
        isUserLocation: false
      });
    }),
    shareReplay(1)
  );

  readonly redirectPath$: Observable<string> = this.activatedRoute.params.pipe(
    map((params: Params) => `${AppRoutes.GAMES}/${GamesRoutes.SHARED}/${params['id']}`)
  );

  applicationSent(gameId: string) {
    this.router.navigateByUrl(`${AppRoutes.GAMES}/${GamesRoutes.DETAILS}/${gameId}`);
  }

  sendMessage(userId: string): void {
    const redirectToGameFromChatUrl: string = this.router.url;
    sessionStorage.setItem(CacheKey.RedirectToGameFromChatUrl, redirectToGameFromChatUrl);

    this.createSingleChatCommandHandler.create(userId).subscribe();
  }
}
