import { Directive, HostListener, inject, Input } from '@angular/core';
import { PLATFORM_TOKEN, PlatformProvider } from '../tokens';
import { CoordinatesDTO } from '../interfaces';

@Directive({
  standalone: true,
  selector: '[navigate]'
})
export class NavigateDirective {
  @Input('navigate') coordinates!: CoordinatesDTO;

  private platform: PlatformProvider = inject(PLATFORM_TOKEN);

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    event.stopImmediatePropagation();

    this.openNavigate();
  }

  private openNavigate(): void {
    if (this.platform.isAndroid) {
      const url: string = `google.navigation:q=${this.coordinates.latitude},${this.coordinates.longitude}`;
      window.open(url, '_blank');
    } else if (this.platform.isiOS) {
      const url: string = `maps://maps.google.com/maps?daddr=${this.coordinates.latitude},${this.coordinates.longitude}&amp;ll=`;
      // const url = `http://maps.apple.com/?ll=${this.coordinates.latitude},${this.coordinates.longitude}`;
      window.open(url, '_blank');
    } else {
      const url: string = `https://www.google.com/maps/dir/?api=1&destination=${this.coordinates.latitude},${this.coordinates.longitude}`;
      window.open(url, '_blank');
    }
  }
}
