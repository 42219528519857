<div class="or-my-32-px or-mx-24-px">
  <div class="or-d-flex or-justify-content-center">
    <span class="or-head-xl or-mb-16-px">Wybierz dni</span>
  </div>
  <div class="or-d-flex or-flex-col or-mb-24-px or-ml-60-px">
    <div *ngFor="let day of days$ | async">
      <ion-checkbox labelPlacement="end" [checked]="day.checked" (ionChange)="checkDays(day.id)"
        ><span class="or-text-lg">{{ day.name }}</span></ion-checkbox
      >
    </div>
    <div></div>
  </div>
  <div class="or-d-flex or-justify-content-center">
    <button class="button-secondary-style or-h-48-px or-w-60-pc or-head-md or-mr-8-px" (click)="closeModal()">
      Anuluj
    </button>
    <button class="button-primary-style or-h-48-px or-w-60-pc or-head-md" (click)="saveData()">Zapisz</button>
  </div>
</div>
