import { inject, InjectionToken } from '@angular/core';
import { from, Observable, of, switchMap } from 'rxjs';
import { PLATFORM_TOKEN, PlatformProvider } from './platform.token';
import { CoordinatesDTO } from '../interfaces';

export const GEOLOCATION_TOKEN: InjectionToken<GeolocationProvider> = new InjectionToken<GeolocationProvider>(
  'GEOLOCATION_TOKEN',
  {
    factory: (): GeolocationProvider => {
      const platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);

      return {
        getCoordinates(): Observable<CoordinatesDTO | null> {
          return from(platformProvider.isPermissionGranted()).pipe(
            switchMap((permission) => {
              if (permission) {
                return new Observable<CoordinatesDTO>((observer) => {
                  navigator.geolocation.getCurrentPosition(
                    (position: GeolocationPosition) => {
                      observer.next({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                      });
                      observer.complete();
                    },
                    (error) => {
                      observer.error(error);
                    }
                  );
                });
              }
              return of(null);
            })
          );
        }
      };
    }
  }
);

export interface GeolocationProvider {
  getCoordinates(): Observable<CoordinatesDTO | null>;
}
