import { AngularFirestore } from "@angular/fire/compat/firestore";
import { inject } from "@angular/core";
import { map, Observable } from "rxjs";
import { firestoreGetDataUtil } from "../utils";

export class AllowToWebViewService {
  private readonly firestore: AngularFirestore = inject(AngularFirestore);

  allowedUsers(): Observable<string[]> {
    return firestoreGetDataUtil<{ users: string[] }>(
      this.firestore,
      "block-web-view",
      "allowed-users"
    ).pipe(map((data: { users: string[] }) => data.users));
  }
}
