<div class="or-d-flex or or-justify-content-between or-head-md">
  <button (click)="setPeriod(7)" class="button-primary-style or-h-32-px or-head-md">7 dni</button>
  <button (click)="setPeriod(14)" class="button-primary-style or-h-32-px or-head-md">14 dni</button>
  <button (click)="setPeriod(30)" class="button-primary-style or-h-32-px or-head-md">30 dni</button>
</div>

<div class="custom-divider or-my-24-px or-w-100-pc"></div>

<div *ngFor="let game of games$ | async">
  <div class="border-radius-4 border-primary or-bg-white or-cursor-pointer or-p-12-px or-mb-8-px or-text-lg">
    <div><span class="or-head-lg">{{ game?.host?.displayName }}</span> | {{ game?.name }}</div>
    <div>{{ game?.location?.name }}</div>
    <div>{{ game.gameDateStart | dateFormat : game.gameDateEnd }}</div>
    <div class="or-d-flex or or-justify-content-between">
      <div>{{ game?.visibility }}</div>

      <div>{{ game?.occupiedSlots }}/{{ game?.totalSlots }} ({{ game?.playerApplications?.length }})</div>
      <div>{{ game?.priceAmount }} PLN</div>
    </div>

    <div
      *ngIf="game?.isOrganizedByPaidHost || game?.isOrganizedByHost"
      [ngClass]="
        game?.isOrganizedByPaidHost ? 'or-color-red' : 'or-color-primary'
      "
      class="or-head-md">
      {{ game?.isOrganizedByPaidHost ? "Paid host" : "Regular host" }}
    </div>

    <div>{{ game?.isRecurring ? "Powtarzalna" : "Pojedyncza" }}</div>

    <div class="custom-divider or-my-12-px or-w-100-pc"></div>

    <div class="or-d-flex or or-justify-content-between or-my-8-px or-head-md">
      <button (click)="goToDetails(game.gameId)" class="button-primary-style or-h-32-px or-head-md">Szczegóły</button>
      <button [copyToClipboard]="game?.host?.hostId" class="button-primary-style or-h-32-px or-head-md">
        ID Hosta
      </button>
      <button [copyToClipboard]="game.gameId" class="button-primary-style or-h-32-px or-head-md">ID Gry</button>
    </div>
  </div>
</div>
