import { inject } from '@angular/core';
import { map, Observable } from 'rxjs';
import { UsersListDetailsDTO, UsersListDTO, UsersSummaryModel } from '../models';
import { PlatformType } from '@core';
import { USERS_LIST_DTO, UsersListDtoPort } from '../../infrastructure/http-services';

export class AdminUsersSummaryDataQueryHandler {
  private readonly usersListDtoPort: UsersListDtoPort = inject(USERS_LIST_DTO);

  summaryData(): Observable<UsersSummaryModel> {
    return this.usersListDtoPort.getAllUsers().pipe(
      map((data: UsersListDTO) => data.users),
      map((users: UsersListDetailsDTO[]) => users.filter((user: UsersListDetailsDTO) => !!user.versionInfo)),
      map((users: UsersListDetailsDTO[]) => {
        const { iOsCount, androidCount, webCount, mobilewebCount, isHostCount, isPaidHostCount, versionCounts } =
          this.calculateCount(users);

        return {
          totalUsers: users.length,
          iOsCount: iOsCount,
          androidCount: androidCount,
          mobilewebCount: mobilewebCount,
          webCount: webCount,
          version: versionCounts,
          isHost: isHostCount,
          isPaidHost: isPaidHostCount
        };
      })
    );
  }

  versionEntries(version: Record<string, number | null>): { key: string; value: number | null }[] {
    return Object.entries(version)
      .map(([key, value]) => ({ key, value }))
      .sort((a, b) => {
        return this.sortVersions(a, b);
      });
  }

  private calculateCount(users: UsersListDetailsDTO[]) {
    const iOsCount: number = users.filter((user: UsersListDetailsDTO) => user.platform === PlatformType.IOS)?.length;
    const androidCount: number = users.filter(
      (user: UsersListDetailsDTO) => user.platform === PlatformType.ANDROID
    )?.length;
    const webCount: number = users.filter((user: UsersListDetailsDTO) => user.platform === PlatformType.WEB)?.length;
    const mobilewebCount: number = users.filter(
      (user: UsersListDetailsDTO) => user.platform === PlatformType.MOBILE_WEB
    )?.length;

    const isHostCount: number = users.filter((user: UsersListDetailsDTO) => user.isHost)?.length;
    const isPaidHostCount: number = users.filter((user: UsersListDetailsDTO) => user.isPaidHost)?.length;
    const versionCounts: Record<string, number> = users.reduce((acc, user) => {
      if (acc[user.versionInfo]) {
        acc[user.versionInfo] += 1;
      } else {
        acc[user.versionInfo] = 1;
      }
      return acc;
    }, {} as Record<string, number>);
    return {
      iOsCount,
      androidCount,
      webCount,
      mobilewebCount,
      isHostCount,
      isPaidHostCount,
      versionCounts
    };
  }

  private sortVersions(a: { value: number | null; key: string }, b: { value: number | null; key: string }) {
    if (a.key === 'null') return 1;
    if (b.key === 'null') return -1;
    if (a.key === 'null' && b.key === 'null') return 0;

    const partsA = a.key.split('.').map(Number);
    const partsB = b.key.split('.').map(Number);

    for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
      const partA = partsA[i] || 0;
      const partB = partsB[i] || 0;

      if (partA > partB) return -1;
      if (partA < partB) return 1;
    }

    return 0;
  }
}
