import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { PlatformProvider, PLATFORM_TOKEN } from '../tokens';
import { AppVersion } from '../utils';

export class AppInfoInterceptor implements HttpInterceptor {
  private platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const addAppInfo = req.clone({
      headers: req.headers.set('x-app-version', AppVersion).set('x-app-platform', this.platformProvider.platformType)
    });

    return next.handle(addAppInfo);
  }
}
