import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ProfileRouting } from './profile.routing';
import { ReferrerHttpService, UserService } from './infrastructure';

@NgModule({
  imports: [RouterModule.forChild(ProfileRouting)],
  providers: [UserService, ReferrerHttpService]
})
export class ProfileModule {}
