<ng-container *ngIf="userDetails$ | async as userDetails">
  <ng-container *ngIf="isPageLoaded$ | async">
    <lib-title-navbar
      [showSettings]="userDetails.isMyProfile"
      (onSettingsClicked)="goToSettings()"
      [title]="userDetails.isMyProfile ? 'Mój profil' : 'Profil'"
    ></lib-title-navbar>

    <div class="top-container"></div>

    <div
      class="or-d-flex or-position-relative or-justify-content-center z-index-999 negative-top-margin-35-px"
    >
      <lib-or-avatar
        [avatarUrl]="userDetails.thumbnail"
        [avatarSize]="'lg'"
        [initials]="userDetails.displayName"
        [rightMargin]="false"
      ></lib-or-avatar>
    </div>

    <div
      class="or-d-flex or-flex-col or-align-items-center or-mt-12-px or-mb-4-px"
    >
      <div class="or-d-flex or-align-items-center">
        <div class="or-head-lg">{{ userDetails.displayName }}</div>
        <ng-container *ngIf="userDetails.rate > 0">
          <img
            class="or-ml-12-px or-mr-4-px or-size-16-px or-mb-4-px"
            src="assets/icons/star-fill.svg"
          />
          <div class="or-head-xs">{{ userDetails.rate.toFixed(2) }}</div>
        </ng-container>
      </div>
      <div
        *ngIf="!userDetails.isMyProfile"
        class="or-text-sm or-color-secondary-2"
      >
        Aktywny {{ calculatelastActive(userDetails.lastActive) }}
      </div>
    </div>

    <div
      (click)="openNotificationPermissions()"
      *ngIf="!(isNotificationPermissionsEnabled$ | async)"
      class="or-d-flex or-justify-content-center or-mt-12-px"
    >
      <div
        class="or-bg-shade-2 or-p-4-px border-black border-radius-8 or-text-md"
      >
        Włącz powiadomienia w aplikacji
      </div>
    </div>

    <div *ngIf="userDetails.isMyProfile" class="or-px-16-px or-mt-12-px">
      <lib-alert-bar></lib-alert-bar>
    </div>

    <div [ngClass]="calculateClass(userDetails.isMyProfile)">
      <div class="or-mt-8-px">
        <lib-user-stats [userStats]="userDetails.userStats"></lib-user-stats>
      </div>

      <div class="or-mt-24-px or-px-24-px">
        <lib-player-preferences
          [playerPreferences]="userDetails.playerPreferences"
        ></lib-player-preferences>
      </div>

      <div
        *ngIf="userDetails.penaltyHistory?.length || userDetails.activePenalty"
        class="or-px-20-px or-mt-24-px"
      >
        <lib-card-history
          [penaltyHistory]="userDetails.penaltyHistory"
          [activePenalty]="userDetails.activePenalty"
        ></lib-card-history>
      </div>

      <ng-container *ngIf="trophiesFF$ | async">
        <lib-trophies></lib-trophies>
      </ng-container>

      <lib-user-comments></lib-user-comments>
    </div>
  </ng-container>
</ng-container>
<lib-bottom-navbar></lib-bottom-navbar>
