import { ChangeDetectionStrategy, Component, inject, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  BottomNavbarComponent,
  CloseAdBannerComponent,
  GameCardV2Component,
  GamesMenuComponent,
  RedirectTypeEnum,
  TitileNavbarComponent
} from '@ui-components';
import { AppRoutes } from 'src/app/app-routes.enum';
import { HostedGamesRoutes } from '../../hosted-games.routes.enum';
import { BehaviorSubject, Observable, shareReplay, switchMap, take, tap } from 'rxjs';
import { GetHostedPastGamesQueryHandler } from '../../application/handlers';
import { Router } from '@angular/router';
import {
  ADS_TOKEN,
  AdsProvider,
  AdsUnitId,
  FullHeightScrollableContainerDirective,
  GameDetailsModel,
  MODAL_TOKEN,
  ModalProvider,
  PLATFORM_TOKEN,
  PlatformProvider
} from '@core';
import { CreateGameViewService } from '../../application/view-services';
import { FeatureFlag, FeatureFlagHandler } from '@feature-flags';
import { BannerAdSize } from '@capacitor-community/admob/dist/esm/banner/banner-ad-size.enum';
import { BannerAdPosition } from '@capacitor-community/admob/dist/esm/banner/banner-ad-position.enum';

@Component({
  selector: 'lib-past-future-games',
  templateUrl: './hosted-past-games.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    BottomNavbarComponent,
    GamesMenuComponent,
    TitileNavbarComponent,
    FullHeightScrollableContainerDirective,
    GameCardV2Component,
    CloseAdBannerComponent
  ],
  providers: [GetHostedPastGamesQueryHandler]
})
export class HostedPastGamesComponent implements OnInit {
  @ViewChild(FullHeightScrollableContainerDirective)
  containerDirective!: FullHeightScrollableContainerDirective;

  public redirectType: RedirectTypeEnum = RedirectTypeEnum.HOSTED;

  private readonly getHostedPastGamesQueryHandler: GetHostedPastGamesQueryHandler =
    inject(GetHostedPastGamesQueryHandler);
  private readonly router: Router = inject(Router);
  private readonly createGameViewService: CreateGameViewService = inject(CreateGameViewService);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly adsProvider: AdsProvider = inject(ADS_TOKEN);
  private readonly featureFlagHandler: FeatureFlagHandler = inject(FeatureFlagHandler);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);

  private showPlaceholderSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly isPageLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  readonly games$: Observable<Map<string, GameDetailsModel[]>> = this.modalProvider.showLoading$().pipe(
    switchMap(() => this.getHostedPastGamesQueryHandler.getGames()),
    tap((games: Map<string, GameDetailsModel[]>) => this.showPlaceholderSubject.next(games?.size === 0)),
    tap(() => this.isPageLoaded$.next(true)),
    tap(() => this.modalProvider.dismissLoading$()),
    shareReplay(1)
  );

  readonly showPlaceholder$: Observable<boolean> = this.showPlaceholderSubject.asObservable();

  ngOnInit(): void {
    this.initBottomBanner();
  }

  goToFutureGames(): void {
    this.router.navigate([`${AppRoutes.HOST}/${HostedGamesRoutes.FUTURE}`]);
  }

  goToPastGames(): void {
    this.router.navigate([`${AppRoutes.HOST}/${HostedGamesRoutes.PAST}`]);
  }

  addSimilarGame(game: GameDetailsModel): void {
    this.createGameViewService.setGame(game);
    this.router.navigateByUrl(`${AppRoutes.HOST}/${HostedGamesRoutes.CREATE_GAME}`);
  }

  private initBottomBanner(): void {
    const bottomMargin: number = this.platformProvider.isiOS ? 50 : 70;

    this.featureFlagHandler
      .isEnabled$(FeatureFlag.AD_MOB)
      .pipe(
        take(1),
        tap((isEnabled: boolean) => {
          if (isEnabled)
            return this.adsProvider.showBanner({
              adSize: BannerAdSize.BANNER,
              position: BannerAdPosition.BOTTOM_CENTER,
              adId: AdsUnitId.BannerV1,
              margin: bottomMargin
            });

          return;
        })
      )
      .subscribe();
  }
}
