<div class="or-d-flex or-justify-content-center">
  <div
    class="card-box-shadow border-radius-4 or-p-8-px played-games-bg or-w-100-pc or-d-flex or-mr-16-px or-align-items-center or-justify-content-center">
    <div class="or-head-xxl or-color-white or-mr-8-px">{{ userStats.playedGames }}</div>
    <div class="or-text-sm or-color-white or-d-flex or-flex-col">
      <div>Rozegranych</div>
      <div>gierek</div>
    </div>
  </div>

  <div
    class="card-box-shadow border-radius-4 or-p-8-px hosted-games-bg or-w-100-pc or-d-flex or-align-items-center or-justify-content-center">
    <div class="or-head-xxl or-color-white or-mr-8-px">{{ userStats.hostedGames }}</div>
    <div class="or-text-sm or-color-white or-d-flex or-flex-col">
      <div>Zorganizowanych</div>
      <div>gierek</div>
    </div>
  </div>
</div>
