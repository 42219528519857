import { LocationDetailsDtoPort } from './location-details.dto-port';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { ENV_CONFIG, EnvConfig, NerabyGamesDTO } from '@core';

export class LocationDetailsService implements LocationDetailsDtoPort {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  getLocationDetails(id: string): Observable<NerabyGamesDTO> {
    return this.httpClient.get<NerabyGamesDTO>(`${this.env.apiUrl}/location/${id}/games`);
  }
}
