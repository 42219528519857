import { AppRoutes } from 'src/app/app-routes.enum';
import { TabsDataModel } from './tabs-data.interface';

export const tabsData: TabsDataModel[] = [
  {
    route: AppRoutes.DASHBOARD,
    name: 'Home',
    icon: '',
    id: AppRoutes.DASHBOARD
  },
  {
    route: AppRoutes.FIND_GAMES,
    name: 'Szukaj gierek',
    icon: '',
    id: AppRoutes.FIND_GAMES
  },
  {
    route: AppRoutes.MESSAGES,
    name: 'Wiadomości',
    icon: '',
    id: AppRoutes.MESSAGES
  },
  {
    route: AppRoutes.PROFILE,
    name: 'Profil',
    icon: '',
    id: AppRoutes.PROFILE
  }
];
