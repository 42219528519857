<div (click)="redirect()" class="or-p-16-px wm72h-container border-radius-4 or-cursor-pointer">
  <div class="or-d-flex or-justify-content-between or-align-items-center">
    <div class="or-d-flex or-align-items-center">
      <img src="assets/wm72h/1818.png" class="or-mr-16-px or-p-12-px or-bg-white border-radius-4 or-size-48-px" />
      <div>
        <div class="or-head-md or-mb-4-px">Wielki 72H Mecz</div>
        <div class="or-text-sm wm72h-color">
          Razem tworzymy historię, jednocześnie wspierając tych, którzy tego potrzebują!
        </div>
      </div>
    </div>
    <img src="assets/wm72h/chevron-right.svg" />
  </div>
</div>
