import { GameLevel } from '../enums';

export const gameLevelMapper: Record<GameLevel, string> = {
  [GameLevel.BEGINNER]: 'Rekreacyjny',
  [GameLevel.INTERMEDIATE_BEGINNER]: 'Średnio-Rekreacyjny',
  [GameLevel.INTERMEDIATE]: 'Średni',
  [GameLevel.ADVANCED]: 'Wysoki',
  [GameLevel.PROFESSIONAL]: 'Zawodowy'
};

export const reversedGameLevelMapper: Record<string, GameLevel> = Object.entries(gameLevelMapper).reduce(
  (acc, [key, value]) => {
    acc[value] = GameLevel[key as keyof typeof GameLevel];
    return acc;
  },
  {} as Record<string, GameLevel>
);
