import { AfterViewInit, ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import {
  AreFiltersDefaultQueryHandler,
  GetFilterDaysQueryHandler,
  GetFiltersQueryHandler,
  SetFilterCommandHandler
} from '../../application/handlers';
import { Observable, shareReplay } from 'rxjs';
import { CalendarHeader } from '../../application/interfaces';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { GameLevel, gameLevelMapper, LocationTypeEnum } from '@core';
import { SelectableMultiCheckboxModel } from '@ui-components';
import { FiltersValues } from '../../application/models';
import { FiltersViewService } from './view-service/filters.view-service';

@Component({
  selector: 'lib-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgForOf, AsyncPipe, NgClass, NgIf],
  providers: [
    GetFilterDaysQueryHandler,
    SetFilterCommandHandler,
    AreFiltersDefaultQueryHandler,
    GetFiltersQueryHandler,
    FiltersViewService
  ]
})
export class FiltersComponent implements OnInit, AfterViewInit {
  @Input() showDistanceFilter: boolean = true;
  @Input() gamesCount!: number | undefined;

  protected readonly gameLevelMapper = gameLevelMapper;

  private readonly getFilterDaysQueryHandler: GetFilterDaysQueryHandler = inject(GetFilterDaysQueryHandler);
  private readonly getFiltersQueryHandler: GetFiltersQueryHandler = inject(GetFiltersQueryHandler);
  private readonly areFiltersDefaultQueryHandler: AreFiltersDefaultQueryHandler = inject(AreFiltersDefaultQueryHandler);
  private readonly filtersViewService: FiltersViewService = inject(FiltersViewService);

  readonly gameCount$: Observable<number> = this.filtersViewService.gameCount$;

  readonly daysRange$: Observable<CalendarHeader[]> = this.getFilterDaysQueryHandler.filterDays();

  readonly savedFilters$: Observable<FiltersValues> = this.getFiltersQueryHandler.filters().pipe(shareReplay(1));

  readonly areFiltersDefault$: Observable<boolean> = this.areFiltersDefaultQueryHandler.areDefault();

  readonly availableGameLevels$: Observable<SelectableMultiCheckboxModel<GameLevel>[]> =
    this.filtersViewService.availableGameLevels$;

  readonly availableLocationTypes$: Observable<SelectableMultiCheckboxModel<LocationTypeEnum>[]> =
    this.filtersViewService.availableLocationTypes$;

  ngOnInit(): void {
    this.filtersViewService.initialize(this.gamesCount);
  }

  ngAfterViewInit(): void {
    this.filtersViewService.removeDistanceFilter();
  }

  openDistanceFilter(): void {
    this.filtersViewService.openDistanceFilter();
  }

  openGameLevel(): void {
    this.filtersViewService.openGameLevel();
  }

  openLocationType(): void {
    this.filtersViewService.openLocationType();
  }

  setDate(data: CalendarHeader): void {
    this.filtersViewService.setDate(data);
  }

  getGameLevelName(gameLevel: GameLevel[]): string {
    return this.filtersViewService.getGameLevelName(gameLevel);
  }

  getLocationTypeName(locationType: LocationTypeEnum[]): string {
    return this.filtersViewService.getLocationTypeName(locationType);
  }

  resetFilters(): void {
    this.filtersViewService.resetFilters();
  }
}
