import { Routes } from '@angular/router';
import { HostedGamesRoutes } from './hosted-games.routes.enum';
import {
  CreateGameComponent,
  HostedFutureGamesComponent,
  HostedGameDetailsComponent,
  HostedPastGamesComponent,
  InvitePlayersComponent
} from './components';
import { createGameGuard } from './application/guards';

export const HostedGamesRouting: Routes = [
  {
    path: HostedGamesRoutes.ROOT,
    redirectTo: HostedGamesRoutes.FUTURE,
    pathMatch: 'full'
  },
  {
    path: HostedGamesRoutes.FUTURE,
    component: HostedFutureGamesComponent
  },
  { path: HostedGamesRoutes.PAST, component: HostedPastGamesComponent },
  {
    path: `${HostedGamesRoutes.FUTURE}/:id`,
    component: HostedGameDetailsComponent
  },
  {
    path: `${HostedGamesRoutes.FUTURE}/:id/${HostedGamesRoutes.INVITE}`,
    component: InvitePlayersComponent
  },

  {
    path: HostedGamesRoutes.CREATE_GAME,
    component: CreateGameComponent,
    canActivate: [createGameGuard]
  }
];
