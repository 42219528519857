import { StartupState } from './startup.state';
import { Observable, OperatorFunction } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { EnvironmentInjector, runInInjectionContext } from '@angular/core';

export type StepFunction<T extends StartupState> = (state: T) => Observable<T>;

export function addStepMethod<T extends StartupState>(
  injector: EnvironmentInjector,
  step: StepFunction<T>
): OperatorFunction<T, T> {
  return (source: Observable<T>) => {
    return source.pipe(switchMap((s: T) => runInInjectionContext(injector, () => step(s))));
  };
}
