import { inject } from '@angular/core';
import { ReferrerHttpService } from '../../infrastructure';

export class SetReferrerCommandHandler {
  private readonly referrerHttpService: ReferrerHttpService = inject(ReferrerHttpService);

  setReferrer(referrerToken: string) {
    return this.referrerHttpService.setReferrer(referrerToken);
  }
}
