export enum AdsUnitId {
  BannerV1 = 'ca-app-pub-8749138706409071/8568402530',
  FullScreenV1 = 'ca-app-pub-8749138706409071/2035881259',

  TestBanner = 'ca-app-pub-3940256099942544/6300978111',
  TestInterstital = 'ca-app-pub-3940256099942544/1033173712',
  TestRewardVideo = 'ca-app-pub-3940256099942544/5224354917',
  TestRewardInterstitial = 'ca-app-pub-3940256099942544/5354046379',
  TestAppOpen = 'ca-app-pub-| `3940256099942544/3419835294'
}
