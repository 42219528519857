import { Observable } from "rxjs";
import {
  HOSTED_GAMES_DTO,
  HostedGamesDTOPort,
} from "../../infrastructure/http-service";
import { inject } from "@angular/core";

export class RemoveGameCommandHandler {
  private readonly hostedGamesDTOPort: HostedGamesDTOPort =
    inject(HOSTED_GAMES_DTO);

  removeGame(gameId: string): Observable<void> {
    return this.hostedGamesDTOPort.removeGame(gameId);
  }
}
