import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from "@angular/core";
import { AsyncPipe, NgClass, NgForOf } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { BehaviorSubject, map, Observable } from "rxjs";
import { MODAL_TOKEN, ModalProvider } from "@core";
import { SingleRadioItemModel } from "./single-radio-item.model";

@Component({
  selector: "lib-selectable-single-radio-modal",
  templateUrl: "./selectable-single-radio-modal.component.html",
  styleUrls: ["./selectable-single-radio-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, IonicModule, NgClass, NgForOf],
})
export class SelectableSingleRadioModalComponent implements AfterViewInit {
  @Input() header!: string;
  @Input() buttonText!: string;
  @Input() providedData!: SingleRadioItemModel[];
  @Input() selectedData!: SingleRadioItemModel;

  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  readonly providedData$: BehaviorSubject<SingleRadioItemModel[]> =
    new BehaviorSubject<SingleRadioItemModel[]>([]);
  readonly selectedData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  ngAfterViewInit(): void {
    this.providedData$.next(this.providedData);
    this.selectedData$.next(this.selectedData);
  }

  isSelected(value: any): Observable<boolean> {
    return this.selectedData$
      .asObservable()
      .pipe(map((data: SingleRadioItemModel[]) => data === value));
  }

  setData(event: CustomEvent): void {
    const distance = event.detail.value;

    this.selectedData$.next(distance);
  }

  closeModal(): void {
    this.modalProvider.dismissModal$(this.selectedData$.value);
  }
}
