<lib-title-navbar title="Wiadomości"></lib-title-navbar>

<ng-container
  *ngIf="{
    channelType: activeChannelType$ | async,
    isChatLoaded: isChatLoaded$ | async,
    isChatAvailable: isChatAvailable$ | async,
  } as data">
  <div class="header-bg-color or-d-flex or-justify-content-evenly margin-top--1-px">
    <div
      (click)="openChannelType(ChannelTypeEnum.TEAM)"
      class="or-head-sm or-py-8-px or-px-8-px negative-margin-bottom-1-px"
      [ngClass]="
        data.channelType === ChannelTypeEnum.TEAM
          ? 'or-color-white bottom-border-active'
          : 'or-color-secondary-2'
      ">
      <img
        *ngIf="isUnreadTeam$ | async"
        src="assets/icons/unread-icon.svg"
        class="or-position-absolute z-index-999 or-ml-56-px" />
      Grupki
    </div>
    <div
      (click)="openChannelType(ChannelTypeEnum.MULTI)"
      class="or-head-sm or-py-8-px or-px-8-px negative-margin-bottom-1-px"
      [ngClass]="
        data.channelType === ChannelTypeEnum.MULTI
          ? 'or-color-white bottom-border-active'
          : 'or-color-secondary-2'
      ">
      <img
        *ngIf="isUnreadMulti$ | async"
        src="assets/icons/unread-icon.svg"
        class="or-position-absolute z-index-999 or-ml-48-px" />
      Gierki
    </div>
    <div
      (click)="openChannelType(ChannelTypeEnum.SINGLE)"
      class="or-head-sm or-py-8-px or-px-8-px negative-margin-bottom-1-px"
      [ngClass]="
        data.channelType === ChannelTypeEnum.SINGLE
          ? 'or-color-white bottom-border-active'
          : 'or-color-secondary-2'
      ">
      <img
        *ngIf="isUnreadSingle$ | async"
        src="assets/icons/unread-icon.svg"
        class="or-position-absolute z-index-999 or-ml-72-px" />
      Prywatne
    </div>
  </div>

  <div
    *ngIf="data.isChatLoaded"
    [ngClass]="
      isiOS
        ? 'full-height-scrollable-container-ios'
        : 'full-height-scrollable-container'
    ">
    <stream-channel-list (click)="openChannel()" *ngIf="data.isChatAvailable; else emptyChatList"></stream-channel-list>
  </div>
  <lib-bottom-navbar></lib-bottom-navbar>

  <ng-template #emptyChatList>
    <div class="or-justify-content-center or-d-flex or-flex-col or-h-100-pc or-px-32-px">
      <div class="or-text-align-center or-head-lg">Twój chat jest pusty.</div>
      <div class="or-text-align-center or-text-lg">{{ emptyChatText[data.channelType ?? ChannelTypeEnum.SINGLE] }}</div>
    </div>
  </ng-template>
</ng-container>
