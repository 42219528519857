import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AppRoutes } from 'src/app/app-routes.enum';
import { Router } from '@angular/router';
import { AppVersion, PLATFORM_TOKEN, PlatformProvider } from '@core';
import { UpdateService } from '../infrastructure/update.service';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { getNewVersionNative } from '../application/utils';

@Component({
  selector: 'lib-blocked',
  templateUrl: './blocked-version.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe]
})
export class BlockedVersionPage {
  private readonly router: Router = inject(Router);
  private readonly updateService: UpdateService = inject(UpdateService);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);

  readonly newestVersion$: Observable<string> = this.updateService.newestVersion();

  readonly appVersion = AppVersion;

  goToApp(): void {
    this.router.navigate([`/${AppRoutes.ROOT}`]);
  }

  getApk(): void {
    getNewVersionNative(this.platformProvider);
  }
}
