import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { CopyService, CopyValueToClipboardDirective, GameDetailsModel, NavigateDirective } from '@core';
import { IonicModule } from '@ionic/angular';
import { NgClass, NgIf } from '@angular/common';
import { OrAvatarComponent } from '../or-avatar/or-avatar.component';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/app-routes.enum';
import { GamesRoutes } from '../../../../games/src/lib/games-routes.enum';
import { HostedGamesRoutes } from '../../../../hosted-games/src/lib/hosted-games.routes.enum';
import { RedirectTypeEnum } from './enums/redirect-type.enum';
import { ShareGameDirective } from '../share-game';

@Component({
  selector: 'lib-game-card',
  templateUrl: './game-card.component.html',
  styleUrls: ['./game-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IonicModule,
    NgIf,
    OrAvatarComponent,
    NavigateDirective,
    CopyValueToClipboardDirective,
    ShareGameDirective,
    NgClass
  ],
  providers: [CopyService]
})
export class GameCardComponent {
  @Input({ required: true }) game!: GameDetailsModel;
  @Input() showMoreDetails: boolean = false;
  @Input() showHostInfo: boolean = false;
  @Input() showPhoneNumber: boolean = true;
  @Input() showShareButton: boolean = false;
  @Input() showVisibility: boolean = false;
  @Input() showAddSimilarGame: boolean = false;
  @Input() adjustToChip: boolean = false;
  @Input() redirectType!: RedirectTypeEnum;

  @Output() onAddSimilarGameClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() onSendMessage: EventEmitter<string> = new EventEmitter<string>();

  private readonly router: Router = inject(Router);

  goToDetails(): void {
    if (this.redirectType === RedirectTypeEnum.GAME) {
      this.router.navigate([`${AppRoutes.GAMES}/${GamesRoutes.DETAILS}/${this.game.gameId}`]);
    }

    if (this.redirectType === RedirectTypeEnum.HOSTED) {
      this.router.navigate([`${AppRoutes.HOST}/${HostedGamesRoutes.FUTURE}/${this.game.gameId}`]);
    }
  }

  similarGameClicked(event: MouseEvent): void {
    event.stopImmediatePropagation();
    this.onAddSimilarGameClicked.emit();
  }

  sendMessage(userId: string | undefined, event: Event): void {
    event.stopImmediatePropagation();
    this.onSendMessage.emit(userId);
  }
}
