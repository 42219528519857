import { filter, first, map, Observable, of, switchMap, tap } from 'rxjs';
import { StartupState } from '../startup.state';
import { IsAuthedService, USER_DATA_TOKEN, UserDataProvider } from '@core';
import { inject } from '@angular/core';
import { StartupStep } from '../startup-step';

export class ConnectAuthStep extends StartupStep {
  private readonly isAuthedService: IsAuthedService = inject(IsAuthedService);
  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);

  executeStep(state: StartupState): Observable<StartupState> {
    return this.isAuthedService.isAuthed$.pipe(
      filter(Boolean),
      first(),
      tap(() => (state.authed$ = of(true))),
      tap(() => this.userDataProvider.setUserData()),
      map(() => state)
    );
  }
}
