import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { OrAvatarComponent } from '@ui-components';
import { switchMap, take } from 'rxjs';
import {
  ActionModalComponent,
  GameDetailsModel,
  GameStatusEnum,
  MODAL_TOKEN,
  ModalProvider,
  NavigateDirective
} from '@core';
import { AppRoutes } from 'src/app/app-routes.enum';
import { Router } from '@angular/router';
import { provideGameApplicationsService } from '../../infrastructure/http-service';
import { RemoveApplicationCommandHandler } from '../../application/handlers';
import { LeaveGameWithAlertComponent } from '../leave-game-with-alert/leave-game-with-alert.component';
import { GamesRoutes } from '../../games-routes.enum';

@Component({
  selector: 'lib-my-hosted-game',
  templateUrl: './manage-my-game.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, OrAvatarComponent, NavigateDirective, AsyncPipe],
  providers: [RemoveApplicationCommandHandler, provideGameApplicationsService()]
})
export class ManageMyGameComponent {
  @Input() game!: GameDetailsModel;

  private readonly router: Router = inject(Router);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly removeApplicationCommandHandler: RemoveApplicationCommandHandler = inject(
    RemoveApplicationCommandHandler
  );

  leaveGameModal(): void {
    this.modalProvider.showModal$({
      component: ActionModalComponent,
      cssClass: 'present-modal',
      componentProps: {
        header: 'Rezygnacja z gierki',
        message: 'Czy na pewno chcesz zrezygnować z gierki?',
        btnOk: 'Zrezygnuj',
        btnCancel: 'Anuluj',
        action: () => (this.canLeave() ? this.leaveGame() : this.leaveGameWithAlert())
      }
    });
  }

  leaveGameWithAlert(): void {
    this.modalProvider.showModal$({
      component: ActionModalComponent,
      cssClass: 'present-modal',
      componentProps: {
        header: 'Opuszczasz gierkę 3 godziny przed jej rozpoczęciem',
        message: '',
        btnOk: 'Opuść gierkę',
        btnCancel: 'Anuluj',
        action: () => this.leaveGame(),
        template: LeaveGameWithAlertComponent
      }
    });
  }

  private leaveGame(): void {
    this.removeApplicationCommandHandler
      .remove(this.game.gameId, this.game.gameStatus as GameStatusEnum)
      .pipe(
        take(1),
        switchMap(() => this.router.navigate([AppRoutes.GAMES]))
      )
      .subscribe();
  }

  private canLeave(): boolean {
    if (this.game.gameStatus === GameStatusEnum.Applied) return true;

    const now: Date = new Date();
    const gameStart: Date = new Date(this.game.gameDateStart);

    const timeDifference = gameStart.getTime() - now.getTime();
    const threeHoursInMilliseconds = 3 * 60 * 60 * 1000;

    return timeDifference > threeHoursInMilliseconds;
  }

  goToLineups(): void {
    this.modalProvider.dismissAllStoredModals();
    this.router.navigate([`${AppRoutes.GAMES}/${GamesRoutes.LINEUPS}/${this.game.gameId}`]);
  }
}
