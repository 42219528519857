import { BehaviorSubject, map, Observable, of, take, tap } from "rxjs";
import { FiltersValues } from "../../application/models";

export class FiltersStorage {
  private startValue: FiltersValues = {} as FiltersValues;

  private filtersSubject: BehaviorSubject<FiltersValues> =
    new BehaviorSubject<FiltersValues>(this.startValue);

  filters$: Observable<FiltersValues> = this.filtersSubject.asObservable();

  constructor() {
    this.filters$.subscribe((x) => console.log(x));
  }

  setFilters(data: Partial<FiltersValues>): Observable<void> {
    return this.filtersSubject.asObservable().pipe(
      take(1),
      tap((state) => this.filtersSubject.next({ ...state, ...data })),
      map(() => void 0)
    );
  }

  reset(): Observable<void> {
    this.filtersSubject.next(this.startValue);
    return of(void 0);
  }
}
