<lib-title-navbar [showBackArrow]="true" title="Ranking - Pytania i Odpowiedzi"></lib-title-navbar>
<div class="or-mx-16-px">
  <div class="or-d-flex or-flex-col or-align-items-center or-mt-32-px">
    <img src="assets/icons/ranks.svg" />

    <div class="or-head-lg or-mt-16-px">Czym jest Ranking?</div>
    <div class="or-text-md or-color-secondary-2 or-text-align-center or-mt-16-px">
      W ORLIKFY wierzymy, że piłka nożna to coś więcej niż gra – to pasja, emocje i rywalizacja. Chcemy dać Wam
      możliwość nie tylko czerpania radości z gry na orliku, ale także zdobywania realnych nagród! Dzięki nam granie
      stanie się nie tylko świetną zabawą, ale również szansą na osiągnięcie konkretnych korzyści. Dołącz do nas i
      odkryj, jak gra może zmieniać się w coś wyjątkowego!
    </div>
  </div>

  <div class="or-mt-24-px or-p-12-px or-bg-white border-radius-4">
    <div class="or-head-sm or-mb-8-px">W jaki sposób możesz zdobywać punkty?</div>
    <div class="or-ml-8-px">
      <li class="or-text-sm or-color-secondary-2">
        Stworzenie grupki z powyżej 20 graczami: <br />
        <span class="or-ml-28-px">50 punktów </span>
      </li>
      <li class="or-text-sm or-color-secondary-2">
        Zorganizowanie gierki:<br />
        <span class="or-ml-28-px"> 3 graczy - 10 punktów, </span><br />
        <span class="or-ml-28-px"> 6 graczy - 20 punktów</span>
      </li>
      <li class="or-text-sm or-color-secondary-2">Zagranie w gierce - 10 punktów</li>
      <li class="or-text-sm or-color-secondary-2">Komplement - 20 punktów</li>
      <li class="or-text-sm or-color-secondary-2">Osiągnięcie - 40 punktów</li>
      <li class="or-text-sm or-color-secondary-2">Żółta kartka - <span class="or-color-red">-20 punktów</span></li>
      <li class="or-text-sm or-color-secondary-2">Czerwona kartka - <span class="or-color-red">-50 punktów</span></li>
    </div>
  </div>
</div>
