<lib-title-navbar title="Generuj post"></lib-title-navbar>
<div class="or-mt-20-px">
  <div class="or-d-flex or-justify-content-evenly or-mb-20-px">
    <button (click)="setDay(3)" class="button-primary-style or-head-md or-p-12-px">3</button>
    <button (click)="setDay(4)" class="button-primary-style or-head-md or-p-12-px">4</button>
    <button (click)="setDay(5)" class="button-primary-style or-head-md or-p-12-px">5</button>
    <button (click)="setDay(6)" class="button-primary-style or-head-md or-p-12-px">6</button>
    <button (click)="setDay(7)" class="button-primary-style or-head-md or-p-12-px">7</button>
  </div>
  <div class="or-d-flex or-justify-content-evenly or-mb-24-px or-align-items-center">
    <div class="or-head-xl">Ilość gierek: {{ (games$ | async)?.length }}</div>
    <button
      (click)="copy()"
      class="button-primary-style or-h-48-px or-w-30-pc or-head-md"
      [class.disabled-button]="!(games$ | async)?.length">
      Skopiuj post
    </button>
  </div>
  <div *ngFor="let game of games$ | async">
    <div>{{ game }}</div>
    <br />
    <br />
  </div>
</div>
