<lib-title-navbar
  title="Organizowane gierki"
  [showAddGame]="true"
  (onAddGameOpened)="goToGameCreator()"></lib-title-navbar>
<div class="top-container"></div>

<div class="margin-top--24-px">
  <lib-games-menu
    (goToFutureEvent)="goToFutureGames()"
    (goToPastEvent)="goToPastGames()"
    [isActiveFuture]="true"></lib-games-menu>
</div>

<div
  class="or-mt-24-px"
  [ngClass]="{
    'or-align-items-center or-d-flex or-justify-content-center':
      showPlaceholder$ | async
  }"
  [class.full-height-scrollable-container]="!isiOS"
  [class.full-height-scrollable-container-ios]="isiOS">
  <div *ngFor="let game of games$ | async" class="or-mb-12-px">
    <lib-hosted-future-games-list [game]="game"></lib-hosted-future-games-list>
  </div>

  <ng-container *ngIf="isPageLoaded$ | async">
    <div *ngIf="showPlaceholder$ | async" class="or-head-sm">
      <div class="or-mb-24-px">Nie organizujesz żadnych gierek.</div>

      <button class="button-primary-style or-h-48-px or-w-100-pc or-head-md" (click)="goToGameCreator()">
        Dodaj gierkę
      </button>
    </div>
  </ng-container>
</div>

<lib-bottom-navbar></lib-bottom-navbar>
