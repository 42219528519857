import { ResolveFn } from "@angular/router";
import { inject } from "@angular/core";
import { LoadFutureGamesCommandHandler } from "../handlers";

export const loadFuturePlayerGamesResolver: ResolveFn<void> = () => {
  const loadFutureGamesCommandHandler: LoadFutureGamesCommandHandler = inject(
    LoadFutureGamesCommandHandler
  );

  loadFutureGamesCommandHandler.load().subscribe();
};
