<lib-title-navbar title="Szczegóły gierki" [showDots]="true" (onDotsClicked)="manageGame()"></lib-title-navbar>

<div
  class="or-p-16-px or-bg-white"
  [ngClass]="
    isiOS
      ? 'full-height-scrollable-container-ios'
      : 'full-height-scrollable-container'
  ">
  <ng-container *ngIf="game$ | async as game">
    <lib-game-details [game]="game" [showGameTypeDetails]="true" [showGoToChat]="true"></lib-game-details>

    <div class="or-mt-12-px">
      <div *ngIf="game.playersApplications?.length" class="or-head-xs or-color-secondary-2 or-mt-24-px or-mb-12-px">
        Oczekujący na akceptację
      </div>
      <ng-container *ngFor="let player of game.playersApplications; let i = index">
        <div class="or-d-flex or-justify-content-between">
          <div class="or-d-flex or-align-items-center" [redirectToUserProfile]="player.userId">
            <lib-or-avatar
              [avatarUrl]="player.userThumbnail"
              [initials]="player.userDisplayName"
              [avatarSize]="'sm'"></lib-or-avatar>

            <div class="or-head-sm or-d-flex or-align-items-center">
              {{ i + 1 }}.&nbsp;<span class="or-truncate-at-line-1">{{ player.userDisplayName }}</span>
            </div>
            <div class="or-d-flex or-align-items-center or-ml-8-px">
              <img
                *ngIf="
                  player.playerPreferences?.preferredPosition === 'GOALKEEPER'
                "
                class="or-mr-4-px"
                src="assets/icons/goalkeeper.svg" />
              <img *ngIf="player.penalty" [src]="cardReasonMapper[player.penalty.penaltyReason].icon" />
            </div>
          </div>
          <div class="or-d-flex or-align-items-center">
            <img
              src="assets/icons/trash-icon.svg"
              class="or-mr-4-px"
              (click)="
                rejectApplication(game.gameId, player.gamePlayerApplicationId)
              " />
            <img
              (click)="sendMessage(player.userId)"
              src="assets/icons/message-application-icon.svg"
              class="or-mr-4-px" />
            <img
              src="assets/add-button.svg"
              class="or-size-32-px"
              (click)="
                acceptPlayer(game.gameId, player.gamePlayerApplicationId)
              " />
          </div>
        </div>

        <div class="or-d-flex or-justify-content-center or-my-4-px">
          <div class="custom-divider-hosted-details or-my-4-px"></div></div
      ></ng-container>

      <div *ngIf="game.players?.length" class="or-head-xs or-color-secondary-2 or-mt-24-px or-mb-12-px">
        Lista graczy
      </div>

      <ng-container *ngFor="let player of game.players; let i = index">
        <div class="or-d-flex or-justify-content-between">
          <div class="or-d-flex or-align-items-center" [redirectToUserProfile]="player.userId">
            <lib-or-avatar
              [avatarUrl]="player.userThumbnail"
              [initials]="player.userDisplayName"
              [avatarSize]="'sm'"></lib-or-avatar>

            <div class="or-head-sm or-truncate-at-line-1">{{ i + 1 }}. {{ player.userDisplayName }}</div>
            <div class="or-d-flex or-align-items-center or-ml-8-px">
              <img
                *ngIf="
                  player.playerPreferences?.preferredPosition === 'GOALKEEPER'
                "
                class="or-mr-4-px"
                src="assets/icons/goalkeeper.svg" />
              <img *ngIf="player.penalty" [src]="cardReasonMapper[player.penalty.penaltyReason].icon" />
            </div>
          </div>
          <div class="or-d-flex or-justify-content-end or-align-items-center">
            <img
              *ngIf="!player.isHost && player.userId"
              (click)="sendMessage(player.userId)"
              src="assets/icons/message-icon.svg"
              class="or-mr-4-px" />
            <img
              (click)="changePaymentStatus(player.playerId, player.isPaid)"
              src="assets/icons/payment-icon.svg"
              [ngClass]="{ disabled: !player.isPaid }" />
            <img
              class="or-pl-12-px"
              (click)="managePlayer(game.gameId, player, true)"
              src="assets/icons/multi-dots-icon.svg" />
          </div>
        </div>

        <div class="or-d-flex or-justify-content-center or-my-4-px">
          <div class="custom-divider-hosted-details or-my-4-px"></div></div
      ></ng-container>

      <div
        *ngIf="game.gamePlayersLeft?.length"
        (click)="changePlayersLeftVisibility()"
        class="or-mt-24-px or-mb-12-px or-d-flex or-justify-content-between or-align-items-center">
        <div class="or-head-xs or-color-secondary-2">Gracze którzy opuścili gierkę</div>
        <img
          [src]="
            playersLeftExpanded
              ? 'assets/icons/chevron-up.svg'
              : 'assets/icons/chevron-down-primary.svg'
          " />
      </div>
      <ng-container *ngIf="playersLeftExpanded">
        <ng-container *ngTemplateOutlet="playersLeft"></ng-container>
      </ng-container>

      <div
        *ngIf="game.invitationsRejected?.length"
        (click)="changeInvitationsRejectedVisibility()"
        class="or-mt-24-px or-mb-12-px or-d-flex or-justify-content-between or-align-items-center">
        <div class="or-head-xs or-color-secondary-2">
          Gracze którzy odrzucili zaproszenie ({{ game.invitationsRejected.length }}/{{ summaryInvitations(
          game.invitationsRejected.length, game.invitations.length ) }})
        </div>
        <img
          [src]="
            invitationsRejectedExpanded
              ? 'assets/icons/chevron-up.svg'
              : 'assets/icons/chevron-down-primary.svg'
          " />
      </div>
      <ng-container *ngIf="invitationsRejectedExpanded">
        <ng-container *ngTemplateOutlet="invitationsRejected"></ng-container>
      </ng-container>
    </div>

    <ng-container *ngIf="isInviteOptionsVisible$ | async">
      <div class="or-mt-24-px">
        <lib-invite-options [game]="game" (onActionPerformed)="refresh()"></lib-invite-options>
      </div>
    </ng-container>

    <ng-template #playersLeft>
      <ng-container *ngFor="let player of game.gamePlayersLeft; let i = index">
        <div class="or-d-flex or-justify-content-between">
          <div class="or-d-flex or-align-items-center" [redirectToUserProfile]="player.userId">
            <lib-or-avatar
              [avatarUrl]="player.userThumbnail"
              [initials]="player.userDisplayName"
              [avatarSize]="'sm'"></lib-or-avatar>

            <div class="or-head-sm or-truncate-at-line-1">{{ i + 1 }}. {{ player.userDisplayName }}</div>
            <div class="or-d-flex or-align-items-center or-ml-8-px">
              <img
                *ngIf="
                  player.playerPreferences?.preferredPosition === 'GOALKEEPER'
                "
                class="or-mr-4-px"
                src="assets/icons/goalkeeper.svg" />
              <img *ngIf="player.penalty" [src]="cardReasonMapper[player.penalty.penaltyReason].icon" />
            </div>
          </div>

          <div class="or-d-flex or-justify-content-end or-align-items-center">
            <img
              *ngIf="!player.isHost && player.userId"
              (click)="sendMessage(player.userId)"
              src="assets/icons/message-icon.svg" />
            <img
              class="or-pl-12-px"
              (click)="managePlayer(game.gameId, player, false)"
              src="assets/icons/multi-dots-icon.svg" />
          </div>
        </div>

        <div class="or-d-flex or-justify-content-center or-my-4-px">
          <div class="custom-divider-hosted-details or-my-4-px"></div></div
      ></ng-container>
    </ng-template>

    <ng-template #invitationsRejected>
      <ng-container *ngFor="let player of game.invitationsRejected; let i = index">
        <div class="or-d-flex or-justify-content-between">
          <div class="or-d-flex or-align-items-center" [redirectToUserProfile]="player.userId">
            <lib-or-avatar
              [avatarUrl]="player.userThumbnail"
              [initials]="player.userDisplayName"
              [avatarSize]="'sm'"></lib-or-avatar>

            <div class="or-head-sm or-truncate-at-line-1">{{ i + 1 }}. {{ player.userDisplayName }}</div>
            <div class="or-d-flex or-align-items-center or-ml-8-px">
              <img *ngIf="player.penalty" [src]="cardReasonMapper[player.penalty.penaltyReason].icon" />
            </div>
          </div>

          <div class="or-d-flex or-justify-content-end or-align-items-center">
            <img (click)="sendMessage(player.userId)" src="assets/icons/message-icon.svg" />
          </div>
        </div>

        <div class="or-d-flex or-justify-content-center or-my-4-px">
          <div class="custom-divider-hosted-details or-my-4-px"></div></div
      ></ng-container>
    </ng-template>
  </ng-container>
</div>

<lib-bottom-navbar></lib-bottom-navbar>

<or-loading></or-loading>
