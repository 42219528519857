import { BehaviorSubject, Observable, of } from "rxjs";
import { FutureGameDTO } from "../../application/interfaces";

const INITIAL_STATE: FutureGameDTO = {
  approved: [],
  waiting: [],
  invitations: [],
};

export class FutureGamesStorage {
  private readonly gamesSubject: BehaviorSubject<FutureGameDTO> =
    new BehaviorSubject<FutureGameDTO>(INITIAL_STATE);

  readonly games$: Observable<FutureGameDTO> = this.gamesSubject.asObservable();

  setContext(data: FutureGameDTO): Observable<void> {
    return of(this.gamesSubject.next(data));
  }
}
