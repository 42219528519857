import { AfterViewInit, ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { INTRODUCTION_DTO, IntroductionDtoPort } from '../../infrastructure';
import { USER_DATA_TOKEN, UserDataProvider } from '@core';
import { delay, of, switchMap, take } from 'rxjs';
import { IntroductionEnum } from '../../application/enums';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/app-routes.enum';
import { slideInOutFromRight } from '@ui-components';
import { NgIf } from '@angular/common';

@Component({
  templateUrl: './intro-teams.component.html',
  styleUrls: ['../introduction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  animations: [slideInOutFromRight],
  imports: [NgIf]
})
export class IntroTeamsComponent implements OnInit, AfterViewInit {
  private readonly introductionDtoPort: IntroductionDtoPort = inject(INTRODUCTION_DTO);
  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);
  private readonly router: Router = inject(Router);

  showElement = true;

  ngOnInit() {
    this.userDataProvider.userData$
      .pipe(
        take(1),
        switchMap((userData) => this.introductionDtoPort.markAsViewed(IntroductionEnum.INTRO_TEAMS, userData.userId))
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
  }

  goToMap(): void {
    this.showElement = false;

    of(null)
      .pipe(
        take(1),
        delay(100),
        switchMap(() => this.router.navigateByUrl(AppRoutes.ROOT))
      )
      .subscribe();
  }
}
