import { Observable, of } from 'rxjs';
import { StartupState } from '../startup.state';
import { inject } from '@angular/core';
import { StartupStep } from '../startup-step';
import { ADS_TOKEN, AdsProvider } from '@core';

export class InitAdsStep extends StartupStep {
  private readonly adsProvider: AdsProvider = inject(ADS_TOKEN);

  executeStep(state: StartupState): Observable<StartupState> {
    this.adsProvider.initialize();
    return of(state);
  }
}
