import { SocialMetaTagInfo } from '../interfaces';
import { DynamicLinkType } from '../enums';

export const socialMetaTagInfoMapper: Record<DynamicLinkType, SocialMetaTagInfo> = {
  [DynamicLinkType.GAME]: {
    socialTitle: 'Orlikfy',
    socialDescription: 'Pozwól by gierki znajdowały się same!',
    socialImageLink:
      'https://firebasestorage.googleapis.com/v0/b/orlikfy-infra-prod.appspot.com/o/assets%2Fsocial-link-image-v5.jpeg?alt=media&token=92205f42-c539-4db1-913d-761bd5aabdfe'
  },
  [DynamicLinkType.REFERRAL]: {
    socialTitle: 'Orlikfy',
    socialDescription: 'Pozwól by gierki znajdowały się same!',
    socialImageLink:
      'https://firebasestorage.googleapis.com/v0/b/orlikfy-infra-prod.appspot.com/o/assets%2Fsocial-link-image-v5.jpeg?alt=media&token=92205f42-c539-4db1-913d-761bd5aabdfe'
  },
  [DynamicLinkType.TEAM_INVITATION]: {
    socialTitle: 'Orlikfy',
    socialDescription: 'Wbijaj do naszej grupki!',
    socialImageLink:
      'https://firebasestorage.googleapis.com/v0/b/orlikfy-infra-prod.appspot.com/o/assets%2Fteam-invitation.png?alt=media&token=e763eaf7-7db8-4e08-88df-c3a747ff9cd8'
  }
};
