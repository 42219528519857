import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TitileNavbarComponent } from '@ui-components';
import { Router } from '@angular/router';
import { AdminPanelRoutes } from '../../admin-panel.routes.enum';
import { AppRoutes } from 'src/app/app-routes.enum';

@Component({
  templateUrl: './admin-home.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TitileNavbarComponent]
})
export class AdminHomePage {
  private readonly router: Router = inject(Router);

  goToDeveloperSettings(): void {
    this.router.navigate([`${AppRoutes.ADMIN_PANEL}/${AdminPanelRoutes.DEVELOPER_SETTINGS}`]);
  }

  goToUsers(): void {
    this.router.navigate([`${AppRoutes.ADMIN_PANEL}/${AdminPanelRoutes.USERS}`]);
  }

  goToGames(): void {
    this.router.navigate([`${AppRoutes.ADMIN_PANEL}/${AdminPanelRoutes.GAMES}`]);
  }

  goToGeneratePost() {
    this.router.navigate([`${AppRoutes.ADMIN_PANEL}/${AdminPanelRoutes.GENERATE_POST}`]);
  }
}
