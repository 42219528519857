import { map, Observable, switchMap, tap } from 'rxjs';
import {
  FEATURE_FLAGS_CONTEXT,
  FEATURE_FLAGS_DTO,
  FeatureFlagsContextPort,
  FeatureFlagsDtoPort
} from '../../infrastructure';
import { inject } from '@angular/core';
import { FeatureFlagsRecord } from '../models';

export class LoadFeatureFlagsCommandHandler {
  private readonly featureFlagsDTO: FeatureFlagsDtoPort = inject(FEATURE_FLAGS_DTO);
  private readonly featureFlagsContext: FeatureFlagsContextPort = inject(FEATURE_FLAGS_CONTEXT);

  loadFlags(): Observable<number> {
    return this.featureFlagsDTO.getFlags().pipe(
      switchMap((flags: FeatureFlagsRecord) => this.featureFlagsContext.setState(flags)),
      map((flags: FeatureFlagsRecord) => Object.keys(flags)?.length)
    );
  }
}
