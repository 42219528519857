import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from "@angular/core";
import { NgIf } from "@angular/common";
import { switchMap, take, tap } from "rxjs";
import { Share } from "@capacitor/share";
import { inviteTeamText } from "../../application/utils";
import { GenerateInviteUrlCommandHandler } from "../../application/handlers";
import { CopyService, MODAL_TOKEN, ModalProvider } from "@core";
import { TeamInviteData } from "../../application/models";

@Component({
  selector: "lib-share-team-modal",
  templateUrl: "./share-team-modal.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf],
  providers: [GenerateInviteUrlCommandHandler, CopyService],
})
export class ShareTeamModalComponent {
  private readonly generateInviteUrlCommandHandler: GenerateInviteUrlCommandHandler =
    inject(GenerateInviteUrlCommandHandler);
  private readonly copyService: CopyService = inject(CopyService);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  @Input() teamData!: TeamInviteData;

  advancedShare(): void {
    this.modalProvider
      .showLoading$()
      .pipe(
        switchMap(() =>
          this.generateInviteUrlCommandHandler.generate(
            this.teamData.teamId,
            this.teamData.token
          )
        ),
        take(1),
        tap((deeplink: string) => {
          return Share.share({
            text: inviteTeamText(deeplink),
            url: deeplink,
          });
        }),
        tap(() => this.modalProvider.dismissLoading$()),
        switchMap(() => this.modalProvider.dismissModal$())
      )
      .subscribe();
  }

  copyGameLink() {
    this.modalProvider
      .showLoading$()
      .pipe(
        switchMap(() =>
          this.generateInviteUrlCommandHandler.generate(
            this.teamData.teamId,
            this.teamData.token
          )
        ),
        take(1),
        switchMap((deeplink: string) =>
          this.copyService.copyToClipboard(inviteTeamText(deeplink))
        ),
        tap(() => this.modalProvider.dismissLoading$()),
        switchMap(() => this.modalProvider.dismissModal$())
      )
      .subscribe();
  }
}
