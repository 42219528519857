import { Observable, switchMap, take } from 'rxjs';
import { inject } from '@angular/core';
import { NOTIFICATIONS_ALERTS_DTO, NotificationsAlertsDTOPort } from '../../infrastructure';
import { CoordinatesDTO, GEOLOCATION_TOKEN, GeolocationProvider } from '@core';

export class UpdateAlertsLocationCommandHandler {
  private notificationsAlertsDtoPort: NotificationsAlertsDTOPort = inject(NOTIFICATIONS_ALERTS_DTO);
  private geolocationProvider: GeolocationProvider = inject(GEOLOCATION_TOKEN);

  setLocation(): Observable<void> {
    return this.geolocationProvider.getCoordinates().pipe(
      take(1),
      switchMap((coordinates: CoordinatesDTO | null) => this.notificationsAlertsDtoPort.setLocation(coordinates))
    );
  }
}
