import { Routes } from '@angular/router';
import { HostedGamesRoutes } from './hosted-games.routes.enum';
import {
  CreateGameComponent,
  HostedFutureGamesComponent,
  HostedGameDetailsComponent,
  HostedPastGamesComponent,
  InvitePlayersComponent,
  ReassignGameComponent
} from './components';
import { createGameGuard } from './application/guards';
import { reassignGameResolver } from './application/resolvers';
import { isAuthedGuard } from '@auth';
import { blockWebVersionGuard } from '@core';

export const HostedGamesRouting: Routes = [
  {
    path: HostedGamesRoutes.ROOT,
    redirectTo: HostedGamesRoutes.FUTURE,
    pathMatch: 'full'
  },
  {
    path: HostedGamesRoutes.FUTURE,
    component: HostedFutureGamesComponent,
    canActivate: [isAuthedGuard, blockWebVersionGuard]
  },
  {
    path: HostedGamesRoutes.PAST,
    component: HostedPastGamesComponent,
    canActivate: [isAuthedGuard, blockWebVersionGuard]
  },
  {
    path: `${HostedGamesRoutes.FUTURE}/:id`,
    component: HostedGameDetailsComponent,
    canActivate: [isAuthedGuard, blockWebVersionGuard]
  },
  {
    path: `${HostedGamesRoutes.FUTURE}/:id/${HostedGamesRoutes.INVITE}`,
    component: InvitePlayersComponent,
    canActivate: [isAuthedGuard, blockWebVersionGuard]
  },
  {
    path: HostedGamesRoutes.CREATE_GAME,
    component: CreateGameComponent,
    canActivate: [createGameGuard, isAuthedGuard, blockWebVersionGuard]
  },
  { path: `${HostedGamesRoutes.REASSIGN}/:gameId`, component: ReassignGameComponent, resolve: { reassignGameResolver } }
];
