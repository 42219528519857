import { Component, inject, OnInit } from "@angular/core";
import { StartupV1Service } from "./startup-v1.service";

@Component({
  selector: "app-component",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  private readonly startupService: StartupV1Service = inject(StartupV1Service);

  ngOnInit(): void {
    this.startupService.init();
  }
}
