import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { OrLoadingComponent, OrLoadingService, TitileNavbarComponent } from '@ui-components';
import { IonicModule } from '@ionic/angular';
import { BehaviorSubject, combineLatest, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { CommonModule } from '@angular/common';
import {
  ActionModalComponent,
  availableLevels,
  GameLevel,
  gameLevelMapper,
  MODAL_TOKEN,
  ModalProvider,
  NAVIGATION_TOKEN,
  NavigationProvider,
  NOTIFICATION_TOKEN,
  NotificationPermissionProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
  PresentModalComponent
} from '@core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AlertsSettingsDTO } from '../../application/interfaces';
import { GetAlertsSettingsQueryHandler, UpdateAlertsSettingsCommandHandler } from '../../application/handlers';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import { SpecificDaysModalComponent } from './specific-days-modal/specific-days-modal.component';
import { showFromMapper } from '../../application/mappers';
import { ShowFrom } from '../../application/enums';

@Component({
  selector: 'lib-notifications-settings',
  templateUrl: './alert-settings.component.html',
  styleUrls: ['./alert-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TitileNavbarComponent, IonicModule, CommonModule, OrLoadingComponent],
  providers: [GetAlertsSettingsQueryHandler, UpdateAlertsSettingsCommandHandler, OrLoadingService]
})
export class AlertSettingsComponent implements OnInit {
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly getAlertsSettingsQueryHandler: GetAlertsSettingsQueryHandler = inject(GetAlertsSettingsQueryHandler);
  private readonly updateAlertsSettingsCommandHandler: UpdateAlertsSettingsCommandHandler = inject(
    UpdateAlertsSettingsCommandHandler
  );
  private readonly orLoadingService: OrLoadingService = inject(OrLoadingService);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly navigationProvider: NavigationProvider = inject(NAVIGATION_TOKEN);
  private notificationPermissionProvider: NotificationPermissionProvider = inject(NOTIFICATION_TOKEN);

  public availableShowFromValues = Object.values(ShowFrom);

  private defaultValues: AlertsSettingsDTO = {
    isEnabled: false,
    price: { max: 40, min: 0, currency: 'PLN' },
    coordinates: { latitude: 0, longitude: 0 },
    maxDistanceMeters: 30,
    time: { hoursMin: 0, hoursMax: 24 },
    gameLevel: [GameLevel.BEGINNER, GameLevel.INTERMEDIATE, GameLevel.ADVANCED, GameLevel.PROFESSIONAL],
    specificDaysType: ShowFrom.ALL
  };

  private shouldShowDistanceToast: boolean = false;

  public gameLevels: GameLevel[] = availableLevels;
  public gameLevelMapper: Record<GameLevel, string> = gameLevelMapper;
  public viewFromValuesMapper: Record<ShowFrom, string> = showFromMapper;

  private notificationsValuesSubject: BehaviorSubject<AlertsSettingsDTO> = new BehaviorSubject<AlertsSettingsDTO>(
    this.defaultValues
  );

  readonly notificationsValues$: Observable<AlertsSettingsDTO> = this.notificationsValuesSubject.asObservable();

  readonly isiOS: boolean = this.platformProvider.isiOS;

  ngOnInit(): void {
    this.loadSettings();
  }

  loadSettings(): void {
    this.getAlertsSettingsQueryHandler
      .getAlertsSettings()
      .pipe(
        take(1),
        tap((settings: AlertsSettingsDTO) => this.notificationsValuesSubject.next(settings ?? this.defaultValues))
      )
      .subscribe();
  }

  saveSettings(): void {
    combineLatest([this.activatedRoute.queryParamMap, this.notificationsValues$])
      .pipe(
        take(1),
        tap(() => this.orLoadingService.show()),
        map(([queryParams, values]: [ParamMap, AlertsSettingsDTO]) => {
          const days: string[] = queryParams.getAll('day');
          return {
            ...values,
            specificWeekdays: days
          };
        }),
        tap((result: AlertsSettingsDTO) => this.notificationsValuesSubject.next(result)),
        switchMap((result: AlertsSettingsDTO) => this.updateAlertsSettingsCommandHandler.updateAlertsSettings(result)),
        take(1)
      )
      .subscribe({
        next: () => {
          this.loadSettings();
          this.orLoadingService.hide();
          this.successModal();
          this.navigationProvider.back();
        },
        error: () => {
          this.orLoadingService.hide();
          this.modalProvider.presentAlert$({
            message: 'Włącz lokalizację aby używać tej funkcji.',
            buttons: ['Zamknij']
          });
          return of(void 0);
        }
      });
  }

  toggleNotifications(event: boolean): void {
    this.notificationPermissionProvider
      .isEnabled()
      .pipe(
        take(1),
        switchMap((isEnabled: boolean) => {
          if (!isEnabled)
            this.modalProvider.showModal$({
              component: ActionModalComponent,
              componentProps: {
                header: 'Brak uprawnień do powiadomień w aplikacji',
                message: 'Nadaj uprawnienia do powiadomień aby otrzymywać powiadomienia',
                btnOk: 'Włącz',
                btnCancel: 'Anuluj',
                action: () => this.openNotificationPermissions()
              },
              cssClass: 'present-modal'
            });

          return of(void 0);
        })
      )
      .subscribe();

    this.notificationsValues$
      .pipe(
        take(1),
        map((values: AlertsSettingsDTO) =>
          this.notificationsValuesSubject.next({
            ...values,
            isEnabled: event
          })
        )
      )
      .subscribe();

    this.notificationsValuesSubject.value;
  }

  onPriceChange(event: CustomEvent): void {
    this.notificationsValues$
      .pipe(
        take(1),
        map((values: AlertsSettingsDTO) =>
          this.notificationsValuesSubject.next({
            ...values,
            price: {
              min: event.detail.value.lower,
              max: event.detail.value.upper,
              currency: 'PLN'
            }
          })
        )
      )
      .subscribe();
  }

  onDistanceChange(event: CustomEvent): void {
    const value = event.detail.value;

    if (value === 71 && this.shouldShowDistanceToast) {
      this.shouldShowDistanceToast = false;
      this.showDistanceToast();
    }

    if (value !== 71) {
      this.shouldShowDistanceToast = true;
    }

    this.notificationsValues$
      .pipe(
        take(1),
        map((values: AlertsSettingsDTO) =>
          this.notificationsValuesSubject.next({
            ...values,
            maxDistanceMeters: value
          })
        )
      )
      .subscribe();
  }

  onTimeChange(event: CustomEvent): void {
    this.notificationsValues$
      .pipe(
        take(1),
        map((values: AlertsSettingsDTO) =>
          this.notificationsValuesSubject.next({
            ...values,
            time: {
              hoursMin: event.detail.value.lower,
              hoursMax: event.detail.value.upper
            }
          })
        )
      )
      .subscribe();
  }

  levelChips(level: GameLevel): boolean {
    return this.notificationsValuesSubject.value.gameLevel.some((gameLevel: GameLevel) => gameLevel === level);
  }

  setLevelChips(value: GameLevel): void {
    const currentValues = this.notificationsValuesSubject.value;
    const currentSpecificValues = this.notificationsValuesSubject.value.gameLevel;

    if (currentSpecificValues.some((v) => v === value)) {
      const updatedGameLevel = currentSpecificValues.filter((v) => v !== value);
      this.notificationsValuesSubject.next({
        ...currentValues,
        gameLevel: updatedGameLevel
      });
    } else {
      const updatedGameLevel = [...currentSpecificValues, value];
      this.notificationsValuesSubject.next({
        ...currentValues,
        gameLevel: updatedGameLevel
      });
    }
  }

  viewFrom(from: ShowFrom): boolean {
    return this.notificationsValuesSubject.value.specificDaysType === from;
  }

  setViewFromChips(value: ShowFrom): void {
    if (value === ShowFrom.WEEKDAYS) {
      this.openSpecifcDaysModal();
    }

    const currentValues = this.notificationsValuesSubject.value;

    this.notificationsValuesSubject.next({
      ...currentValues,
      specificDaysType: value
    });
  }

  openSpecifcDaysModal(): void {
    this.notificationsValues$
      .pipe(
        take(1),
        switchMap((values) =>
          this.modalProvider.showModal$({
            component: SpecificDaysModalComponent,
            cssClass: 'present-modal',
            componentProps: { selectedDays: values.specificWeekdays ?? [] }
          })
        )
      )
      .subscribe();
  }

  private successModal(): void {
    this.modalProvider.showModal$({
      component: PresentModalComponent,
      componentProps: {
        header: 'Sukces',
        message: 'Ustawienia zostały zapisane',
        btnTxt: 'Zamknij'
      },
      cssClass: 'present-modal'
    });
  }

  private showDistanceToast() {
    this.modalProvider
      .showToast$({
        message: 'Brak limitu odległości',
        position: 'top',
        duration: 3000,
        cssClass: ['or-d-flex or-text-align-center or-text-lg or-color-white or-mt-12-px or-toast-style']
      })
      .pipe(take(1))
      .subscribe();
  }

  formatDistanceValue(maxDistanceMeters: number) {
    if (maxDistanceMeters === 71 || maxDistanceMeters === 0) {
      return `70+ km`;
    }
    return `do ${maxDistanceMeters} km`;
  }

  private openNotificationPermissions() {
    if (this.platformProvider.isAndroid)
      return NativeSettings.openAndroid({
        option: AndroidSettings.AppNotification
      });

    return NativeSettings.openIOS({
      option: IOSSettings.Notifications
    });
  }
}
