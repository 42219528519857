import { HttpClient } from "@angular/common/http";
import { inject } from "@angular/core";
import { ENV_CONFIG, EnvConfig } from "@core";
import {
  AddCommentPayloadDTO,
  UserCommentDTO,
} from "../application/interfaces";
import { Observable } from "rxjs";

export class UserCommentsService {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  addComment(payload: AddCommentPayloadDTO): Observable<void> {
    return this.httpClient.post<void>(
      `${this.env.apiUrl}/user-comments`,
      payload
    );
  }

  getComment(userId: string): Observable<UserCommentDTO> {
    return this.httpClient.get<UserCommentDTO>(
      `${this.env.apiUrl}/user-comments/${userId}`
    );
  }
}
