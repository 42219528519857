import { inject } from '@angular/core';
import { HOSTED_GAMES_DTO, HostedGamesDTOPort } from '../../infrastructure/http-service';
import { DateFormatPipe } from '@ui-components';
import { map, Observable, take } from 'rxjs';
import { mapToHostedGameQueryUtil } from './utils/map-to-hosted-game-query.util';
import { GameDetailsModel, GameDTO } from '@core';

export class GetHostedGameQueryHandler {
  private readonly hostedGamesDTOPort: HostedGamesDTOPort = inject(HOSTED_GAMES_DTO);
  private readonly dateFormatPipe: DateFormatPipe = inject(DateFormatPipe);

  getGame(gameId: string): Observable<GameDetailsModel> {
    return this.hostedGamesDTOPort.getOneGame(gameId).pipe(
      take(1),
      map((game: GameDTO) => mapToHostedGameQueryUtil(game, this.dateFormatPipe))
    );
  }
}
