import { ChangeDetectionStrategy, Component, inject, OnInit, ViewChild } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, Observable, shareReplay, switchMap, take, tap } from 'rxjs';
import {
  BottomNavbarComponent,
  CloseAdBannerComponent,
  GameCardV2Component,
  GamesMenuComponent,
  RedirectTypeEnum,
  TitileNavbarComponent
} from '@ui-components';
import { AppRoutes } from 'src/app/app-routes.enum';
import { GamesRoutes } from '../../games-routes.enum';
import { Router } from '@angular/router';
import { FutureGameDTO } from '../../application/interfaces';
import {
  ADS_TOKEN,
  AdsProvider,
  AdsUnitId,
  FullHeightScrollableContainerDirective,
  GameDetailsModel,
  PLATFORM_TOKEN,
  PlatformProvider
} from '@core';
import { ChatService, CreateSingleChatCommandHandler } from '@messages';
import { GetFutureGamesQueryHandler } from '../../application/handlers';
import { FeatureFlag, FeatureFlagHandler } from '@feature-flags';
import { BannerAdSize } from '@capacitor-community/admob/dist/esm/banner/banner-ad-size.enum';
import { BannerAdPosition } from '@capacitor-community/admob/dist/esm/banner/banner-ad-position.enum';

@Component({
  selector: 'lib-future-games',
  templateUrl: './future-games.component.html',
  styleUrls: ['./future-games.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TitileNavbarComponent,
    BottomNavbarComponent,
    GamesMenuComponent,
    FullHeightScrollableContainerDirective,
    GameCardV2Component,
    CloseAdBannerComponent
  ],
  providers: [GetFutureGamesQueryHandler, CreateSingleChatCommandHandler, ChatService]
})
export class FutureGamesComponent implements OnInit {
  @ViewChild(FullHeightScrollableContainerDirective)
  containerDirective!: FullHeightScrollableContainerDirective;

  protected readonly gameRedirectType: RedirectTypeEnum = RedirectTypeEnum.GAME;

  private readonly gamesTypeSubject: BehaviorSubject<keyof FutureGameDTO> = new BehaviorSubject<keyof FutureGameDTO>(
    'approved'
  );

  private showPlaceholderSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly isPageLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly getFutureGamesQueryHandler: GetFutureGamesQueryHandler = inject(GetFutureGamesQueryHandler);
  private readonly createSingleChatCommandHandler: CreateSingleChatCommandHandler =
    inject(CreateSingleChatCommandHandler);
  private readonly router: Router = inject(Router);
  private readonly adsProvider: AdsProvider = inject(ADS_TOKEN);
  private readonly featureFlagHandler: FeatureFlagHandler = inject(FeatureFlagHandler);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);

  readonly games$: Observable<GameDetailsModel[]> = this.gamesTypeSubject.asObservable().pipe(
    switchMap((type: keyof FutureGameDTO) =>
      type === 'approved' ? this.getFutureGamesQueryHandler.getApproved() : this.getFutureGamesQueryHandler.getWaiting()
    ),
    tap((games: GameDetailsModel[]) => this.showPlaceholderSubject.next(!games.length)),
    tap(() => this.isPageLoaded$.next(true))
  );

  readonly showPlaceholder$: Observable<boolean> = this.showPlaceholderSubject.asObservable().pipe(shareReplay(1));

  get isApprovedTabActive() {
    return this.gamesTypeSubject.getValue() === 'approved';
  }

  ngOnInit(): void {
    this.initBottomBanner();
  }

  changeDataSource(event: keyof FutureGameDTO): void {
    this.gamesTypeSubject.next(event);
  }

  goToFutureGames(): void {
    this.router.navigate([`${AppRoutes.GAMES}/${GamesRoutes.FUTURE}`]);
  }

  goToPastGames(): void {
    this.router.navigate([`${AppRoutes.GAMES}/${GamesRoutes.PAST}`]);
  }

  sendMessage(userId: string): void {
    this.createSingleChatCommandHandler.create(userId).subscribe();
  }

  private initBottomBanner(): void {
    const bottomMargin: number = this.platformProvider.isiOS ? 50 : 70;

    this.featureFlagHandler
      .isEnabled$(FeatureFlag.AD_MOB)
      .pipe(
        take(1),
        tap((isEnabled: boolean) => {
          if (isEnabled)
            return this.adsProvider.showBanner({
              adSize: BannerAdSize.BANNER,
              position: BannerAdPosition.BOTTOM_CENTER,
              adId: AdsUnitId.BannerV1,
              margin: bottomMargin
            });

          return;
        })
      )
      .subscribe();
  }
}
