import { inject } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { UserDetailsDTO } from '../interfaces';
import { UserService } from '../../infrastructure';

export class UserDetailsQueryHandler {
  private readonly userService: UserService = inject(UserService);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  details(): Observable<UserDetailsDTO> {
    return this.activatedRoute.params.pipe(switchMap((params: Params) => this.userService.getDetails(params['id'])));
  }
}
