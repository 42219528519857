<ng-container *ngIf="alerts$ | async as alerts">
  <div class="or-w-100-pc">
    <div class="border-radius-8 or-bg-alertbar or-cursor-pointer or-p-16-px">
      <div class="or-d-flex or-justify-content-between or-align-items-center">
        <div (click)="goToAlerts()">
          <div class="or-head-md or-mb-2-px or-mt-4-px">
            Informuj o gierkach w pobliżu!
          </div>
          <div class="or-text-sm or-color-secondary-2">
            Kliknij by sprawdzić ustawienia alertów
          </div>
        </div>

        <ion-toggle
          [checked]="alerts.isEnabled"
          (ionChange)="toggleNotifications($event)"
        >
        </ion-toggle>
      </div>
    </div>
  </div>
</ng-container>
