import {
  NOTIFICATIONS_ALERTS_DTO,
  NotificationsAlertsDTOPort,
} from "../../infrastructure";
import { inject } from "@angular/core";
import { Observable, of, switchMap } from "rxjs";
import {
  NOTIFICATION_TOKEN,
  NotificationPermissionProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
} from "@core";

export class PushPermissionsStatusCommandHandler {
  private notificationsAlertsDtoPort: NotificationsAlertsDTOPort = inject(
    NOTIFICATIONS_ALERTS_DTO
  );
  private platformProvier: PlatformProvider = inject(PLATFORM_TOKEN);
  private notificationPermissionProvider: NotificationPermissionProvider =
    inject(NOTIFICATION_TOKEN);

  setValue(): Observable<void> {
    if (!this.platformProvier.isNativeRun) return of(void 0);

    return this.notificationPermissionProvider
      .isEnabled()
      .pipe(
        switchMap((isEnabled: boolean) =>
          this.notificationsAlertsDtoPort.setPushStatus(isEnabled)
        )
      );
  }
}
