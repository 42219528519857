import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import {
  BottomNavbarComponent,
  OrAvatarComponent,
  TitileNavbarComponent,
} from "@ui-components";
import {
  CopyService,
  CopyValueToClipboardDirective,
  LocalRefreshService,
  MODAL_TOKEN,
  ModalProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
  RedirectToUserProfileDirective,
  USER_DATA_TOKEN,
  UserDataProvider,
  UserModel,
} from "@core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { AsyncPipe, NgClass, NgForOf, NgIf } from "@angular/common";
import { TeamCardComponent } from "../team-card/team-card.component";
import { map, Observable, shareReplay, switchMap, take, tap } from "rxjs";
import { TeamDTO, TeamMemberDTO } from "../../application/interfaces";
import { ManageTeamMemberComponent } from "../manage-team-member/manage-team-member.component";
import { CreateSingleChatCommandHandler } from "@messages";
import { ManageTeamComponent } from "../manage-team/manage-team.component";
import { GetTeamDetailsQueryHandler } from "../../application/handlers";
import { AppRoutes } from "src/app/app-routes.enum";
import { TeamsRoutes } from "../../teams-routes.enum";
import { ShareTeamModalComponent } from "../share-team-modal/share-team-modal.component";
import { TeamInviteData } from "../../application/models";

@Component({
  selector: "lib-team-details",
  templateUrl: "./team-details.component.html",
  styleUrls: ["./team-details.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TitileNavbarComponent,
    BottomNavbarComponent,
    NgClass,
    TeamCardComponent,
    AsyncPipe,
    NgIf,
    NgForOf,
    OrAvatarComponent,
    CopyValueToClipboardDirective,
    RedirectToUserProfileDirective,
  ],
  providers: [
    CopyService,
    CreateSingleChatCommandHandler,
    GetTeamDetailsQueryHandler,
    LocalRefreshService,
  ],
})
export class TeamDetailsComponent {
  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly createSingleChatCommandHandler: CreateSingleChatCommandHandler =
    inject(CreateSingleChatCommandHandler);
  private readonly getTeamDetailsQueryHandler: GetTeamDetailsQueryHandler =
    inject(GetTeamDetailsQueryHandler);
  private readonly localRefreshService: LocalRefreshService =
    inject(LocalRefreshService);
  private readonly router: Router = inject(Router);

  teamDetails$: Observable<TeamDTO> = this.localRefreshService.refresh$.pipe(
    switchMap(() => this.modalProvider.showLoading$()),
    switchMap(() => this.activatedRoute.params),
    switchMap((params: Params) =>
      this.getTeamDetailsQueryHandler.teamDetails(params["id"])
    ),
    tap(() => this.modalProvider.dismissLoading$()),
    shareReplay(1)
  );

  readonly userId$: Observable<string> = this.userDataProvider.userData$.pipe(
    map((userData: UserModel) => userData.userId)
  );

  readonly isiOS: boolean = this.platformProvider.isiOS;

  manageTeam(): void {
    this.teamDetails$
      .pipe(
        take(1),
        switchMap((team: TeamDTO) =>
          this.modalProvider.showModal$({
            component: ManageTeamComponent,
            cssClass: "modal-auto",
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            componentProps: { team: team },
          })
        )
      )
      .subscribe();
  }

  sendMessage(userId: string) {
    this.createSingleChatCommandHandler.create(userId).subscribe();
  }

  managePlayer(member: TeamMemberDTO, teamId: string): void {
    this.modalProvider
      .showModal$({
        component: ManageTeamMemberComponent,
        cssClass: "modal-auto",
        initialBreakpoint: 1,
        breakpoints: [0, 1],
        componentProps: { member: member, teamId: teamId },
      })
      .subscribe(() => this.localRefreshService.emit());
  }

  addMember(): void {
    this.teamDetails$
      .pipe(
        take(1),
        switchMap((teamDetails) =>
          this.router.navigateByUrl(
            `${AppRoutes.TEAMS}/${teamDetails.teamId}/${teamDetails.invitationToken}/${TeamsRoutes.INVITE}`
          )
        )
      )
      .subscribe();
  }

  inviteViaLink(): void {
    this.teamDetails$
      .pipe(
        take(1),
        switchMap((teamDetails) => {
          const payload: TeamInviteData = {
            teamId: teamDetails.teamId,
            token: teamDetails.invitationToken,
          };

          return this.modalProvider.showModal$({
            component: ShareTeamModalComponent,
            componentProps: { teamData: payload },
            cssClass: "modal-auto",
            initialBreakpoint: 1,
            breakpoints: [0, 1],
          });
        })
      )
      .subscribe();
  }
}
