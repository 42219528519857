import { catchError, map, Observable, of } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { inject, Injectable } from '@angular/core';

@Injectable()
export class IsAuthedService {
  private readonly authClient: AngularFireAuth = inject(AngularFireAuth);

  readonly isAuthed$: Observable<boolean> = this.authClient.authState.pipe(
    map((user) => !!user),
    catchError(() => {
      return of(false);
    })
  );

  readonly token$: Observable<string | null> = this.authClient.idToken;
}
