<div class="or-p-16-px">
  <div class="or-d-flex or-justify-content-center or-mt-24-px"><img src="assets/icons/check-circle.svg" /></div>
  <div class="or-head-xl or-text-align-center or-mt-24-px">Udało Ci się zdobyć nowe osiągnięcie! ⚽</div>
  <div class="or-d-flex or-justify-content-center or-mt-24-px">
    <img [src]="trophyMapper[trophyType].icon" class="or-size-112-px" />
  </div>
  <div class="or-head-lg or-text-align-center or-mt-24-px">{{trophyMapper[trophyType].name}}</div>
  <div class="or-text-md or-color-secondary-2 or-text-align-center or-mt-16-px">
    {{trophyMapper[trophyType].description}}
  </div>
  <button (click)="close()" class="button-primary-style or-h-48-px or-w-100-pc or-head-md or-mt-24-px">Zamknij</button>
</div>
