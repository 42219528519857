<lib-title-navbar title="Organizowane gierki"></lib-title-navbar>
<div class="top-container"></div>

<div class="margin-top--24-px">
  <lib-games-menu
    (goToFutureEvent)="goToFutureGames()"
    (goToPastEvent)="goToPastGames()"
    [isActivePast]="true"></lib-games-menu>
</div>

<div
  [class.full-height-scrollable-container]="!isiOS"
  [class.full-height-scrollable-container-ios]="isiOS"
  [ngClass]="{
    'or-align-items-center or-d-flex or-justify-content-center':
      showPlaceholder$ | async
  }">
  <div *ngFor="let date of (games$ | async)?.keys()">
    <div class="or-ml-32-px or-head-sm or-mb-8-px or-color-secondary-2 or-mt-24-px">{{ date }}</div>
    <div *ngFor="let game of (games$ | async)?.get(date)" class="or-mb-12-px">
      <lib-game-card
        [game]="game"
        [showAddSimilarGame]="true"
        [redirectType]="redirectType"
        (onAddSimilarGameClicked)="addSimilarGame(game)"></lib-game-card>
    </div>
  </div>

  <ng-container *ngIf="isPageLoaded$ | async">
    <div *ngIf="showPlaceholder$ | async" class="or-head-sm">Nie organizowałeś żadnych gierek.</div>
  </ng-container>
</div>

<lib-bottom-navbar></lib-bottom-navbar>
