<div class="or-h-80-px">
  <ng-container *ngIf="isAuthed$ | async">
    <ng-container *ngIf="isMyGame$ | async">
      <ng-container *ngTemplateOutlet="dividier"></ng-container>
      <button (click)="redirectToHostedGameDetails()" class="button-primary-style or-h-48-px or-w-100-pc or-head-md">
        Sprawdź szczegóły gierki
      </button>
    </ng-container>

    <ng-container *ngIf="showJoinButton$ | async">
      <ng-container *ngTemplateOutlet="dividier"></ng-container>
      <button
        class="button-primary-style or-h-48-px or-w-100-pc or-head-md"
        [sendApplication]="game.gameId"
        (applicationSent)="applicationSentEvent($event)"
        (onBlockMulticlick)="blockMulticlick()"
        [disabled]="isDisabled">
        Dołącz do gierki!
      </button>
    </ng-container>

    <ng-container *ngIf="showWaitingButton$ | async">
      <ng-container *ngTemplateOutlet="dividier"></ng-container>
      <button class="button-primary-style or-h-48-px or-w-100-pc or-head-md disabled-button">
        Czekasz na zatwierdzenie
      </button>
    </ng-container>

    <ng-container *ngIf="showJoinedButton$ | async">
      <ng-container *ngTemplateOutlet="dividier"></ng-container>
      <button (click)="redirectToGameDetails()" class="button-primary-style or-h-48-px or-w-100-pc or-head-md">
        Sprawdź szczegóły gierki
      </button>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!(isAuthed$ | async)">
    <ng-container *ngTemplateOutlet="dividier"></ng-container>
    <button class="button-primary-style or-h-48-px or-w-100-pc or-head-md" (click)="redirectToAuth()">
      Zaloguj się i dołącz do gierki!
    </button>
  </ng-container>

  <ng-template #dividier>
    <div class="or-d-flex or-justify-content-center">
      <div class="custom-divider or-my-16-px"></div>
    </div>
  </ng-template>
</div>
