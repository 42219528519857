import { from, Observable, switchMap, take, tap } from "rxjs";
import { AUTH_DTO, AuthDtoPort } from "../../infrastructure/http-services";
import { inject, Injector } from "@angular/core";
import {
  MODAL_TOKEN,
  ModalProvider,
  USER_DATA_TOKEN,
  UserDataProvider,
} from "@core";
import { setReferrerIdUtil } from "./set-referrer-id.util";

export class AuthWithProviderCommandHandler {
  private readonly authDtoPort: AuthDtoPort = inject(AUTH_DTO);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);
  private readonly injector: Injector = inject(Injector);

  authWithGoogle(): Observable<void> {
    return this.modalProvider.showLoading$().pipe(
      switchMap(() => from(this.authDtoPort.authWithGoogle())),
      tap(() => this.userDataProvider.setUserData()),
      take(1),
      switchMap(() => setReferrerIdUtil(this.injector))
    );
  }

  authWithApple(): Observable<void> {
    return this.modalProvider.showLoading$().pipe(
      switchMap(() => from(this.authDtoPort.authWithApple())),
      tap(() => this.userDataProvider.setUserData()),
      take(1),
      switchMap(() => setReferrerIdUtil(this.injector))
    );
  }
}
