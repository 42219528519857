<lib-title-navbar title="Ustawienia"></lib-title-navbar>

<div class="or-pt-16-px">
  <div
    (click)="editProfile()"
    class="or-p-4-px or-mb-8-px or-bg-white or-mx-16-px border-radius-8 or-cursor-pointer or-d-flex or-align-items-center">
    <img src="assets/icons/edit-2.svg" class="or-size-40-px" />
    <div class="or-text-lg or-ml-12-px">Edytuj profil</div>
  </div>

  <div
    (click)="goToAlertsSettings()"
    class="or-p-4-px or-mb-8-px or-bg-white or-mx-16-px border-radius-8 or-cursor-pointer or-d-flex or-align-items-center">
    <img src="assets/icons/push.svg" class="or-size-40-px" />
    <div class="or-text-lg or-ml-12-px">Ustaw alerty o nowych gierkach</div>
  </div>

  <div
    (click)="openContact()"
    class="or-p-4-px or-mb-8-px or-bg-white or-mx-16-px border-radius-8 or-cursor-pointer or-d-flex or-align-items-center">
    <img src="assets/icons/message-shade.svg" class="or-size-40-px" />
    <div class="or-text-lg or-ml-12-px">Napisz do nas</div>
  </div>

  <div
    (click)="openInstagram()"
    class="or-p-4-px or-mb-8-px or-bg-white or-mx-16-px border-radius-8 or-cursor-pointer or-d-flex or-align-items-center">
    <img src="assets/icons/network.svg" class="or-size-40-px" />
    <div class="or-text-lg or-ml-12-px">Dowiedz się więcej</div>
  </div>

  <div
    (click)="openReferral()"
    class="or-p-4-px or-mb-8-px or-bg-white or-mx-16-px border-radius-8 or-cursor-pointer or-d-flex or-align-items-center disabled">
    <img src="assets/icons/reffer.svg" class="or-size-40-px" />
    <div class="or-ml-12-px">
      <div class="or-text-lg">Polecaj znajomym</div>
      <div class="or-text-sm or-color-secondary-2">Zdobywaj punkty i graj o nagrody!</div>
    </div>
  </div>

  <div
    *ngIf="isNativeRun"
    (click)="updateApp()"
    class="or-p-4-px or-mb-8-px or-bg-white or-mx-16-px border-radius-8 or-cursor-pointer or-d-flex or-align-items-center or-d-flex or-justify-content-between">
    <div class="or-align-items-center or-d-flex">
      <div>
        <img src="assets/icons/update-icon.svg" class="or-size-40-px" />
      </div>
      <div class="or-text-lg or-ml-12-px">Sprawdź aktualizacje</div>
    </div>

    <img *ngIf="!(isNewestVersion$ | async)" src="assets/icons/unread-icon.svg" class="or-h-12-px or-w-12-px" />
  </div>

  <div
    *ngIf="shouldShowAdminPanel$ | async"
    (click)="goToAdminPanel()"
    class="or-p-4-px or-mb-8-px or-bg-white or-mx-16-px border-radius-8 or-cursor-pointer or-d-flex or-align-items-center">
    <img src="assets/icons/reffer.svg" class="or-size-40-px" />
    <div class="or-text-lg or-ml-12-px">Panel admina</div>
  </div>

  <div
    (click)="logout()"
    class="or-p-4-px or-mb-8-px or-bg-white or-mx-16-px border-radius-8 or-cursor-pointer or-d-flex or-align-items-center">
    <img src="assets/icons/logout.svg" class="or-size-40-px" />
    <div class="or-text-lg or-ml-12-px">Wyloguj się</div>
  </div>
</div>
