import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from "@angular/core";
import { AsyncPipe, NgIf } from "@angular/common";
import { OrAvatarComponent } from "@ui-components";
import { CopyService, MODAL_TOKEN, ModalProvider } from "@core";
import { Router } from "@angular/router";
import { AppRoutes } from "src/app/app-routes.enum";
import { TeamDTO } from "../../application/interfaces";
import { TeamsRoutes } from "../../teams-routes.enum";
import {
  GenerateInviteUrlCommandHandler,
  LeaveTeamCommandHandler,
  RemoveTeamCommandHandler,
} from "../../application/handlers";
import { switchMap, take, tap } from "rxjs";
import { TeamsService } from "../../infrastructure";
import { Share } from "@capacitor/share";
import { inviteTeamText } from "../../application/utils";

@Component({
  selector: "lib-manage-team",
  templateUrl: "./manage-team.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, OrAvatarComponent, AsyncPipe],
  providers: [
    LeaveTeamCommandHandler,
    TeamsService,
    RemoveTeamCommandHandler,
    GenerateInviteUrlCommandHandler,
    CopyService,
  ],
})
export class ManageTeamComponent {
  @Input() team!: TeamDTO;

  private readonly router: Router = inject(Router);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly leaveTeamCommandHandler: LeaveTeamCommandHandler = inject(
    LeaveTeamCommandHandler
  );
  private readonly removeTeamCommandHandler: RemoveTeamCommandHandler = inject(
    RemoveTeamCommandHandler
  );
  private readonly generateInviteUrlCommandHandler: GenerateInviteUrlCommandHandler =
    inject(GenerateInviteUrlCommandHandler);
  private readonly copyService: CopyService = inject(CopyService);

  editTeam(): void {
    this.modalProvider.dismissAllStoredModals();
    this.router.navigateByUrl(
      `${AppRoutes.TEAMS}/${this.team.teamId}/${TeamsRoutes.EDIT}/${this.team.teamName}`
    );
  }

  addMember(): void {
    this.modalProvider.dismissAllStoredModals();
    this.router.navigateByUrl(
      `${AppRoutes.TEAMS}/${this.team.teamId}/${this.team.invitationToken}/${TeamsRoutes.INVITE}`
    );
  }

  advancedShare(): void {
    this.modalProvider
      .showLoading$()
      .pipe(
        switchMap(() =>
          this.generateInviteUrlCommandHandler.generate(
            this.team.teamId,
            this.team.invitationToken
          )
        ),
        take(1),
        tap((deeplink: string) => {
          return Share.share({
            text: inviteTeamText(deeplink),
            url: deeplink,
          });
        }),
        tap(() => this.modalProvider.dismissLoading$()),
        switchMap(() => this.modalProvider.dismissModal$())
      )
      .subscribe();
  }

  copyGameLink() {
    this.modalProvider
      .showLoading$()
      .pipe(
        switchMap(() =>
          this.generateInviteUrlCommandHandler.generate(
            this.team.teamId,
            this.team.invitationToken
          )
        ),
        take(1),
        switchMap((deeplink: string) =>
          this.copyService.copyToClipboard(inviteTeamText(deeplink))
        ),
        tap(() => this.modalProvider.dismissLoading$()),
        switchMap(() => this.modalProvider.dismissModal$())
      )
      .subscribe();
  }

  leaveTeam(): void {
    this.leaveTeamCommandHandler
      .leave(this.team.teamId)
      .pipe(
        tap(() => this.modalProvider.dismissAllStoredModals()),
        switchMap(() => this.router.navigateByUrl(AppRoutes.DASHBOARD))
      )
      .subscribe();
  }

  // removeTeam(): void {
  //   this.removeTeamCommandHandler
  //     .remove(this.team.teamId)
  //     .pipe(
  //       tap(() => this.modalProvider.dismissAllStoredModals()),
  //       switchMap(() => this.router.navigateByUrl(AppRoutes.DASHBOARD))
  //     )
  //     .subscribe();
  // }
}
