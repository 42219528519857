import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { AsyncPipe, NgClass, NgForOf } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { BehaviorSubject, map, Observable, take, tap } from 'rxjs';
import { MODAL_TOKEN, ModalProvider } from '@core';
import { SelectableMultiCheckboxModel } from './selectable-multi-checkbox.model';

@Component({
  selector: 'lib-selectable-multi-checkbox-modal',
  templateUrl: './selectable-multi-checkbox-modal.component.html',
  styleUrls: ['./selectable-multi-checkbox-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, IonicModule, NgClass, NgForOf]
})
export class SelectableMultiCheckboxModalComponent<T> implements OnInit {
  @Input() header!: string;
  @Input() buttonText!: string;
  @Input() providedData!: SelectableMultiCheckboxModel<T>[];
  @Input() selectedData!: T[];

  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  readonly providedData$: BehaviorSubject<SelectableMultiCheckboxModel<T>[]> = new BehaviorSubject<
    SelectableMultiCheckboxModel<T>[]
  >([]);
  readonly selectedData$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);

  ngOnInit(): void {
    this.providedData$.next(this.providedData);
    this.selectedData$.next(this.selectedData);
  }

  isSelected(value: T): Observable<boolean> {
    return this.selectedData$.asObservable().pipe(
      take(1),
      map((selectedData: T[]) => selectedData?.some((item: T) => item === value))
    );
  }

  selectItem(data: T): void {
    this.selectedData$
      .asObservable()
      .pipe(
        take(1),
        map((selectedData: T[]) => {
          const isSelected: boolean = selectedData?.some((selectedItem: T) => selectedItem === data);

          if (isSelected) return selectedData.filter((selectedItem: T) => selectedItem !== data);

          return [...(selectedData || []), data];
        }),
        tap((newData: T[]) => this.selectedData$.next(newData))
      )
      .subscribe();
  }

  closeModal(): void {
    this.modalProvider.dismissModal$(this.selectedData$.value);
  }
}
