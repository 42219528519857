import { Observable } from 'rxjs';
import { StartupState } from './startup.state';

export abstract class StartupStep extends Function {
  protected state?: StartupState;

  protected abstract executeStep(state: StartupState): Observable<StartupState>;

  constructor(state: StartupState) {
    super();
    return new Proxy(this, {
      apply: (): Observable<StartupState> => this.executeStep(state)
    });
  }
}
