import { map, Observable, switchMap, take, tap } from 'rxjs';
import { inject } from '@angular/core';
import { NotificationBody, NotificationPayload, NotificationResponseDTO } from '../interfaces';
import { NotificationsService } from '../../infrastructure';
import { GlobalRefreshService, MODAL_TOKEN, ModalProvider } from '@core';
import { NotificationType } from '../enums';
import { NewTrophyRecievedModalComponent } from '../../components/new-trophy-recieved-modal/new-trophy-recieved-modal.component';

export class NotificationObserverQueryHandler {
  private readonly notificationsService: NotificationsService = inject(NotificationsService);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly globalRefreshService: GlobalRefreshService = inject(GlobalRefreshService);

  observeNotification(): Observable<void> {
    return this.globalRefreshService.refresh$.pipe(
      switchMap(() => this.notificationsService.notificationsList()),
      map((notifications: NotificationResponseDTO) =>
        notifications.notifications?.filter(
          (notification: NotificationBody) =>
            notification?.payload?.notificationType === NotificationType.NewTrophyReceived && !notification.isRead
        )
      ),
      tap((data: NotificationBody[]) =>
        data?.map((notification: NotificationBody) => this.newTrophyRecieved(notification.payload))
      ),
      map(() => void 0)
    );
  }

  newTrophyRecieved(data: NotificationPayload): void {
    this.modalProvider
      .showModal$({
        component: NewTrophyRecievedModalComponent,
        componentProps: {
          trophyType: data.trophyType
        },
        cssClass: 'present-modal'
      })
      .pipe(
        take(1),
        switchMap(() => this.notificationsService.markAsRead([data.notificationId]))
      )
      .subscribe();
  }
}
