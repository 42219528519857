import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TeamsRoutes } from './teams-routes.enum';
import {
  CreateTeamComponent,
  CreateTeamFinishComponent,
  EditTeamComponent,
  SwitchAutoApproveComponent,
  TeamDetailsComponent,
  TeamInvitationAcceptComponent,
  TeamInvitePlayersComponent
} from './components';
import { blockWebVersionGuard } from '@core';
import { isAuthedGuard } from '@auth';
import { teamInvitationAcceptResolver } from './application/resolvers';

const routes: Routes = [
  {
    path: TeamsRoutes.CREATE,
    component: CreateTeamComponent,
    canActivate: [blockWebVersionGuard, isAuthedGuard]
  },
  {
    path: `:id/:token/${TeamsRoutes.CREATE_FINISH}`,
    component: CreateTeamFinishComponent,
    canActivate: [blockWebVersionGuard, isAuthedGuard]
  },
  {
    path: `:id/${TeamsRoutes.TEAM_DETAILS}`,
    component: TeamDetailsComponent,
    canActivate: [blockWebVersionGuard, isAuthedGuard]
  },
  {
    path: `:id/:token/${TeamsRoutes.INVITE}`,
    component: TeamInvitePlayersComponent,
    canActivate: [blockWebVersionGuard, isAuthedGuard]
  },
  {
    path: `:id/${TeamsRoutes.AUTO_APPROVE}`,
    component: SwitchAutoApproveComponent,
    canActivate: [blockWebVersionGuard, isAuthedGuard]
  },
  {
    path: `:id/${TeamsRoutes.EDIT}/:teamName`,
    component: EditTeamComponent,
    canActivate: [blockWebVersionGuard, isAuthedGuard]
  },
  {
    path: `${TeamsRoutes.ACCEPT_INVITATION}/:teamId/:token`,
    component: TeamInvitationAcceptComponent,
    resolve: { teamInvitationAcceptResolver }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TeamsRoutingModule {}
