import { inject } from "@angular/core";
import { DateFormatPipe } from "@ui-components";
import { combineLatest, map, Observable, switchMap, take } from "rxjs";
import {
  LineupPlayers,
  LineupsDTO,
  LineupsModel,
  LineupsTeams,
  PlayerGameDTO,
} from "../interfaces";
import {
  GAMES_DTO,
  GamesDTOPort,
  LineupsService,
} from "../../infrastructure/http-service";
import { mapToGameQueryUtil } from "./utils/map-to-game-query.util";
import { GameDetailsModel, GamePlayersDTO } from "@core";
import { ActivatedRoute, Params } from "@angular/router";

export class LineupsQueryHandler {
  private readonly gameDTOPort: GamesDTOPort = inject(GAMES_DTO);
  private readonly lineupsService: LineupsService = inject(LineupsService);
  private readonly dateFormatPipe: DateFormatPipe = inject(DateFormatPipe);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  getLineups(): Observable<LineupsModel> {
    return this.activatedRoute.params.pipe(
      take(1),
      switchMap((params: Params) =>
        combineLatest([
          this.gameDTOPort
            .getOneGame(params["id"])
            .pipe(
              map((game: PlayerGameDTO) =>
                mapToGameQueryUtil(game, this.dateFormatPipe)
              )
            ),
          this.lineupsService.getLineups(params["id"]),
        ]).pipe(
          take(1),
          map(([game, lineups]: [GameDetailsModel, LineupsDTO]) => {
            if (lineups?.lineups?.length) {
              return this.loadExistingLineups(game, lineups);
            }
            return this.loadGamePlayers(game);
          })
        )
      )
    );
  }

  private loadExistingLineups(
    game: GameDetailsModel,
    lineups: LineupsDTO
  ): LineupsModel {
    const players: LineupsTeams[] = lineups?.lineups
      ?.flat()
      .map((players: LineupPlayers) => players.lineupPlayers)
      .flat();

    const specificPlayer = (playerId: string): GamePlayersDTO => {
      return game?.players?.find(
        (gamePlayer: GamePlayersDTO) => gamePlayer?.playerId === playerId
      ) as GamePlayersDTO;
    };

    return {
      isLineupsSaved: true,
      isHost: game.isHost,
      players: players?.map((lineupPlayer: LineupsTeams) => {
        return {
          userId: specificPlayer(lineupPlayer.playerId)?.userId,
          playerId: lineupPlayer.playerId,
          displayName: specificPlayer(lineupPlayer.playerId)?.userDisplayName,
          playerPreferences: specificPlayer(lineupPlayer.playerId)
            ?.playerPreferences,
          thumbnail: specificPlayer(lineupPlayer.playerId)?.userThumbnail,
        };
      }),
    };
  }

  private loadGamePlayers(game: GameDetailsModel): LineupsModel {
    return {
      isLineupsSaved: false,
      isHost: game.isHost,
      players: (game.players as GamePlayersDTO[])?.map(
        (player: GamePlayersDTO) => {
          return {
            userId: player?.userId,
            playerId: player?.playerId,
            displayName: player?.userDisplayName,
            playerPreferences: player?.playerPreferences,
            thumbnail: player?.userThumbnail,
          };
        }
      ),
    };
  }
}
