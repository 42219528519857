<ng-container *ngFor="let user of usersList$ | async; let i = index">
  <div class="or-text-lg" [copyToClipboard]="user.userId">
    <div class="or-d-flex or-justify-content-between or-mb-4-px or-align-items-center">
      <div class="or-head-md or-max-w-60-pc or-w-60-pc">
        <div>{{ i + 1 }}. {{ user.name }} {{ user.lastName }}</div>
        <div class="or-ml-20-px or-text-md">{{ user.email }}</div>
      </div>
      <div>
        <img (click)="sendMessage(user.userId, $event)" src="assets/icons/message-host-icon.svg" class="or-mr-4-px" />
      </div>
    </div>
    <div class="or-d-flex or-justify-content-center or-align-items-center">
      <div class="or-mr-20-px or-head-md or-max-w-30-pc or-w-30-pc">
        <div class="or-d-flex or-align-items-center">
          <ng-container *ngIf="user.platform === 'android'; then androidIcon"></ng-container>
          <ng-container *ngIf="user.platform === 'ios'; then iOsIcon"></ng-container>
          <ng-container *ngIf="user.platform === 'mobileweb'; then mobilewebIcon"></ng-container>
          <ng-container *ngIf="user.platform === 'web'; then webIcon"></ng-container>
          <div class="or-ml-4-px">{{ user.versionInfo }}</div>
        </div>
      </div>
      <div class="or-mr-20-px or-max-w-30-pc or-w-30-pc">{{ user.registerDate | date : "dd.MM.yyyy" }}</div>
      <div class="or-max-w-30-pc or-w-10-pc">
        {{ user.stats.hostedGames }}/{{ user.stats.playedGames }}/{{ user.stats.gameApplications }}
      </div>
    </div>
    <div class="custom-divider or-my-12-px or-w-100-pc"></div>
  </div>
</ng-container>

<ng-template #androidIcon> <img src="assets/icons/android.svg" class="or-size-32-px" /></ng-template>

<ng-template #iOsIcon><img src="assets/icons/apple.svg" class="or-size-32-px" /></ng-template>

<ng-template #webIcon
  ><img src="assets/icons/bottom-navbar/bottom-find-games-filled.svg" class="or-size-24-px"
/></ng-template>

<ng-template #mobilewebIcon>
  <img src="assets/icons/bottom-navbar/bottom-find-games-filled.svg" class="or-size-24-px" />
  &nbsp;MW
</ng-template>
