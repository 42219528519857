import { ChangeDetectionStrategy, Component, inject, ViewEncapsulation } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TabsDataModel } from './tabs-data.interface';
import { tabsData } from './tabs-data';
import { PLATFORM_TOKEN, PlatformProvider, USER_DATA_TOKEN, UserDataProvider, UserModel } from '@core';
import { map, Observable } from 'rxjs';
import { UnreadMessagesQueryHandler } from 'projects/messages/src/lib/application/handlers';

@Component({
  selector: 'lib-bottom-navbar',
  templateUrl: './bottom-navbar.component.html',
  styleUrls: ['./bottom-navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [IonicModule, CommonModule]
})
export class BottomNavbarComponent {
  private readonly router: Router = inject(Router);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly unreadMessagesQueryHandler: UnreadMessagesQueryHandler = inject(UnreadMessagesQueryHandler);
  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);

  public tabsData: TabsDataModel[] = tabsData;

  readonly isUnreadMessage$: Observable<boolean> = this.unreadMessagesQueryHandler.isUnreadMessage();

  readonly userId$: Observable<string> = this.userDataProvider.userData$.pipe(
    map((userData: UserModel) => userData.userId)
  );

  readonly isiOS: boolean = this.platformProvider.isiOS;

  constructor() {
    this.updateTabs();
  }

  private updateTabs(): void {
    this.tabsData = tabsData.map((tab: TabsDataModel) => ({
      ...tab,
      icon: this.isActive(tab.id)
        ? `assets/icons/bottom-navbar/bottom-${tab.id}-filled.svg`
        : `assets/icons/bottom-navbar/bottom-${tab.id}.svg`
    }));
  }

  isActive(url: string): boolean {
    return this.router.url.includes(url);
  }

  redirect(route: string, userId: string) {
    const profileUserId: string = route === 'profile' ? `/${userId}` : '';
    return this.router.navigate([`/${route}${profileUserId}`]);
  }
}
