import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { MODAL_TOKEN, ModalProvider, TrophyTypeEnum, userTrophiesMapper, UserTrophyModel } from '@core';

@Component({
  selector: 'lib-new-trophy-recieved-modal',
  templateUrl: './new-trophy-recieved-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class NewTrophyRecievedModalComponent {
  @Input() trophyType!: TrophyTypeEnum;

  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  public trophyMapper: Record<TrophyTypeEnum, UserTrophyModel> = userTrophiesMapper;

  close(): void {
    this.modalProvider.dismissModal$();
  }
}
