import { inject } from '@angular/core';
import { map, Observable, switchMap } from 'rxjs';
import { FeatureFlagDto, FeatureFlagsRecord } from '../models';
import { USER_DATA_TOKEN, UserDataProvider, UserModel } from '@core';
import { FEATURE_FLAGS_CONTEXT, FeatureFlagsContextPort } from '../../infrastructure';
import { FeatureFlag } from '../enums';

export class FeatureFlagHandler {
  private readonly featureFlagsContextPort: FeatureFlagsContextPort = inject(FEATURE_FLAGS_CONTEXT);
  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);

  isEnabled$(flag: FeatureFlag): Observable<boolean> {
    return this.userDataProvider.userData$.pipe(
      switchMap((userData: UserModel) =>
        this.featureFlagsContextPort.flags$.pipe(
          map((flags: FeatureFlagsRecord) => {
            const selectedFlag: FeatureFlagDto = flags[flag];

            if (!selectedFlag) return false;

            if (selectedFlag?.isEnabled && !selectedFlag?.users?.length) return true;

            return selectedFlag?.isEnabled && selectedFlag?.users?.includes(userData.userId);
          })
        )
      )
    );
  }
}
