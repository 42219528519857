import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { cardReasonMapper } from '../../application/utils';
import { GetUserPenaltiesQueryHandler } from '../../application/handlers';
import { GroupedUserPenalties } from '../../application/interfaces/grouped-user-penalties.model';
import { Observable } from 'rxjs';
import { PenaltiesService } from '../../infrastructure';

@Component({
  selector: 'lib-card-history',
  templateUrl: './card-history.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgForOf, NgClass, AsyncPipe],
  providers: [GetUserPenaltiesQueryHandler, PenaltiesService]
})
export class CardHistoryComponent {
  private readonly getUserPenaltiesQueryHandler: GetUserPenaltiesQueryHandler = inject(GetUserPenaltiesQueryHandler);

  public cardReasonMapper = cardReasonMapper;
  public isExpanded: boolean = false;

  readonly cardHistory$: Observable<GroupedUserPenalties> = this.getUserPenaltiesQueryHandler.getPenalties();

  changeVisibility(): void {
    this.isExpanded = !this.isExpanded;
  }
}
