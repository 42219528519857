import { AfterViewInit, ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { AsyncPipe, NgClass, NgForOf } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { MODAL_TOKEN, ModalProvider } from '@core';
import { SingleRadioItemModel } from './single-radio-item.model';

@Component({
  selector: 'lib-selectable-single-radio-modal',
  templateUrl: './selectable-single-radio-modal.component.html',
  styleUrls: ['./selectable-single-radio-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, IonicModule, NgClass, NgForOf]
})
export class SelectableSingleRadioModalComponent<T> implements OnInit {
  @Input() header!: string;
  @Input() buttonText!: string;
  @Input() providedData!: SingleRadioItemModel<T>[];
  @Input() selectedData!: T;

  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  readonly providedData$: BehaviorSubject<SingleRadioItemModel<T>[]> = new BehaviorSubject<SingleRadioItemModel<T>[]>(
    []
  );
  readonly selectedData$: BehaviorSubject<T> = new BehaviorSubject<T>(null as T);

  ngOnInit(): void {
    this.providedData$.next(this.providedData);
    this.selectedData$.next(this.selectedData);
  }

  isSelected(value: T): Observable<boolean> {
    return this.selectedData$.asObservable().pipe(map((selectedItem: T) => selectedItem === value));
  }

  setData(event: CustomEvent): void {
    const distance = event.detail.value;

    this.selectedData$.next(distance);
  }

  closeModal(): void {
    this.modalProvider.dismissModal$(this.selectedData$.value);
  }
}
