import { inject } from '@angular/core';
import { CHAT_CHANEL_DTO, ChatDtoPort } from '../../infrastructure/http-service';
import { ChannelMembersDTO, ChannelMembersResponseDTO } from '../interfaces';
import { map, Observable } from 'rxjs';

export class ChannelMembersQueryHandler {
  private readonly chatDtoPort: ChatDtoPort = inject(CHAT_CHANEL_DTO);

  getMembers(channelId: string): Observable<ChannelMembersDTO[]> {
    return this.chatDtoPort.channelMembers(channelId).pipe(map((data: ChannelMembersResponseDTO) => data.members));
  }
}
