import { Observable, of, switchMap } from 'rxjs';
import { Router, UrlTree } from '@angular/router';
import { ChatInitializer } from '../initializers';
import { inject } from '@angular/core';
import { AppRoutes } from 'src/app/app-routes.enum';
import { ActiveChannelModel } from '../interfaces';

export const isChannelLoadedGuard = (): Observable<UrlTree | boolean> => {
  const chatInitializer: ChatInitializer = inject(ChatInitializer);
  const router: Router = inject(Router);

  return chatInitializer.activeChannelData$.pipe(
    switchMap((activeChannel: ActiveChannelModel) => {
      if (!activeChannel) return of(router.createUrlTree([AppRoutes.MESSAGES]));

      return of(true);
    })
  );
};
