import { inject, Injector, runInInjectionContext } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/app-routes.enum';
import { TeamsRoutes } from '../../../../../teams/src/lib/teams-routes.enum';
import { LocalStorageKey } from '@core';
import { TeamInvitationPayload } from '../../../../../teams/src/lib/application/models';
import { ReassignGamePayload } from '../../../../../hosted-games/src/lib/application/interfaces';
import { HostedGamesRoutes } from '../../../../../hosted-games/src/lib/hosted-games.routes.enum';

export const redirectAfterLoginUtil = (injector: Injector) => {
  const sharedGameId: string | null = sessionStorage.getItem(LocalStorageKey.SharedGame);
  const teamInvitationAccept: TeamInvitationPayload = JSON.parse(
    sessionStorage.getItem(LocalStorageKey.TeamInvitationAccept) as string
  );
  const reassignGame: ReassignGamePayload = JSON.parse(sessionStorage.getItem(LocalStorageKey.ReassignGame) as string);

  return runInInjectionContext(injector, () => {
    const router: Router = inject(Router);

    if (sharedGameId) {
      return router.navigate([`/${AppRoutes.GAMES}/shared/${sharedGameId}`]);
    }

    if (teamInvitationAccept) {
      const url: string = `${AppRoutes.TEAMS}/${TeamsRoutes.ACCEPT_INVITATION}/${teamInvitationAccept.teamId}/${teamInvitationAccept.token}`;

      return router.navigate([url]);
    }

    if (reassignGame) {
      const url: string = `${AppRoutes.HOST}/${HostedGamesRoutes.REASSIGN}/${reassignGame.gameId}`;

      return router.navigate([url], { queryParams: { token: reassignGame.token } });
    }

    return router.navigate([`/${AppRoutes.ROOT}`]);
  });
};
