<lib-title-navbar title="Twoje gierki"></lib-title-navbar>
<div class="top-container"></div>

<div class="margin-top--24-px">
  <lib-games-menu
    (goToFutureEvent)="goToFutureGames()"
    (goToPastEvent)="goToPastGames()"
    [isActiveFuture]="true"></lib-games-menu>
</div>

<div class="or-d-flex or-justify-content-around or-mt-24-px or-px-16-px or-text-lg or-color-secondary">
  <div
    (click)="changeDataSource('approved')"
    class="or-pb-8-px"
    [ngClass]="{
      'or-color-black bottom-border-active': isApprovedTabActive
    }">
    Potwierdzone
  </div>
  <div
    (click)="changeDataSource('waiting')"
    class="or-pb-8-px"
    [ngClass]="{
      'or-color-black bottom-border-active': !isApprovedTabActive
    }">
    Oczekujące
  </div>
</div>
<div class="custom-divider or-w-100-pc"></div>

<div
  [class.full-height-scrollable-container]="!isiOS"
  [class.full-height-scrollable-container-ios]="isiOS"
  *ngIf="chips$ | async as chips">
  <div *ngFor="let game of games$ | async" class="or-mb-12-px or-mt-16-px">
    <ion-chip class="or-ml-32-px z-index-2 or-head-xs chips-margin-bottom--20-px or-mt-0-px" [ngClass]="chips.class"
      >{{ chips.name }}</ion-chip
    >

    <lib-game-card
      [game]="game"
      [showHostInfo]="true"
      [showMoreDetails]="true"
      [showPhoneNumber]="isApprovedTabActive"
      [showShareButton]="true"
      [redirectType]="redirectType"
      [adjustToChip]="true"
      (onSendMessage)="sendMessage($event)"></lib-game-card>
  </div>

  <div
    *ngIf="showPlaceholder$ | async"
    class="or-align-items-center or-d-flex or-justify-content-center or-head-sm or-h-100-pc">
    Nie grasz w żadnych gierkach.
  </div>
</div>

<lib-bottom-navbar></lib-bottom-navbar>
