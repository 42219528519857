import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { GameDetailsModel } from '@core';

@Injectable()
export class CreateGameViewService {
  private readonly previousGameSubject: BehaviorSubject<GameDetailsModel | null> =
    new BehaviorSubject<GameDetailsModel | null>(null);

  private readonly isRecurringSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  readonly previousGame$: Observable<GameDetailsModel | null> = this.previousGameSubject.asObservable();

  readonly previousGame: GameDetailsModel | null = this.previousGameSubject.value;

  readonly isRecurring: boolean = this.isRecurringSubject.value;

  readonly isRecurring$: Observable<boolean> = this.isRecurringSubject.asObservable();

  setGame(value: GameDetailsModel): void {
    this.previousGameSubject.next(value);
  }

  setIsRecurring(value: boolean): void {
    this.isRecurringSubject.next(value);
  }

  resetPreviousGame(): void {
    this.previousGameSubject.next(null);
  }
}
