import { Directive, HostListener, inject, Input } from '@angular/core';
import { MODAL_TOKEN, ModalProvider } from '../../../../core/src/lib/tokens';
import { ShareGameComponent } from './share-game.component';
import { GameDetailsModel } from '@core';

@Directive({
  standalone: true,
  selector: '[shareGame]'
})
export class ShareGameDirective {
  @Input('shareGame') shareGame!: GameDetailsModel;

  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    event.stopImmediatePropagation();

    this.modalProvider.showModal$({
      component: ShareGameComponent,
      componentProps: {
        game: this.shareGame
      },
      cssClass: 'modal-auto',
      initialBreakpoint: 1,
      breakpoints: [0, 1]
    });
  }
}
