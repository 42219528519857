<ng-container *ngIf="isPageLoaded$ | async">
  <ng-container
    *ngIf="{
      metadata: lineupsMetadata$ | async,
      shouldShowLineups: shouldShowLineups$ | async
    } as data">
    <lib-title-navbar
      title="Zobacz składy"
      [showRandomize]="data.metadata?.isHost"
      (onRandomizeClicked)="shufflePlayers()"></lib-title-navbar>

    <ng-container *ngIf="data.metadata?.isSaved || data.shouldShowLineups; else emptyState">
      <div
        *ngIf="players$ | async as players"
        class="or-px-16-px or-pt-20-px"
        [class.full-height-scrollable-container]="
          !isiOS && !data.metadata?.isHost
        "
        [class.full-height-scrollable-container-ios]="
          isiOS && !data.metadata?.isHost
        "
        [class.full-height-scrollable-container-host]="
          !isiOS && data.metadata?.isHost
        "
        [class.full-height-scrollable-container-ios-host]="
          isiOS && data.metadata?.isHost
        ">
        <ion-reorder-group (ionItemReorder)="doReorder($event)" [disabled]="!data.metadata?.isHost">
          <ng-container *ngFor="let player of players; let i = index">
            <div>
              <div *ngIf="i === 0" class="or-head-xs or-color-secondary-2">Skład 1</div>
              <div *ngIf="i === this.splitTeamSize()" class="or-head-xs or-color-secondary-2">Skład 2</div>
              <div
                class="or-d-flex or-align-items-center or-justify-content-between or-py-8-px lineups-border"
                [class.or-mb-32-px]="i === this.splitTeamSize() - 1">
                <div class="or-d-flex or-align-items-center">
                  <lib-or-avatar
                    [avatarUrl]="player.thumbnail"
                    [initials]="player.displayName"
                    [avatarSize]="'sm'"></lib-or-avatar>

                  <div>
                    <div class="or-head-sm or-d-flex or-align-items-center">
                      <div>{{ player.displayName }}</div>
                      <img
                        *ngIf="
                          player.playerPreferences?.preferredPosition ===
                          'GOALKEEPER'
                        "
                        class="or-ml-8-px"
                        src="assets/icons/goalkeeper.svg" />
                    </div>
                    <div
                      *ngIf="
                        player.playerPreferences?.preferredLevel ||
                        player.playerPreferences?.preferredPosition
                      "
                      class="or-note-sm or-color-secondary-2">
                      {{ preferredPositionMapper[ player.playerPreferences.preferredPosition ] }}
                      <span
                        *ngIf="
                          player.playerPreferences?.preferredLevel &&
                          player.playerPreferences?.preferredPosition
                        "
                        >/</span
                      >
                      {{ gameLevelMapper[player.playerPreferences.preferredLevel] }}
                    </div>
                  </div>
                </div>

                <ion-reorder slot="end">
                  <img src="assets/icons/reorder.svg" />
                </ion-reorder>
              </div>
            </div>
          </ng-container>
        </ion-reorder-group>
      </div>
    </ng-container>

    <div
      *ngIf="
        data.metadata?.isHost &&
        (data.metadata?.isSaved || data.shouldShowLineups)
      "
      class="or-w-100-pc or-d-flex or-justify-content-center">
      <button (click)="save()" class="button-primary-style or-h-48-px or-head-md or-w-100-pc or-mx-32-px or-mt-12-px">
        Zapisz składy!
      </button>
    </div>

    <ng-template #emptyState>
      <div
        class="or-d-flex or-flex-col or-align-items-center or-px-16-px full-height-scrollable-container-ios-host or-justify-content-center">
        <img src="assets/lineups-illustration.svg" />
        <ng-container *ngIf="data.metadata?.isHost">
          <div class="or-text-align-center or-head-md">Wylosuj składy na następną gierkę!</div>
          <div class="or-text-align-center or-text-lg or-color-secondary-2">
            Kliknij w prawym górnym rogu na ikonkę losowania, by wyznaczyć składy na gierkę.
          </div>

          <button (click)="shufflePlayers()" class="button-primary-style or-h-48-px or-w-50-pc or-head-md or-mt-32-px">
            Losuj!
          </button>
        </ng-container>

        <ng-container *ngIf="!data.metadata?.isHost">
          <div class="or-text-align-center or-head-md">Składy nie zostały jeszcze wylosowane.</div>
        </ng-container>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
