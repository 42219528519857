import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TitileNavbarComponent } from '@ui-components';
import { CopyService, USER_DATA_TOKEN, UserDataProvider, UserModel } from '@core';
import { map, Observable, switchMap, take } from 'rxjs';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
  selector: 'lib-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TitileNavbarComponent, AsyncPipe, NgIf],
  providers: [CopyService]
})
export class ReferralComponent {
  private readonly copyService: CopyService = inject(CopyService);
  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);

  readonly referralCode$: Observable<string> = this.userDataProvider.userData$.pipe(
    map((userData: UserModel) => userData.referralToken)
  );

  copy(): void {
    this.referralCode$
      .pipe(
        take(1),
        switchMap((code: string) => this.copyService.copyToClipboard(code.toUpperCase()))
      )
      .subscribe();
  }
}
