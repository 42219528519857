import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { ENV_CONFIG, EnvConfig } from '@core';
import { map, Observable } from 'rxjs';
import { CreateTeamDTO, TeamCandidatesDTO, TeamDTO, UpdateTeamMemberPayloadDTO } from '../../application/interfaces';

export class TeamsService {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  createTeam(payload: CreateTeamDTO): Observable<TeamDTO> {
    return this.httpClient.post<TeamDTO>(`${this.env.apiUrl}/teams`, payload);
  }

  updateTeam(teamId: string, payload: CreateTeamDTO): Observable<TeamDTO> {
    return this.httpClient.patch<TeamDTO>(`${this.env.apiUrl}/teams/${teamId}`, payload);
  }

  getTeams(): Observable<TeamDTO[]> {
    return this.httpClient.get<{ teams: TeamDTO[] }>(`${this.env.apiUrl}/teams`).pipe(map((res) => res.teams));
  }

  getTeamDetails(teamId: string): Observable<TeamDTO> {
    return this.httpClient.get<TeamDTO>(`${this.env.apiUrl}/teams/${teamId}`);
  }

  removeMember(teamId: string, memberId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.env.apiUrl}/teams/${teamId}/members/${memberId}`);
  }

  leaveTeam(teamId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.env.apiUrl}/teams/${teamId}/member/me`);
  }

  removeTeam(teamId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.env.apiUrl}/teams/${teamId}`);
  }

  updateMember(teamId: string, memberId: string, payload: UpdateTeamMemberPayloadDTO): Observable<void> {
    return this.httpClient.patch<void>(`${this.env.apiUrl}/teams/${teamId}/members/${memberId}`, payload);
  }

  getCandidates(teamId: string): Observable<TeamCandidatesDTO[]> {
    return this.httpClient
      .get<{ availableToInvite: TeamCandidatesDTO[] }>(`${this.env.apiUrl}/teams/${teamId}/members/candidates`)
      .pipe(map((res) => res.availableToInvite));
  }

  addTeamMember(teamId: string, payload: { userIds: string[]; isAutoApproved: boolean }): Observable<TeamDTO> {
    return this.httpClient.post<TeamDTO>(`${this.env.apiUrl}/teams/${teamId}/members`, {
      ...payload
    });
  }

  joinToTeam(teamId: string, token: string): Observable<void> {
    return this.httpClient.post<void>(`${this.env.apiUrl}/teams/${teamId}/invitations/accept`, null, {
      params: { invitationToken: token }
    });
  }
}
