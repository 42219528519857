<div class="or-d-flex or-justify-content-between or-align-items-center or-mt-8-px">
  <div class="or-head-lg">{{ game.gameName }}</div>

  <div class="or-align-items-center or-d-flex">
    <div class="or-mr-8-px">
      <ng-content></ng-content>
    </div>
    <img
      *ngIf="adminAccess"
      (click)="showAdminDetails()"
      src="assets/icons/eye-icon.svg"
      class="or-cursor-pointer or-mr-8-px" />

    <img src="assets/icons/share-icon.svg" [shareGame]="game" class="or-cursor-pointer" />
  </div>
</div>

<div class="or-d-flex or-align-items-center or-mt-12-px">
  <img src="assets/icons/clock-icon.svg" class="or-mr-8-px" />

  <div>
    <div class="or-head-xs or-color-secondary-2">Data</div>
    <div class="or-text-md">{{ game.formattedDate }}</div>
  </div>
</div>

<div class="or-d-flex or-align-items-center or-mt-12-px">
  <img src="assets/icons/map-pin-icon.svg" class="or-mr-8-px" />

  <div>
    <div class="or-head-xs or-color-secondary-2">Adres</div>
    <div class="or-text-md">
      {{ game.location.address }}, {{ game.location.city }}
      <span
        *ngIf="showNavigateLink"
        class="or-text-sm or-color-primary or-text-decoration-underline or-cursor-pointer"
        [navigate]="game.location.coordinates"
        >Nawiguj</span
      >
    </div>
  </div>
</div>

<div class="or-d-flex or-justify-content-space-around">
  <div>
    <div class="or-d-flex or-align-items-center or-mt-12-px">
      <img src="assets/icons/member-icon.svg" class="or-mr-8-px" />

      <div>
        <div class="or-head-xs or-color-secondary-2">Gracze</div>
        <div class="or-text-md">{{ game.occupiedSlots }}/{{ game.totalSlots }}</div>
      </div>
    </div>

    <div *ngIf="showGameTypeDetails" class="or-d-flex or-align-items-center or-mt-12-px">
      <img src="assets/icons/field-icon.svg" class="or-mr-8-px" />

      <div>
        <div class="or-head-xs or-color-secondary-2">Typ gierki</div>
        <div class="or-text-md">{{ gameType }}</div>
      </div>
    </div>

    <div class="or-d-flex or-align-items-center or-mt-12-px">
      <img src="assets/icons/price-icon.svg" class="or-mr-8-px" />

      <div>
        <div class="or-head-xs or-color-secondary-2">Cena</div>
        <div *ngIf="game.priceAmount" class="or-text-md">{{ game.priceAmount }} zł</div>
        <div *ngIf="!game.priceAmount" class="or-text-md">Darmowa</div>
      </div>
    </div>

    <div *ngIf="game.priceAmount" class="or-d-flex or-align-items-center or-mt-12-px">
      <ng-container *ngTemplateOutlet="locationType"></ng-container>
    </div>
  </div>
  <div class="or-ml-28-px">
    <div class="or-d-flex or-align-items-center or-mt-12-px">
      <img src="assets/icons/level-icon.svg" class="or-mr-8-px" />

      <div>
        <div class="or-head-xs or-color-secondary-2">Poziom</div>
        <div class="or-text-md">{{ game.level }}</div>
      </div>
    </div>

    <div *ngIf="showGameTypeDetails" class="or-d-flex or-align-items-center or-mt-12-px">
      <img src="assets/icons/map-icon.svg" class="or-mr-8-px" />

      <div>
        <div class="or-head-xs or-color-secondary-2">Widoczność na mapie</div>
        <div class="or-text-md">{{ gameVisibilityLabel }}</div>
      </div>
    </div>

    <div *ngIf="game.priceAmount" class="or-d-flex or-align-items-center or-mt-12-px">
      <img src="assets/icons/payment-3-icon.svg" class="or-mr-8-px" />

      <div>
        <div class="or-head-xs or-color-secondary-2">Płatność</div>
        <div class="or-text-md">{{ game.paymentType.join(" / ") }}</div>
      </div>
    </div>

    <div *ngIf="!game.priceAmount" class="or-d-flex or-align-items-center or-mt-12-px">
      <ng-container *ngTemplateOutlet="locationType"></ng-container>
    </div>
  </div>
</div>

<div *ngIf="showGoToChat" class="or-d-flex or-mt-16-px">
  <div
    (click)="goToChat($event, game.gameId)"
    class="border-shade border-radius-4 or-p-8-px or-mr-8-px or-w-100-pc or-bg-white or-d-flex">
    <img src="assets/icons/team-chat.svg" class="or-mr-8-px" />
    <div class="or-text-md or-my-4-px">Chat gierki</div>
  </div>
</div>

<div class="or-d-flex or-align-items-center or-mt-16-px">
  <div>
    <div class="or-head-xs or-color-secondary-2">Szczegóły gierki</div>
    <div class="or-text-md">{{ game.description }}</div>
  </div>
</div>

<div *ngIf="showHostInfo" class="or-d-flex or-align-items-center or-mt-24-px">
  <lib-or-avatar
    [avatarUrl]="game.host?.thumbnail"
    [initials]="game.host?.displayName"
    [redirectToUserProfile]="game.host?.hostId"
    [avatarSize]="'sm'"></lib-or-avatar>

  <div class="or-d-flex or-justify-content-between or-w-100-pc">
    <div [redirectToUserProfile]="game.host?.hostId">
      <div>
        <div class="or-d-flex or-align-items-center">
          <div class="or-head-sm">{{ game.host?.displayName }}</div>
          <ng-container *ngIf="hostRating()">
            <img class="or-ml-8-px or-mr-4-px or-size-16-px or-mb-4-px" src="assets/icons/star-fill.svg" />
            <div class="or-head-xs">{{ hostRating() }}</div>
          </ng-container>
        </div>

        <div class="or-text-sm or-color-secondary-2">Aktywny {{ calculatelastActive(game.host?.lastActive) }}</div>
      </div>
    </div>

    <div *ngIf="!(isMyGame$ | async)" class="or-d-flex or-align-items-center">
      <img (click)="sendMessage(game.host?.hostId, $event)" src="assets/icons/message-host-icon.svg" />
      <div
        *ngIf="showPhoneNumber"
        [copyToClipboard]="game.gameContactPhone"
        class="button-primary-style or-align-items-center or-d-flex or-head-md or-justify-content-between or-px-8-px or-ml-4-px or-h-32-px or-cursor-pointer"
        [ngClass]="{ 'disabled-button': !game.gameContactPhone }">
        <img src="assets/icons/phone-white.svg" class="or-mr-4-px" />
        <span class="or-color-white or-head-xs text-no-wrap" [ngClass]="{ 'disabled-button': !game.gameContactPhone }"
          >Skopiuj numer</span
        >
      </div>
    </div>
  </div>
</div>

<ng-template #locationType>
  <img src="assets/icons/location-type/location-type.svg" class="or-mr-8-px" />

  <div>
    <div class="or-head-xs or-color-secondary-2">Typ boiska</div>
    <div class="or-text-md">{{ locationTypeMapper[game.locationType].name }}</div>
  </div>
</ng-template>
