import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StartupStepsMiddleware } from './startup-steps.middleware';
import { StartupState } from './startup.state';
import { addStepMethod } from './startup-operators';
import {
  AppNativeEventsStep,
  ConnectAuthStep,
  DeepLinkingStep,
  GeolocationPermissionsStep,
  HideSplashScreenStep,
  InitAdsStep,
  InitNavigationStep,
  LoadFeatureFlagsStep,
  NotificationsStep,
  ObserveNotificationStep,
  ObserveProfileTypeStep,
  SetDefaultFiltersStep,
  SetupAlertsStep,
  ShowSplashScreenStep
} from './steps';

@Injectable({
  providedIn: 'root'
})
export class StartupStepService extends StartupStepsMiddleware<StartupState> {
  private _initState?: StartupState = {};

  get state(): StartupState | undefined {
    return this._initState;
  }

  init(initState?: StartupState): Observable<StartupState> {
    this._initState = {
      ...initState
    };
    console.log('Startup steps flow start.');
    this.configure(addStepMethod(this.injector, (state: StartupState) => new ShowSplashScreenStep(state)()));
    this.configure(addStepMethod(this.injector, (state: StartupState) => new HideSplashScreenStep(state)()));

    this.configure(addStepMethod(this.injector, (state: StartupState) => new ConnectAuthStep(state)()));

    //WITH AUTH
    this.configure(addStepMethod(this.injector, (state: StartupState) => new LoadFeatureFlagsStep(state)()));
    this.configure(addStepMethod(this.injector, (state: StartupState) => new SetDefaultFiltersStep(state)()));
    this.configure(addStepMethod(this.injector, (state: StartupState) => new NotificationsStep(state)()));
    this.configure(addStepMethod(this.injector, (state: StartupState) => new ObserveNotificationStep(state)()));
    this.configure(addStepMethod(this.injector, (state: StartupState) => new ObserveProfileTypeStep(state)()));
    this.configure(addStepMethod(this.injector, (state: StartupState) => new SetupAlertsStep(state)()));

    //WITHOUT AUTH
    this.configure(addStepMethod(this.injector, (state: StartupState) => new GeolocationPermissionsStep(state)()));
    this.configure(addStepMethod(this.injector, (state: StartupState) => new AppNativeEventsStep(state)()));
    this.configure(addStepMethod(this.injector, (state: StartupState) => new InitNavigationStep(state)()));
    this.configure(addStepMethod(this.injector, (state: StartupState) => new DeepLinkingStep(state)()));
    this.configure(addStepMethod(this.injector, (state: StartupState) => new InitAdsStep(state)()));

    return this.run({ ...initState });
  }
}
