<div class="or-d-flex or-px-16-px overflow-y-auto or-mt-16-px or-mb-4-px">
  <div
    (click)="goToHosted()"
    class="border-shade border-radius-4 or-px-12-px or-pt-12-px or-pb-8-px or-mr-8-px or-w-112-px or-min-w-112-px or-bg-white">
    <img src="assets/icons/bottom-navbar/bottom-host.svg" />
    <div class="or-text-md or-my-4-px">Organizator</div>
  </div>

  <div
    (click)="goToGames()"
    class="border-shade border-radius-4 or-px-12-px or-pt-12-px or-pb-8-px or-mr-8-px or-w-112-px or-min-w-112-px or-bg-white">
    <img src="assets/icons/bottom-navbar/bottom-games.svg" />
    <div class="or-text-md or-my-4-px">Gracz</div>
  </div>

  <div
    *ngIf="rankFF$ | async"
    (click)="goToRank()"
    class="border-shade border-radius-4 or-px-12-px or-pt-12-px or-pb-8-px or-mr-8-px or-w-112-px or-min-w-112-px or-bg-white">
    <img src="assets/icons/ranks.svg" class="or-size-20-px" />
    <div class="or-text-md or-my-4-px">Rankingi</div>
  </div>

  <div
    (click)="goToAlerts()"
    class="border-shade border-radius-4 or-px-12-px or-pt-12-px or-pb-8-px or-w-112-px or-min-w-112-px or-bg-white">
    <img src="assets/icons/dashboard-alerts.svg" />
    <div class="or-text-md or-my-4-px">Alerty o gierkach</div>
  </div>
</div>
