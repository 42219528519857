import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'lib-wm72h',
  templateUrl: './wm72h.component.html',
  styleUrls: ['./wm72h.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: []
})
export class Wm72hComponent {
  redirect(): void {
    window.open('https://competit.pl/w/1818/', ' _blank');
  }
}
