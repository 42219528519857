import { Routes } from '@angular/router';
import {
  Intro1Component,
  Intro2Component,
  Intro3Component,
  Intro4Component,
  IntroTeamsComponent
} from './lib/components';
import { IntroductionEnum } from './lib/application/enums';

export const IntroductionRouting: Routes = [
  {
    path: IntroductionEnum.INTRO_1,
    component: Intro1Component
  },
  {
    path: IntroductionEnum.INTRO_2,
    component: Intro2Component
  },
  {
    path: IntroductionEnum.INTRO_3,
    component: Intro3Component
  },
  {
    path: IntroductionEnum.INTRO_4,
    component: Intro4Component
  },
  {
    path: IntroductionEnum.INTRO_TEAMS,
    component: IntroTeamsComponent
  }
];
