import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ProfileRouting } from "./profile.routing";
import { UserService } from "./infrastructure";

@NgModule({
  imports: [RouterModule.forChild(ProfileRouting)],
  providers: [UserService],
})
export class ProfileModule {}
