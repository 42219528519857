import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { UpdateProfileTypeCommandHandler } from '../../application/handlers';
import { BehaviorSubject } from 'rxjs';
import { MODAL_TOKEN, ModalProvider, ProfileType } from '@core';

@Component({
  selector: 'lib-profile-type-modal',
  templateUrl: './profile-type-modal.component.html',
  styleUrls: ['./profile-type-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, IonicModule, NgClass, NgIf],
  providers: [UpdateProfileTypeCommandHandler]
})
export class ProfileTypeModalComponent {
  private modalProvider: ModalProvider = inject(MODAL_TOKEN);

  readonly selectedType$: BehaviorSubject<ProfileType> = new BehaviorSubject<ProfileType>(
    null as unknown as ProfileType
  );
  readonly profileType = ProfileType;

  setType(event: CustomEvent) {
    const type: ProfileType = event.detail.value;
    this.selectedType$.next(type);
  }

  closeModal() {
    if (!this.selectedType$.value) return;

    this.modalProvider.dismissModal$(this.selectedType$.value);
  }
}
