<div class="or-head-sm or-color-secondary-2">Historia kartek</div>
<div class="or-mt-12-px">
  <div *ngFor="let card of penalty; let i = index" class="or-d-flex">
    <img
      [src]="cardReasonMapper[card.penaltyReason].icon"
      class="or-align-self-start or-d-flex or-mr-8-px"
    />
    <div [ngClass]="{ 'or-mb-8-px': i !== penalty.length - 1 }">
      <div class="or-text-md or-mb-4-px">
        {{ cardReasonMapper[card.penaltyReason].displayName }}
      </div>
      <div class="or-head-xs or-color-secondary-2">
        {{ card.author.displayName }} | {{ formatDate(card.penaltyDate) }}
      </div>
    </div>
  </div>
</div>
