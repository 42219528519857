import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { OrAvatarComponent } from '@ui-components';
import { GamePlayersDTO, MODAL_TOKEN, ModalProvider } from '@core';

@Component({
  selector: 'lib-add-card-success-modal',
  templateUrl: './add-comment-success-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, IonicModule, OrAvatarComponent]
})
export class AddCommentSuccessModalComponent {
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  @Input() player!: GamePlayersDTO;

  closeModal(): void {
    this.modalProvider.dismissModal$();
  }
}
