import { Observable, of } from 'rxjs';
import { StartupState } from '../startup.state';
import { inject } from '@angular/core';
import { StartupStep } from '../startup-step';
import { NAVIGATION_TOKEN, NavigationProvider } from '@core';

export class InitNavigationStep extends StartupStep {
  private readonly navigationProvider: NavigationProvider = inject(NAVIGATION_TOKEN);

  executeStep(state: StartupState): Observable<StartupState> {
    this.navigationProvider.init();
    return of(state);
  }
}
