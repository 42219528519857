<div class="or-d-flex or-justify-content-center">
  <div
    class="or-align-items-center or-d-flex or-flex-col or-w-100-pc or-mr-36-px"
  >
    <div class="or-head-sm or-color-secondary-2 or-mb-8-px">
      Preferowana pozycja
    </div>
    <div
      class="border-shade border-radius-4 or-p-8-px or-bg-white or-d-flex or-justify-content-center or-w-100-pc"
    >
      <img src="assets/icons/preferred-position.svg" />
      <div
        class="or-text-lg or-my-4-px or-ml-8-px"
        [class.or-color-secondary-2]="!playerPreferences.preferredPosition"
      >
        {{
          preferredPositionMapper[playerPreferences.preferredPosition] ??
            "Nie wybrano"
        }}
      </div>
    </div>
  </div>

  <div class="or-align-items-center or-d-flex or-flex-col or-w-100-pc">
    <div class="or-head-sm or-color-secondary-2 or-mb-8-px">
      Poziom umiejętności
    </div>
    <div
      class="border-shade border-radius-4 or-p-8-px or-bg-white or-d-flex or-justify-content-center or-w-100-pc"
    >
      <img src="assets/icons/preferred-level.svg" />
      <div
        class="or-text-lg or-my-4-px or-ml-8-px"
        [class.or-color-secondary-2]="!playerPreferences.preferredLevel"
      >
        {{ gameLevelMapper[playerPreferences.preferredLevel] ?? "Nie wybrano" }}
      </div>
    </div>
  </div>
</div>
