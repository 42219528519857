import { Routes } from '@angular/router';
import { ProfileRoutes } from './profile.routes.enum';
import {
  AllTrophiesComponent,
  ContactComponent,
  EditProfileComponent,
  ProfileComponent,
  ProfileSettingsComponent,
  ReferralComponent
} from './components';

export const ProfileRouting: Routes = [
  {
    path: ProfileRoutes.EDIT,
    component: EditProfileComponent
  },
  {
    path: ProfileRoutes.CONTACT,
    component: ContactComponent
  },
  {
    path: ProfileRoutes.REFERRAL,
    component: ReferralComponent
  },
  {
    path: ProfileRoutes.SETTINGS,
    component: ProfileSettingsComponent
  },
  {
    path: ProfileRoutes.ALL_TROPHIES,
    component: AllTrophiesComponent
  },
  {
    path: ProfileRoutes.ID,
    component: ProfileComponent
  }
];
