import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from "@angular/core";
import { AsyncPipe, NgIf } from "@angular/common";
import { OrAvatarComponent } from "@ui-components";
import { ButtonLabels, MODAL_TOKEN, ModalProvider } from "@core";
import { TeamMemberDTO } from "../../application/interfaces";
import {
  RemoveTeamMemberCommandHandler,
  UpdateTeamMemberCommandHandler,
} from "../../application/handlers";
import { TeamsService } from "../../infrastructure/";

@Component({
  selector: "lib-manage-team-member",
  templateUrl: "./manage-team-member.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, OrAvatarComponent, AsyncPipe],
  providers: [
    RemoveTeamMemberCommandHandler,
    UpdateTeamMemberCommandHandler,
    TeamsService,
  ],
})
export class ManageTeamMemberComponent {
  @Input() member!: TeamMemberDTO;
  @Input() teamId!: string;

  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly removeTeamMemberCommandHandler: RemoveTeamMemberCommandHandler =
    inject(RemoveTeamMemberCommandHandler);
  private readonly updateTeamMemberCommandHandler: UpdateTeamMemberCommandHandler =
    inject(UpdateTeamMemberCommandHandler);

  get autoApproveText(): ButtonLabels {
    const header: string = this.member.isAutoApproved
      ? "Usuń z listy ulubionych graczy"
      : "Ulubiony gracz";

    const text: string = this.member.isAutoApproved
      ? "Wyłącz automatyczną akceptację"
      : "Kliknij, aby gracz byl automatycznie akceptowany";
    return { header, text };
  }

  removePlayer(memberId: string): void {
    this.removeTeamMemberCommandHandler
      .remove(this.teamId, memberId)
      .subscribe(() => this.modalProvider.dismissAllStoredModals());
  }

  switchAutoApprove(member: TeamMemberDTO): void {
    this.updateTeamMemberCommandHandler
      .update(this.teamId, member.userId, {
        isAutoApproved: !member.isAutoApproved,
      })
      .subscribe(() => this.modalProvider.dismissAllStoredModals());
  }
}
