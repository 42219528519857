import { HOSTED_GAME_APPLICATIONS_DTO, HostedGameApplicationsDTOPort } from '../../infrastructure/http-service';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

export class ApproveApplicationsCommandHandler {
  private readonly hostedGameApplicationsDTOPort: HostedGameApplicationsDTOPort = inject(HOSTED_GAME_APPLICATIONS_DTO);

  approve(gameId: string, applicationIds: string[]): Observable<void> {
    return this.hostedGameApplicationsDTOPort.approveApplications(gameId, applicationIds);
  }
}
