import {
  HOSTED_GAMES_DTO,
  HostedGamesDTOPort,
} from "../../infrastructure/http-service";
import { inject } from "@angular/core";
import { Observable } from "rxjs";
import { CustomPlayerModel } from "../interfaces";

export class EditPlayerCommandHandler {
  private readonly hostedGamesDTOPort: HostedGamesDTOPort =
    inject(HOSTED_GAMES_DTO);

  editPlayer(
    gameId: string,
    player: CustomPlayerModel,
    playerId: string
  ): Observable<void> {
    return this.hostedGamesDTOPort.editPlayer(gameId, player, playerId);
  }
}
