import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { OrAvatarComponent } from '@ui-components';
import { GamePlayersDTO, MODAL_TOKEN, ModalProvider } from '@core';
import { CardReason, CardType } from '../../../application/enums';
import { cardReasonMapper } from '../../../application/utils';

@Component({
  selector: 'lib-add-card-success-modal',
  templateUrl: './add-card-success-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, IonicModule, OrAvatarComponent]
})
export class AddCardSuccessModalComponent {
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  @Input() player!: GamePlayersDTO;
  @Input() reason!: CardReason;

  get cardName(): string {
    return cardReasonMapper[this.reason].cardType === CardType.RED ? 'czerwoną' : 'żółtą';
  }

  get cardIcon(): string {
    return cardReasonMapper[this.reason].icon;
  }

  closeModal(): void {
    this.modalProvider.dismissModal$();
  }
}
