import { filter, first, Observable, of, switchMap, tap } from 'rxjs';
import { StartupState } from '../startup.state';
import { inject } from '@angular/core';
import { StartupStep } from '../startup-step';
import { LoadFeatureFlagsCommandHandler } from '@feature-flags';

export class LoadFeatureFlagsStep extends StartupStep {
  private readonly loadFeatureFlagsCommandHandler: LoadFeatureFlagsCommandHandler =
    inject(LoadFeatureFlagsCommandHandler);

  executeStep(state: StartupState): Observable<StartupState> {
    state.authed$
      ?.pipe(
        filter(Boolean),
        first(),
        switchMap(() => this.loadFeatureFlagsCommandHandler.loadFlags()),
        tap(() => (state.featureFlagsLoaded$ = of(true)))
      )
      .subscribe();

    return of(state);
  }
}
