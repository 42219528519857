import { Observable, of, switchMap, take, tap } from "rxjs";
import { inject, Injector, runInInjectionContext } from "@angular/core";
import { AUTH_DTO, AuthDtoPort } from "../../infrastructure/http-services";
import { IsAuthedService } from "@core";

export const setReferrerIdUtil = (injector: Injector): Observable<void> => {
  const referrerKey: string = "referrer";
  const referrerId = localStorage.getItem(referrerKey);

  return runInInjectionContext(injector, () => {
    const authDtoPort: AuthDtoPort = inject(AUTH_DTO);
    const isAuthedService: IsAuthedService = inject(IsAuthedService);

    return isAuthedService.isAuthed$.pipe(
      switchMap((isAuthed: boolean) => {
        if (isAuthed && referrerId)
          return authDtoPort.setReferrer(referrerId).pipe(
            take(1),
            tap(() => localStorage.removeItem(referrerKey))
          );

        return of(void 0);
      })
    );
  });
};
