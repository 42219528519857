import {
  BehaviorSubject,
  combineLatest,
  combineLatestWith,
  debounceTime,
  EMPTY,
  filter,
  first,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  take,
  tap
} from 'rxjs';
import {
  SelectableMultiCheckboxModalComponent,
  SelectableMultiCheckboxModel,
  SelectableSingleRadioModalComponent,
  SingleRadioItemModel
} from '@ui-components';
import {
  CoordinatesDTO,
  GameLevel,
  gameLevelMapper,
  GEOLOCATION_TOKEN,
  GeolocationProvider,
  LocationTypeEnum,
  locationTypeMapper,
  MODAL_TOKEN,
  ModalProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
  PresentModalComponent
} from '@core';
import { FiltersValues } from '../../../application/models';
import { GetFiltersQueryHandler, SetFilterCommandHandler } from '../../../application/handlers';
import { inject } from '@angular/core';
import { availableFilterDistanceConst } from '../../../application/utils';
import { CalendarHeader } from '../../../application/interfaces';

export class FiltersViewService {
  private readonly getFiltersQueryHandler: GetFiltersQueryHandler = inject(GetFiltersQueryHandler);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly setFilterCommandHandler: SetFilterCommandHandler = inject(SetFilterCommandHandler);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly geolocationProvider: GeolocationProvider = inject(GEOLOCATION_TOKEN);

  private readonly coordinatesSubject: BehaviorSubject<CoordinatesDTO | null> =
    new BehaviorSubject<CoordinatesDTO | null>(null);

  readonly gameCountSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  readonly savedFilters$: Observable<FiltersValues> = this.getFiltersQueryHandler.filters().pipe(shareReplay(1));

  readonly availableDistance$: Observable<SingleRadioItemModel<number>[]> = of(availableFilterDistanceConst);

  readonly coordinates$: Observable<CoordinatesDTO | null> = this.coordinatesSubject.asObservable();

  readonly gameCount$: Observable<number> = this.gameCountSubject.asObservable().pipe(debounceTime(100));

  readonly availableGameLevels$: Observable<SelectableMultiCheckboxModel<GameLevel>[]> = of(
    Object.values(GameLevel)
  ).pipe(
    combineLatestWith(this.savedFilters$),
    take(1),
    map(([gameLevel, filters]: [GameLevel[], FiltersValues]) =>
      gameLevel.map((level: GameLevel) => {
        return {
          name: gameLevelMapper[level],
          value: level,
          isSelected: filters.gameLevel?.includes(level)
        };
      })
    )
  );

  readonly availableLocationTypes$: Observable<SelectableMultiCheckboxModel<LocationTypeEnum>[]> = of(
    Object.values(LocationTypeEnum)
  ).pipe(
    combineLatestWith(this.savedFilters$),
    take(1),
    map(([locationType, filters]: [LocationTypeEnum[], FiltersValues]) =>
      locationType.map((location: LocationTypeEnum) => {
        return {
          name: locationTypeMapper[location].name,
          value: location,
          isSelected: filters.locationType?.includes(location)
        };
      })
    )
  );

  initialize(gamesCount: number | undefined): void {
    this.savedFilters$
      .pipe(
        take(1),
        tap((filters: FiltersValues) =>
          this.coordinatesSubject.next({ latitude: filters?.latitude, longitude: filters?.longitude })
        )
      )
      .subscribe();

    this.gameCountSubject.next(gamesCount ?? 0);

    this.loadRealCoordinates(false);
  }

  removeDistanceFilter(): void {
    this.gameCount$
      .pipe(
        switchMap((gameCount: number) => {
          if (gameCount < 1)
            return this.setFilterCommandHandler.set({
              maxDistanceMeters: undefined
            });

          return EMPTY;
        })
      )
      .subscribe();
  }

  openDistanceFilter(): void {
    this.loadRealCoordinates(true);

    combineLatest([this.availableDistance$, this.savedFilters$, this.platformProvider.isPermissionGranted()])
      .pipe(
        take(1),
        switchMap(
          ([availableDistance, filters, isGranted]: [SingleRadioItemModel<number>[], FiltersValues, boolean]) => {
            if (!isGranted) {
              this.locationPermissionDenied();
              return EMPTY;
            }
            return this.distanceModal(availableDistance, filters);
          }
        ),
        combineLatestWith(this.coordinates$),
        take(1),
        switchMap(([distance, coordinates]: [number | null, CoordinatesDTO | null]) =>
          this.setFilterCommandHandler.set({
            maxDistanceMeters: distance ?? undefined,
            latitude: coordinates?.latitude,
            longitude: coordinates?.longitude
          })
        )
      )
      .subscribe();
  }

  openGameLevel(): void {
    combineLatest([this.savedFilters$, this.availableGameLevels$])
      .pipe(
        take(1),
        switchMap(([selectedLevels, availableLevels]: [FiltersValues, SelectableMultiCheckboxModel<GameLevel>[]]) =>
          this.gameLevelModal(availableLevels, selectedLevels.gameLevel)
        ),
        switchMap((gameLevels: GameLevel[]) => {
          return this.setFilterCommandHandler.set({
            gameLevel: gameLevels
          });
        })
      )
      .subscribe();
  }

  openLocationType(): void {
    combineLatest([this.savedFilters$, this.availableLocationTypes$])
      .pipe(
        take(1),
        switchMap(
          ([savedFilters, availableLocationTypes]: [FiltersValues, SelectableMultiCheckboxModel<LocationTypeEnum>[]]) =>
            this.locationTypeModal(availableLocationTypes, savedFilters.locationType)
        ),
        switchMap((selectedLocations: LocationTypeEnum[]) =>
          this.setFilterCommandHandler.set({
            locationType: selectedLocations
          })
        )
      )
      .subscribe();
  }

  getGameLevelName(gameLevel: GameLevel[]): string {
    if (!gameLevel?.length) return 'dowolny';

    if (gameLevel?.length === 1) return gameLevelMapper[gameLevel[0]];

    return `${gameLevelMapper[gameLevel[0]]} +${gameLevel?.length - 1}`;
  }

  setDate(data: CalendarHeader): void {
    if (data.isSelected) {
      this.setFilterCommandHandler.set({ specificDate: undefined }).subscribe();
    } else {
      this.setFilterCommandHandler.set({ specificDate: data.value }).subscribe();
    }
  }

  resetFilters(): void {
    this.loadRealCoordinates(false);

    this.coordinates$
      .pipe(
        take(1),
        switchMap((coordinates: CoordinatesDTO | null) => {
          const areCoordinatesAvailable: boolean = !!coordinates?.latitude && !!coordinates?.longitude;

          return this.setFilterCommandHandler.set({
            specificDate: undefined,
            maxDistanceMeters: areCoordinatesAvailable ? 70000 : undefined,
            locationType: [],
            gameLevel: [],
            longitude: areCoordinatesAvailable ? coordinates?.longitude : undefined,
            latitude: areCoordinatesAvailable ? coordinates?.latitude : undefined
          });
        })
      )

      .subscribe();
  }

  getLocationTypeName(locationType: LocationTypeEnum[]): string {
    if (!locationType?.length) return 'dowolny';

    if (locationType?.length === 1) return locationTypeMapper[locationType[0]].name;

    return `${locationTypeMapper[locationType[0]]?.name} +${locationType?.length - 1}`;
  }

  private locationTypeModal(
    availableLocationTypes: SelectableMultiCheckboxModel<LocationTypeEnum>[],
    selectedLocationTypes: LocationTypeEnum[]
  ): Observable<LocationTypeEnum[]> {
    return this.modalProvider
      .showModal$<LocationTypeEnum[]>({
        component: SelectableMultiCheckboxModalComponent<LocationTypeEnum>,
        cssClass: 'present-modal',
        componentProps: {
          buttonText: 'Wybierz',
          header: 'Typ boiska',
          providedData: availableLocationTypes,
          selectedData: selectedLocationTypes
        }
      })
      .pipe(map((result: LocationTypeEnum[]) => result ?? selectedLocationTypes));
  }

  private gameLevelModal(
    availableLevels: SelectableMultiCheckboxModel<GameLevel>[],
    selectedLevels: GameLevel[]
  ): Observable<GameLevel[]> {
    return this.modalProvider
      .showModal$<GameLevel[]>({
        component: SelectableMultiCheckboxModalComponent<GameLevel>,
        cssClass: 'present-modal',
        componentProps: {
          buttonText: 'Wybierz',
          header: 'Poziom gierki',
          providedData: availableLevels,
          selectedData: selectedLevels
        }
      })
      .pipe(map((result: GameLevel[]) => result ?? selectedLevels));
  }

  private distanceModal(
    availableDistance: SingleRadioItemModel<number>[],
    filters: FiltersValues
  ): Observable<number | null> {
    return this.modalProvider
      .showModal$<number>({
        component: SelectableSingleRadioModalComponent,
        cssClass: 'present-modal',
        componentProps: {
          buttonText: 'Wybierz',
          header: 'Odległość',
          providedData: availableDistance,
          selectedData: filters.maxDistanceMeters ?? null
        }
      })
      .pipe(map((result: number) => (result ?? filters.maxDistanceMeters) || null));
  }

  private locationPermissionDenied(): void {
    this.setFilterCommandHandler
      .set({
        maxDistanceMeters: undefined,
        latitude: undefined,
        longitude: undefined
      })
      .pipe(
        take(1),
        switchMap(() =>
          this.modalProvider.showModal$<void>({
            component: PresentModalComponent,
            componentProps: {
              header: 'Włącz lokalizację',
              message: 'Włącz lokalizację jeśli chcesz korzystać z tej funkcji.',
              btnTxt: 'Zamknij'
            },
            cssClass: 'present-modal'
          })
        )
      )
      .subscribe();
  }

  private loadRealCoordinates(withMessage: boolean) {
    this.coordinates$
      .pipe(
        filter((coordinates: CoordinatesDTO | null) => !coordinates?.latitude || !coordinates?.longitude),
        first(),
        tap(() => {
          if (withMessage) this.modalProvider.showLoading$({ duration: 0, message: 'Pobieranie lokalizacji...' });
        }),
        switchMap(() => this.geolocationProvider.getCoordinates()),
        switchMap((coordinates: CoordinatesDTO | null) => {
          this.coordinatesSubject.next(coordinates);

          return this.setFilterCommandHandler.set({
            latitude: coordinates?.latitude,
            longitude: coordinates?.longitude
          });
        }),
        tap(() => {
          if (withMessage) this.modalProvider.dismissLoading$();
        })
      )
      .subscribe();
  }
}
