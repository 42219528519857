import { inject } from '@angular/core';
import { Observable, switchMap, take } from 'rxjs';
import { ChannelTypeEnum } from '../enums';
import { ChatInitializer } from '../initializers';

export class OpenChatCommandHandler {
  private readonly chatInitializer: ChatInitializer = inject(ChatInitializer);

  open(channelExternalId: string, channelType: ChannelTypeEnum): Observable<void> {
    return this.chatInitializer.init(channelType).pipe(
      take(1),
      switchMap(() => this.chatInitializer.openChannel(channelExternalId, channelType))
    );
  }
}
