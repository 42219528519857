<lib-title-navbar [title]="navbarTitle$ | async"></lib-title-navbar>

<div class="or-pt-32-px or-px-20-px" [fullHeightScrollableContainer]="180">
  <form class="or-justify-content-center or-mb-48-px" [formGroup]="createGameForm">
    <div class="or-d-flex or-flex-col">
      <div class="or-d-flex or-flex-col or-mb-12-px">
        <span class="or-head-xs or-color-secondary-2 or-mb-2-px">Nazwa wydarzenia</span>

        <input formControlName="name" [class.error-field]="isFieldInvalid('name')" class="input-style outline-none" />
        <div *ngIf="nameValueInvalid" class="or-text-sm or-color-red or-mt-2-px or-ml-4-px">
          Maksymalna długość to 25 znaków
        </div>
      </div>

      <div class="or-d-flex or-justify-content-between or-mb-12-px">
        <div class="or-d-flex or-flex-col or-w-75-pc">
          <div class="or-head-xs or-color-secondary-2 or-mb-2-px">Data rozpoczęcia</div>
          <div
            id="startDate"
            class="input-style or-d-flex or-align-items-center"
            [class.error-field]="isFieldInvalid('startDate')">
            <span>{{ getFormattedDate }}</span>
          </div>

          <ion-modal class="date-time-modal" trigger="startDate" [initialBreakpoint]="1" [breakpoints]="[0, 1]">
            <ng-template>
              <ion-datetime
                [value]="startDate$ | async"
                presentation="date-time"
                [preferWheel]="true"
                (ionChange)="changeStartDate($event)"
                size="cover"
                [showDefaultButtons]="true"
                done-text="Wybierz"
                cancel-text="Zamknij"></ion-datetime> </ng-template
          ></ion-modal>
        </div>

        <div class="or-d-flex or-flex-col or-w-20-pc">
          <div class="or-head-xs or-color-secondary-2 or-mb-2-px">Czas (min)</div>
          <input
            type="number"
            formControlName="duration"
            class="input-style outline-none"
            [class.error-field]="isFieldInvalid('duration')" />
        </div>
      </div>

      <div class="or-d-flex or-flex-col or-mb-12-px">
        <span class="or-head-xs or-color-secondary-2 or-mb-2-px">Adres</span>

        <input
          ngx-gp-autocomplete
          #placesRef="ngx-places"
          (onAddressChange)="handleAddressChange($event)"
          class="input-style outline-none"
          [placeholder]="(previousGame$ | async)?.location?.address ?? ''"
          [options]="autoCompleteOptions"
          [value]="previousGame ? previousGame.location.name : null"
          [class.error-field]="isFieldInvalid('address')" />
      </div>

      <div class="or-d-flex or-flex-col or-mb-12-px">
        <span class="or-head-xs or-color-secondary-2 or-mb-2-px">Typ boiska</span>
        <input
          (click)="openLocationTypeModal()"
          class="input-style outline-none"
          [value]="getLocationTypeValue"
          [class.error-field]="isFieldInvalid('locationType')" />
      </div>

      <div class="or-d-flex or-justify-content-between or-mb-12-px">
        <div class="or-d-flex or-flex-col or-w-35-pc">
          <div class="or-head-xs or-color-secondary-2 text-no-wrap or-mb-2-px">Ilu graczy szukasz?</div>
          <input
            type="number"
            formControlName="totalSlots"
            class="input-style outline-none"
            [class.error-field]="isFieldInvalid('totalSlots')" />
        </div>

        <div class="or-d-flex or-flex-col or-w-60-pc">
          <div class="or-head-xs or-color-secondary-2 or-mb-2-px">Poziom</div>
          <input
            (click)="openGameLevelModal()"
            class="input-style outline-none"
            [value]="getGameLevelValue"
            [class.error-field]="isFieldInvalid('level')" />
        </div>
      </div>

      <div class="or-d-flex or-justify-content-between or-mb-12-px">
        <div class="or-d-flex or-flex-col or-w-35-pc">
          <div class="or-head-xs or-color-secondary-2 or-mb-2-px">Cena</div>
          <input
            type="number"
            formControlName="priceAmount"
            class="input-style outline-none"
            [class.error-field]="isFieldInvalid('priceAmount')" />
        </div>

        <div class="or-d-flex or-flex-col or-w-60-pc">
          <div class="or-head-xs or-color-secondary-2 or-mb-2-px">Płatność</div>
          <input
            [disabled]="!isPaymentTypeVisible"
            (click)="openPaymentModal()"
            class="input-style outline-none"
            [value]="getPaymenValue"
            [class.error-field]="isFieldInvalid('paymentTypes')"
            [class.disabled-button]="isFreeGame" />
        </div>
      </div>

      <div class="or-d-flex or-flex-col or-mb-12-px">
        <span class="or-head-xs or-color-secondary-2 or-mb-2-px">Opis wydarzenia</span>
        <ion-textarea
          formControlName="description"
          class="textarea-style outline-none"
          maxlength="250"
          rows="3"
          auto-grow="true"></ion-textarea>
      </div>

      <div class="or-d-flex or-flex-col or-mb-12-px">
        <span class="or-head-xs or-color-secondary-2 or-mb-2-px">Numer telefonu</span>
        <div class="or-d-flex or-align-items-center">
          <div class="phone-input-prefix or-align-content-center or-align-items-center">+48</div>
          <input formControlName="phone" class="phone-input outline-none or-w-100-pc" type="text" inputmode="numeric" />
        </div>
        <div class="or-note-sm or-mb-12-px or-mt-4-px">
          Podanie numeru telefonu pozwoli graczom w szybki sposób wysłać Blika bądź skontaktować się z Tobą w razie
          potrzeby
        </div>
      </div>

      <div *ngIf="!(isEditMode$ | async)" class="or-align-items-center or-d-flex">
        <ion-checkbox
          (ionChange)="createGamePlayerForHost($event)"
          checked="true"
          labelPlacement="end"
          justify="start"
          class="or-text-md"></ion-checkbox>
        <span class="or-head-sm or-ml-8-px">Chcę zagrać w tej gierce!</span>
      </div>

      <div class="or-align-items-center or-d-flex or-mt-12-px">
        <ion-checkbox
          (ionChange)="markAsPrivateGame($event)"
          [checked]="(previousVisibility$ | async) === VisibilityEnum.PRIVATE"
          labelPlacement="end"
          justify="start"></ion-checkbox>
        <span class="or-head-sm or-ml-8-px">Ukryj na mapie i liście</span>
      </div>

      <div class="or-mt-2-px or-note-sm or-ml-36-px">
        Wydarzenie nie pojawi się na mapie i liście gier. W razie chęci znalezienia graczy do gierki, będziesz mógł to
        zmienić w ustawieniach gry.
      </div>

      <div class="or-align-items-center or-d-flex or-mt-12-px">
        <ion-checkbox
          (ionChange)="markAsIsRecurring($event)"
          [checked]="previousRecurring$ | async"
          labelPlacement="end"
          justify="start"></ion-checkbox>
        <span class="or-head-sm or-ml-8-px">Powtarzalna gierka</span>
      </div>

      <div class="or-mb-12-px or-mt-2-px or-note-sm or-ml-36-px">
        Zaznacz, by wydarzenie tworzyło się automatycznie co tydzień. Dzięki temu, nie będziesz musiał tworzyć nowej
        gierki.
      </div>
    </div>
  </form>
</div>

<div class="or-bg-shade or-d-flex or-justify-content-center or-pb-24-px or-w-100-pc z-index-999">
  <div class="or-d-flex or-w-90-pc">
    <button
      (click)="create()"
      type="submit"
      class="button-primary-style or-h-48-px or-w-100-pc or-head-md"
      [ngClass]="{ 'disabled-button': !createGameForm.valid }">
      {{ okButtonTitile$ | async }}
    </button>
  </div>
</div>
