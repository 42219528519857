import { BehaviorSubject, map, Observable, take } from 'rxjs';
import { AdminUsersFiltersModel } from '../../../application/models';
import { AdminUsersFiltersContextPort } from './admin-users-filters.context-port';

export class AdminUsersFiltersStorage implements AdminUsersFiltersContextPort {
  private readonly filtersSubject: BehaviorSubject<AdminUsersFiltersModel> =
    new BehaviorSubject<AdminUsersFiltersModel>({ search: '', platform: [] });

  filters$: Observable<AdminUsersFiltersModel> = this.filtersSubject.asObservable();

  patchContext(state: Partial<AdminUsersFiltersModel>): Observable<void> {
    return this.filtersSubject.asObservable().pipe(
      take(1),
      map((context: AdminUsersFiltersModel) => this.filtersSubject.next({ ...context, ...state }))
    );
  }
}
