import { Observable, switchMap, take } from "rxjs";
import { inject } from "@angular/core";
import { FutureGamesStorage } from "../../infrastructure/storage";
import { GAMES_DTO, GamesDTOPort } from "../../infrastructure/http-service";
import { FutureGameDTO } from "../interfaces";

export class LoadFutureGamesCommandHandler {
  private readonly futureGamesStorage: FutureGamesStorage =
    inject(FutureGamesStorage);
  private readonly gamesDTOPort: GamesDTOPort = inject(GAMES_DTO);

  load(): Observable<void> {
    return this.gamesDTOPort.getFutureGames().pipe(
      take(1),
      switchMap((data: FutureGameDTO) =>
        this.futureGamesStorage.setContext(data)
      )
    );
  }
}
