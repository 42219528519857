import { NOTIFICATIONS_DTO, NotificationsDTOPort } from '../../infrastructure';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

export class MarkNotificationAsClickedCommandHandler {
  private readonly notificationsDtoPort: NotificationsDTOPort = inject(NOTIFICATIONS_DTO);

  markasClicked(notificationId: string): Observable<void> {
    return this.notificationsDtoPort.markAsClicked(notificationId);
  }
}
