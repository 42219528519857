<ion-header [ngClass]="{ 'or-pt-32-px header-bg-color': isIOs() }">
  <ion-toolbar class="header-bg-color or-d-flex or-h-72-px">
    <div class="or-align-items-center or-d-flex or-justify-content-between">
      <img (click)="goBack()" src="assets/icons/back-arrow-icon.svg" class="or-cursor-pointer" />

      <div class="or-d-flex or-flex-col or-align-items-center">
        <div (click)="redirect()" class="or-color-white or-head-md text-no-wrap">{{ details.channelName }}</div>
        <div *ngIf="details.channelType === 'multi'" class="or-color-white or-text-sm text-no-wrap or-mt-4-px">
          {{ date }}
        </div>
      </div>

      <div class="or-align-content-center or-d-flex or-min-w-36-px">
        <img
          *ngIf="
            details.channelType === 'multi' || details.channelType === 'team'
          "
          (click)="dotsClicked()"
          src="assets/icons/multi-dots-white-icon.svg"
          class="or-px-16-px" />
      </div>
    </div>
  </ion-toolbar>
</ion-header>
