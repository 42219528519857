import { inject, Injector, runInInjectionContext } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from '../../../../../../src/app/app-routes.enum';
import { TeamsRoutes } from '../../../../../teams/src/lib/teams-routes.enum';

export enum RedirectStoragePath {
  SHARED_GAME = 'shared-game',
  TEAM_INVITATION_ACCEPT = 'team-invitation-accept'
}

interface StorageTeamInvitationAccept {
  teamId: string;
  token: string;
}

export const redirectAfterLoginUtil = (injector: Injector) => {
  const sharedGameId: string | null = sessionStorage.getItem(RedirectStoragePath.SHARED_GAME);
  const teamInvitationAccept: StorageTeamInvitationAccept = JSON.parse(
    sessionStorage.getItem(RedirectStoragePath.TEAM_INVITATION_ACCEPT) as string
  );

  return runInInjectionContext(injector, () => {
    const router: Router = inject(Router);

    if (sharedGameId) {
      return router.navigate([`/${AppRoutes.GAMES}/shared/${sharedGameId}`]);
    }

    if (teamInvitationAccept) {
      const url: string = `${AppRoutes.TEAMS}/${TeamsRoutes.ACCEPT_INVITATION}/${teamInvitationAccept.teamId}/${teamInvitationAccept.token}`;

      return router.navigate([url]);
    }

    return router.navigate([`/${AppRoutes.ROOT}`]);
  });
};
