<div class="or-d-flex or-flex-col or-justify-content-between">
  <div>
    <ng-container *ngIf="!isHostedGame">
      <div class="or-head-xl or-text-align-center">Jak oceniasz organizatora?</div>
      <div class="or-d-flex or-justify-content-center">
        <bar-rating max="5" [rate]="0" (rateChange)="changeHostRate($event)" class="or-my-12-px">
          <img *ratingActive class="or-m-4-px or-size-40-px" src="assets/icons/star-fill.svg" />
          <img *ratingInactive class="or-m-4-px or-size-40-px" src="assets/icons/star.svg" />
        </bar-rating>
      </div>

      <div class="or-d-flex or-justify-content-center or-mb-20-px">
        <div class="custom-divider"></div>
      </div>
    </ng-container>

    <div class="or-head-xl or-text-align-center">Jak oceniasz naszą aplikację?</div>
    <div class="or-d-flex or-justify-content-center">
      <bar-rating max="5" [rate]="0" (rateChange)="changeAppRate($event)" class="or-mt-12-px">
        <img *ratingActive class="or-m-4-px or-size-40-px" src="assets/icons/star-fill.svg" />
        <img *ratingInactive class="or-m-4-px or-size-40-px" src="assets/icons/star.svg" />
      </bar-rating>
    </div>

    <form [formGroup]="ratingForm" class="or-mx-20-px or-mb-16-px">
      <ion-textarea
        *ngIf="showCommentLabel"
        formControlName="comment"
        class="textarea-style or-text-lg outline-none"
        maxlength="1000"
        rows="5"
        placeholder="Opisz, co Ci się nie podoba..."></ion-textarea>
    </form>
  </div>

  <div class="or-d-flex or-justify-content-center or-mb-16-px">
    <button
      (click)="sendRate()"
      [class.disabled-button]="ratingNotSet"
      class="button-primary-style or-h-48-px or-w-90-pc or-head-md">
      Wystaw ocenę!
    </button>
  </div>
</div>
