import { DeepLinkService, DynamicLinkType, SocialMetaTagInfo, socialMetaTagInfoMapper } from '@core';
import { inject } from '@angular/core';
import { environment } from 'src/environment/environment';
import { AppRoutes } from 'src/app/app-routes.enum';
import { TeamsRoutes } from '../../teams-routes.enum';
import { Observable } from 'rxjs';

export class GenerateInviteUrlCommandHandler {
  private readonly deepLinkService: DeepLinkService = inject(DeepLinkService);

  generate(teamId: string, token: string): Observable<string> {
    const socialMetaTagInfo: SocialMetaTagInfo = socialMetaTagInfoMapper[DynamicLinkType.TEAM_INVITATION];
    const url: string = `${environment.frontendUrl}/${AppRoutes.TEAMS}/${TeamsRoutes.ACCEPT_INVITATION}/${teamId}/${token}`;

    return this.deepLinkService.generateLink(url, socialMetaTagInfo);
  }
}
