import { CommentTypeEnum } from "../enums";
import { CommentModel } from "../interfaces";

export const commentMapper: Record<CommentTypeEnum, CommentModel> = {
  [CommentTypeEnum.GOOD_PLAYER]: {
    displayName: "Wyjątkowo dobry gracz",
    value: CommentTypeEnum.GOOD_PLAYER,
  },
  [CommentTypeEnum.FAIR_PLAY]: {
    displayName: "Trzyma się zasad Fair Play",
    value: CommentTypeEnum.FAIR_PLAY,
  },
  [CommentTypeEnum.TEAM_PLAY]: {
    displayName: "Gra zespołowo",
    value: CommentTypeEnum.TEAM_PLAY,
  },
  [CommentTypeEnum.HELPFUL]: {
    displayName: "Pomocny dla reszty grupki",
    value: CommentTypeEnum.HELPFUL,
  },
  [CommentTypeEnum.SPRINTER]: {
    displayName: "Biega całe spotkanie",
    value: CommentTypeEnum.SPRINTER,
  },
};
