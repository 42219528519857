import { ContactDtoPort } from './contact.dto-port';
import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { ENV_CONFIG, EnvConfig } from '@core';
import { Observable } from 'rxjs';

export class ContactService implements ContactDtoPort {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  sendMessage(message: string): Observable<void> {
    return this.httpClient.post<void>(`${this.env.apiUrl}/contact/message`, {
      message: message
    });
  }
}
