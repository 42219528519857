import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { IsAuthedService, LocalStorageKey } from '@core';
import { inject } from '@angular/core';
import { take, tap } from 'rxjs';

export const sharedGameResolver: ResolveFn<void> = (activatedRoute: ActivatedRouteSnapshot) => {
  const isAuthed: IsAuthedService = inject(IsAuthedService);
  const sharedGameId: string = activatedRoute.params['id'];

  isAuthed.isAuthed$
    .pipe(
      take(1),
      tap((isAuthed: boolean) => {
        if (isAuthed) return sessionStorage.removeItem(LocalStorageKey.SharedGame);

        return sessionStorage.setItem(LocalStorageKey.SharedGame, sharedGameId);
      })
    )
    .subscribe();
};
