import { UserService } from '../../infrastructure';
import { inject } from '@angular/core';
import { map, Observable, switchMap } from 'rxjs';
import { UserTrophiesDTO, UserTrophyDTO } from '../interfaces';
import { ActivatedRoute, Params } from '@angular/router';
import { userTrophiesMapper, UserTrophyModel } from '@core';

export class GetUserTrophiesQueryHandler {
  private readonly userService: UserService = inject(UserService);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  getTrophies(): Observable<UserTrophyModel[]> {
    return this.activatedRoute.params.pipe(
      switchMap((params: Params) => this.userService.getUserTrophies(params['id'])),
      map((trophies: UserTrophiesDTO) =>
        trophies.trophies.map((trophy: UserTrophyDTO) => {
          return {
            name: userTrophiesMapper[trophy.trophyType]?.name,
            description: userTrophiesMapper[trophy.trophyType]?.description,
            icon: userTrophiesMapper[trophy.trophyType]?.icon
          };
        })
      )
    );
  }
}
