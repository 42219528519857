<div class="or-px-32-px or-pt-32-px or-head-lg or-text-align-center">{{ header }}</div>

<ion-radio-group (ionChange)="setData($event)" [value]="selectedData$ | async">
  <div class="or-px-32-px or-pt-20-px or-pb-16-px">
    <ion-radio
      [value]="item.value"
      labelPlacement="end"
      alignment="center"
      justify="start"
      *ngFor="let item of providedData$ | async"
      class="or-p-16-px or-mb-8-px or-w-100-pc"
      [ngClass]="
        (isSelected(item.value) | async)
          ? 'is-active or-bg-focus or-color-primary'
          : 'is-no-active or-bg-white'
      ">
      <div
        class="or-head-sm"
        [ngClass]="
          (isSelected(item.value) | async)
            ? ' or-color-primary'
            : 'or-color-black'
        ">
        {{ item.name }}
      </div>
    </ion-radio>
  </div>
</ion-radio-group>

<div class="or-d-flex or-justify-content-center">
  <button class="button-primary-style or-h-48-px or-w-60-pc or-head-md or-mb-32-px" (click)="closeModal()">
    {{ buttonText }}
  </button>
</div>
