import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, map, Observable, take, tap } from 'rxjs';
import { ENV_CONFIG, EnvConfig } from '../env';
import { ChatUserDataDTO, UserDTO, UserModel } from '../interfaces';

const INIT_STATE: UserModel = {
  userId: null,
  email: null,
  phone: null,
  displayName: null,
  name: null,
  surname: null,
  role: null,
  avatarUrl: null,
  isHost: null,
  isPaidHost: null,
  chatSettings: null
} as unknown as UserModel;

@Injectable({ providedIn: 'root' })
export class UserDataService {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  private readonly userDataSubject: BehaviorSubject<UserModel> = new BehaviorSubject<UserModel>(INIT_STATE);

  readonly userData$: Observable<UserModel> = this.userDataSubject.asObservable();

  setUserData(): void {
    combineLatest([this.getUserData(), this.getChatUserData()])
      .pipe(
        take(1),
        map(([userData, chatUserData]: [UserDTO, ChatUserDataDTO]) => {
          return {
            userId: userData.userId,
            userAuthId: userData.userAuthId,
            email: userData.email,
            phone: userData.phone?.substring(3),
            displayName: `${userData.name} ${userData.surname}`,
            name: userData.name,
            surname: userData.surname,
            role: userData.role,
            avatarUrl: userData.thumbnailUrl,
            isHost: userData.isHost,
            isPaidHost: userData.isPaidHost,
            chatSettings: chatUserData,
            playerPreferences: userData.playerPreferences,
            profileType: userData.profileType,
            referralToken: userData.referralToken,
            canSetReferral: userData.canSetReferral
          };
        }),
        tap((userData: UserModel) => this.userDataSubject.next(userData))
      )
      .subscribe();
  }

  resetUserDataState() {
    this.userDataSubject.next(INIT_STATE);
  }

  private getUserData(): Observable<UserDTO> {
    return this.httpClient.get<UserDTO>(`${this.env.apiUrl}/users/me`);
  }

  private getChatUserData(): Observable<ChatUserDataDTO> {
    return this.httpClient.get<ChatUserDataDTO>(`${this.env.apiUrl}/chat/user/me`);
  }
}
