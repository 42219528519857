export enum ErrorEnum {
  HostApprovalAllSlotsAlreadyTaken = 'HostApprovalAllSlotsAlreadyTaken',
  GameAlreadyPending = 'GameAlreadyPending',
  GameApplicationAlreadyPending = 'GameApplicationAlreadyPending',
  GameApplicationAlreadyApproved = 'GameApplicationAlreadyApproved',
  NotHandledTimezone = 'NotHandledTimezone',
  GameEndDateBeforeStartDate = 'GameEndDateBeforeStartDate',
  GameStartDateBeforeCurrentDatePlusOffset = 'GameStartDateBeforeCurrentDatePlusOffset',
  CannotEditGameAfterItsFinished = 'CannotEditGameAfterItsFinished',
  CannotCancelGameIfSomePlayersAlreadyPaid = 'CannotCancelGameIfSomePlayersAlreadyPaid',
  UserDeleted = 'UserDeleted',
  CannotApproveGamePlayerApplicationItsTooLate = 'CannotApproveGamePlayerApplicationItsTooLate',
  CannotCreateRatingAlreadyExists = 'CannotCreateRatingAlreadyExists'
}
