import { ModalProvider, PresentModalComponent } from '@core';

export const showInstructionModal = (modalProvider: ModalProvider) => {
  modalProvider.showModal$({
    component: PresentModalComponent,
    componentProps: {
      header: 'Czym jest chat gierki?',
      message: chatInstruction,
      btnTxt: 'Zamknij',
      centerMessage: false
    },
    cssClass: 'present-modal'
  });
};

const chatInstruction: string = `
<p>Chat gierki, to miejsce w którym możesz pisać z ludźmi którzy są w Twojej gierce.</p>
W zależności od wyboru Twojej gierki:
<ul>
  <li><b>gierka pojedyncza</b> - chat zostanie usunięty 3 godziny po zakończonym spotkaniu</li>
  <li><b>gierka powtarzalna</b> - chat będzie stały, ale po każdej zakończonej gierce wiadomości będą czyszczone 3 godziny po zakończonym spotkaniu</li>
</ul>
`;
