import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppRoutes } from "./app-routes.enum";
import { AuthModule, isAuthedGuard, referrerResolver } from "@auth";
import { FindGamesModule } from "@find-games";
import { GamesModule } from "@games";
import { HostedGamesModule } from "@hosted-games";
import { ProfileModule } from "@profile";
import { NotificationsModule } from "@notifications";
import { MessagesModule } from "@messages";
import { BlockedVersionPage, UpdateGuard } from "@update";
import {
  BlockedWebViewComponent,
  PolicyPrivacyComponent,
} from "@ui-components";
import { AdminPanelModule } from "@admin-panel";
import { IntroductionModule } from "../../projects/introduction/src/introduction.module";
import { IntroductionGuard, provideIntroductionService } from "@introduction";
import { DashboardModule } from "@dashboard";
import { TeamsModule } from "@teams";
import { blockWebVersionGuard } from "@core";

const routes: Routes = [
  {
    path: AppRoutes.ROOT,
    redirectTo: AppRoutes.DASHBOARD,
    pathMatch: "full",
    resolve: { referrerResolver },
  },
  {
    path: AppRoutes.DASHBOARD,
    loadChildren: () => DashboardModule,
    canActivate: [
      isAuthedGuard,
      blockWebVersionGuard,
      UpdateGuard,
      IntroductionGuard,
    ],
    resolve: { referrerResolver },
    providers: [provideIntroductionService()],
  },
  {
    path: AppRoutes.AUTH,
    loadChildren: () => AuthModule,
    canActivate: [UpdateGuard],
  },
  {
    path: AppRoutes.GAMES,
    loadChildren: () => GamesModule,
    canActivate: [UpdateGuard],
  },
  {
    path: AppRoutes.FIND_GAMES,
    loadChildren: () => FindGamesModule,
    canActivate: [blockWebVersionGuard, UpdateGuard],
  },
  {
    path: AppRoutes.HOST,
    loadChildren: () => HostedGamesModule,
    canActivate: [blockWebVersionGuard, isAuthedGuard, UpdateGuard],
  },
  {
    path: AppRoutes.PROFILE,
    loadChildren: () => ProfileModule,
    canActivate: [blockWebVersionGuard, isAuthedGuard, UpdateGuard],
  },

  {
    path: AppRoutes.NOTIFICATIONS,
    loadChildren: () => NotificationsModule,
    canActivate: [blockWebVersionGuard, isAuthedGuard, UpdateGuard],
  },
  {
    path: AppRoutes.MESSAGES,
    loadChildren: () => MessagesModule,
    canActivate: [blockWebVersionGuard, isAuthedGuard, UpdateGuard],
  },
  {
    path: AppRoutes.TEAMS,
    loadChildren: () => TeamsModule,
    canActivate: [UpdateGuard],
    resolve: { referrerResolver },
  },
  {
    path: AppRoutes.BLOCKED_VERSION,
    component: BlockedVersionPage,
  },
  {
    path: AppRoutes.POLICY_PRIVACY,
    component: PolicyPrivacyComponent,
  },
  {
    path: AppRoutes.ADMIN_PANEL,
    loadChildren: () => AdminPanelModule,
    canActivate: [blockWebVersionGuard, isAuthedGuard],
  },
  {
    path: AppRoutes.INTRODUCTION,
    loadChildren: () => IntroductionModule,
    canActivate: [blockWebVersionGuard, isAuthedGuard],
  },
  {
    path: AppRoutes.WEB_BLOCKED,
    component: BlockedWebViewComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
