<div [class.or-mr-8-px]="rightMargin">
  <ion-avatar
    *ngIf="avatarUrl && !base64 && !imageFallback"
    [ngClass]="avatarClass"
  >
    <img
      [src]="avatarUrl"
      [class.avatar-white-border]="showWhiteBorder"
      [class.custom-avatar-sm-size-with-borders]="showWhiteBorder"
      (error)="onImageError()"
    />
  </ion-avatar>

  <div
    *ngIf="(!avatarUrl && initials && !base64) || (imageFallback && initials)"
    class="or-bg-focus or-color-primary"
    [ngClass]="initialsClass"
    [class.avatar-white-border]="showWhiteBorder"
  >
    {{ getInitials }}
  </div>

  <div *ngIf="base64 && !avatarUrl && !initials" [ngClass]="avatarClass">
    <img [src]="base64" class="custom-avatar-img" />
  </div>
</div>
