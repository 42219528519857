import { inject, Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, tap } from 'rxjs';
import { AppRoutes } from 'src/app/app-routes.enum';

@Injectable({ providedIn: 'root' })
export class NavigationService implements OnDestroy {
  private readonly router: Router = inject(Router);
  private _history!: string[];
  private subscription: Subscription = Subscription.EMPTY;

  get history(): string[] {
    return this._history?.filter((h: string) => h.toLowerCase() !== this.router.url?.toLowerCase()) || [];
  }

  get hasHistory(): boolean {
    return !!this.history?.length;
  }

  init(): void {
    if (this._history) {
      return;
    }

    this._history = [];
    this.subscription = this.router.events
      .pipe(
        tap((nav: unknown) => {
          if (nav instanceof NavigationEnd) {
            const historySize: number = this._history.length;
            const hasTwoItems: boolean = historySize > 1;
            if (hasTwoItems && this._history[historySize - 2] === nav.urlAfterRedirects) {
              this._history.pop();
              return;
            }

            if (!historySize || (historySize && this._history[historySize - 1] !== nav.urlAfterRedirects))
              this._history.push(nav.urlAfterRedirects);
          }
        })
      )
      .subscribe();
  }

  previousPage(): string {
    return this._history?.length > 0 ? this._history[this._history.length - 1] : AppRoutes.ROOT;
  }

  back(): void {
    if (!this.hasHistory) {
      this.router.navigateByUrl(AppRoutes.ROOT);
      return;
    }

    this._history.pop();
    const backUrl: string = this._history?.length > 0 ? this._history[this._history.length - 1] : AppRoutes.ROOT;
    this.router.navigateByUrl(backUrl);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
