import { ChangeDetectionStrategy, Component, EventEmitter, inject, Output } from '@angular/core';
import { MODAL_TOKEN, ModalProvider, PresentModalComponent } from '@core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { from, take } from 'rxjs';

@Component({
  selector: 'lib-reset-password',
  templateUrl: './reset-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule]
})
export class ResetPasswordComponent {
  @Output() email = new EventEmitter<string>();

  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly angularFireAuth: AngularFireAuth = inject(AngularFireAuth);

  readonly emailForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required])
  });

  closeModal(): void {
    this.modalProvider.dismissAllStoredModals();
  }

  resetPassword(): void {
    from(this.angularFireAuth.sendPasswordResetEmail(this.emailForm.get('email')?.value))
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.modalProvider.showModal$({
            component: PresentModalComponent,
            componentProps: {
              header: 'Przypomnij hasło',
              message: 'Sukces. Jeśli posiadasz u nas konto link zostanie wyslany na Twój adres e-mail.',
              btnTxt: 'Zamknij'
            },
            cssClass: 'present-modal'
          });
        },
        error: () => {
          this.modalProvider.showModal$({
            component: PresentModalComponent,
            componentProps: {
              header: 'Przypomnij hasło',
              message: 'Podaj poprawny e-mail',
              btnTxt: 'Zamknij'
            },
            cssClass: 'present-modal'
          });
        }
      });
  }
}
