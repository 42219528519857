import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { environment } from 'src/environment/environment';
import { getNewVersionWebAndroid, getNewVersionWebiOS } from '@update';
import { NgIf } from '@angular/common';

@Component({
  selector: 'lib-blocked-web-view',
  templateUrl: './blocked-web-view.component.html',
  styleUrls: ['./blocked-web-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf]
})
export class BlockedWebViewComponent {
  @Input({ required: true }) path: string = '';

  public isPopupVisible: boolean = false;

  redirectToNativeApp(): void {
    const sharedUrl: string = `${environment.customUrl}/${this.path}`;
    const defaultUrl: string = `${environment.customUrl}`;

    const url = this.path ? sharedUrl : defaultUrl;
    window.open(url, '_blank');
    this.isPopupVisible = true;
  }

  downloadAndroid(): void {
    getNewVersionWebAndroid();
  }

  downloadiOS(): void {
    getNewVersionWebiOS();
  }
}
