import { UnreadMessagesDTO } from "../interfaces";
import { map, Observable, shareReplay, switchMap } from "rxjs";
import {
  UNREAD_MESSAGES_DTO,
  UnreadMessagesDtoPort,
} from "../../infrastructure/firebase-service";
import { inject } from "@angular/core";
import { USER_DATA_TOKEN, UserDataProvider, UserModel } from "@core";
import { ChannelTypeEnum } from "../enums";

export class UnreadMessagesQueryHandler {
  private readonly unreadMessagesDtoPort: UnreadMessagesDtoPort =
    inject(UNREAD_MESSAGES_DTO);
  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);

  readonly unreadMessages$: Observable<UnreadMessagesDTO[]> =
    this.userDataProvider.userData$.pipe(
      switchMap((userData: UserModel) =>
        this.unreadMessagesDtoPort.unreadCount(userData.userId)
      ),
      shareReplay(1)
    );

  isUnreadMessage(): Observable<boolean> {
    return this.unreadMessages$.pipe(
      map((channels: UnreadMessagesDTO[]) =>
        channels.some(
          (channel: UnreadMessagesDTO) =>
            channel.newMessageAt > channel.readAt || !channel.readAt
        )
      )
    );
  }

  isUnreadMulti(): Observable<boolean> {
    return this.unreadMessages$.pipe(
      map((channels: UnreadMessagesDTO[]) =>
        channels.some(
          (channel: UnreadMessagesDTO) =>
            (channel.newMessageAt > channel.readAt &&
              channel.channelType === ChannelTypeEnum.MULTI) ||
            (channel.channelType === ChannelTypeEnum.MULTI && !channel.readAt)
        )
      )
    );
  }

  isUnreadSingle(): Observable<boolean> {
    return this.unreadMessages$.pipe(
      map((channels: UnreadMessagesDTO[]) =>
        channels.some(
          (channel: UnreadMessagesDTO) =>
            (channel.newMessageAt > channel.readAt &&
              channel.channelType === ChannelTypeEnum.SINGLE) ||
            (channel.channelType === ChannelTypeEnum.SINGLE && !channel.readAt)
        )
      )
    );
  }

  isUnreadTeam(): Observable<boolean> {
    return this.unreadMessages$.pipe(
      map((channels: UnreadMessagesDTO[]) =>
        channels.some(
          (channel: UnreadMessagesDTO) =>
            (channel.newMessageAt > channel.readAt &&
              channel.channelType === ChannelTypeEnum.TEAM) ||
            (channel.channelType === ChannelTypeEnum.TEAM && !channel.readAt)
        )
      )
    );
  }
}
