import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TitileNavbarComponent } from '@ui-components';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { PLATFORM_TOKEN, PlatformProvider } from '@core';
import { Router, RouterLink } from '@angular/router';
import { AppRoutes } from '../app-routes.enum';

@Component({
  selector: 'lib-custom-notification',
  templateUrl: './wm72h-info.component.html',
  styleUrls: ['./wm72h-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TitileNavbarComponent, AsyncPipe, NgIf, NgForOf, RouterLink]
})
export class Wm72hInfoComponent {
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly router: Router = inject(Router);

  readonly isiOS: boolean = this.platformProvider.isiOS;
  readonly imageUrl =
    'https://firebasestorage.googleapis.com/v0/b/orlikfy-infra-prod.appspot.com/o/assets%2Fwm72h.png?alt=media&token=1721238d-2eee-49a5-b623-d967cf2892eb';

  back(): void {
    this.router.navigateByUrl(AppRoutes.DASHBOARD);
  }

  redirect(): void {
    window.open('https://competit.pl/w/1818/', ' _blank');
  }
}
