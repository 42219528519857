<div class="or-d-flex or-flex-col negative-margin-top-20-px">
  <div class="or-text-md or-text-align-center or-mb-20-px">
    Dbając o dobrą kulturę w naszej aplikacji, powinieneś wykonać następujące akcje:
  </div>

  <div class="border-shade border-radius-4 or-p-16-px or-bg-white or-mb-8-px">
    <div class="or-head-sm or-mb-4-px">Znajdź zastępstwo</div>
    <div class="or-text-sm">Poszukaj zawodnika na swoje miejsce i poinformuj gospodarza, że jest On za Ciebie.</div>
  </div>

  <div class="border-shade border-radius-4 or-p-16-px or-bg-white or-mb-20-px">
    <div class="or-head-sm or-mb-4-px">Napisz do organizatora gierki</div>
    <div class="or-text-sm">Kiedy nie możesz znaleźć zastępstwa, skontaktuj się z gospodarzem gierki.</div>
  </div>

  <div class="or-text-md or-text-align-center">
    W przypadku nie przyjścia na gierkę, gospodarz będzie mógł Ci wystawić czerwoną kartkę. Będzie ona widoczna w Twoim
    profilu dla wszystkich użytkowników.
  </div>
</div>
