import { AUTH_DTO, AuthDtoPort } from "../../infrastructure/http-services";
import { inject, Injector } from "@angular/core";
import { CredentialsDTO } from "../models";
import { from, Observable, switchMap, take } from "rxjs";
import { setReferrerIdUtil } from "./set-referrer-id.util";

export class RegisterCommandHandler {
  private readonly authDtoPort: AuthDtoPort = inject(AUTH_DTO);
  private readonly injector: Injector = inject(Injector);

  register(credentials: CredentialsDTO): Observable<void> {
    return from(this.authDtoPort.register(credentials)).pipe(
      take(1),
      switchMap(() => setReferrerIdUtil(this.injector))
    );
  }
}
