<ng-container *ngIf="savedFilters$ | async as savedFilters">
  <div class="or-d-flex overflow-x-auto or-pl-8-px or-pr-8-px or-mt-16-px">
    <div
      *ngFor="let day of daysRange$ | async"
      (click)="setDate(day)"
      class="or-head-sm or-mr-4-px or-p-8-px or-d-flex or-justify-content-center or-cursor-pointer text-no-wrap or-min-w-60-px"
      [ngClass]="{
        checked: day.isSelected,
        unchecked: !day.isSelected
      }"
    >
      <div
        class="or-d-flex or-flex-col or-align-items-center or-justify-content-center"
      >
        <div>{{ day.date }}</div>
        <div class="or-text-transform-capitalize-first-letter">
          {{ day.dayAbbreviation }}
        </div>
      </div>
    </div>
  </div>

  <div class="or-px-12-px or-d-flex or-mt-8-px">
    <div
      *ngIf="showDistanceFilter"
      (click)="openDistanceModal()"
      class="border-shade border-radius-4 or-d-flex or-mr-12-px or-bg-white or-p-8-px or-text-md"
    >
      <div class="or-color-secondary-2 or-mr-4-px">Odległość:</div>
      <div>
        {{
          savedFilters.maxDistanceMeters
            ? savedFilters.maxDistanceMeters / 1000 + "  km"
            : "dowolna"
        }}
      </div>
    </div>
    <div
      class="border-shade border-radius-4 or-d-flex or-bg-white or-p-8-px or-text-md"
    >
      <div class="or-color-secondary-2 or-mr-4-px">Poziom:</div>
      <div>dowolny</div>
    </div>
  </div>
</ng-container>
