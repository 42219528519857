<div class="or-pb-8-px">
  <lib-filters></lib-filters>
</div>

<div
  [class.full-height-scrollable-container]="!isiOS"
  [class.full-height-scrollable-container-ios]="isiOS"
>
  <div
    *ngIf="isAdmin$"
    class="or-align-items-center or-d-flex or-justify-content-between or-mx-16-px"
  >
    <div class="or-text-md">Pokaż prywatne gierki</div>
    <ion-toggle
      [checked]="true"
      (ionChange)="setShowPrivateGames($event.detail.checked)"
    >
    </ion-toggle>
  </div>

  <div *ngFor="let group of games$ | async; let first = first">
    <div
      class="or-head-md or-color-secondary-2 or-pl-20-px"
      [ngClass]="first ? 'or-mt-8-px' : 'or-mt-24-px'"
    >
      {{ group.date }}
    </div>
    <div *ngFor="let game of group.games" class="or-my-12-px">
      <lib-list-game-card [game]="game"></lib-list-game-card>
    </div>
  </div>
</div>
