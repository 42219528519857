<lib-title-navbar title="Poleć znajomego!"></lib-title-navbar>
<div class="or-mt-64-px or-px-20-px">
  <div class="or-head-xxl or-text-align-center">
    Poleć ORLIKFY<br />
    i zdobywaj nagrody!
  </div>
  <div class="or-text-xl or-text-align-center or-mt-28-px">
    Rejestrując nowe konto w Orlikfy wykorzystując poniższy link, Ty i Twój znajomy otrzymacie punkty, które możecie
    wymienić na nagrody!
  </div>

  <ng-container *ngIf="referralUrl$ | async as url">
    <div *ngIf="url" class="or-d-flex or-flex-col or-w-100-pc or-text-align-center">
      <div class="or-head-md or-mt-48-px">Twój indywidualny link:</div>
      <div (click)="copy()" class="or-d-flex or-justify-content-center or-align-items-center or-mt-12-px input-style">
        <div class="or-text-md or-mr-12-px">{{ url }}</div>
        <img src="assets/icons/copy-referral.svg" />
      </div>
    </div>
  </ng-container>
</div>
