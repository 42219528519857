<div class="or-mx-20-px or-align-items-center or-d-flex or-flex-col or-justify-content-center or-h-100vh">
  <div class="or-head-xl or-mb-28-px or-text-align-center">
    Jeśli chcesz nadal korzystać z apliakcji musisz ją zaktualizować
  </div>
  <button class="button-secondary-style or-h-48-px or-w-100-pc or-head-md or-mb-12-px" (click)="goToApp()">
    Przejdź do aplikacji
  </button>
  <button class="button-primary-style or-h-48-px or-w-100-pc or-head-md" (click)="getApk()">
    Pobierz aktualizację
  </button>

  <div class="or-mt-24-px">Twoja wersja: {{appVersion}}</div>
  <div>Najnowsza wersja: {{newestVersion$ | async}}</div>
</div>
