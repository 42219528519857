import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import {
  BottomNavbarComponent,
  DateFormatPipe,
  GameCardV2Component,
  GameDetailsComponent,
  OrAvatarComponent,
  RedirectTypeEnum,
  TitileNavbarComponent
} from '@ui-components';
import { BehaviorSubject, Observable, switchMap, tap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { AppRoutes } from 'src/app/app-routes.enum';
import { Router } from '@angular/router';
import { ProfileRoutes } from '../../profile.routes.enum';
import {
  formattedTimeDifferenceUtil,
  GameDetailsModel,
  GlobalRefreshService,
  MODAL_TOKEN,
  ModalProvider,
  NOTIFICATION_TOKEN,
  NotificationPermissionProvider,
  PLATFORM_TOKEN,
  PlatformProvider
} from '@core';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import { GetUserLastGamesQueryHandler, UserDetailsQueryHandler } from '../../application/handlers';
import { UserDetailsDTO } from '../../application/interfaces';
import { CardHistoryComponent, UserCommentsComponent } from '@fair-play';
import { UserStatsComponent } from '../user-stats/user-stats.component';
import { PlayerPreferencesComponent } from '../player-preferences/player-preferences.component';
import { AlertBarComponent } from '../alert-bar/alert-bar.component';
import { TrophiesComponent } from '../trophies/trophies.component';
import { UserGamesHttpService } from '../../infrastructure';
import { CreateSingleChatCommandHandler } from '@messages';
import { PlayerRankComponent, RankService } from '@rank';
import { FeatureFlag, FeatureFlagHandler } from '@feature-flags';

@Component({
  selector: 'lib-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TitileNavbarComponent,
    BottomNavbarComponent,
    IonicModule,
    OrAvatarComponent,
    CardHistoryComponent,
    UserStatsComponent,
    PlayerPreferencesComponent,
    AlertBarComponent,
    UserCommentsComponent,
    TrophiesComponent,
    GameDetailsComponent,
    DateFormatPipe,
    PlayerRankComponent,
    GameCardV2Component
  ],
  providers: [
    UserDetailsQueryHandler,
    GetUserLastGamesQueryHandler,
    UserGamesHttpService,
    CreateSingleChatCommandHandler,
    RankService
  ]
})
export class ProfileComponent {
  private readonly router: Router = inject(Router);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly notificationPermissionProvider: NotificationPermissionProvider = inject(NOTIFICATION_TOKEN);
  private readonly globalRefreshService: GlobalRefreshService = inject(GlobalRefreshService);
  private readonly cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  private readonly userDetailsQueryHandler: UserDetailsQueryHandler = inject(UserDetailsQueryHandler);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly getUserLastGamesQueryHandler: GetUserLastGamesQueryHandler = inject(GetUserLastGamesQueryHandler);
  private readonly createSingleChatCommandHandler: CreateSingleChatCommandHandler =
    inject(CreateSingleChatCommandHandler);
  private readonly featureFlagHandler: FeatureFlagHandler = inject(FeatureFlagHandler);

  readonly isPageLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public readonly isiOS: boolean = this.platformProvider.isiOS;
  public isStatsActive: boolean = true;
  public readonly redirectType: RedirectTypeEnum = RedirectTypeEnum.GAME;

  readonly userDetails$: Observable<UserDetailsDTO> = this.modalProvider.showLoading$().pipe(
    switchMap(() => this.userDetailsQueryHandler.details()),
    tap(() => this.isPageLoaded$.next(true)),
    tap(() => this.modalProvider.dismissLoading$())
  );

  readonly isNotificationPermissionsEnabled$: Observable<boolean> = this.globalRefreshService.refresh$.pipe(
    tap(() => this.cdr.markForCheck()),
    switchMap(() => this.notificationPermissionProvider.isEnabled()),
    tap(() => this.cdr.detectChanges())
  );

  readonly userLastGames$: Observable<GameDetailsModel[]> = this.getUserLastGamesQueryHandler.getLastGames();

  readonly rankFF$: Observable<boolean> = this.featureFlagHandler.isEnabled$(FeatureFlag.RANK);

  openNotificationPermissions() {
    if (this.platformProvider.isAndroid)
      return NativeSettings.openAndroid({
        option: AndroidSettings.AppNotification
      });

    return NativeSettings.openIOS({
      option: IOSSettings.Notifications
    });
  }

  goToSettings(): void {
    this.router.navigate([`${AppRoutes.PROFILE}/${ProfileRoutes.SETTINGS}`]);
  }

  calculatelastActive(date: string): string {
    return formattedTimeDifferenceUtil(new Date(date)).toLowerCase();
  }

  calculateClass(isMyProfile: boolean): string {
    if (this.isiOS && isMyProfile) return 'full-height-scrollable-container-ios-my-profile';
    if (this.isiOS && !isMyProfile) return 'full-height-scrollable-container-ios';

    if (!this.isiOS && isMyProfile) return 'full-height-scrollable-container-my-profile';
    return 'full-height-scrollable-container';
  }

  showStats(): void {
    this.isStatsActive = true;
  }

  showLastGames(): void {
    this.isStatsActive = false;
  }

  sendMessage(userId: string): void {
    this.createSingleChatCommandHandler.create(userId).subscribe();
  }
}
