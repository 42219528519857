import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from "@angular/core";
import { GamePlayersDTO, MODAL_TOKEN, ModalProvider } from "@core";
import { OrAvatarComponent } from "@ui-components";
import { AsyncPipe, NgClass, NgForOf, NgIf } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { commentMapper } from "../../application/utils";
import {
  AddCommentPayloadDTO,
  CommentModel,
} from "../../application/interfaces";
import { BehaviorSubject, take } from "rxjs";
import { CommentTypeEnum } from "../../application/enums";
import { UserCommentsService } from "../../infrastructure";
import { AddCommentSuccessModalComponent } from "./add-comment-success-modal/add-comment-success-modal.component";
import { AddCommentCommandHandler } from "../../application/handlers/add-comment.command-handler";

@Component({
  selector: "lib-add-comment-modal",
  templateUrl: "./add-comment-modal.component.html",
  styleUrls: ["./add-comment-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [OrAvatarComponent, NgIf, IonicModule, NgForOf, AsyncPipe, NgClass],
  providers: [AddCommentCommandHandler, UserCommentsService],
})
export class AddCommentModalComponent {
  @Input() gameId!: string;
  @Input() player!: GamePlayersDTO;

  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly addCommentCommandHandler: AddCommentCommandHandler = inject(
    AddCommentCommandHandler
  );

  public comments: CommentModel[] = Object.values(commentMapper);

  readonly selectedComment$: BehaviorSubject<CommentTypeEnum> =
    new BehaviorSubject<CommentTypeEnum>(null as unknown as CommentTypeEnum);

  closeModal() {
    this.modalProvider.dismissModal$();
  }

  addComent(): void {
    const comment: CommentTypeEnum = this.selectedComment$.value;
    const payload: AddCommentPayloadDTO = {
      gameId: this.gameId,
      userId: this.player.userId,
      commentType: commentMapper[comment].value,
    };

    this.addCommentCommandHandler
      .addComment(payload)
      .pipe(take(1))
      .subscribe(() => {
        this.modalProvider.dismissModal$();
        this.confirmationModal();
      });
  }

  select(event: CustomEvent): void {
    this.selectedComment$.next(event.detail.value);
  }

  selectBox(value: CommentTypeEnum): void {
    this.selectedComment$.next(value);
  }

  isSelected(value: CommentTypeEnum): boolean {
    return this.selectedComment$.value === value;
  }

  private confirmationModal(): void {
    this.modalProvider
      .showModal$({
        component: AddCommentSuccessModalComponent,
        cssClass: "present-modal",
        componentProps: {
          player: this.player,
        },
      })
      .subscribe(() => this.modalProvider.dismissAllStoredModals());
  }
}
