import { inject } from "@angular/core";
import {
  AdminUsersFiltersModel,
  UsersListDetailsDTO,
  UsersListDTO,
} from "../models";
import { combineLatestWith, map, Observable } from "rxjs";
import {
  USERS_LIST_DTO,
  UsersListDtoPort,
} from "../../infrastructure/http-services";
import {
  AdminUsersFiltersContextPort,
  AMIN_USERS_CONTEXT,
} from "../../infrastructure/storages";

export class GetAdminUsersListQueryHandler {
  private readonly usersListDtoPort: UsersListDtoPort = inject(USERS_LIST_DTO);
  private readonly adminUsersFiltersContextPort: AdminUsersFiltersContextPort =
    inject(AMIN_USERS_CONTEXT);

  getList(): Observable<UsersListDetailsDTO[]> {
    return this.usersListDtoPort.getAllUsers().pipe(
      map((data: UsersListDTO) => data.users),
      map((users: UsersListDetailsDTO[]) =>
        users.filter((user: UsersListDetailsDTO) => !!user.versionInfo)
      ),
      map((users: UsersListDetailsDTO[]) =>
        users
          .map((user: UsersListDetailsDTO) => user)
          .sort((a, b) => b.registerDate - a.registerDate)
      ),
      combineLatestWith(this.adminUsersFiltersContextPort.filters$),
      map(([users, context]: [UsersListDetailsDTO[], AdminUsersFiltersModel]) =>
        this.filterByQuery(users, context)
      ),
      combineLatestWith(this.adminUsersFiltersContextPort.filters$),
      map(
        ([users, context]: [UsersListDetailsDTO[], AdminUsersFiltersModel]) => {
          if (!context.platform?.length) return users;
          return users.filter((user: UsersListDetailsDTO) =>
            context.platform.includes(user.platform)
          );
        }
      )
    );
  }

  private filterByQuery(
    users: UsersListDetailsDTO[],
    context: AdminUsersFiltersModel
  ): UsersListDetailsDTO[] {
    return users.filter(
      (user: UsersListDetailsDTO) =>
        user.name.toLowerCase().includes(context.search.toLowerCase()) ||
        user.lastName.toLowerCase().includes(context.search.toLowerCase()) ||
        user.email.toLowerCase().includes(context.search.toLowerCase()) ||
        user.versionInfo.toLowerCase().includes(context.search.toLowerCase())
    );
  }
}
