import { EMPTY, filter, first, Observable, of, switchMap, take } from 'rxjs';
import { StartupState } from '../startup.state';
import { inject } from '@angular/core';
import { StartupStep } from '../startup-step';
import { CoordinatesDTO, GEOLOCATION_TOKEN, GeolocationProvider } from '@core';
import { SetFilterCommandHandler } from '../../../../projects/find-games/src/lib/application/handlers';

export class SetDefaultFiltersStep extends StartupStep {
  private readonly setFilterCommandHandler: SetFilterCommandHandler = inject(SetFilterCommandHandler);
  private readonly geolocationProvider: GeolocationProvider = inject(GEOLOCATION_TOKEN);

  executeStep(state: StartupState): Observable<StartupState> {
    state.authed$
      ?.pipe(
        filter(Boolean),
        first(),
        switchMap(() => this.geolocationProvider.getCoordinates()),
        take(1),
        switchMap((coordinates: CoordinatesDTO | null) => {
          if (coordinates?.longitude && coordinates?.latitude) {
            return this.setFilterCommandHandler.set({
              maxDistanceMeters: 70000,
              latitude: coordinates?.latitude,
              longitude: coordinates?.longitude
            });
          }

          return EMPTY;
        })
      )
      .subscribe();

    return of(state);
  }
}
