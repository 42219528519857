import { Directive, HostListener, inject, Input } from "@angular/core";
import { Router } from "@angular/router";
import { AppRoutes } from "src/app/app-routes.enum";
import { MODAL_TOKEN, ModalProvider } from "../tokens";

@Directive({
  standalone: true,
  selector: "[redirectToUserProfile]",
})
export class RedirectToUserProfileDirective {
  @Input("redirectToUserProfile") userId!: string | null | undefined;

  private readonly router: Router = inject(Router);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  @HostListener("click", ["$event"])
  onClick(event: Event): void {
    event.stopImmediatePropagation();

    if (this.userId) {
      this.modalProvider.dismissModal$();

      this.router.navigateByUrl(`${AppRoutes.PROFILE}/${this.userId}`);
    }
  }
}
