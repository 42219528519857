import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TitileNavbarComponent } from '../navbar';

@Component({
  selector: 'lib-policy-privacy',
  templateUrl: './policy-privacy.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TitileNavbarComponent]
})
export class PolicyPrivacyComponent {}
