import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, map, Observable, shareReplay, switchMap, take, tap } from 'rxjs';
import { GamesListQueryHandler } from '../../application/handlers';
import {
  FullHeightScrollableContainerDirective,
  GameDetailsModel,
  MODAL_TOKEN,
  ModalProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
  USER_DATA_TOKEN,
  UserDataProvider,
  UserModel,
  UserRoleEnum
} from '@core';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { GamesListModel } from '../../application/interfaces';
import { IonicModule } from '@ionic/angular';
import { FiltersComponent } from '../filters/filters.component';
import { GameCardV2Component } from '@ui-components';
import { GameDetailsModalComponent } from '@games';
import { VisibilityEnum } from '../../../../../hosted-games/src/lib/application/enums';

@Component({
  selector: 'lib-games-list',
  templateUrl: './games-list.component.html',
  styleUrls: ['./games-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgForOf,
    AsyncPipe,
    IonicModule,
    NgIf,
    FiltersComponent,
    NgClass,
    FullHeightScrollableContainerDirective,
    GameCardV2Component
  ],
  providers: [GamesListQueryHandler]
})
export class GamesListComponent implements OnInit {
  private readonly gamesListQueryHandler: GamesListQueryHandler = inject(GamesListQueryHandler);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);

  private readonly showPrivate$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  readonly isAdmin$: Observable<boolean> = this.userDataProvider.userData$.pipe(
    map((userData: UserModel) => userData.role === UserRoleEnum.ADMIN)
  );

  readonly privateAccess$: Observable<boolean> = combineLatest([this.showPrivate$, this.isAdmin$]).pipe(
    map(([showPrivate, isAdmin]: [boolean, boolean]) => showPrivate && isAdmin)
  );

  readonly games$: Observable<GamesListModel[]> = this.modalProvider.showLoading$().pipe(
    switchMap(() => this.privateAccess$),
    switchMap((showPrivate: boolean) => this.gamesListQueryHandler.getGames(showPrivate)),
    tap(() => this.modalProvider.dismissLoading$()),
    shareReplay(1)
  );

  readonly gamesCount$ = this.games$.pipe(
    map((games: GamesListModel[]) => {
      const allGames: GameDetailsModel[] = games?.map((game: GamesListModel) => game?.games?.flat())?.flat();
      const privateGames: GameDetailsModel[] = allGames?.filter(
        (game: GameDetailsModel) => game.visibility === VisibilityEnum.PRIVATE
      );

      return {
        totalGames: allGames?.length,
        privateGames: privateGames?.length
      };
    })
  );

  ngOnInit(): void {
    if (this.platformProvider.isNativeRun) {
      this.platformProvider.requestPermissions();
    }
  }

  setShowPrivateGames(value: boolean) {
    this.showPrivate$.next(value);
  }

  openDetailsModal(gameId: string): void {
    this.userDataProvider.userData$
      .pipe(
        take(1),
        switchMap((userData: UserModel) =>
          this.modalProvider.showModal$({
            component: GameDetailsModalComponent,
            componentProps: {
              gameId: gameId,
              adminAccess: userData.role === UserRoleEnum.ADMIN
            },
            cssClass: 'modal-auto',
            initialBreakpoint: 1,
            breakpoints: [0, 1]
          })
        )
      )
      .subscribe();
  }
}
