import { TeamsService } from '../../infrastructure';
import { inject } from '@angular/core';

export class GetTeamCandidatesQueryHandler {
  private readonly teamsService: TeamsService = inject(TeamsService);

  candidates(teamId: string) {
    return this.teamsService.getCandidates(teamId);
  }
}
