<ng-container *ngIf="userId$ | async as userId">
  <div class="or-position-fixed or-left-0 or-w-100-pc or-bottom-0 z-index-999 or-bg-white border-top-1-px-black">
    <div class="or-d-flex or-justify-content-between">
      <div
        *ngFor="let tab of tabsData"
        (click)="redirect(tab.route, userId)"
        [class.active]="isActive(tab.route)"
        class="or-w-100-pc"
        [ngClass]="isiOS ? 'or-pb-16-px' : 'or-pb-8-px'">
        <div class="or-align-items-center or-d-flex or-flex-col or-justify-content-center or-mx-auto or-pt-8-px">
          <ion-icon
            [src]="tab.icon"
            class="or-w-24-px z-index-3 or-size-28-px or-py-4-px or-px-12-px border-radius-12"
            [class.or-bg-focus]="isActive(tab.route)"></ion-icon>
          <div
            class="z-index-3 or-text-sm or-mt-4-px"
            [ngClass]="
              isActive(tab.route) ? 'or-color-primary' : 'or-color-secondary-2'
            ">
            {{ tab.name }}
          </div>

          <ng-container *ngIf="tab.route === 'messages'">
            <ng-container *ngIf="isUnreadMessage$ | async">
              <img
                src="assets/icons/unread-icon.svg"
                class="or-ml-28-px or-bottom-48-px or-position-absolute z-index-999" />
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>
