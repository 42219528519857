import { Observable } from 'rxjs';
import { CONTACT_DTO, ContactDtoPort } from '../../infrastructure';
import { inject } from '@angular/core';

export class SendFeedbackCommandHandler {
  private readonly contactDtoPort: ContactDtoPort = inject(CONTACT_DTO);

  send(message: string): Observable<void> {
    return this.contactDtoPort.sendMessage(message);
  }
}
