import { filter, first, Observable, of, switchMap, take, tap } from 'rxjs';
import { StartupState } from '../startup.state';
import { StartupStep } from '../startup-step';
import { MODAL_TOKEN, ModalProvider, ProfileType, USER_DATA_TOKEN, UserDataProvider, UserModel } from '@core';
import { ProfileTypeModalComponent } from '../../../../projects/profile/src/lib/components/profile-type-modal/profile-type-modal.component';
import { UpdateProfileTypeCommandHandler } from '../../../../projects/profile/src/lib/application/handlers';
import { inject } from '@angular/core';

export class ObserveProfileTypeStep extends StartupStep {
  private modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private updateProfileTypeCommandHandler: UpdateProfileTypeCommandHandler = inject(UpdateProfileTypeCommandHandler);
  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);

  executeStep(state: StartupState): Observable<StartupState> {
    state.authed$
      ?.pipe(
        filter(Boolean),
        first(),
        switchMap(() => this.userDataProvider.userData$),
        filter((userData: UserModel) => !userData.profileType && !!userData.name),
        first(),
        switchMap(() =>
          this.modalProvider.showModal$<ProfileType>({
            component: ProfileTypeModalComponent,
            cssClass: 'present-modal',
            backdropDismiss: false
          })
        ),
        take(1),
        switchMap((type: ProfileType) => this.updateProfileTypeCommandHandler.updateProfileType(type)),
        tap(() => this.userDataProvider.setUserData())
      )
      .subscribe();

    return of(state);
  }
}
