import { filter, first, Observable, of, switchMap, tap } from 'rxjs';
import { StartupState } from '../startup.state';
import { inject } from '@angular/core';
import { StartupStep } from '../startup-step';
import { PushPermissionsStatusCommandHandler, UpdateAlertsLocationCommandHandler } from '@notifications';

export class SetupAlertsStep extends StartupStep {
  private readonly updateAlertsLocationCommandHandler: UpdateAlertsLocationCommandHandler = inject(
    UpdateAlertsLocationCommandHandler
  );
  private readonly pushPermissionsStatusCommandHandler: PushPermissionsStatusCommandHandler = inject(
    PushPermissionsStatusCommandHandler
  );

  executeStep(state: StartupState): Observable<StartupState> {
    state.authed$
      ?.pipe(
        filter(Boolean),
        first(),
        switchMap(() => this.updateAlertsLocationCommandHandler.setLocation()),
        switchMap(() => this.pushPermissionsStatusCommandHandler.setValue())
      )
      .subscribe();

    return of(state);
  }
}
