<div class="or-p-16-px or-pt-40-px or-bg-shade">
  <div class="or-head-xs or-color-secondary-2 or-mb-12-px">Szczegóły gracza</div>

  <div class="or-d-flex or-align-items-center or-mb-20-px or-justify-content-between">
    <div class="or-d-flex or-align-items-center">
      <lib-or-avatar
        [avatarUrl]="player.userThumbnail"
        [initials]="player.userDisplayName"
        [avatarSize]="'sm'"></lib-or-avatar>

      <div class="or-head-sm or-truncate-at-line-1">{{ player.userDisplayName }}</div>

      <div class="or-d-flex or-align-items-center or-ml-8-px">
        <img
          *ngIf="player.playerPreferences?.preferredPosition === 'GOALKEEPER'"
          class="or-mr-4-px"
          src="assets/icons/goalkeeper.svg" />

        <img *ngIf="player.penalty" [src]="cardReasonMapper[player.penalty.penaltyReason].icon" />
      </div>
    </div>

    <img *ngIf="player.isPaid" src="assets/icons/payment-icon.svg" />
  </div>

  <div
    *ngIf="isPlayerInGame"
    (click)="changePaymentStatus()"
    class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-mb-8-px or-d-flex or-p-8-px">
    <img src="assets/icons/payment-2-icon.svg" class="or-mr-8-px" />
    <div *ngIf="!player.isPaid" class="or-text-md">Oznacz jako opłacone</div>
    <div *ngIf="player.isPaid" class="or-text-md">Oznacz jako nieopłacone</div>
  </div>

  <div
    *ngIf="!player.isHost && player.userId"
    (click)="addComment()"
    class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-mb-8-px or-d-flex or-p-8-px or-h-56-px or-max-h-28-px">
    <img src="assets/icons/comment-icon-shade.svg" class="or-mr-8-px or-size-32-px" />
    <div class="or-text-md">Przyznaj komplement</div>
  </div>

  <div
    *ngIf="!player.isHost && player.userId"
    (click)="addCard()"
    class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-mb-8-px or-d-flex or-p-8-px or-h-56-px or-max-h-28-px">
    <img src="assets/icons/shade-card.svg" class="or-mr-8-px or-size-32-px" />
    <div class="or-text-md">Przyznaj kartkę</div>
  </div>

  <div
    *ngIf="!player.userId && isPlayerInGame"
    (click)="editPlayer()"
    class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-mb-8-px or-d-flex or-p-8-px or-h-56-px or-max-h-28-px">
    <img src="assets/icons/edit-2-border.svg" class="or-mr-8-px or-size-32-px" />
    <div class="or-text-md">Edytuj gracza</div>
  </div>

  <div
    *ngIf="isPlayerInGame"
    (click)="removePlayer(player.isPaid)"
    class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-d-flex or-p-8-px">
    <img src="assets/icons/trash-icon.svg" class="or-mr-8-px" />
    <div class="or-text-md or-color-red">Usuń gracza</div>
  </div>
</div>
