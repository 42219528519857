import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Clipboard } from '@capacitor/clipboard';
import { ModalService } from './modal.service';

export class CopyService {
  private readonly modalService: ModalService = inject(ModalService);

  copyToClipboard(value: string): Observable<void> {
    Clipboard.write({ string: value });
    return this.modalService.showToast({
      message: 'Skopiowano do schowka',
      position: 'bottom',
      duration: 2000
    });
  }
}
