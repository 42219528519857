<img src="assets/intro/blue-background.svg" class="intro-header" />

<div *ngIf="showElement" [@slideInOutFromRight] class="or-mb-96-px">
  <div class="or-d-flex or-justify-content-center or-mt-48-px or-mb-32-px or-w-100-pc">
    <img src="assets/intro/intro-2.svg" class="or-size-24" />
  </div>

  <div class="or-text-align-center or-mx-16-px">
    <div class="or-head-xl or-mb-20-px">Kontroluj gierkę jako organizator!</div>

    <div class="or-text-lg or-color-secondary-2">
      Koniec z żmudnym pilnowaniem Facebooka w trakcie dnia gierki. Aplikacja w automatyczny sposób znajdzie graczy dla
      Twojej gierki a Ty w jednym miejscu będziesz mógł informować innych. Koniec z ciągłym odpisywaniem na komentarze
      czy wiadomości!
    </div>
  </div>
</div>

<img (click)="goToMap()" src="assets/right-arrow-button.svg" class="intro-next-button-position" />
