import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ChannelDetailsDTO,
  ChannelMembersResponseDTO,
  CreateSingleChatResponseDTO
} from '../../application/interfaces';

export const CHAT_CHANEL_DTO: InjectionToken<ChatDtoPort> = new InjectionToken<ChatDtoPort>('CHAT_CHANEL_DTO');

export interface ChatDtoPort {
  channelDetails(channelId: string): Observable<ChannelDetailsDTO>;
  channelDetailsByReference(params: string): Observable<ChannelDetailsDTO>;
  channelMembers(channelId: string): Observable<ChannelMembersResponseDTO>;
  createSingleChat(userId: string): Observable<CreateSingleChatResponseDTO>;
  muteChannel(channelId: string, value: boolean): Observable<void>;
}
