<ng-container *ngIf="isMapLoaded$ | async as isMapLoaded">
  <ng-container *ngIf="isMapLoaded.isLoaded">
    <div
      class="or-position-absolute or-w-100-pc z-index-999 or-bg-shade or-pb-12-px"
    >
      <lib-filters [showDistanceFilter]="false"></lib-filters>
    </div>

    <div
      class="or-position-absolute z-index-999 or-mx-20-px"
      [style.width]="arrivalBarWidth$ | async"
      [ngClass]="
        isiOS ? 'location-banner-position-ios' : 'location-banner-position'
      "
    >
      <lib-location-button></lib-location-button>
    </div>
  </ng-container>

  <div #map class="map-container"></div>

  <ng-container *ngIf="isMapLoaded.isLoaded">
    <div
      *ngIf="isAuthed$ | async"
      class="or-position-absolute z-index-3 or-mx-20-px"
      [ngClass]="isiOS ? 'or-bottom-96-px' : 'or-bottom-80-px'"
      [style.width]="arrivalBarWidth$ | async"
    >
      <lib-arrival-bar></lib-arrival-bar>
    </div>
  </ng-container>
</ng-container>
