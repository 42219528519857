import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'dateFormat',
  standalone: true
})
export class DateFormatPipe implements PipeTransform {
  transform(startDate: string, endDate: string): string {
    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit'
    };
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    };
    const formattedStartDate = new Date(startDate).toLocaleDateString('pl-PL', options);
    const formattedStartTime = new Date(startDate).toLocaleTimeString('pl-PL', timeOptions);
    const formattedEndTime = new Date(endDate).toLocaleTimeString('pl-PL', timeOptions);
    return `${
      formattedStartDate.charAt(0).toUpperCase() + formattedStartDate.slice(1)
    } | ${formattedStartTime} - ${formattedEndTime}`;
  }
}
