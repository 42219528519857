<lib-title-navbar title="News"></lib-title-navbar>

<div [class.full-height-scrollable-container]="!isiOS" [class.full-height-scrollable-container-ios]="isiOS">
  <div class="or-px-16-px or-pt-24-px">
    <div class="or-head-xl or-mb-20-px">Orlikfy gra razem z Fundacją MK Sport!</div>

    <img class="or-mb-20-px custom-image-size" [src]="imageUrl" />

    <div class="or-text-md or-mb-20-px">
      Dołącz do historycznego wydarzenia - Zagraj w 72 godzinnym meczu w Krakowie i graj dla dobra dzieci i młodzieży z
      ośrodków opiekuńczo-wychowawczych
    </div>

    <div class="or-head-lg or-mb-20-px">Czym jest Wielki Charytatywny Mecz 72h?</div>

    <div class="or-text-md or-mb-20-px">
      Masz szansę być częścią jedynego w swoim rodzaju wydarzenia sportowego – Wielkiego Charytatywnego Meczu 72h!
    </div>

    <div class="or-text-md or-mb-20-px">
      W dniach 9-12 stycznia 2025 roku Hala Suche Stawy w Krakowie zamieni się w arenę, gdzie przez pełne 72 godziny
      gramy dla dobra dzieci i młodzieży.
    </div>

    <div class="or-head-sm or-mb-20-px">
      Orlikfy wspiera Wielki Charytatywny Mecz - Będziemy tam i zagramy razem z Wami!
    </div>

    <div class="or-text-md or-mb-20-px">
      Liczba miejsc jest ograniczona do 1728 osób, a zapisy trwają do 31 grudnia 2024 roku
    </div>

    <div class="or-head-lg or-my-20-px">
      Dzięki za uwagę,<br />
      Team Orlikfy!
    </div>
  </div>
</div>

<div class="or-d-flex or-justify-content-center">
  <button (click)="redirect()" class="button-primary-style or-h-48-px or-w-100-pc or-head-md or-mx-20-px">
    Dołącz do wydarzenia!
  </button>
</div>
