<div class="or-my-32-px or-mx-24-px">
  <div class="or-d-flex or-justify-content-center">
    <span class="or-head-xl or-mb-16-px or-text-align-center">{{
      header
    }}</span>
  </div>
  <div class="or-mb-20-px" [class.or-text-align-center]="centerMessage">
    <span
      class="or-text-xl"
      [class.or-text-align-center]="centerMessage"
      [innerHTML]="message"
    ></span>
  </div>
  <div class="or-d-flex or-justify-content-center">
    <button
      class="button-primary-style or-h-48-px or-w-60-pc or-head-md"
      (click)="closeModal()"
    >
      {{ btnTxt }}
    </button>
  </div>
</div>
