import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { GetUserCommentQueryHandler } from "../../application/handlers";
import { Observable } from "rxjs";
import { UserCommentDTO } from "../../application/interfaces";
import { AsyncPipe, NgClass, NgForOf, NgIf } from "@angular/common";
import { UserCommentsService } from "../../infrastructure";
import { format } from "date-fns";
import { commentMapper } from "../../application/utils";

@Component({
  selector: "lib-user-comments",
  templateUrl: "./user-comments.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, NgForOf, NgIf, NgClass],
  providers: [GetUserCommentQueryHandler, UserCommentsService],
})
export class UserCommentsComponent {
  private readonly getUserCommentQueryHandler: GetUserCommentQueryHandler =
    inject(GetUserCommentQueryHandler);

  public commentMapper = commentMapper;

  readonly comments$: Observable<UserCommentDTO> =
    this.getUserCommentQueryHandler.getComment();

  formatDate(date: Date): string {
    return format(date, "dd.MM.yyyy");
  }
}
