<lib-home-navbar
  (onAddGameOpened)="goToGameCreator()"
  [isNewNotifications]="!!(isNewNotifications$ | async)"
  [showFiltersIcon]="false"></lib-home-navbar>

<div class="dashboard-top-container"></div>

<div class="negative-margin-55-px z-index-999">
  <lib-dashboard-navbar> </lib-dashboard-navbar>
</div>

<div [fullHeightScrollableContainer]="205">
  <lib-red-card-indicator></lib-red-card-indicator>

  <ng-container *ngIf="!isEmptyStateVisible">
    <lib-dashboard-teams (isTeamsAvailable)="setIsTeamsAvailable($event)"></lib-dashboard-teams>

    <lib-dashboard-invitations
      (isInvitationsAvailable)="setIsInvitationsAvailable($event)"
      (invitationAccepted)="invitationAccepted()"></lib-dashboard-invitations>

    <lib-dashboard-games-list (isGameAvailable)="setIsGameAvailable($event)"></lib-dashboard-games-list>
  </ng-container>

  <ng-container *ngIf="isEmptyStateVisible">
    <lib-dashboard-empty-state></lib-dashboard-empty-state>
  </ng-container>

  <lib-create-team></lib-create-team>
</div>

<lib-bottom-navbar></lib-bottom-navbar>

<lib-close-ad-banner [containerDirective]="containerDirective"></lib-close-ad-banner>
