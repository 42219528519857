import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { BarRatingModule } from 'ngx-bar-rating';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RatingDTO } from '../../application/interfaces';
import { exhaustMap, of, take, tap } from 'rxjs';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/app-routes.enum';
import { PLATFORM_TOKEN, PlatformProvider } from '@core';
import { InAppReview } from '@capacitor-community/in-app-review';
import { RateAppCommandHandler } from '../../application/handlers';

@Component({
  selector: 'lib-rating-app',
  templateUrl: './rating-app.component.html',
  styleUrls: ['./rating-app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [BarRatingModule, AsyncPipe, IonicModule, ReactiveFormsModule, NgIf, NgClass]
})
export class RatingAppComponent implements OnInit {
  private readonly rateApplicationCommandHandler: RateAppCommandHandler = inject(RateAppCommandHandler);
  private readonly router: Router = inject(Router);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);

  @Input({ required: true }) gameId!: string;
  @Input({ required: true }) isHostedGame!: boolean;

  readonly ratingForm: FormGroup = new FormGroup({
    comment: new FormControl(),
    gameId: new FormControl(),
    appRate: new FormControl(0),
    hostRate: new FormControl(0)
  });

  get isIos(): boolean {
    return this.platformProvider.isiOS;
  }

  ngOnInit() {
    this.ratingForm.patchValue({ gameId: this.gameId });
  }

  get showCommentLabel(): boolean {
    return this.ratingForm.get('appRate')?.value < 4 && this.ratingForm.get('appRate')?.value !== 0;
  }

  get ratingNotSet(): boolean {
    return (
      this.ratingForm.get('appRate')?.value === 0 ||
      (this.ratingForm.get('hostRate')?.value === 0 && !this.isHostedGame)
    );
  }

  changeAppRate(event: number): void {
    this.ratingForm.patchValue({ appRate: event });
  }

  changeHostRate(event: number): void {
    this.ratingForm.patchValue({ hostRate: event });
  }

  sendRate(): void {
    if (this.ratingNotSet) return;

    const data: RatingDTO = this.ratingForm.getRawValue();

    const payload: RatingDTO = {
      ...data,
      hostRate: this.isHostedGame ? null : data.hostRate
    };

    of(payload)
      .pipe(
        exhaustMap((payload) =>
          this.rateApplicationCommandHandler.rateApp(payload).pipe(
            take(1),
            tap(() => this.rateInStore(payload.appRate))
          )
        )
      )
      .subscribe(() => this.router.navigate([AppRoutes.FIND_GAMES]));
  }

  private rateInStore(appRate: number) {
    if (!this.platformProvider.isNativeRun || appRate < 5) return;

    InAppReview.requestReview();
  }
}
