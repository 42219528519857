import { Observable, of } from 'rxjs';
import { StartupState } from '../startup.state';
import { PLATFORM_TOKEN, PlatformProvider } from '@core';
import { inject } from '@angular/core';
import { StartupStep } from '../startup-step';

export class GeolocationPermissionsStep extends StartupStep {
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);

  executeStep(state: StartupState): Observable<StartupState> {
    if (this.platformProvider.isNativeRun) {
      this.platformProvider.requestPermissions();
    }

    return of(state);
  }
}
