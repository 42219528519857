import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { BehaviorSubject, filter, Observable, shareReplay, switchMap, take, tap } from 'rxjs';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { GameDetailsComponent } from '@ui-components';
import { JoinButtonsComponent } from '../join-buttons/join-buttons.component';
import { GameDetailsQueryHandler } from '../../application/handlers';
import { provideGamesService } from '../../infrastructure/http-service';
import { GameDetailsModel, MODAL_TOKEN, ModalProvider } from '@core';
import { CreateSingleChatCommandHandler, provideChatService } from '@messages';

@Component({
  templateUrl: './game-details-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, IonicModule, GameDetailsComponent, JoinButtonsComponent],
  providers: [GameDetailsQueryHandler, provideGamesService(), CreateSingleChatCommandHandler, provideChatService()]
})
export class GameDetailsModalComponent implements OnInit {
  @Input() gameId!: string;
  @Input() adminAccess: boolean = false;

  private readonly gameDetailsQueryHandler: GameDetailsQueryHandler = inject(GameDetailsQueryHandler);
  private readonly createSingleChatCommandHandler: CreateSingleChatCommandHandler =
    inject(CreateSingleChatCommandHandler);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  private readonly gameIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  readonly gameDetails$: Observable<GameDetailsModel> = this.gameIdSubject.asObservable().pipe(
    filter((gameId: string) => !!gameId),
    switchMap((gameId: string) => this.gameDetailsQueryHandler.getGameDetails(gameId)),
    shareReplay(1)
  );

  ngOnInit(): void {
    this.gameIdSubject.next(this.gameId);
  }

  sendMessage(userId: string): void {
    this.createSingleChatCommandHandler
      .create(userId)
      .pipe(
        take(1),
        tap(() => this.modalProvider.dismissAllStoredModals())
      )
      .subscribe();
  }
}
