import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { TitileNavbarComponent } from "@ui-components";
import { UsersListComponent } from "../users-list/users-list.component";
import { UsersSummaryComponent } from "../users-summary/users-summary.component";
import { UsersFiltersComponent } from "../users-filters/users-filters.component";
import { provideUsersListService } from "../../../infrastructure/http-services";
import { PatchAdminUsersFiltersCommandHandler } from "../../../application/handlers";

@Component({
  selector: "lib-users-home",
  templateUrl: "./users-home.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TitileNavbarComponent,
    UsersListComponent,
    UsersSummaryComponent,
    UsersFiltersComponent,
  ],
  providers: [provideUsersListService(), PatchAdminUsersFiltersCommandHandler],
})
export class UsersHomeComponent {
  private readonly patchAdminUsersFiltersCommandHandler: PatchAdminUsersFiltersCommandHandler =
    inject(PatchAdminUsersFiltersCommandHandler);

  clearContext(): void {
    this.patchAdminUsersFiltersCommandHandler.reset().subscribe();
  }
}
