import { addWeeks, eachDayOfInterval, isSameDay } from "date-fns";
import { map, Observable } from "rxjs";
import { FiltersStorage } from "../../infrastructure/storages";
import { inject } from "@angular/core";
import { formatDateToString } from "@core";
import { CalendarHeader } from "../interfaces";
import { FiltersValues } from "../models";

export class GetFilterDaysQueryHandler {
  private readonly filtersStorage: FiltersStorage = inject(FiltersStorage);

  filterDays(): Observable<CalendarHeader[]> {
    const startDate: Date = new Date();
    const endDate: Date = addWeeks(startDate, 2);

    const availableRange: Date[] = eachDayOfInterval({
      start: startDate,
      end: endDate,
    });

    return this.filtersStorage.filters$.pipe(
      map((filters: FiltersValues) => {
        return availableRange.map((day) => {
          return {
            dayAbbreviation: formatDateToString(day, "EEEEEE"),
            date: formatDateToString(day, "d.M"),
            isSelected: isSameDay(day, filters.specificDate),
            value: day,
          };
        });
      })
    );
  }
}
