import { inject, InjectionToken } from '@angular/core';
import { Platform } from '@ionic/angular';
import { from, Observable } from 'rxjs';
import { PlatformType } from '../enums/platform-type.enum';
import { Geolocation } from '@capacitor/geolocation';
import { PermissionStates } from '../enums';

export const PLATFORM_TOKEN: InjectionToken<PlatformProvider> = new InjectionToken<PlatformProvider>('PLATFORM_TOKEN', {
  factory: (): PlatformProvider => {
    const platform: Platform = inject(Platform);
    return {
      get isAndroid(): boolean {
        return platform.is(PlatformType.HYBRID) && platform.is(PlatformType.ANDROID);
      },

      get isiOS(): boolean {
        return platform.is(PlatformType.HYBRID) && platform.is(PlatformType.IOS);
      },

      get isiOSBrowser(): boolean {
        return !platform.is(PlatformType.HYBRID) && platform.is(PlatformType.IOS);
      },

      get isNativeRun(): boolean {
        return platform.is(PlatformType.HYBRID);
      },

      get isDesktop(): boolean {
        return !platform.is(PlatformType.HYBRID);
      },

      get isMobileWeb(): boolean {
        return platform.is(PlatformType.MOBILE_WEB);
      },

      get platformType(): PlatformType {
        if (this.isMobileWeb) {
          return PlatformType.MOBILE_WEB;
        }
        if (this.isDesktop) {
          return PlatformType.WEB;
        }
        if (this.isAndroid) {
          return PlatformType.ANDROID;
        }
        if (this.isiOS) {
          return PlatformType.IOS;
        }
        if (this.isNativeRun) {
          return PlatformType.HYBRID;
        }
        return PlatformType.OTHER;
      },

      get isReady$(): Observable<string> {
        return from(platform.ready());
      },

      isLocationEnabled: async () => {
        try {
          const currentPermissionStates = (await Geolocation.checkPermissions()).location;

          return currentPermissionStates !== PermissionStates.DENIED;
        } catch (e) {
          return false;
        }
      },

      isPermissionGranted: async () => {
        try {
          const currentPermissionStates = (await Geolocation.checkPermissions()).location;

          return currentPermissionStates === PermissionStates.GRANTED;
        } catch (e) {
          return false;
        }
      },

      requestPermissions: async () => {
        await Geolocation.requestPermissions();
      }
    };
  }
});

export interface PlatformProvider {
  get isAndroid(): boolean;
  get isiOS(): boolean;
  get isiOSBrowser(): boolean;
  get isNativeRun(): boolean;
  get isDesktop(): boolean;
  get isMobileWeb(): boolean;
  get platformType(): PlatformType;
  get isReady$(): Observable<string>;
  isPermissionGranted(): Promise<boolean>;
  isLocationEnabled(): Promise<boolean>;
  requestPermissions(): void;
}
