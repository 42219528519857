export enum TeamsRoutes {
  ROOT = "",
  CREATE = "create",
  CREATE_FINISH = "create-finish",
  TEAM_DETAILS = "details",
  INVITE = "invite",
  AUTO_APPROVE = "auto-approve",
  EDIT = "edit",
  ACCEPT_INVITATION = "accept-invitation",
}
