import { map, Observable } from 'rxjs';
import { inject } from '@angular/core';
import { HOSTED_GAMES_DTO, HostedGamesDTOPort } from '../../infrastructure/http-service';
import { CreateGameDTO, CreateGameResponseDTO, CreateGameResponseViewModel } from '../interfaces';
import { DateFormatPipe } from '@ui-components';
import { mapToCreateGameViewModelUtil } from './utils/map-to-create-game-view-model.util';

export class CreateGameCommandHandler {
  private readonly hostedGamesDTOPort: HostedGamesDTOPort = inject(HOSTED_GAMES_DTO);
  private readonly dateFormatPipe: DateFormatPipe = inject(DateFormatPipe);

  createGame(game: CreateGameDTO): Observable<CreateGameResponseViewModel> {
    return this.hostedGamesDTOPort
      .createGame({
        ...game,
        gameContactPhone: game.gameContactPhone ? `+48${game.gameContactPhone}` : null,
        priceAmount: game.priceAmount.toString(),
        paymentTypes: !game.priceAmount ? [] : game.paymentTypes
      })
      .pipe(map((response: CreateGameResponseDTO) => mapToCreateGameViewModelUtil(response, this.dateFormatPipe)));
  }
}
