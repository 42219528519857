<div
  *ngIf="isRedCardActive$ | async"
  class="or-d-flex or-align-items-center or-p-12-px border-red border-radius-4 or-bg-red-2 or-mt-16-px or-mx-16-px">
  <img [src]="cardReasonMapper[cardReason.ABSENCE].icon" class="or-mr-16-px or-size-28-px" />
  <div>
    <div class="or-head-sm">Dostałeś czerwoną kartkę!</div>
    <div class="or-text-sm or-color-secondary-2 or-mt-4-px">
      Twoja kartka będzie wyświetlana organizatorom następnych gierek przez 14 dni.
    </div>
  </div>
</div>
