<div class="or-p-16-px or-pt-40-px or-bg-shade">
  <div class="or-head-xs or-color-secondary-2 or-mb-12-px">Zarządzaj chatem</div>

  <div
    (click)="muteChannel()"
    class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-mb-8-px or-d-flex or-p-8-px or-h-56-px or-max-h-28-px">
    <img [src]="muteChatLabel.icon" class="or-mr-8-px or-size-32-px" />
    <div>
      <div class="or-text-md">{{ muteChatLabel.header }}</div>
    </div>
  </div>

  <div
    *ngIf="channelDetails.channelType === 'multi'"
    (click)="showInstructionModal()"
    class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-mb-8-px or-d-flex or-p-8-px or-h-56-px or-max-h-28-px">
    <img src="assets/icons/info-shade-icon.svg" class="or-mr-8-px or-size-32-px" />
    <div>
      <div class="or-text-md">Pomoc</div>
      <div class="or-text-sm or-color-secondary-2">Przeczytaj, na czym polega chat grupowy</div>
    </div>
  </div>
</div>
