import { inject } from '@angular/core';
import { environment } from 'src/environment/environment';
import { USER_DATA_TOKEN, UserDataProvider, UserModel } from '@core';
import { BehaviorSubject, delay, map, Observable, switchMap, take, tap } from 'rxjs';
import { ChannelService, ChatClientService, StreamI18nService } from 'stream-chat-angular';
import { chatTranslation } from 'src/assets/i18n/chat-translation';
import { ActiveChannelModel } from '../interfaces/active-channel.model';
import { ChannelTypeEnum } from '../enums';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/app-routes.enum';
import { MessagesRoutes } from '../../messages-routes.enum';

export class ChatInitializer {
  private userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);
  private chatService: ChatClientService = inject(ChatClientService);
  private channelService: ChannelService = inject(ChannelService);
  private streamI18nService: StreamI18nService = inject(StreamI18nService);
  private router: Router = inject(Router);

  private readonly isChatAvailableSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  readonly isChatAvailable$: Observable<boolean> = this.isChatAvailableSubject.asObservable();

  readonly activeChannelData$: Observable<ActiveChannelModel> = this.channelService.activeChannel$.pipe(
    map((channel) => channel?.data?.['orlikfyData'] as ActiveChannelModel)
  );

  readonly userData$: Observable<UserModel> = this.userDataProvider.userData$;

  init(type: ChannelTypeEnum): Observable<void> {
    return this.userData$.pipe(
      take(1),
      tap(() => this.stopWatching()),
      tap(() => this.channelService.reset()),
      tap((userData: UserModel) => this.initChatService(userData)),
      tap((userData: UserModel) => this.initChannels(userData?.chatSettings?.chatUserExternalId, type)),
      tap(() => this.streamI18nService.setTranslation('pl', chatTranslation)),
      map(() => void 0)
    );
  }

  stopWatching(): void {
    this.channelService.activeChannel?.stopWatching();
    this.channelService.deselectActiveChannel();
  }

  openChannel(channelExternalId: string, channelType: ChannelTypeEnum): Observable<void> {
    this.stopWatching();

    return this.userData$.pipe(
      take(1),
      delay(200),
      switchMap((userData: UserModel) => {
        return this.getSpecificChannel(channelExternalId, channelType, userData.chatSettings.chatUserExternalId);
      }),
      tap((specificChannel) => {
        if (specificChannel) {
          this.channelService.setAsActiveChannel(specificChannel);
        }
      }),
      switchMap(() => this.router.navigateByUrl(`${AppRoutes.MESSAGES}/${MessagesRoutes.CHAT}?type=${channelType}`)),
      map(() => void 0)
    );
  }

  private async getSpecificChannel(
    channelExternalId: string,
    channelType: ChannelTypeEnum,
    userExternalId: string,
    limit: number = 30
  ): Promise<any | undefined> {
    let offset = 0;
    let specificChannel: any | undefined = undefined;

    while (!specificChannel) {
      const response = await this.chatService.chatClient.queryChannels(
        {
          type: channelType,
          members: {
            $in: [userExternalId]
          }
        },
        { last_message_at: -1 },
        { watch: true, limit, offset }
      );

      if (response.length === 0) break;

      specificChannel = response.find((channel) => channel.id === channelExternalId);

      offset += limit;
    }

    return specificChannel;
  }

  private initChatService(userData: UserModel): void {
    this.chatService.init(
      environment.chatToken,
      userData?.chatSettings?.chatUserExternalId,
      userData?.chatSettings?.token
    );
  }

  private async initChannels(chatUserExternalId: string, type: ChannelTypeEnum) {
    const availableChats = await this.channelService.init(
      {
        type: type,
        members: {
          $in: [chatUserExternalId]
        }
      },
      { last_message_at: -1 },
      { watch: true, limit: 10000 },
      false
    );

    this.isChatAvailableSubject.next(!!availableChats?.length);
  }
}
