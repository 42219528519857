import { FiltersStorage } from '../../infrastructure/storages';
import { inject } from '@angular/core';
import { map, Observable } from 'rxjs';
import { FiltersValues } from '../models';
import { CoordinatesDTO } from '@core';

export class AreFiltersDefaultQueryHandler {
  private readonly filtersStorage: FiltersStorage = inject(FiltersStorage);

  areTheSame(coordinates: CoordinatesDTO | null): Observable<boolean> {
    return this.filtersStorage.filters$.pipe(
      map((filters: FiltersValues) => {
        return (
          !filters.gameLevel?.length &&
          !filters.specificDate &&
          !filters.locationType?.length &&
          this.checkLocation(filters.maxDistanceMeters, !!coordinates)
        );
      })
    );
  }

  private checkLocation(selectedDistance: number, locationEnabled: boolean): boolean {
    if (locationEnabled) return selectedDistance === 70000;

    return !selectedDistance;
  }

  private areArraysEqual(arr1: unknown[], arr2: unknown[]): boolean {
    if (arr1?.length !== arr2?.length) return false;

    return arr1?.every((item: unknown) => arr2?.includes(item));
  }
}
