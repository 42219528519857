import { from, map, Observable, switchMap } from "rxjs";
import { AUTH_DTO, AuthDtoPort } from "../../infrastructure/http-services";
import { inject } from "@angular/core";

export class RemoveAccountCommandHandler {
  private readonly authDtoPort: AuthDtoPort = inject(AUTH_DTO);

  removeAccount(password: string): Observable<void> {
    return from(this.authDtoPort.reauthenticate(password)).pipe(
      switchMap(() => this.authDtoPort.removeAccount()),
      map(() => void 0)
    );
  }
}
