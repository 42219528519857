<ng-container
  *ngIf="{
    approvedGames: (playerApprovedGames$ | async),
    waitingGames: (playerWaitingGames$ | async),
    hostedGames: (hostedGames$ | async)
  } as games"
>
  <div
    *ngIf="
      games.approvedGames?.length ||
      games.waitingGames?.length ||
      games.hostedGames?.length
    "
    (click)="changeGamesVisibility()"
    class="or-d-flex or-justify-content-between or-align-items-center or-px-24-px or-mt-20-px"
  >
    <div class="or-head-md">Twoje najbliższe gierki</div>
    <img
      [src]="
        isGamesExpanded
          ? 'assets/icons/chevron-up.svg'
          : 'assets/icons/chevron-down-primary.svg'
      "
    />
  </div>

  <ng-container *ngIf="isGamesExpanded">
    <ng-container *ngIf="games.hostedGames?.length">
      <div
        class="or-pl-24-px or-mt-16-px or-mb-12-px or-head-md or-color-secondary-2"
      >
        Organizator
      </div>
      <div *ngFor="let game of games.hostedGames" class="or-mb-12-px">
        <lib-hosted-future-games-list
          [game]="game"
        ></lib-hosted-future-games-list>
      </div>
    </ng-container>

    <ng-container
      *ngIf="games.approvedGames?.length || games.waitingGames?.length"
    >
      <div
        class="or-pl-24-px or-mt-24-px or-mb-12-px or-head-md or-color-secondary-2"
      >
        Gracz
      </div>
      <div *ngFor="let game of games.approvedGames" class="or-mb-12-px">
        <ion-chip
          class="or-ml-32-px z-index-2 or-head-xs chips-margin-bottom--20-px or-mt-0-px approved"
          >Potwierdzona</ion-chip
        >

        <lib-game-card
          [game]="game"
          [showMoreDetails]="true"
          [showShareButton]="true"
          [redirectType]="gameRedirectType"
          [adjustToChip]="true"
          (onSendMessage)="sendMessage($event)"
        >
        </lib-game-card>
      </div>

      <div *ngFor="let game of games.waitingGames" class="or-mb-12-px">
        <ion-chip
          class="or-ml-32-px z-index-2 or-head-xs chips-margin-bottom--20-px or-mt-0-px waiting"
          >Oczekująca</ion-chip
        >

        <lib-game-card
          [game]="game"
          [showMoreDetails]="true"
          [showShareButton]="true"
          [redirectType]="gameRedirectType"
          [adjustToChip]="true"
          (onSendMessage)="sendMessage($event)"
        >
        </lib-game-card>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
