<div class="or-d-flex or-px-16-px overflow-y-auto or-mt-16-px">
  <div
    *ngIf="canCreateTeam"
    (click)="createTeam()"
    class="border-radius-4 or-p-12-px or-mr-8-px or-w-144-px or-min-w-144-px create-team-bg">
    <img src="assets/icons/dashboard-navbar-team.svg" />
    <div class="or-text-md or-mt-8-px or-color-white">Przenieś swoją</div>
    <div class="or-text-md or-color-white">grupkę do Orlikfy!</div>
  </div>

  <div
    (click)="goToHosted()"
    class="border-shade border-radius-4 or-p-12-px or-mr-8-px or-w-144-px or-min-w-144-px or-bg-white">
    <img src="assets/icons/bottom-navbar/bottom-host.svg" />
    <div class="or-head-md or-my-4-px">Organizator</div>
    <div class="or-text-md or-color-secondary-2">Organizuj gierki</div>
  </div>

  <div
    (click)="goToGames()"
    class="border-shade border-radius-4 or-p-12-px or-mr-8-px or-w-144-px or-min-w-144-px or-bg-white">
    <img src="assets/icons/bottom-navbar/bottom-games.svg" />
    <div class="or-head-md or-my-4-px">Gracz</div>
    <div class="or-text-md or-color-secondary-2">Twoje gierki</div>
  </div>

  <div (click)="goToAlerts()" class="border-shade border-radius-4 or-p-12-px or-w-144-px or-min-w-144-px or-bg-white">
    <img src="assets/icons/dashboard-alerts.svg" />
    <div class="or-head-md or-my-4-px">Alerty o gierkach</div>
    <div class="or-text-md or-color-secondary-2">Ustaw alerty</div>
  </div>

  <!--  <div-->
  <!--    class="border-shade border-radius-4 or-p-16-px or-w-144-px or-min-w-144-px or-bg-white"-->
  <!--   >-->
  <!--    <img src="assets/icons/ranks.svg" />-->
  <!--    <div class="or-head-md">Rankingi</div>-->
  <!--    <div class="or-text-md or-color-secondary-2">Zbieraj punkty</div>-->
  <!--  </div>-->
</div>
