<ng-container *ngIf="trophies$ | async as trophies">
  <ng-container *ngIf="trophies?.length">
    <div class="or-px-20-px or-mt-24-px">
      <div class="or-d-flex or-justify-content-between">
        <div class="or-head-sm or-color-secondary-2">Osiągnięcia</div>
        <div (click)="showAllTrophies()" class="or-head-sm or-color-primary">
          Sprawdź wszystkie
        </div>
      </div>
      <div class="or-mt-12-px">
        <div
          *ngFor="let trophy of trophies; let i = index"
          class="or-d-flex or-align-items-center or-mb-12-px"
        >
          <img
            [src]="trophy.icon"
            class="or-d-flex or-align-self-start or-mr-8-px"
          />
          <div>
            <div class="or-head-sm or-mb-4-px">
              {{ trophy.name }}
            </div>
            <div class="or-text-sm or-color-secondary-6">
              {{ trophy.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
