import { NotificationType } from '../enums';
import { AppRoutes } from 'src/app/app-routes.enum';
import { HostedGamesRoutes } from '../../../../../hosted-games/src/lib/hosted-games.routes.enum';
import { GamesRoutes } from '../../../../../games/src/lib/games-routes.enum';
import { Router } from '@angular/router';
import { NotificationsRoutes } from '../../notifications-routes.enum';
import { ChannelTypeEnum } from '../../../../../messages/src/lib/application/enums';
import { TeamsRoutes } from '../../../../../teams/src/lib/teams-routes.enum';

export const notificationRedirectUtil = (data: {
  type: NotificationType;
  gameId: string;
  router: Router;
  customId?: string;
  channelType?: ChannelTypeEnum;
  customRedirectUrl?: string;
  teamId: string;
  receiverUserId: string;
}): Promise<boolean> => {
  const hostedGames: NotificationType[] = [
    NotificationType.HostedGameNewApplication,
    NotificationType.HostedGamePlayerLeft,
    NotificationType.HostedGameReminder,
    NotificationType.HostedGamePlayerAutoApproved
  ];
  const playerGames: NotificationType[] = [
    NotificationType.GameApplicationApproved,
    NotificationType.GameReminder,
    NotificationType.GameCanceled
  ];

  const sharedGames: NotificationType[] = [NotificationType.NewGameByAlert, NotificationType.GameInvitationRecived];

  const profile: NotificationType[] = [
    NotificationType.UserPenaltyReceived,
    NotificationType.NewTrophyReceived,
    NotificationType.NewCommentReceived
  ];

  if (hostedGames.includes(data.type)) {
    return data.router.navigate([`${AppRoutes.HOST}/${HostedGamesRoutes.FUTURE}/${data.gameId}`]);
  }

  if (playerGames.includes(data.type)) {
    return data.router.navigate([`${AppRoutes.GAMES}/${GamesRoutes.DETAILS}/${data.gameId}`]);
  }

  if (sharedGames.includes(data.type)) {
    return data.router.navigate([`${AppRoutes.GAMES}/${GamesRoutes.SHARED}/${data.gameId}`]);
  }

  if (data.type === NotificationType.GameFinished || data.type === NotificationType.HostedGameFinished) {
    return data.router.navigate([`${AppRoutes.GAMES}/${GamesRoutes.GAME_FINISHED}/${data.gameId}`]);
  }

  if (profile.includes(data.type)) {
    return data.router.navigate([`${AppRoutes.PROFILE}/${data.receiverUserId}`]);
  }

  if (data.type === NotificationType.ChatNewMessage) {
    return data.router.navigate([AppRoutes.MESSAGES], {
      queryParams: { type: data.channelType }
    });
  }

  if (data.type === NotificationType.CustomNotification && !data.customRedirectUrl) {
    return data.router.navigate([
      `${AppRoutes.NOTIFICATIONS}/${NotificationsRoutes.CUSTOM_NOTIFICATION}/${data.customId}`
    ]);
  }

  if (data.type === NotificationType.CustomNotification && data.customRedirectUrl) {
    return data.router.navigate([`${data.customRedirectUrl}`]);
  }

  if (data.type === NotificationType.TeamMemberAddedByHost) {
    return data.router.navigate([`${AppRoutes.TEAMS}/${data.teamId}/${TeamsRoutes.TEAM_DETAILS}`]);
  }

  if (data.type === NotificationType.GameLineupsCreated) {
    return data.router.navigate([`${AppRoutes.GAMES}/${GamesRoutes.LINEUPS}/${data.gameId}`]);
  }

  return data.router.navigate([AppRoutes.NOTIFICATIONS]);
};
