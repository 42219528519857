import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { HIDE_PAYMENTS_MODAL } from '../../application/utils';

@Component({
  selector: 'lib-payment-confirm-checkbox',
  templateUrl: './payment-confirm-checkbox.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonicModule]
})
export class PaymentConfirmCheckboxComponent {
  change(event: CustomEvent): void {
    const result: boolean = event?.detail?.checked;

    localStorage.setItem(HIDE_PAYMENTS_MODAL, JSON.stringify({ isHide: result }));
  }
}
