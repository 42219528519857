import { AfterViewInit, ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import {
  AreFiltersDefaultQueryHandler,
  GetFilterDaysQueryHandler,
  GetFiltersQueryHandler,
  SetFilterCommandHandler
} from '../../application/handlers';
import {
  BehaviorSubject,
  combineLatest,
  combineLatestWith,
  debounceTime,
  EMPTY,
  map,
  Observable,
  of,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap
} from 'rxjs';
import { CalendarHeader } from '../../application/interfaces';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import {
  CoordinatesDTO,
  GameLevel,
  gameLevelMapper,
  GEOLOCATION_TOKEN,
  GeolocationProvider,
  LocationTypeEnum,
  locationTypeMapper,
  MODAL_TOKEN,
  ModalProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
  PresentModalComponent
} from '@core';
import {
  SelectableMultiCheckboxModalComponent,
  SelectableMultiCheckboxModel,
  SelectableSingleRadioModalComponent,
  SingleRadioItemModel
} from '@ui-components';
import { availableFilterDistanceConst } from '../../application/utils';
import { FiltersValues } from '../../application/models';

@Component({
  selector: 'lib-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgForOf, AsyncPipe, NgClass, NgIf],
  providers: [GetFilterDaysQueryHandler, SetFilterCommandHandler, AreFiltersDefaultQueryHandler, GetFiltersQueryHandler]
})
export class FiltersComponent implements OnInit, AfterViewInit {
  @Input() showDistanceFilter: boolean = true;
  @Input() gamesCount!: number | undefined;

  private readonly getFilterDaysQueryHandler: GetFilterDaysQueryHandler = inject(GetFilterDaysQueryHandler);
  private readonly setFilterCommandHandler: SetFilterCommandHandler = inject(SetFilterCommandHandler);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly getFiltersQueryHandler: GetFiltersQueryHandler = inject(GetFiltersQueryHandler);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly areFiltersDefaultQueryHandler: AreFiltersDefaultQueryHandler = inject(AreFiltersDefaultQueryHandler);
  private readonly geolocationProvider: GeolocationProvider = inject(GEOLOCATION_TOKEN);

  private readonly coordinatesSubject: BehaviorSubject<CoordinatesDTO | null> =
    new BehaviorSubject<CoordinatesDTO | null>(null);
  private readonly gameCountSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  protected readonly gameLevelMapper = gameLevelMapper;

  readonly coordinates$ = this.coordinatesSubject.asObservable().pipe(debounceTime(100));

  readonly gameCount$: Observable<number> = this.gameCountSubject.asObservable().pipe(debounceTime(100));

  readonly availableDistance$: Observable<SingleRadioItemModel<number>[]> = of(availableFilterDistanceConst);

  readonly daysRange$: Observable<CalendarHeader[]> = this.getFilterDaysQueryHandler.filterDays();

  readonly savedFilters$: Observable<FiltersValues> = this.getFiltersQueryHandler.filters().pipe(shareReplay(1));

  readonly areFiltersDefault$: Observable<boolean> = this.coordinates$.pipe(
    switchMap((coordinates: CoordinatesDTO | null) => this.areFiltersDefaultQueryHandler.areTheSame(coordinates)),
    startWith(true)
  );

  readonly availableGameLevels$: Observable<SelectableMultiCheckboxModel<GameLevel>[]> = of(
    Object.values(GameLevel)
  ).pipe(
    combineLatestWith(this.savedFilters$),
    take(1),
    map(([gameLevel, filters]: [GameLevel[], FiltersValues]) =>
      gameLevel.map((level: GameLevel) => {
        return {
          name: gameLevelMapper[level],
          value: level,
          isSelected: filters.gameLevel?.includes(level)
        };
      })
    )
  );

  readonly availableLocationTypes$: Observable<SelectableMultiCheckboxModel<LocationTypeEnum>[]> = of(
    Object.values(LocationTypeEnum)
  ).pipe(
    combineLatestWith(this.savedFilters$),
    take(1),
    map(([locationType, filters]: [LocationTypeEnum[], FiltersValues]) =>
      locationType.map((location: LocationTypeEnum) => {
        return {
          name: locationTypeMapper[location].name,
          value: location,
          isSelected: filters.locationType?.includes(location)
        };
      })
    )
  );

  constructor() {
    this.geolocationProvider
      .getCoordinates()
      .pipe(
        take(1),
        tap((coordinates: CoordinatesDTO | null) => this.coordinatesSubject.next(coordinates))
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.gameCountSubject.next(this.gamesCount ?? 0);
  }

  ngAfterViewInit(): void {
    this.gameCount$
      .pipe(
        switchMap((gameCount: number) => {
          if (gameCount < 1)
            return this.setFilterCommandHandler.set({
              maxDistanceMeters: undefined,
              latitude: undefined,
              longitude: undefined
            });

          return of(void 0);
        })
      )
      .subscribe();
  }

  openDistanceFilter(): void {
    combineLatest([this.availableDistance$, this.savedFilters$, this.platformProvider.isPermissionGranted()])
      .pipe(
        take(1),
        switchMap(
          ([availableDistance, filters, isGranted]: [SingleRadioItemModel<number>[], FiltersValues, boolean]) => {
            if (!isGranted) {
              this.locationPermissionDenied();
              return EMPTY;
            }
            return this.distanceModal(availableDistance, filters);
          }
        ),
        combineLatestWith(this.coordinates$),
        switchMap(([distance, coordinates]: [number | null, CoordinatesDTO | null]) =>
          this.setFilterCommandHandler.set({
            maxDistanceMeters: distance ?? undefined,
            latitude: distance ? coordinates?.latitude : undefined,
            longitude: distance ? coordinates?.longitude : undefined
          })
        )
      )
      .subscribe();
  }

  openGameLevel(): void {
    combineLatest([this.savedFilters$, this.availableGameLevels$])
      .pipe(
        take(1),
        switchMap(([selectedLevels, availableLevels]: [FiltersValues, SelectableMultiCheckboxModel<GameLevel>[]]) =>
          this.gameLevelModal(availableLevels, selectedLevels.gameLevel)
        ),
        switchMap((gameLevels: GameLevel[]) => {
          return this.setFilterCommandHandler.set({
            gameLevel: gameLevels
          });
        })
      )
      .subscribe();
  }

  openLocationType(): void {
    combineLatest([this.savedFilters$, this.availableLocationTypes$])
      .pipe(
        take(1),
        switchMap(
          ([savedFilters, availableLocationTypes]: [FiltersValues, SelectableMultiCheckboxModel<LocationTypeEnum>[]]) =>
            this.locationTypeModal(availableLocationTypes, savedFilters.locationType)
        ),
        switchMap((selectedLocations: LocationTypeEnum[]) =>
          this.setFilterCommandHandler.set({
            locationType: selectedLocations
          })
        )
      )
      .subscribe();
  }

  setDate(data: CalendarHeader): void {
    if (data.isSelected) {
      this.setFilterCommandHandler.set({ specificDate: undefined }).subscribe();
    } else {
      this.setFilterCommandHandler.set({ specificDate: data.value }).subscribe();
    }
  }

  getGameLevelName(gameLevel: GameLevel[]): string {
    if (!gameLevel?.length) return 'dowolny';

    if (gameLevel?.length === 1) return this.gameLevelMapper[gameLevel[0]];

    return `${this.gameLevelMapper[gameLevel[0]]} +${gameLevel?.length - 1}`;
  }

  getLocationTypeName(locationType: LocationTypeEnum[]): string {
    if (!locationType?.length) return 'dowolny';

    if (locationType?.length === 1) return locationTypeMapper[locationType[0]].name;

    return `${locationTypeMapper[locationType[0]]?.name} +${locationType?.length - 1}`;
  }

  resetFilters(): void {
    this.coordinates$
      .pipe(
        take(1),
        switchMap((coordinates: CoordinatesDTO | null) =>
          this.setFilterCommandHandler.set({
            specificDate: undefined,
            maxDistanceMeters: coordinates ? 70000 : undefined,
            locationType: [],
            gameLevel: [],
            longitude: coordinates ? coordinates.longitude : undefined,
            latitude: coordinates ? coordinates.latitude : undefined
          })
        )
      )

      .subscribe();
  }

  private distanceModal(
    availableDistance: SingleRadioItemModel<number>[],
    filters: FiltersValues
  ): Observable<number | null> {
    return this.modalProvider
      .showModal$<number>({
        component: SelectableSingleRadioModalComponent,
        cssClass: 'present-modal',
        componentProps: {
          buttonText: 'Wybierz',
          header: 'Odległość',
          providedData: availableDistance,
          selectedData: filters.maxDistanceMeters ?? null
        }
      })
      .pipe(map((result: number) => (result ?? filters.maxDistanceMeters) || null));
  }

  private gameLevelModal(
    availableLevels: SelectableMultiCheckboxModel<GameLevel>[],
    selectedLevels: GameLevel[]
  ): Observable<GameLevel[]> {
    return this.modalProvider
      .showModal$<GameLevel[]>({
        component: SelectableMultiCheckboxModalComponent<GameLevel>,
        cssClass: 'present-modal',
        componentProps: {
          buttonText: 'Wybierz',
          header: 'Poziom gierki',
          providedData: availableLevels,
          selectedData: selectedLevels
        }
      })
      .pipe(map((result: GameLevel[]) => result ?? selectedLevels));
  }

  private locationTypeModal(
    availableLocationTypes: SelectableMultiCheckboxModel<LocationTypeEnum>[],
    selectedLocationTypes: LocationTypeEnum[]
  ): Observable<LocationTypeEnum[]> {
    return this.modalProvider
      .showModal$<LocationTypeEnum[]>({
        component: SelectableMultiCheckboxModalComponent<LocationTypeEnum>,
        cssClass: 'present-modal',
        componentProps: {
          buttonText: 'Wybierz',
          header: 'Typ boiska',
          providedData: availableLocationTypes,
          selectedData: selectedLocationTypes
        }
      })
      .pipe(map((result: LocationTypeEnum[]) => result ?? selectedLocationTypes));
  }

  private locationPermissionDenied(): Observable<void> {
    return this.modalProvider.showModal$({
      component: PresentModalComponent,
      componentProps: {
        header: 'Włącz lokalizację',
        message: 'Włącz lokalizację jeśli chcesz korzystać z tej funkcji.',
        btnTxt: 'Zamknij'
      },
      cssClass: 'present-modal'
    });
  }
}
