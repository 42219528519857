import { CHAT_CHANEL_DTO, ChatDtoPort } from '../../infrastructure/http-service';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ChannelDetailsQueryParam } from '../enums';
import { ChannelDetailsDTO, ChannelDetailsPayloadDTO } from '../interfaces';

export class ChannelDetailsByReferenceQueryHandler {
  private readonly chatDtoPort: ChatDtoPort = inject(CHAT_CHANEL_DTO);

  details(payload: ChannelDetailsPayloadDTO): Observable<ChannelDetailsDTO> {
    const params = () => {
      if (payload.param === ChannelDetailsQueryParam.TEAM) return `teamId=${payload.teamId}`;
      return `gameId=${payload.gameId}`;
    };

    return this.chatDtoPort.channelDetailsByReference(params());
  }
}
