<lib-chat-navbar
  *ngIf="channelDetails$ | async as details"
  [date]="
    details.closestGame
      ? (details.closestGame.gameDateStart
        | dateFormat : details.closestGame.gameDateEnd)
      : 'Brak najbliższej gierki'
  "
  [details]="details"
  (onDotsClicked)="manageChannel()"
  (actionOnBack)="stopWatching()"
></lib-chat-navbar>

<ng-container *ngIf="isPageLoaded$ | async">
  <div
    [ngClass]="
      isiOs ? 'message-container-height-ios' : 'message-container-height'
    "
  >
    <stream-channel>
      <div>
        <stream-message-list
          [ngClass]="isiOs ? 'message-list-height-ios' : 'message-list-height'"
        ></stream-message-list>
      </div>
      <stream-message-input
        class="or-position-absolute or-bottom-0 or-w-100vw"
      ></stream-message-input>
    </stream-channel>
  </div>
</ng-container>
