import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { inject } from '@angular/core';
import { IsAuthedService } from '@core';

export class AuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isAuthedService: IsAuthedService = inject(IsAuthedService);

    return isAuthedService.token$.pipe(
      switchMap((token: string | null) => {
        if (token) {
          const authReq = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + token)
          });
          return next.handle(authReq);
        }
        return next.handle(req);
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
}
