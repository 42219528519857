import { inject, InjectionToken } from '@angular/core';
import { filter, Observable } from 'rxjs';
import { UserDataService } from '../services';
import { UserModel } from '../interfaces';

export const USER_DATA_TOKEN: InjectionToken<UserDataProvider> = new InjectionToken<UserDataProvider>(
  'USER_DATA_TOKEN',
  {
    factory: (): UserDataProvider => {
      const userService: UserDataService = inject(UserDataService);

      return {
        userData$: userService.userData$.pipe(filter((userData: UserModel) => !!userData.userId)),
        setUserData(): void {
          userService.setUserData();
        },
        resetUserData(): void {
          userService.resetUserDataState();
        }
      };
    }
  }
);

export interface UserDataProvider {
  userData$: Observable<UserModel>;
  setUserData(): void;
  resetUserData(): void;
}
