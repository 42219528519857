import { HttpClient } from "@angular/common/http";
import { inject } from "@angular/core";
import { ENV_CONFIG, EnvConfig } from "@core";
import { LineupsDTO } from "../../application/interfaces";
import { Observable } from "rxjs";

export class LineupsService {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  getLineups(gameId: string): Observable<LineupsDTO> {
    return this.httpClient.get<LineupsDTO>(
      `${this.env.apiUrl}/game-lineups/${gameId}`
    );
  }

  save(payload: LineupsDTO, gameId: string): Observable<void> {
    return this.httpClient.put<void>(
      `${this.env.apiUrl}/game-lineups/${gameId}`,
      payload
    );
  }
}
