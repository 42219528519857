import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { AdminUsersFiltersModel } from '../../../application/models';

export const AMIN_USERS_CONTEXT: InjectionToken<AdminUsersFiltersContextPort> =
  new InjectionToken<AdminUsersFiltersContextPort>('AMIN_USERS_CONTEXT');

export interface AdminUsersFiltersContextPort {
  filters$: Observable<AdminUsersFiltersModel>;
  patchContext(state: Partial<AdminUsersFiltersModel>): Observable<void>;
}
