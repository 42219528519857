import { inject } from '@angular/core';
import { CredentialsDTO } from '../models';
import { from, map, Observable, take } from 'rxjs';
import { AuthService } from '../../infrastructure/http-services';

export class RegisterCommandHandler {
  private readonly authService: AuthService = inject(AuthService);

  register(credentials: CredentialsDTO): Observable<void> {
    return from(this.authService.register(credentials)).pipe(
      take(1),
      map(() => void 0)
    );
  }
}
