import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { ENV_CONFIG, EnvConfig } from '@core';
import { Observable } from 'rxjs';
import { HostedGameApplicationsDTOPort } from './hosted-game-applications.dto-port';

export class HostedGameApplicationsService implements HostedGameApplicationsDTOPort {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  approveApplications(gameId: string, applicationIds: string[]): Observable<void> {
    const payload = { gamePlayerApplicationIds: applicationIds };

    return this.httpClient.post<void>(`${this.env.apiUrl}/hosted-games/${gameId}/applications/approve-many`, payload);
  }

  rejectApplications(gameId: string, applicationIds: string[]): Observable<void> {
    const payload = { gamePlayerApplicationIds: applicationIds };

    return this.httpClient.post<void>(`${this.env.apiUrl}/hosted-games/${gameId}/applications/reject-many`, payload);
  }
}
