import { AlertsSettingsDTO } from '../interfaces';
import { map, Observable, switchMap } from 'rxjs';
import { inject } from '@angular/core';
import { NOTIFICATIONS_ALERTS_DTO, NotificationsAlertsDTOPort } from '../../infrastructure';
import { CoordinatesDTO, GEOLOCATION_TOKEN, GeolocationProvider } from '@core';
import { ShowFrom } from '../enums';

export class UpdateAlertsSettingsCommandHandler {
  private notificationsAlertsDtoPort: NotificationsAlertsDTOPort = inject(NOTIFICATIONS_ALERTS_DTO);
  private geolocationProvider: GeolocationProvider = inject(GEOLOCATION_TOKEN);

  switchAlerts(value: boolean): Observable<void> {
    return this.notificationsAlertsDtoPort.switchAlerts(value);
  }

  updateAlertsSettings(settings: AlertsSettingsDTO): Observable<void> {
    return this.geolocationProvider.getCoordinates().pipe(
      map((coordinates: CoordinatesDTO | null) => {
        const updatedSpecificDate: AlertsSettingsDTO =
          settings.specificDaysType === ShowFrom.ALL ? this.setWeekdaysToEmpty(settings) : this.setWeekdays(settings);

        const distance: number = settings.maxDistanceMeters === 71 ? 0 : settings.maxDistanceMeters;

        return {
          ...updatedSpecificDate,
          maxDistanceMeters: distance * 1000,
          coordinates: coordinates
        };
      }),
      switchMap((result: AlertsSettingsDTO) => this.notificationsAlertsDtoPort.updateAlerts(result))
    );
  }

  private setWeekdaysToEmpty(data: AlertsSettingsDTO): AlertsSettingsDTO {
    return {
      ...data,
      specificWeekdays: []
    };
  }

  private setWeekdays(data: AlertsSettingsDTO): AlertsSettingsDTO {
    const preventToEmptyWeekdays = (data: AlertsSettingsDTO) => {
      if (data.specificDaysType === ShowFrom.WEEKDAYS && !data.specificWeekdays?.length) {
        return { ...data, specificDaysType: ShowFrom.ALL };
      }
      return data;
    };

    if (data.specificDaysType === ShowFrom.WEEKDAYS) return preventToEmptyWeekdays(data);
    return data;
  }
}
