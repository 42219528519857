import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { NotificationResponseDTO } from "../../../application/interfaces";

export const NOTIFICATIONS_DTO: InjectionToken<NotificationsDTOPort> =
  new InjectionToken<NotificationsDTOPort>("NOTIFICATIONS_DTO");

export interface NotificationsDTOPort {
  registerToken(token: string): Observable<void>;
  unregisterToken(token: string): Observable<void>;
  notificationsList(): Observable<NotificationResponseDTO>;
  markAsRead(ids: string[]): Observable<void>;
  markAsClicked(notificationId: string): Observable<void>;
}
