import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthRoutes } from './auth-routes.enum';
import { AuthHomePage, LoginPage, RegisterPage } from './pages';

const routes: Routes = [
  {
    path: '',
    component: AuthHomePage
  },
  {
    path: AuthRoutes.LOGIN,
    component: LoginPage
  },
  {
    path: AuthRoutes.REGISTER,
    component: RegisterPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
