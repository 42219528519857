import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private sessionKey = 'session_uuid';

  constructor() {
    this.initSession();
  }

  getUuid(): string {
    return sessionStorage.getItem(this.sessionKey) || '';
  }

  private initSession(): void {
    if (!sessionStorage.getItem(this.sessionKey)) {
      sessionStorage.setItem(this.sessionKey, crypto.randomUUID());
    }
  }
}
