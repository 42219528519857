import { TeamsService } from '../../infrastructure';
import { inject } from '@angular/core';

export class AddTeamMemberCommandHandler {
  private readonly teamsService: TeamsService = inject(TeamsService);

  add(teamId: string, userIds: string[]) {
    const payload = {
      userIds: userIds,
      isAutoApproved: false
    };
    return this.teamsService.addTeamMember(teamId, payload);
  }
}
