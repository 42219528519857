<ng-container *ngIf="{selectedType : selectedType$ | async} as data">
  <div class="or-my-32-px or-mx-24-px">
    <div class="or-d-flex or-justify-content-center">
      <span class="or-head-xl or-mb-16-px or-text-align-center">Wybierz typ profilu</span>
    </div>
    <div class="or-d-flex or-justify-content-center or-text-align-center or-mb-20-px">
      <span class="or-text-xl">Wybierz typ profilu. To ustawienie możesz zmienic w menu edycji profilu.</span>
    </div>

    <div class="or-mb-24-px">
      <ion-radio-group (ionChange)="setType($event)" [value]="false">
        <div class="or-d-flex or-justify-content-center or-head-sm or-flex-col">
          <div
            class="or-align-items-center or-d-flex or-justify-content-evenly or-w-100-pc"
            [ngClass]="data.selectedType === profileType.PLAYER
          ? 'is-active or-bg-focus or-color-primary'
          : 'is-no-active or-bg-white' ">
            <ion-radio [value]="profileType.PLAYER" class="or-mx-20-px" labelPlacement="end" alignment="center">
              <div class="or-d-flex or-flex-col">
                <div
                  class="or-head-md or-mb-4-px"
                  [ngClass]="data.selectedType === profileType.PLAYER ? ' or-color-primary' : 'or-color-black' ">
                  Gracz
                </div>
                <div class="or-note-sm white-space-normal">Wybierz, jeśli częściej jesteś graczem w gierkach.</div>
              </div>
            </ion-radio>
          </div>

          <div
            class="or-align-items-center or-d-flex or-justify-content-evenly or-mt-12-px or-w-100-pc"
            [ngClass]="
          data.selectedType === profileType.HOST
          ? 'is-active or-bg-focus or-color-primary'
          : 'is-no-active or-bg-white'">
            <ion-radio [value]="profileType.HOST" class="or-mx-20-px" labelPlacement="end" alignment="center">
              <div class="or-d-flex or-flex-col">
                <div
                  class="or-head-md or-mb-4-px"
                  [ngClass]="data.selectedType === profileType.HOST ? ' or-color-primary' : 'or-color-black'">
                  Organizator
                </div>
                <div class="or-note-sm white-space-normal">Wybierz, jeśli częściej organizujesz gierki orlikowe.</div>
              </div>
            </ion-radio>
          </div>
        </div>
      </ion-radio-group>
    </div>

    <div class="or-d-flex or-justify-content-center">
      <button
        class="button-primary-style or-h-48-px or-w-60-pc or-head-md"
        [class.disabled]="!data.selectedType"
        (click)="closeModal()">
        Zapisz
      </button>
    </div>
  </div>
</ng-container>
