<ng-container *ngIf="userDetails$ | async as userDetails">
  <ng-container *ngIf="isPageLoaded$ | async">
    <lib-title-navbar
      [showSettings]="userDetails.isMyProfile"
      (onSettingsClicked)="goToSettings()"
      [title]="userDetails.isMyProfile ? 'Mój profil' : 'Profil'"></lib-title-navbar>

    <div class="top-container"></div>

    <div class="or-d-flex or-position-relative or-justify-content-center z-index-999 negative-top-margin-35-px">
      <lib-or-avatar
        [avatarUrl]="userDetails.thumbnail"
        [avatarSize]="'lg'"
        [initials]="userDetails.displayName"
        [rightMargin]="false"></lib-or-avatar>
    </div>

    <div class="or-d-flex or-flex-col or-align-items-center or-mt-12-px or-mb-4-px">
      <div class="or-d-flex or-align-items-center">
        <div class="or-head-lg">{{ userDetails.displayName }}</div>
        <ng-container *ngIf="userDetails.rate > 0">
          <img class="or-ml-12-px or-mr-4-px or-size-16-px or-mb-4-px" src="assets/icons/star-fill.svg" />
          <div class="or-head-xs">{{ userDetails.rate.toFixed(2) }}</div>
        </ng-container>
      </div>
      <div *ngIf="!userDetails.isMyProfile" class="or-text-sm or-color-secondary-2">
        Aktywny {{ calculatelastActive(userDetails.lastActive) }}
      </div>
    </div>

    <div
      (click)="openNotificationPermissions()"
      *ngIf="!(isNotificationPermissionsEnabled$ | async)"
      class="or-d-flex or-justify-content-center or-mt-12-px">
      <div class="or-bg-shade-2 or-p-4-px border-black border-radius-8 or-text-md">Włącz powiadomienia w aplikacji</div>
    </div>

    <div *ngIf="userDetails.isMyProfile" class="or-px-16-px or-mt-12-px">
      <lib-alert-bar></lib-alert-bar>
    </div>

    <div class="or-d-flex or-justify-content-evenly margin-top--1-px bottom-border-selector or-mt-12-px">
      <div
        (click)="showStats()"
        class="or-text-md or-py-8-px or-px-8-px negative-margin-bottom-1-px"
        [ngClass]="isStatsActive ? 'bottom-border-active' : 'or-color-secondary-2'">
        Statystyki
      </div>
      <div
        (click)="showLastGames()"
        class="or-text-md or-py-8-px or-px-8-px negative-margin-bottom-1-px"
        [ngClass]="!isStatsActive ? 'bottom-border-active' : 'or-color-secondary-2'">
        Ostatnie gierki
      </div>
    </div>

    <div [ngClass]="calculateClass(userDetails.isMyProfile)">
      <ng-container *ngTemplateOutlet="isStatsActive ? stats : lastGames"></ng-container>
    </div>

    <ng-template #stats>
      <div class="or-px-28-px or-mt-24-px">
        <lib-player-preferences [playerPreferences]="userDetails.playerPreferences"></lib-player-preferences>
      </div>

      <div class="or-px-20-px or-mt-20-px">
        <lib-user-stats [userStats]="userDetails.userStats"></lib-user-stats>
      </div>

      <div class="or-px-20-px or-mt-20-px">
        <lib-card-history></lib-card-history>
      </div>

      <div class="or-px-20-px or-mt-12-px">
        <lib-user-comments></lib-user-comments>
      </div>

      <div class="or-px-20-px or-mt-12-px or-mb-16-px">
        <lib-trophies></lib-trophies>
      </div>
    </ng-template>

    <ng-template #lastGames>
      <div class="or-mt-16-px or-mb-16-px">
        <div *ngFor="let game of userLastGames$ | async" class="or-mt-8-px">
          <div
            class="border-radius-8 or-bg-white or-cursor-pointer or-mx-16-px or-pb-12-px or-pt-4-px or-px-12-px z-index-1 card-box-shadow"
            *ngIf="game">
            <div class="or-mt-8-px or-d-flex or-justify-content-between">
              <div class="or-head-lg">{{ game.gameName }}</div>
            </div>

            <div class="or-d-flex or-align-items-center or-mt-8-px">
              <img src="assets/icons/clock-icon.svg" class="or-mr-8-px" />

              <div class="or-text-md">{{ game.formattedDate }}</div>
            </div>

            <div class="or-d-flex or-align-items-center or-mt-4-px">
              <img src="assets/icons/map-pin-icon.svg" class="or-mr-8-px" />

              <div class="or-text-md">{{ game.location.address }}, {{ game.location.city }}</div>
            </div>

            <div class="custom-divider or-mt-8-px or-w-100-pc"></div>

            <div class="or-d-flex or-justify-content-between or-align-items-center or-mt-12-px">
              <div class="or-ml-2-px or-d-flex or-align-items-center">
                <lib-or-avatar
                  [avatarUrl]="game.host?.thumbnail"
                  [avatarSize]="'xs'"
                  [initials]="game.host?.displayName"></lib-or-avatar>
                <div class="or-head-sm">{{ game.host?.displayName }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
<lib-bottom-navbar></lib-bottom-navbar>
