import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';
import { GameLevel, gameLevelMapper, PlayerPreferencesDTO, PreferredPosition, preferredPositionMapper } from '@core';

@Component({
  selector: 'lib-player-preferences',
  templateUrl: './player-preferences.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf]
})
export class PlayerPreferencesComponent {
  @Input({ required: true }) playerPreferences!: PlayerPreferencesDTO;

  public gameLevelMapper: Record<GameLevel, string> = gameLevelMapper;
  public preferredPositionMapper: Record<PreferredPosition, string> = preferredPositionMapper;
}
