<div class="or-my-32-px or-mx-24-px">
  <div class="or-d-flex or-justify-content-center">
    <span class="or-head-xl or-mb-16-px or-text-align-center"> Przypomnij hasło </span>
  </div>
  <div class="or-d-flex or-justify-content-center or-text-align-center or-mb-20-px">
    <span class="or-text-xl"
      >Link do zresetowania hasła zostanie wysłany na e-mail podany podczas tworzenia konta.</span
    >
  </div>

  <div class="or-mb-24-px">
    <ng-container [formGroup]="emailForm">
      <span class="or-color-secondary-2 or-head-xs or-mb-4-px">E-mail</span>
      <input class="input-style or-mb-12-px outline-none or-w-100-pc" formControlName="email" />
    </ng-container>
  </div>

  <div class="or-d-flex">
    <button class="button-secondary-style or-h-48-px or-w-60-pc or-head-md or-mr-8-px" (click)="closeModal()">
      Zamknij
    </button>
    <button class="button-primary-style or-h-48-px or-w-60-pc or-head-md" (click)="resetPassword()">Wyślij link</button>
  </div>
</div>
