import { filter, first, Observable, of, tap } from 'rxjs';
import { StartupState } from '../startup.state';
import { inject } from '@angular/core';
import { StartupStep } from '../startup-step';
import { NotificationsInitializer } from '@notifications';

export class NotificationsStep extends StartupStep {
  private readonly notificationInitializer: NotificationsInitializer = inject(NotificationsInitializer);

  executeStep(state: StartupState): Observable<StartupState> {
    state.authed$
      ?.pipe(
        filter(Boolean),
        first(),
        tap(() => this.notificationInitializer.init())
      )
      .subscribe();

    return of(state);
  }
}
