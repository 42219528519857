<ng-container *ngIf="comments$ | async as comments">
  <ng-container *ngIf="comments.comments?.length">
    <div CLASS="or-px-20-px or-mt-24-px">
      <div class="or-head-sm or-color-secondary-2">Komplementy</div>
      <div class="or-mt-12-px">
        <div
          *ngFor="let comment of comments.comments; let i = index"
          class="or-d-flex or-align-items-center"
        >
          <img
            [src]="'assets/icons/comment-icon.svg'"
            class="or-align-self-start or-d-flex or-mr-8-px or-mt-2-px"
          />
          <div
            [ngClass]="
              i === comments.comments.length - 1 ? 'or-mb-16-px' : 'or-mb-8-px'
            "
          >
            <div class="or-text-md or-mb-4-px">
              {{ commentMapper[comment.commentType].displayName }}
            </div>
            <div class="or-head-xs or-color-secondary-2">
              {{ comment.author.authorName }} |
              {{ formatDate(comment.createdAt) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
