import { FiltersStorage } from "../../infrastructure/storages";
import { inject } from "@angular/core";

export class GetFiltersQueryHandler {
  private readonly filtersStorage: FiltersStorage = inject(FiltersStorage);

  filters() {
    return this.filtersStorage.filters$;
  }
}
