import { FeatureFlagsContextPort } from './feature-flags.context-port';
import { Observable, of, ReplaySubject } from 'rxjs';
import { FeatureFlagsRecord } from '../../application/models';

export class FeatureFlagsStorage implements FeatureFlagsContextPort {
  private readonly featureFlagsSubject: ReplaySubject<FeatureFlagsRecord> = new ReplaySubject<FeatureFlagsRecord>(1);

  flags$: Observable<FeatureFlagsRecord> = this.featureFlagsSubject.asObservable();

  setState(data: FeatureFlagsRecord): Observable<FeatureFlagsRecord> {
    this.featureFlagsSubject.next(data);
    return of(data);
  }
}
