import {
  NOTIFICATIONS_ALERTS_DTO,
  NotificationsAlertsDTOPort,
} from "../../infrastructure";
import { inject } from "@angular/core";
import { map, Observable } from "rxjs";
import { AlertsSettingsDTO } from "../interfaces";

export class GetAlertsSettingsQueryHandler {
  private notificationsAlertsDtoPort: NotificationsAlertsDTOPort = inject(
    NOTIFICATIONS_ALERTS_DTO
  );

  getAlertsSettings(): Observable<AlertsSettingsDTO> {
    return this.notificationsAlertsDtoPort.getAlerts().pipe(
      map((values: AlertsSettingsDTO) => ({
        ...values,
        maxDistanceMeters:
          values.maxDistanceMeters < 1000
            ? 71
            : values.maxDistanceMeters / 1000,
      }))
    );
  }
}
