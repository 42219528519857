import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { filter, map, Observable, switchMap, take, tap } from 'rxjs';
import { JoinToTeamCommandHandler } from '../../application/handlers';
import { AppRoutes } from 'src/app/app-routes.enum';
import {
  IsAuthedService,
  MODAL_TOKEN,
  ModalProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
  PresentModalComponent
} from '@core';
import { AsyncPipe, NgIf } from '@angular/common';
import { BlockedWebViewComponent } from '@ui-components';
import { TeamsRoutes } from '../../teams-routes.enum';

@Component({
  selector: 'lib-team-invitation-accept',
  templateUrl: 'team-invitation-accept.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, AsyncPipe, BlockedWebViewComponent],
  providers: [JoinToTeamCommandHandler]
})
export class TeamInvitationAcceptComponent implements OnInit {
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly router: Router = inject(Router);
  private readonly joinToTeamCommandHandler: JoinToTeamCommandHandler = inject(JoinToTeamCommandHandler);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly isAuthedService: IsAuthedService = inject(IsAuthedService);

  readonly isNative: boolean = this.platformProvider.isNativeRun;

  readonly redirectPath$: Observable<string> = this.activatedRoute.params.pipe(
    map(
      (params: Params) => `${AppRoutes.TEAMS}/${TeamsRoutes.ACCEPT_INVITATION}/${params['teamId']}/${params['token']}`
    )
  );

  ngOnInit(): void {
    if (!this.isNative) return;

    this.isAuthedService.isAuthed$
      .pipe(
        take(1),
        tap((isAuthed: boolean) => {
          if (!isAuthed) {
            this.router.navigateByUrl(AppRoutes.AUTH);
          }
        }),
        filter((isAuthed: boolean) => isAuthed),
        switchMap(() => this.activatedRoute.params),
        take(1),
        switchMap((params: Params) => {
          const teamId: string = params['teamId'];
          const token: string = params['token']?.includes('?') ? params['token'].split('?')[0] : params['token'];

          return this.joinToTeamCommandHandler.join(teamId, token);
        }),
        switchMap(() => this.router.navigateByUrl(AppRoutes.DASHBOARD))
      )
      .subscribe(() => this.showSuccessMessage());
  }

  private showSuccessMessage(): Observable<void> {
    return this.modalProvider.showModal$({
      component: PresentModalComponent,
      cssClass: 'present-modal',
      componentProps: {
        header: 'Zostałeś dodany do grupki!',
        message: '',
        btnTxt: 'Rozumiem, dzięki!'
      }
    });
  }
}
