import { map, Observable } from 'rxjs';
import { HOSTED_GAMES_DTO, HostedGamesDTOPort } from '../../infrastructure/http-service';
import { inject } from '@angular/core';
import { CreateGameDTO, CreateGameResponseDTO, CreateGameResponseViewModel } from '../interfaces';
import { DateFormatPipe } from '@ui-components';
import { mapToCreateGameViewModelUtil } from './utils/map-to-create-game-view-model.util';

export class UpdateGameCommandHandler {
  private readonly hostedGamesDTOPort: HostedGamesDTOPort = inject(HOSTED_GAMES_DTO);
  private readonly dateFormatPipe: DateFormatPipe = inject(DateFormatPipe);

  updateGame(gameId: string, game: Partial<CreateGameDTO>): Observable<CreateGameResponseViewModel> {
    return this.hostedGamesDTOPort
      .updateGame(gameId, {
        ...game,
        paymentTypes: !game.priceAmount ? [] : game.paymentTypes
      })
      .pipe(map((response: CreateGameResponseDTO) => mapToCreateGameViewModelUtil(response, this.dateFormatPipe)));
  }
}
