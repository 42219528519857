import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { setReferrerIdUtil } from '../handlers/set-referrer-id.util';
import { inject, Injector } from '@angular/core';

export const referrerResolver: ResolveFn<void> = (activatedRoute: ActivatedRouteSnapshot) => {
  const injector: Injector = inject(Injector);

  const referrerKey: string = 'referrer';

  const referrerId: string | null = activatedRoute?.queryParamMap.get(referrerKey);

  return of(true).pipe(
    switchMap(() => {
      if (referrerId) localStorage.setItem(referrerKey, referrerId);

      return of(void 0);
    }),
    switchMap(() => setReferrerIdUtil(injector))
  );
};
