import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomNotificationDTO } from '../../application/interfaces';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { CustomNotificationDtoPort } from './custom-notification.dto-port';
import { firestoreGetDataUtil } from '@core';

export class CustomNotificationService implements CustomNotificationDtoPort {
  private readonly firestore: AngularFirestore = inject(AngularFirestore);

  getData(id: string): Observable<CustomNotificationDTO> {
    return firestoreGetDataUtil(this.firestore, 'custom-notification', id);
  }
}
