import { Observable, of } from 'rxjs';
import { StartupState } from '../startup.state';
import { inject } from '@angular/core';
import { StartupStep } from '../startup-step';
import { App } from '@capacitor/app';
import { environment } from '../../../environment/environment';
import { AppRoutes } from '../../app-routes.enum';
import { Router } from '@angular/router';

export class DeepLinkingStep extends StartupStep {
  private readonly router: Router = inject(Router);

  executeStep(state: StartupState): Observable<StartupState> {
    App.addListener('appUrlOpen', (event) => {
      const pathParts: string[] = event.url.split(environment.domain);
      const redirectUrl: string | undefined = pathParts.pop();

      if (redirectUrl) {
        this.router.navigate([redirectUrl]);
      } else {
        this.router.navigate([AppRoutes.ROOT]);
      }
    });

    return of(state);
  }
}
