import { UserGamesHttpService } from '../../infrastructure';
import { inject } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { map, Observable, switchMap } from 'rxjs';
import { GameDetailsModel, GameDTO } from '@core';
import { mapToGameQueryUtil } from 'projects/games/src/lib/application/handlers/utils/map-to-game-query.util';
import { DateFormatPipe } from '@ui-components';

export class GetUserLastGamesQueryHandler {
  private readonly userGamesHttpService: UserGamesHttpService = inject(UserGamesHttpService);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly dateFormatPipe: DateFormatPipe = inject(DateFormatPipe);

  getLastGames(): Observable<GameDetailsModel[]> {
    return this.activatedRoute.params.pipe(
      switchMap((params: Params) => this.userGamesHttpService.getLastGames(params['id'])),
      map((data: GameDTO[]) => data?.map((game: GameDTO) => mapToGameQueryUtil(game, this.dateFormatPipe)))
    );
  }
}
