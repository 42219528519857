import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'or-avatar-skeleton',
  template: ``,
  styleUrls: ['./avatar-skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'or-skeleton-animated' }
})
export class AvatarSkeletonComponent {}
