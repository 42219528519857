import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { OrAvatarComponent, TitileNavbarComponent } from '@ui-components';
import { IonicModule } from '@ionic/angular';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import {
  GameLevel,
  gameLevelMapper,
  MODAL_TOKEN,
  ModalProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
  PreferredPosition,
  preferredPositionMapper,
  PresentModalComponent
} from '@core';
import { BehaviorSubject, map, Observable, switchMap, take, tap } from 'rxjs';
import { LineupsQueryHandler, SaveLineupsCommandHandler } from '../../application/handlers';
import { LineupsService } from '../../infrastructure/http-service';
import { LineupsModel, LineupsPlayerModel } from '../../application/interfaces';
import { shuffleLineupsPosition } from '../../application/utils/shuffle-lineups-position.util';

interface LineupsMetadata {
  readonly isSaved: boolean;
  readonly isHost: boolean;
}

const lineupsInitValues: LineupsMetadata = {
  isSaved: false,
  isHost: false
};

@Component({
  selector: 'lib-lineups',
  templateUrl: './lineups.component.html',
  styleUrls: ['./lineups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TitileNavbarComponent, IonicModule, NgForOf, NgIf, OrAvatarComponent, AsyncPipe, NgClass],
  providers: [LineupsService, SaveLineupsCommandHandler, LineupsQueryHandler]
})
export class LineupsComponent implements OnInit {
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly saveLineupsCommandHandler: SaveLineupsCommandHandler = inject(SaveLineupsCommandHandler);
  private readonly lineupsQueryHandler: LineupsQueryHandler = inject(LineupsQueryHandler);

  private readonly playersSubject: BehaviorSubject<LineupsPlayerModel[]> = new BehaviorSubject<LineupsPlayerModel[]>(
    []
  );
  private readonly lineupsMetadata: BehaviorSubject<LineupsMetadata> = new BehaviorSubject<LineupsMetadata>(
    lineupsInitValues
  );
  readonly shouldShowLineups$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly isPageLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public gameLevelMapper: Record<GameLevel, string> = gameLevelMapper;
  public preferredPositionMapper: Record<PreferredPosition, string> = preferredPositionMapper;
  readonly isiOS: boolean = this.platformProvider.isiOS;

  readonly players$: Observable<LineupsPlayerModel[]> = this.playersSubject.asObservable();

  readonly lineupsMetadata$: Observable<LineupsMetadata> = this.lineupsMetadata.asObservable();

  ngOnInit(): void {
    this.loadData();
  }

  splitTeamSize(): number {
    if (!this.playersSubject.value) return 0;
    return +(this.playersSubject.value.length / 2).toFixed();
  }

  doReorder(event: CustomEvent): void {
    const itemMove: LineupsPlayerModel = this.playersSubject.value.splice(event.detail.from, 1)[0];
    this.playersSubject.value.splice(event.detail.to, 0, itemMove);

    event.detail.complete();
  }

  shufflePlayers(): void {
    const newList: LineupsPlayerModel[] = shuffleLineupsPosition(this.playersSubject.value);

    this.playersSubject.next(newList);

    this.shouldShowLineups$.next(true);
  }

  save(): void {
    this.playersSubject
      .asObservable()
      .pipe(
        take(1),
        map((players: LineupsPlayerModel[]) => players.map((player: LineupsPlayerModel) => player.playerId)),
        switchMap((players: string[]) => this.saveLineupsCommandHandler.save(players))
      )
      .subscribe(() => this.lineupsSavedModal());
  }

  private loadData(): void {
    this.modalProvider
      .showLoading$()
      .pipe(
        switchMap(() => this.lineupsQueryHandler.getLineups()),
        take(1),
        tap((lineups: LineupsModel) => {
          const metadataPayload: LineupsMetadata = {
            isSaved: lineups.isLineupsSaved,
            isHost: lineups.isHost
          };
          this.lineupsMetadata.next(metadataPayload);
        }),
        tap((lineups: LineupsModel) => {
          this.playersSubject.next(lineups.players);
        }),
        tap(() => this.modalProvider.dismissLoading$()),
        tap(() => this.isPageLoaded$.next(true))
      )
      .subscribe();
  }

  private lineupsSavedModal(): void {
    this.modalProvider.showModal$({
      component: PresentModalComponent,
      componentProps: {
        header: 'Losowanie składów',
        message: 'Składy zostaly zapisane!',
        btnTxt: 'Zamknij'
      },
      cssClass: 'present-modal'
    });
  }
}
