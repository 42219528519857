<ng-container
  *ngIf="{
games: games$ | async,
gamesCount: gamesCount$ | async,
} as data">
  <div class="or-pb-8-px">
    <lib-filters [gamesCount]="data.gamesCount?.totalGames"></lib-filters>
  </div>

  <div [fullHeightScrollableContainer]="290" class="or-mb-16-px">
    <div *ngIf="isAdmin$ | async" class="or-align-items-center or-d-flex or-justify-content-between or-mx-16-px">
      <div class="or-text-md">
        Pokaż prywatne gierki ({{ data.gamesCount?.totalGames }}/{{ data.gamesCount?.privateGames }})
      </div>
      <ion-toggle [checked]="true" (ionChange)="setShowPrivateGames($event.detail.checked)"> </ion-toggle>
    </div>

    <div *ngFor="let group of data.games; let first = first">
      <div class="or-head-md or-color-secondary-2 or-pl-20-px" [ngClass]="first ? 'or-mt-8-px' : 'or-mt-24-px'">
        {{ group.date }}
      </div>
      <div *ngFor="let game of group.games" class="or-my-12-px">
        <lib-game-card-v2
          (click)="openDetailsModal(game.gameId); $event.stopPropagation()"
          [game]="game"
          [showHostDetails]="true"
          [isAdmin]="isAdmin$ | async"
          [showGameParticipationStatus]="true"></lib-game-card-v2>
      </div>
    </div>

    <div
      *ngIf="!data.gamesCount?.totalGames"
      class="or-head-sm or-px-20-px or-d-flex or-text-align-center or-align-items-center empty-state-height">
      Brak dopasowanych gierek. Zmień lub wyczyść filtry aby zobaczyć więcej.
    </div>
  </div>
</ng-container>
