import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/app-routes.enum';
import { NotificationsRoutes } from '@notifications';
import { AsyncPipe, NgIf } from '@angular/common';
import { FeatureFlag, FeatureFlagHandler } from '@feature-flags';
import { Observable } from 'rxjs';

@Component({
  selector: 'lib-dashboard-navbar',
  templateUrl: './dashboard-navbar.component.html',
  styleUrls: ['./dashboard-navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, AsyncPipe]
})
export class DashboardNavbarComponent {
  private readonly router: Router = inject(Router);
  private readonly featureFlagHandler: FeatureFlagHandler = inject(FeatureFlagHandler);

  readonly rankFF$: Observable<boolean> = this.featureFlagHandler.isEnabled$(FeatureFlag.RANK);

  goToHosted(): void {
    this.router.navigateByUrl(AppRoutes.HOST);
  }

  goToGames(): void {
    this.router.navigateByUrl(AppRoutes.GAMES);
  }

  goToAlerts(): void {
    this.router.navigateByUrl(`${AppRoutes.NOTIFICATIONS}/${NotificationsRoutes.ALERTS}`);
  }

  goToRank(): void {
    this.router.navigateByUrl(`${AppRoutes.RANK}`);
  }
}
