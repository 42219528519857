import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import {
  ADS_TOKEN,
  AdsProvider,
  FullHeightScrollableContainerDirective,
  PLATFORM_TOKEN,
  PlatformProvider
} from '@core';
import { BehaviorSubject, delay, filter, first, Observable, tap } from 'rxjs';
import { BannerAdPluginEvents } from '@capacitor-community/admob';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
  selector: 'lib-close-ad-banner',
  templateUrl: './close-ad-banner.component.html',
  styleUrls: ['./close-ad-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, NgIf]
})
export class CloseAdBannerComponent implements OnInit, OnDestroy {
  @ViewChild('closeBannerRef') element!: ElementRef;

  @Input({ required: true }) containerDirective!: FullHeightScrollableContainerDirective;
  @Input() bottomSpace: number = 120;

  private readonly adsProvider: AdsProvider = inject(ADS_TOKEN);
  private readonly renderer2: Renderer2 = inject(Renderer2);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);

  private readonly isVisibleSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  readonly isVisible$: Observable<boolean> = this.isVisibleSubject.asObservable();

  ngOnInit(): void {
    const resolvedBottomSpace = this.platformProvider.isiOS ? this.bottomSpace + 15 : this.bottomSpace;

    this.adsProvider.bannerEvents$
      .pipe(
        filter((bannerEvent: BannerAdPluginEvents | null) => bannerEvent === BannerAdPluginEvents.AdImpression),
        first(),
        tap(() => this.isVisibleSubject.next(true)),
        delay(100),
        tap(() => this.renderer2.addClass(this.element.nativeElement, `close-ad-button`)),
        tap(() => this.renderer2.setStyle(this.element.nativeElement, 'bottom', `${resolvedBottomSpace}px`))
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.closeBanner();
  }

  closeBanner(): void {
    this.isVisibleSubject.next(false);
    this.adsProvider.removeBanner();
    this.containerDirective.setStyle();
  }
}
