import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotificationsRoutes } from './notifications-routes.enum';
import { NotificationsListComponent, AlertSettingsComponent, CustomNotificationComponent } from './components';

const routes: Routes = [
  {
    path: NotificationsRoutes.ROOT,
    component: NotificationsListComponent
  },
  {
    path: NotificationsRoutes.ALERTS,
    component: AlertSettingsComponent
  },
  {
    path: `${NotificationsRoutes.CUSTOM_NOTIFICATION}/:id`,
    component: CustomNotificationComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NotificationsRoutingModule {}
