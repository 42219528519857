import { PenaltiesService } from '../../infrastructure';
import { inject } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { map, Observable, switchMap } from 'rxjs';
import { GroupedPenalties, GroupedUserPenalties } from '../interfaces/grouped-user-penalties.model';
import { PenaltyDTO, PenaltyResponseDTO } from '../interfaces';
import { CardReason } from '../enums';
import { formatDateToString } from '@core';

export class GetUserPenaltiesQueryHandler {
  private readonly penaltiesService: PenaltiesService = inject(PenaltiesService);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  getPenalties(): Observable<GroupedUserPenalties> {
    return this.activatedRoute.params.pipe(
      switchMap((params: Params) => this.penaltiesService.getPenalties(params['id'])),
      map((penaltyResponse: PenaltyResponseDTO) => {
        const { activePenalty, penaltyHistory } = penaltyResponse;

        const allPenalties: PenaltyDTO[] = [...(activePenalty ? [activePenalty] : []), ...(penaltyHistory || [])];

        const groupedPenalties: Record<CardReason, GroupedPenalties> = allPenalties.reduce((acc, penalty) => {
          const { penaltyReason, penaltyDate, author } = penalty;

          return {
            ...acc,
            [penaltyReason]: {
              penaltyType: penaltyReason,
              penaltyDetails: [
                ...(acc[penaltyReason]?.penaltyDetails || []),
                {
                  authorName: author.displayName,
                  createdAt: formatDateToString(penaltyDate, 'dd.MM.yyyy')
                }
              ]
            }
          };
        }, {} as Record<CardReason, GroupedPenalties>);

        const groupedPenaltiesArray: GroupedPenalties[] = Object.values(groupedPenalties);

        return {
          totalPenalties: allPenalties.length,
          groupedPenalties: groupedPenaltiesArray
        };
      })
    );
  }
}
