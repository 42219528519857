import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { DashboardRouting } from "./dashboard.routing";
import {
  FutureGamesStorage,
  LoadFutureGamesCommandHandler,
  provideGamesService,
} from "@games";

@NgModule({
  imports: [RouterModule.forChild(DashboardRouting)],
  providers: [
    provideGamesService(),
    FutureGamesStorage,
    LoadFutureGamesCommandHandler,
  ],
})
export class DashboardModule {}
