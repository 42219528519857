import { ChangeDetectionStrategy, Component, inject, Injector, ViewEncapsulation } from '@angular/core';
import { AuthFormComponent } from '../../components';
import { CredentialsDTO } from '../../application/models';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/app-routes.enum';
import { MODAL_TOKEN, ModalProvider, PLATFORM_TOKEN, PlatformProvider, PresentModalComponent } from '@core';
import { NgClass } from '@angular/common';
import { LoginCommandHandler } from '../../application/handlers';
import { redirectAfterLoginUtil } from '../../application/utils';

@Component({
  templateUrl: './login.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [AuthFormComponent, NgClass],
  standalone: true
})
export class LoginPage {
  private readonly loginCommandHandler: LoginCommandHandler = inject(LoginCommandHandler);
  private readonly router: Router = inject(Router);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly injector: Injector = inject(Injector);

  readonly isiOS: boolean = this.platformProvider.isiOS;

  login(event: CredentialsDTO) {
    this.loginCommandHandler
      .login({
        email: event.email?.trim(),
        password: event.password
      })
      .subscribe({
        next: () => {
          redirectAfterLoginUtil(this.injector);
        },
        error: () => this.errorLogin()
      });
  }

  goBack(): void {
    this.router.navigate([`/${AppRoutes.AUTH}`]);
  }

  private errorLogin(): void {
    this.modalProvider.showModal$({
      component: PresentModalComponent,
      componentProps: {
        header: 'Logowanie',
        message: 'Błedne dane',
        btnTxt: 'Zamknij'
      },
      cssClass: 'present-modal'
    });
  }
}
