import { UserService } from '../../infrastructure';
import { inject } from '@angular/core';
import { switchMap, take } from 'rxjs';
import { ProfileType, USER_DATA_TOKEN, UserDataProvider, UserModel } from '@core';
import { UpdateUserDTO } from '../interfaces';

export class UpdateProfileTypeCommandHandler {
  private readonly userService: UserService = inject(UserService);
  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);

  updateProfileType(type: ProfileType) {
    return this.userDataProvider.userData$.pipe(
      take(1),
      switchMap((userData: UserModel) => {
        const payload: UpdateUserDTO = { name: userData.name, surname: userData.surname, profileType: type };

        return this.userService.updateUser(payload);
      })
    );
  }
}
