import { filter, first, Observable, of, switchMap } from 'rxjs';
import { StartupState } from '../startup.state';
import { inject } from '@angular/core';
import { StartupStep } from '../startup-step';
import { NotificationObserverQueryHandler } from '../../../../projects/notifications/src/lib/application/handlers';

export class ObserveNotificationStep extends StartupStep {
  private readonly notificationObserverQueryHandler: NotificationObserverQueryHandler = inject(
    NotificationObserverQueryHandler
  );

  executeStep(state: StartupState): Observable<StartupState> {
    state.authed$
      ?.pipe(
        filter(Boolean),
        first(),
        switchMap(() => this.notificationObserverQueryHandler.observeNotification())
      )
      .subscribe();

    return of(state);
  }
}
