<ng-container *ngIf="comments$ | async as comments">
  <div *ngIf="comments.totalComments" class="or-bg-white border-radius-4 or-p-12-px card-box-shadow">
    <div (click)="changeVisibility()" class="or-d-flex or-justify-content-between or-align-items-center">
      <div class="or-head-sm">Komplementy ({{comments.totalComments}})</div>
      <img [src]="isExpanded ? 'assets/icons/chevron-up.svg' : 'assets/icons/chevron-down-primary.svg'" />
    </div>
    <div *ngIf="isExpanded" class="or-mt-12-px">
      <div
        *ngFor="let comment of comments.groupedComments; let i = index; let lastComment = last; let firstComment = first"
        class="or-d-flex or-align-items-center"
        [class.or-mb-12-px]="!lastComment || (firstComment && !lastComment)">
        <img [src]="'assets/icons/comment-icon.svg'" class="or-align-self-start or-d-flex or-mr-8-px or-mt-2-px" />
        <div>
          <div class="or-text-md or-mb-4-px">
            {{ commentMapper[comment.commentType].displayName }} ({{ comments.groupedComments[i].commentDetails.length
            }})
          </div>
          <div
            *ngFor="let commentDetails of comment.commentDetails; let lastItem = last; let firstItem = first"
            class="or-head-xs or-color-secondary-2"
            [class.or-mb-2-px]="!lastItem || firstItem">
            {{ commentDetails.authorName }} - {{commentDetails.createdAt }}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
