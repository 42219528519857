import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  selector: "lib-leave-game-with-alert",
  templateUrl: "./leave-game-with-alert.component.html",
  styleUrls: ["./leave-game-with-alert.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class LeaveGameWithAlertComponent {}
