import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserStatsDTO } from '../../application/interfaces';

@Component({
  selector: 'lib-user-stats',
  templateUrl: './user-stats.component.html',
  styleUrls: ['./user-stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class UserStatsComponent {
  @Input({ required: true }) userStats!: UserStatsDTO;
}
