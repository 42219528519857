import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import {
  BottomNavbarComponent,
  GameCardComponent,
  TitileNavbarComponent,
} from "@ui-components";
import { ActivatedRoute, Params } from "@angular/router";
import {
  combineLatest,
  map,
  Observable,
  shareReplay,
  switchMap,
  take,
  tap,
} from "rxjs";
import { AsyncPipe, NgClass, NgIf } from "@angular/common";
import {
  GameDetailsModel,
  MODAL_TOKEN,
  ModalProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
  USER_DATA_TOKEN,
  UserDataProvider,
  UserModel,
} from "@core";
import { AppRoutes } from "src/app/app-routes.enum";
import { RatingAppComponent } from "../rating-app/rating-app.component";
import { provideRatingService } from "../../infrastructure/http-service";
import {
  GameDetailsQueryHandler,
  RateAppCommandHandler,
} from "../../application/handlers";

@Component({
  selector: "lib-game-finished",
  templateUrl: "./game-finished.component.html",
  styleUrls: ["./game-finished.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TitileNavbarComponent,
    BottomNavbarComponent,
    GameCardComponent,
    AsyncPipe,
    NgIf,
    RatingAppComponent,
    NgClass,
  ],
  providers: [provideRatingService(), RateAppCommandHandler],
})
export class GameFinishedComponent {
  public playerText: string =
    "Jeśli nie zapłaciłeś organizatorowi za gierkę, opłać ją za pomocą BLIKA w swoim telefonie.";
  public hostText: string = "Dziękujemy za skorzystanie z Orlikfy!";
  public customBackUrl: string = `${AppRoutes.ROOT}`;

  private readonly gameDetailsQueryHandler: GameDetailsQueryHandler = inject(
    GameDetailsQueryHandler
  );
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  readonly isiOS: boolean = this.platformProvider.isiOS;

  readonly game$: Observable<GameDetailsModel> = this.modalProvider
    .showLoading$()
    .pipe(
      switchMap(() => this.activatedRoute.params),
      take(1),
      switchMap((params: Params) =>
        this.gameDetailsQueryHandler.getGameDetails(params["id"])
      ),
      tap(() => this.modalProvider.dismissLoading$()),
      shareReplay(1)
    );

  readonly isHostedGame$: Observable<boolean> = combineLatest([
    this.game$,
    this.userDataProvider.userData$,
  ]).pipe(
    map(
      ([game, userData]: [GameDetailsModel, UserModel]) =>
        game?.host?.hostId === userData?.userId
    ),
    shareReplay(1)
  );

  readonly messageText$: Observable<string> = this.isHostedGame$.pipe(
    map((isHosted: boolean) => (isHosted ? this.hostText : this.playerText))
  );
}
