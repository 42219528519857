import {
  Directive,
  EventEmitter,
  HostListener,
  inject,
  Input,
  Output,
} from "@angular/core";
import {
  ActionModalComponent,
  MODAL_TOKEN,
  ModalProvider,
  PresentModalComponent,
  USER_DATA_TOKEN,
  UserDataProvider,
  UserModel,
} from "@core";
import { take, tap } from "rxjs";
import { Router } from "@angular/router";
import { AppRoutes } from "src/app/app-routes.enum";
import { ProfileRoutes } from "@profile";
import { SendApplicationResponseDTO } from "../interfaces";
import { SendApplicationCommandHandler } from "../handlers";

@Directive({
  standalone: true,
  selector: "[sendApplication]",
})
export class SendApplicationDirective {
  @Input("sendApplication") gameId!: string;
  @Input("showModal") showModal: boolean = true;
  @Output() applicationSent: EventEmitter<string> = new EventEmitter<string>();
  @Output() onBlockMulticlick: EventEmitter<void> = new EventEmitter<void>();

  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);
  private readonly router: Router = inject(Router);
  private readonly sendApplicationCommandHandler: SendApplicationCommandHandler =
    inject(SendApplicationCommandHandler);

  @HostListener("click")
  onClick() {
    this.onBlockMulticlick.emit();

    this.userDataProvider.userData$
      .pipe(
        take(1),
        tap((userData: UserModel) => {
          if (
            !userData.displayName.trim() ||
            !userData.playerPreferences?.preferredPosition ||
            !userData.playerPreferences?.preferredLevel
          )
            return this.showBlockedModal();

          return this.sendApplication();
        })
      )
      .subscribe();
  }

  showBlockedModal(): void {
    this.modalProvider.showModal$({
      component: ActionModalComponent,
      cssClass: "present-modal",
      componentProps: {
        header: "Uzupełnij profil",
        message: "Aby dołączyć do gierki musisz uzupełnić profil",
        btnOk: "Przejdź do profilu",
        btnCancel: "Anuluj",
        action: () =>
          this.router.navigateByUrl(
            `${AppRoutes.PROFILE}/${ProfileRoutes.EDIT}`
          ),
      },
    });
  }

  private sendApplication(): void {
    this.sendApplicationCommandHandler
      .sendApplication(this.gameId)
      .pipe(take(1))
      .subscribe((response: SendApplicationResponseDTO) => {
        this.applicationSent.emit(this.gameId);
        this.showModal ? this.showMessage(response.autoApproved) : void 0;
      });
  }

  private showMessage(isAutoApproved: boolean): void {
    const waitingApplicationText: string =
      "Wysłałeś chęć udziału zagrania w gierce. Teraz musisz poczekać, aż organizator Cię zaakceptuje. Czekaj na powiadomienie";
    const autoApprovedText: string =
      "Zostałeś automatycznie zaakceptowany do gierki!";

    this.modalProvider.showModal$({
      component: PresentModalComponent,
      componentProps: {
        header: "Dołączenie do gierki",
        message: isAutoApproved ? autoApprovedText : waitingApplicationText,
        btnTxt: "Rozumiem, dzięki!",
      },
      cssClass: "present-modal",
    });
  }
}
