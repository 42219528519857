import { PenaltiesService } from '../../infrastructure';
import { inject } from '@angular/core';
import { map, Observable, switchMap } from 'rxjs';
import { PenaltyResponseDTO } from '../interfaces';
import { USER_DATA_TOKEN, UserDataProvider, UserModel } from '@core';
import { CardType } from '../enums';

export class IsRedCardActiveQueryHandler {
  private readonly penaltiesService: PenaltiesService = inject(PenaltiesService);
  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);

  isRedCardActive(): Observable<boolean> {
    return this.userDataProvider.userData$.pipe(
      switchMap((userData: UserModel) => this.penaltiesService.getPenalties(userData.userId)),
      map((penaltyResponse: PenaltyResponseDTO) => penaltyResponse.activePenalty?.penaltyType === CardType.RED)
    );
  }
}
