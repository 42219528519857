import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { GetTeamsQueryHandler, TeamDTO, TeamsRoutes, TeamsService } from '@teams';
import { map, Observable } from 'rxjs';
import { TeamRole } from '../../../../../teams/src/lib/application/enums';
import { AsyncPipe, NgIf } from '@angular/common';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/app-routes.enum';

@Component({
  selector: 'lib-create-team',
  templateUrl: './create-team.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, AsyncPipe],
  providers: [GetTeamsQueryHandler, TeamsService]
})
export class CreateTeamComponent {
  private readonly getTeamsQueryHandler: GetTeamsQueryHandler = inject(GetTeamsQueryHandler);
  private readonly router: Router = inject(Router);

  readonly isVisible$: Observable<boolean> = this.getTeamsQueryHandler.getTeams().pipe(
    map((teams: TeamDTO[]) => {
      const result: TeamDTO[] = teams?.filter((team: TeamDTO) => team.memberRole === TeamRole.Host);

      return !result?.length;
    })
  );

  createTeam(): void {
    this.router.navigateByUrl(`${AppRoutes.TEAMS}/${TeamsRoutes.CREATE}`);
  }
}
