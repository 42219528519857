export enum CustomNotificationRedirectType {
  InApp = 'inApp',
  Web = 'web'
}

export enum CustomNotificationAttribute {
  RedirectType = 'redirect-type',
  RedirectUrl = 'redirect-url'
}

export enum CustomNotificationType {
  Page = 'page',
  Modal = 'modal',
  Redirect = 'redirect'
}
