import { FiltersValues } from '../models';
import { toUTCWithoutConversion } from '@core';

export const parseFiltersData = (obj: FiltersValues): string => {
  const parts: string[] = [];

  Object.keys(obj).forEach((key: string) => {
    const value = obj[key as keyof FiltersValues];

    if (value === undefined || value === null || (Array.isArray(value) && value.length === 0)) {
      return;
    }

    if (Array.isArray(value)) {
      parts.push(`${key}=${value.map((item) => encodeURIComponent(item.toString())).join(',')}`);
    } else if (value instanceof Date) {
      const utcDate: Date = toUTCWithoutConversion(value);

      parts.push(`${key}=${encodeURIComponent(utcDate.toISOString().split('T')[0])}`);
    } else if (typeof value === 'number') {
      parts.push(`${key}=${value}`);
    } else if (typeof value === 'string') {
      parts.push(`${key}=${encodeURIComponent(value)}`);
    }
  });

  return parts.join('&');
};
