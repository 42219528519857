<div class="or-my-32-px or-mx-24-px">
  <div
    class="or-d-flex or-justify-content-center or-head-xl or-mb-12-px or-text-align-center"
  >
    Wybierz powód przyznania kartki
  </div>

  <div
    class="or-d-flex or-justify-content-center or-text-align-center or-mb-20-px or-text-lg"
  >
    Kartka będzie widoczna w profilu użytkownika na zawsze w aplikacji.
  </div>

  <div
    class="or-d-flex or-align-items-center or-justify-content-center or-mb-20-px"
  >
    <lib-or-avatar
      [avatarUrl]="player.userThumbnail"
      [initials]="player.userDisplayName"
      [avatarSize]="'sm'"
    ></lib-or-avatar>

    <div class="or-head-md or-truncate-at-line-1">
      {{ player.userDisplayName }}
    </div>
  </div>

  <ion-radio-group
    (ionChange)="select($event)"
    [value]="selectedReason$ | async"
  >
    <div class="or-d-flex or-flex-col or-head-sm or-mb-16-px">
      <div
        *ngFor="let reason of reasons"
        class="or-p-12-px or-mb-8-px or-d-flex or-align-items-center or-justify-content-between"
        [ngClass]="
          isSelected(reason.value)
            ? 'reason-box-border-active'
            : 'reason-box-border-no-active'
        "
      >
        <div
          class="or-d-flex or-align-items-center or-w-100-pc or-h-100-pc"
          (click)="selectBox(reason.value)"
        >
          <ion-radio
            [value]="reason.value"
            label-placement="end"
            justify="space-between"
          ></ion-radio>
          <div class="or-ml-12-px">{{ reason.displayName }}</div>
        </div>
        <img [src]="reason.icon" class="or-ml-24-px" />
      </div>
    </div>
  </ion-radio-group>

  <div class="or-d-flex">
    <button
      class="button-secondary-style or-h-48-px or-w-60-pc or-head-md or-mr-8-px"
      (click)="closeModal()"
    >
      Anuluj
    </button>
    <button
      class="button-primary-style or-h-48-px or-w-60-pc or-head-md"
      (click)="addCard()"
    >
      Przyznaj kartkę
    </button>
  </div>
</div>
