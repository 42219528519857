import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { OrAvatarComponent, TitileNavbarComponent } from '@ui-components';
import { LocalRefreshService, MODAL_TOKEN, ModalProvider, PLATFORM_TOKEN, PlatformProvider } from '@core';
import { Observable, switchMap, tap } from 'rxjs';
import { GetTeamDetailsQueryHandler, UpdateTeamMemberCommandHandler } from '../../application/handlers';
import { ActivatedRoute, Params } from '@angular/router';
import { TeamDTO } from '../../application/interfaces';

@Component({
  selector: 'lib-switch-auto-approve',
  templateUrl: './switch-auto-approve.component.html',
  styleUrls: ['./switch-auto-approve.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, IonicModule, NgIf, NgClass, NgForOf, OrAvatarComponent, TitileNavbarComponent],
  providers: [GetTeamDetailsQueryHandler, LocalRefreshService, UpdateTeamMemberCommandHandler]
})
export class SwitchAutoApproveComponent {
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly getTeamDetailsQueryHandler: GetTeamDetailsQueryHandler = inject(GetTeamDetailsQueryHandler);
  private readonly localRefreshService: LocalRefreshService = inject(LocalRefreshService);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly updateTeamMemberCommandHandler: UpdateTeamMemberCommandHandler =
    inject(UpdateTeamMemberCommandHandler);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  readonly isiOS: boolean = this.platformProvider.isiOS;

  readonly teamDetails$: Observable<TeamDTO> = this.localRefreshService.refresh$.pipe(
    switchMap(() => this.modalProvider.showLoading$()),
    switchMap(() => this.activatedRoute.params),
    switchMap((params: Params) => this.getTeamDetailsQueryHandler.teamDetails(params['id'])),
    tap(() => this.modalProvider.dismissLoading$())
  );

  switchAutoApproved(isAutoApproved: boolean, userId: string, teamId: string) {
    this.updateTeamMemberCommandHandler
      .update(teamId, userId, {
        isAutoApproved: !isAutoApproved
      })
      .subscribe(() => this.localRefreshService.emit());
  }
}
