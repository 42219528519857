import { TeamsService } from '../../infrastructure';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

export class JoinToTeamCommandHandler {
  private readonly teamsService: TeamsService = inject(TeamsService);

  join(teamId: string, token: string): Observable<void> {
    return this.teamsService.joinToTeam(teamId, token);
  }
}
