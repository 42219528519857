import { LineupsService } from '../../infrastructure/http-service';
import { inject } from '@angular/core';
import { Observable, switchMap, take } from 'rxjs';
import { LineupsDTO } from '../interfaces';
import { ActivatedRoute, Params } from '@angular/router';

export class SaveLineupsCommandHandler {
  private readonly lineupsService: LineupsService = inject(LineupsService);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  save(players: string[]): Observable<void> {
    return this.activatedRoute.params.pipe(
      take(1),
      switchMap((params: Params) => {
        const teamAlpha: string[] = players.slice(0, this.splitTeamSize(players?.length));
        const teamBeta: string[] = players.slice(this.splitTeamSize(players?.length), players?.length);

        const payload: LineupsDTO = {
          lineups: [
            {
              lineupPlayers: teamAlpha.map((id: string) => ({
                playerId: id
              }))
            },
            {
              lineupPlayers: teamBeta.map((id: string) => ({
                playerId: id
              }))
            }
          ]
        };

        return this.lineupsService.save(payload, params['id']);
      })
    );
  }

  splitTeamSize(length: number): number {
    if (!length) return 0;
    return +(length / 2).toFixed();
  }
}
