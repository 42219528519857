import { NgxSpinnerService } from 'ngx-spinner';
import { inject, Injectable } from '@angular/core';
import { from, Observable, take } from 'rxjs';

@Injectable()
export class OrLoadingService {
  private spinner: NgxSpinnerService = inject(NgxSpinnerService);

  show(): Observable<unknown> {
    return from(this.spinner.show()).pipe(take(1));
  }

  hide(): Observable<unknown> {
    return from(this.spinner.hide()).pipe(take(1));
  }
}
