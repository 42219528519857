import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, Router, UrlTree } from "@angular/router";
import { AppRoutes } from "src/app/app-routes.enum";
import { combineLatest, map, Observable } from "rxjs";
import {
  PLATFORM_TOKEN,
  PlatformProvider,
  USER_DATA_TOKEN,
  UserDataProvider,
} from "../tokens";
import { UserRoleEnum } from "../enums";
import { UserModel } from "../interfaces";
import { AllowToWebViewService } from "../services";
import { environment } from "src/environment/environment";

export const blockWebVersionGuard = (
  route: ActivatedRouteSnapshot
): Observable<true | UrlTree> => {
  const router: Router = inject(Router);
  const platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  const userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);
  const allowToWebViewService: AllowToWebViewService = inject(
    AllowToWebViewService
  );

  const isNativeRun: boolean = platformProvider.isNativeRun;

  return combineLatest([
    userDataProvider.userData$,
    allowToWebViewService.allowedUsers(),
  ]).pipe(
    map(([userData, allowedUsers]: [UserModel, string[]]) => {
      if (
        isNativeRun ||
        userData.role === UserRoleEnum.ADMIN ||
        allowedUsers.includes(userData.userId) ||
        !environment.production
      )
        return true;
      return router.createUrlTree([AppRoutes.WEB_BLOCKED]);
    })
  );
};
