import { CardReason, CardType } from '../enums';
import { CardReasonModel } from '../interfaces';

export const cardIconMapper: Record<CardType, string> = {
  [CardType.RED]: 'assets/icons/red-card.svg',
  [CardType.YELLOW]: 'assets/icons/yellow-card.svg'
};

export const cardReasonMapper: Record<CardReason, CardReasonModel> = {
  [CardReason.ABSENCE]: {
    value: CardReason.ABSENCE,
    displayName: 'Nieobecność zawodnika',
    icon: cardIconMapper[CardType.RED],
    cardType: CardType.RED
  },
  [CardReason.LEAVING_30_MIN]: {
    value: CardReason.LEAVING_30_MIN,
    displayName: 'Opuszczenie gierki 30 min przed',
    icon: cardIconMapper[CardType.YELLOW],
    cardType: CardType.YELLOW
  },
  [CardReason.LATE_15_MIN]: {
    value: CardReason.LATE_15_MIN,
    displayName: 'Spóźnienie powyżej 15 minut',
    icon: cardIconMapper[CardType.YELLOW],
    cardType: CardType.YELLOW
  },
  [CardReason.MISMATCHED_LEVEL]: {
    value: CardReason.MISMATCHED_LEVEL,
    displayName: 'Niedopasowany poziom zawodnika do poziomu gierki',
    icon: cardIconMapper[CardType.YELLOW],
    cardType: CardType.YELLOW
  },
  [CardReason.UNSPORTSMANLIKE_BEHAVIOR]: {
    value: CardReason.UNSPORTSMANLIKE_BEHAVIOR,
    displayName: 'Niesportowe zachowanie',
    icon: cardIconMapper[CardType.YELLOW],
    cardType: CardType.YELLOW
  }
};
