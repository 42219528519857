import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { ENV_CONFIG, EnvConfig, GameDTO } from '@core';
import { map, Observable } from 'rxjs';

export class UserGamesHttpService {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  getLastGames(userId: string): Observable<GameDTO[]> {
    return this.httpClient
      .get<{ pastGames: GameDTO[] }>(`${this.env.apiUrl}/user/${userId}/games`)
      .pipe(map((response) => response.pastGames));
  }
}
