import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnInit,
} from "@angular/core";
import { NgClass, NgIf } from "@angular/common";
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { EditPlayerCommandHandler } from "../../application/handlers";
import { BehaviorSubject, switchMap, take } from "rxjs";
import { CustomPlayerModel } from "../../application/interfaces";
import { GamePlayersDTO, MODAL_TOKEN, ModalProvider } from "@core";
import { provideHostedGamesService } from "../../infrastructure/http-service";
import { IonicModule } from "@ionic/angular";
import { AddPlayerCommandHandler } from "../../application/handlers/add-player.command-handler";

@Component({
  selector: "lib-add-custom-player",
  templateUrl: "./add-custom-player.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgClass, IonicModule, NgIf],
  providers: [
    AddPlayerCommandHandler,
    provideHostedGamesService(),
    EditPlayerCommandHandler,
  ],
})
export class AddCustomPlayerComponent implements OnInit {
  @Input() gameId!: string;
  @Input() player!: GamePlayersDTO;
  @Input() isEdit: boolean = false;

  private readonly addPlayerCommandHandler: AddPlayerCommandHandler = inject(
    AddPlayerCommandHandler
  );
  private readonly editPlayerCommandHandler: EditPlayerCommandHandler = inject(
    EditPlayerCommandHandler
  );
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  private readonly addPlayerSubject: BehaviorSubject<CustomPlayerModel> =
    new BehaviorSubject<CustomPlayerModel>({} as CustomPlayerModel);

  ngOnInit() {
    this.addPLayerForm.valueChanges.subscribe((form) =>
      this.addPlayerSubject.next({ displayName: form.displayName })
    );

    if (this.isEdit) {
      this.addPLayerForm.patchValue({
        displayName: this.player.userDisplayName,
      });
    }
  }

  readonly addPLayerForm: FormGroup = new FormGroup({
    displayName: new FormControl("", [Validators.required]),
  });

  handleAction(): void {
    this.isEdit ? this.editPlayer() : this.addPlayer();
  }

  closeModal(): void {
    this.modalProvider.dismissAllStoredModals();
  }

  private addPlayer(): void {
    this.addPlayerSubject
      .asObservable()
      .pipe(
        take(1),
        switchMap((value: CustomPlayerModel) => {
          return this.addPlayerCommandHandler.addPlayer(this.gameId, value);
        }),
        take(1)
      )
      .subscribe(() => this.closeModal());
  }

  private editPlayer(): void {
    this.addPlayerSubject
      .asObservable()
      .pipe(
        take(1),
        switchMap((value: CustomPlayerModel) => {
          return this.editPlayerCommandHandler.editPlayer(
            this.gameId,
            value,
            this.player.playerId
          );
        }),
        take(1)
      )
      .subscribe(() => this.closeModal());
  }
}
