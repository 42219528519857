import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { MODAL_TOKEN, ModalProvider } from '../../tokens';

@Component({
  templateUrl: './present-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class PresentModalComponent {
  @Input() header!: string;
  @Input() message!: string;
  @Input() btnTxt!: string;
  @Input() centerMessage: boolean = true;

  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  closeModal() {
    this.modalProvider.dismissAllStoredModals();
  }
}
