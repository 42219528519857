import { HostedGamesService } from '../../infrastructure/http-service';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

export class ReassingGameCommandHandler {
  private readonly hostedGamesService: HostedGamesService = inject(HostedGamesService);

  reassign(gameId: string, token: string): Observable<void> {
    return this.hostedGamesService.reassignGame(gameId, token);
  }
}
