<form
  autocomplete="on"
  class="or-d-flex or-justify-content-center margin-top--20-px"
  [formGroup]="credentialsForm"
  (ngSubmit)="onButtonClicked()">
  <div class="or-d-flex or-flex-col or-w-80-pc">
    <input placeholder="E-mail" class="outline-none input-style" formControlName="email" autocomplete="username" />
    <div class="pw-container">
      <input
        placeholder="Hasło"
        [type]="showPassword ? 'text' : 'password'"
        class="outline-none input-style or-mt-8-px or-w-100-pc"
        formControlName="password"
        autocomplete="current-password" />
      <img
        *ngIf="!showPassword"
        src="assets/icons/eye-icon.svg"
        (click)="toggleShowPassword()"
        class="or-size-32-px or-position-absolute password-icon or-size-6" />
      <img
        *ngIf="showPassword"
        src="assets/icons/eye-crossed-icon.svg"
        (click)="toggleShowPassword()"
        class="or-size-32-px or-position-absolute password-icon or-size-6" />
    </div>

    <input
      *ngIf="isRegister"
      [type]="showPassword ? 'text' : 'password'"
      placeholder="Powtórz hasło"
      class="outline-none input-style or-mt-8-px"
      formControlName="password2" />

    <div
      *ngIf="!isRegister"
      (click)="resetPasswordModal()"
      class="or-text-md or-color-primary or-mt-12-px or-cursor-pointer or-text-decoration-underline">
      Przypomnij hasło
    </div>

    <div class="or-d-flex or-justify-content-center or-mt-24-px">
      <button
        type="submit"
        class="button-primary-style or-h-48-px or-w-100-pc or-head-md"
        [class.disabled-button]="!credentialsForm.valid">
        {{ isRegister ? "Zarejestruj się i dołącz do gier!" : "Zaloguj się i dołącz do gier!" }}
      </button>
    </div>
  </div>
</form>
