<div class="or-head-xs or-color-secondary-2 or-mb-8-px">Dodaj osoby do gierki</div>
<div
  [shareGame]="game"
  class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-mb-8-px or-d-flex or-p-8-px or-h-56-px or-max-h-28-px">
  <img src="assets/icons/share-shade-2.svg" class="or-mr-8-px or-size-32-px" />
  <div>
    <div class="or-text-md">Skopiuj link do gierki</div>
  </div>
</div>

<div
  (click)="addPlayer()"
  class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-mb-8-px or-d-flex or-p-8-px or-h-56-px or-max-h-28-px">
  <img src="assets/add-button-shade.svg" class="or-mr-8-px or-size-32-px" />
  <div class="or-text-md">Dodaj gracza ręcznie</div>
</div>

<div
  (click)="goToInvitations()"
  class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-mb-8-px or-d-flex or-p-8-px or-h-56-px or-max-h-28-px">
  <img src="assets/icons/notifications-grey.svg" class="or-mr-8-px or-size-32-px" />
  <div class="or-text-md">Zaproś dawnych graczy</div>
</div>
