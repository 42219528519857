import { Observable, of, OperatorFunction, ReplaySubject } from 'rxjs';
import { EnvironmentInjector, inject } from '@angular/core';
import { StartupState } from './startup.state';
import { tap } from 'rxjs/operators';

export abstract class StartupStepsMiddleware<T extends StartupState> {
  protected readonly injector: EnvironmentInjector = inject(EnvironmentInjector);
  private readonly pipes: OperatorFunction<T, T>[] = [];
  private readonly subject$: ReplaySubject<T> = new ReplaySubject<T>(1);

  protected run(initialState: T): Observable<T> {
    this.subject$.subscribe({
      complete: () => {
        console.log(`Startup flow initialized.`);
      }
    });

    let initialState$: Observable<T> = of(initialState) as Observable<T>;
    this.pipes.forEach((middleware: OperatorFunction<T, T>) => (initialState$ = initialState$.pipe(middleware)));

    initialState$
      .pipe(
        tap((state: T) => this.subject$.next(state)),
        tap(() => this.subject$.complete())
      )
      .subscribe();

    return this.subject$.asObservable();
  }

  configure(step: OperatorFunction<T, T>): void {
    this.pipes.push(step);
  }
}
