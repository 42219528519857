import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { NgIf } from '@angular/common';
import { ButtonLabels, CopyService, CopyValueToClipboardDirective, MODAL_TOKEN, ModalProvider } from '@core';
import { showInstructionModal } from '../../application/utils';
import { MuteChannelCommandHandler } from '../../application/handlers';
import { take, tap } from 'rxjs';
import { ChannelDetailsDTO } from '../../application/interfaces';

@Component({
  selector: 'lib-manage-channel',
  templateUrl: './manage-channel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, CopyValueToClipboardDirective],
  providers: [CopyService, MuteChannelCommandHandler]
})
export class ManageChannelComponent {
  @Input({ required: true }) channelDetails!: ChannelDetailsDTO;

  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly muteChannelCommandHandler: MuteChannelCommandHandler = inject(MuteChannelCommandHandler);

  get muteChatLabel(): ButtonLabels {
    const isMuted: boolean = this.channelDetails?.isMuted;
    const header: string = isMuted ? 'Włącz powiadomienia' : 'Wycisz Chat';

    const icon: string = isMuted ? 'assets/icons/notifications-grey.svg' : 'assets/icons/notifications-muted.svg';
    return { header, icon };
  }

  showInstructionModal(): void {
    this.modalProvider.dismissAllStoredModals();
    showInstructionModal(this.modalProvider);
  }

  muteChannel(): void {
    this.muteChannelCommandHandler
      .mute(this.channelDetails.channelId, !this.channelDetails.isMuted)
      .pipe(
        take(1),
        tap(() => this.modalProvider.dismissAllStoredModals())
      )
      .subscribe();
  }
}
