import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { ENV_CONFIG, EnvConfig } from '@core';
import { Observable } from 'rxjs';
import { AddCardPayloadDTO, PenaltyResponseDTO } from '../application/interfaces';

export class PenaltiesService {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  addCard(payload: AddCardPayloadDTO): Observable<void> {
    return this.httpClient.post<void>(`${this.env.apiUrl}/user/${payload.userId}/penalty`, payload);
  }

  getPenalties(userId: string): Observable<PenaltyResponseDTO> {
    return this.httpClient.get<PenaltyResponseDTO>(`${this.env.apiUrl}/user/${userId}/penalty`);
  }
}
