<lib-title-navbar title="Powiadomienia"></lib-title-navbar>

<div class="or-mb-20-px"></div>

<div [ngClass]="isiOS ? 'full-height-scrollable-container-ios' : 'full-height-scrollable-container'">
  <div *ngIf="isPageLoaded$ | async" class="or-text-md or-mb-8-px or-mx-20-px">Nowe</div>
  <div
    *ngIf="!(newNotifications$ | async)?.length && (isPageLoaded$ | async)"
    class="or-d-flex or-head-sm or-justify-content-center or-my-32-px">
    Brak nowych powiadomień
  </div>

  <ng-container *ngFor="let newNotification of newNotifications$ | async">
    <ng-container
      *ngTemplateOutlet="notificationTemplate; context: { $implicit: newNotification, isPast: false }"></ng-container>
    <div class="custom-divider or-w-90-pc or-my-12-px"></div>
  </ng-container>

  <div *ngIf="(pastNotifications$ | async)?.length" class="or-text-md or-mb-8-px or-mx-20-px or-mt-28-px">Przeszłe</div>
  <ng-container *ngFor="let pastNotification of pastNotifications$ | async; let last = last">
    <ng-container
      *ngTemplateOutlet="notificationTemplate; context: { $implicit: pastNotification, isPast: true }"></ng-container>
    <div class="custom-divider or-w-90-pc or-my-12-px" [class.hide-last-dividier]="last"></div>
  </ng-container>
</div>

<ng-template #notificationTemplate let-notification let-isPast="isPast">
  <div
    (click)="redirect(notification)"
    class="or-d-flex or-align-items-center or-mx-20-px"
    [class.opacity-past]="isPast">
    <div>
      <ng-container *ngIf="!isPast">
        <ng-container *ngTemplateOutlet="unreadNotification"></ng-container>
      </ng-container>
      <lib-or-avatar
        *ngIf="!specificNotificationImage.includes(notification.type)"
        [avatarUrl]="notification.avatar"
        [avatarSize]="'md'"
        [initials]="notification.displayName"></lib-or-avatar>
      <img
        [src]="notification.avatar"
        *ngIf="specificNotificationImage.includes(notification.type)"
        class="or-mr-8-px custom-image" />
    </div>
    <div>
      <div class="or-color-secondary-2 or-head-xs or-mb-2-px">{{ notification.createdAt }}</div>
      <div class="or-text-md or-mb-2-px">{{ notification.title }}</div>
      <div class="or-head-xs">{{ notification.description }}</div>
      <div *ngIf="notification.formattedDate" class="or-text-sm">{{ notification.formattedDate }}</div>
    </div>
  </div>
</ng-template>

<ng-template #unreadNotification>
  <img
    src="assets/icons/unread-icon.svg"
    class="or-h-20-px or-left-40-px or-top-8-px or-position-relative or-w-20-px" />
</ng-template>
