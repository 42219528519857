export enum PlatformType {
  ANDROID = 'android',
  HYBRID = 'hybrid',
  DESKTOP = 'desktop',
  IOS = 'ios',
  OTHER = 'other',
  TABLET = 'tablet',
  WEB = 'web',
  MOBILE_WEB = 'mobileweb'
}
