import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { TitileNavbarComponent } from '@ui-components';
import { IonicModule } from '@ionic/angular';
import { BehaviorSubject, finalize, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { CommonModule } from '@angular/common';
import {
  ActionModalComponent,
  availableLevels,
  FullHeightScrollableContainerDirective,
  GameLevel,
  gameLevelMapper,
  MODAL_TOKEN,
  ModalProvider,
  NAVIGATION_TOKEN,
  NavigationProvider,
  NOTIFICATION_TOKEN,
  NotificationPermissionProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
  PresentModalComponent,
  Weekday,
  weekdayTranslation
} from '@core';
import { AlertsSettingsDTO } from '../../application/interfaces';
import { GetAlertsSettingsQueryHandler, UpdateAlertsSettingsCommandHandler } from '../../application/handlers';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';

@Component({
  selector: 'lib-notifications-settings',
  templateUrl: './alert-settings.component.html',
  styleUrls: ['./alert-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TitileNavbarComponent, IonicModule, CommonModule, FullHeightScrollableContainerDirective],
  providers: [GetAlertsSettingsQueryHandler, UpdateAlertsSettingsCommandHandler]
})
export class AlertSettingsComponent implements OnInit {
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly getAlertsSettingsQueryHandler: GetAlertsSettingsQueryHandler = inject(GetAlertsSettingsQueryHandler);
  private readonly updateAlertsSettingsCommandHandler: UpdateAlertsSettingsCommandHandler = inject(
    UpdateAlertsSettingsCommandHandler
  );
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly navigationProvider: NavigationProvider = inject(NAVIGATION_TOKEN);
  private notificationPermissionProvider: NotificationPermissionProvider = inject(NOTIFICATION_TOKEN);

  private shouldShowDistanceToast: boolean = false;

  public gameLevels: GameLevel[] = availableLevels;
  public gameLevelMapper: Record<GameLevel, string> = gameLevelMapper;
  public weekdaysMapper: Record<Weekday, string> = weekdayTranslation;
  public weekDays: Weekday[] = Object.values(Weekday);

  private defaultValues: AlertsSettingsDTO = {
    isEnabled: false,
    price: { max: 40, min: 0, currency: 'PLN' },
    coordinates: { latitude: 0, longitude: 0 },
    maxDistanceMeters: 30,
    time: { hoursMin: 0, hoursMax: 24 },
    gameLevel: [GameLevel.BEGINNER, GameLevel.INTERMEDIATE, GameLevel.ADVANCED, GameLevel.PROFESSIONAL],
    specificWeekdays: this.weekDays
  };

  private notificationsValuesSubject: BehaviorSubject<AlertsSettingsDTO> = new BehaviorSubject<AlertsSettingsDTO>(
    this.defaultValues
  );

  readonly notificationsValues$: Observable<AlertsSettingsDTO> = this.notificationsValuesSubject.asObservable();

  get isSelectAllWeekdaysVisible(): boolean {
    return this.notificationsValuesSubject.value.specificWeekdays?.length !== 7;
  }

  ngOnInit(): void {
    this.loadSettings();
  }

  loadSettings(): void {
    this.getAlertsSettingsQueryHandler
      .getAlertsSettings()
      .pipe(
        take(1),
        tap((settings: AlertsSettingsDTO) => this.notificationsValuesSubject.next(settings ?? this.defaultValues))
      )
      .subscribe();
  }

  saveSettings(): void {
    this.notificationsValues$
      .pipe(
        take(1),
        tap(() => this.modalProvider.showLoading$()),
        tap((result: AlertsSettingsDTO) => this.notificationsValuesSubject.next(result)),
        switchMap((result: AlertsSettingsDTO) => this.updateAlertsSettingsCommandHandler.updateAlertsSettings(result)),
        take(1),
        finalize(() => this.modalProvider.dismissLoading$())
      )
      .subscribe({
        next: () => {
          this.loadSettings();
          this.successModal();
          this.navigationProvider.back();
        },
        error: () => {
          this.modalProvider.presentAlert$({
            message: 'Włącz lokalizację aby używać tej funkcji.',
            buttons: ['Zamknij']
          });
          return of(void 0);
        }
      });
  }

  toggleNotifications(event: boolean): void {
    this.notificationPermissionProvider
      .isEnabled()
      .pipe(
        take(1),
        switchMap((isEnabled: boolean) => {
          if (!isEnabled)
            this.modalProvider.showModal$({
              component: ActionModalComponent,
              componentProps: {
                header: 'Brak uprawnień do powiadomień w aplikacji',
                message: 'Nadaj uprawnienia do powiadomień aby otrzymywać powiadomienia',
                btnOk: 'Włącz',
                btnCancel: 'Anuluj',
                action: () => this.openNotificationPermissions()
              },
              cssClass: 'present-modal'
            });

          return of(void 0);
        })
      )
      .subscribe();

    this.notificationsValues$
      .pipe(
        take(1),
        map((values: AlertsSettingsDTO) =>
          this.notificationsValuesSubject.next({
            ...values,
            isEnabled: event
          })
        )
      )
      .subscribe();

    this.notificationsValuesSubject.value;
  }

  onPriceChange(event: CustomEvent): void {
    this.notificationsValues$
      .pipe(
        take(1),
        map((values: AlertsSettingsDTO) =>
          this.notificationsValuesSubject.next({
            ...values,
            price: {
              min: event.detail.value.lower,
              max: event.detail.value.upper,
              currency: 'PLN'
            }
          })
        )
      )
      .subscribe();
  }

  onDistanceChange(event: CustomEvent): void {
    const value = event.detail.value;

    if (value === 71 && this.shouldShowDistanceToast) {
      this.shouldShowDistanceToast = false;
      this.showDistanceToast();
    }

    if (value !== 71) {
      this.shouldShowDistanceToast = true;
    }

    this.notificationsValues$
      .pipe(
        take(1),
        map((values: AlertsSettingsDTO) =>
          this.notificationsValuesSubject.next({
            ...values,
            maxDistanceMeters: value
          })
        )
      )
      .subscribe();
  }

  onTimeChange(event: CustomEvent): void {
    this.notificationsValues$
      .pipe(
        take(1),
        map((values: AlertsSettingsDTO) =>
          this.notificationsValuesSubject.next({
            ...values,
            time: {
              hoursMin: event.detail.value.lower,
              hoursMax: event.detail.value.upper
            }
          })
        )
      )
      .subscribe();
  }

  levelChips(level: GameLevel): boolean {
    return this.notificationsValuesSubject.value.gameLevel.some((gameLevel: GameLevel) => gameLevel === level);
  }

  isLevelChecked(value: GameLevel): void {
    const { gameLevel, ...rest } = this.notificationsValuesSubject.value;

    const updatedGameLevel: GameLevel[] = gameLevel.includes(value)
      ? gameLevel.filter((v: GameLevel) => v !== value)
      : [...gameLevel, value];

    this.notificationsValuesSubject.next({
      ...rest,
      gameLevel: updatedGameLevel
    });
  }

  selectWeekday(value: Weekday): void {
    const { specificWeekdays, ...rest } = this.notificationsValuesSubject.value;

    const updatedWeekdays: Weekday[] = specificWeekdays.includes(value)
      ? specificWeekdays.filter((v: Weekday) => v !== value)
      : [...specificWeekdays, value];

    this.notificationsValuesSubject.next({
      ...rest,
      specificWeekdays: updatedWeekdays
    });
  }

  isDayChecked(day: Weekday): boolean {
    return this.notificationsValuesSubject.value.specificWeekdays.some((v) => v === day);
  }

  private successModal(): void {
    this.modalProvider.showModal$({
      component: PresentModalComponent,
      componentProps: {
        header: 'Sukces',
        message: 'Ustawienia zostały zapisane',
        btnTxt: 'Zamknij'
      },
      cssClass: 'present-modal'
    });
  }

  private showDistanceToast() {
    this.modalProvider
      .showToast$({
        message: 'Brak limitu odległości',
        position: 'top',
        duration: 3000,
        cssClass: ['or-d-flex or-text-align-center or-text-lg or-color-white or-mt-12-px or-toast-style']
      })
      .pipe(take(1))
      .subscribe();
  }

  formatDistanceValue(maxDistanceMeters: number) {
    if (maxDistanceMeters === 71 || maxDistanceMeters === 0) {
      return `70+ km`;
    }
    return `do ${maxDistanceMeters} km`;
  }

  private openNotificationPermissions() {
    if (this.platformProvider.isAndroid)
      return NativeSettings.openAndroid({
        option: AndroidSettings.AppNotification
      });

    return NativeSettings.openIOS({
      option: IOSSettings.Notifications
    });
  }

  selectAllWeekdays(): void {
    const currentValues: AlertsSettingsDTO = this.notificationsValuesSubject.value;

    this.notificationsValuesSubject.next({
      ...currentValues,
      specificWeekdays: this.weekDays
    });
  }
}
