import { NgModule } from "@angular/core";
import { FindGamesRouting } from "./find-games.routing";
import { RouterModule } from "@angular/router";
import { FiltersStorage } from "./infrastructure/storages";

@NgModule({
  imports: [RouterModule.forChild(FindGamesRouting)],
  providers: [FiltersStorage],
})
export class FindGamesModule {}
