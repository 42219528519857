import { delay, filter, first, map, Observable, of, tap } from 'rxjs';
import { StartupState } from '../startup.state';
import { StartupStep } from '../startup-step';
import { SplashScreen } from '@capacitor/splash-screen';

export class HideSplashScreenStep extends StartupStep {
  executeStep(state: StartupState): Observable<StartupState> {
    state.authed$
      ?.pipe(
        filter(Boolean),
        first(),
        tap(() => SplashScreen.hide())
      )
      .subscribe();

    of(void 0)
      ?.pipe(
        delay(10000),
        tap(() => SplashScreen.hide()),
        map(() => state)
      )
      .subscribe();

    return of(state);
  }
}
