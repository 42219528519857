import { inject } from '@angular/core';
import { TeamsService } from '../../infrastructure';
import { Observable } from 'rxjs';

export class RemoveTeamCommandHandler {
  private readonly teamsService: TeamsService = inject(TeamsService);

  remove(teamId: string): Observable<void> {
    return this.teamsService.removeTeam(teamId);
  }
}
