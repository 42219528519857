import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { BlockedWebViewComponent } from '@ui-components';
import {
  IsAuthedService,
  MODAL_TOKEN,
  ModalProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
  PresentModalComponent
} from '@core';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { combineLatest, filter, map, Observable, switchMap, take, tap } from 'rxjs';
import { AppRoutes } from '../../../../../../src/app/app-routes.enum';
import { HostedGamesRoutes } from '../../hosted-games.routes.enum';
import { ReassingGameCommandHandler } from '../../application/handlers';

@Component({
  selector: 'lib-reassign-game',
  templateUrl: './reassign-game.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, BlockedWebViewComponent, NgIf],
  providers: [ReassingGameCommandHandler]
})
export class ReassignGameComponent implements OnInit {
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly isAuthedService: IsAuthedService = inject(IsAuthedService);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly router: Router = inject(Router);
  private readonly reassingGameCommandHandler: ReassingGameCommandHandler = inject(ReassingGameCommandHandler);

  readonly isNative: boolean = this.platformProvider.isNativeRun;

  readonly redirectPath$: Observable<string> = combineLatest([
    this.activatedRoute.params,
    this.activatedRoute.queryParamMap
  ]).pipe(
    map(
      ([params, paramMap]: [Params, ParamMap]) =>
        `${AppRoutes.HOST}/${HostedGamesRoutes.REASSIGN}/${params['gameId']}?token=${paramMap.get('token')}`
    )
  );

  ngOnInit(): void {
    if (!this.isNative) return;

    this.isAuthedService.isAuthed$
      .pipe(
        take(1),
        tap((isAuthed: boolean) => {
          if (!isAuthed) {
            this.router.navigateByUrl(AppRoutes.AUTH);
          }
        }),
        filter((isAuthed: boolean) => isAuthed),
        switchMap(() => combineLatest([this.activatedRoute.params, this.activatedRoute.queryParamMap])),
        take(1),
        switchMap(([params, paramMap]: [Params, ParamMap]) => {
          const gameId: string = params['gameId'];
          const token: string = paramMap.get('token') as string;

          return this.reassingGameCommandHandler.reassign(gameId, token);
        }),
        switchMap(() => this.router.navigateByUrl(AppRoutes.DASHBOARD))
      )
      .subscribe(() => this.showSuccessMessage());
  }

  private showSuccessMessage(): Observable<void> {
    return this.modalProvider.showModal$({
      component: PresentModalComponent,
      cssClass: 'present-modal',
      componentProps: {
        header: 'Gierka została przepisana!',
        message: '',
        btnTxt: 'Rozumiem, dzięki!'
      }
    });
  }
}
