import { Weekday } from '../enums';

export const weekdayTranslation: Record<Weekday, string> = {
  [Weekday.Monday]: 'Poniedziałek',
  [Weekday.Tuesday]: 'Wtorek',
  [Weekday.Wednesday]: 'Środa',
  [Weekday.Thursday]: 'Czwartek',
  [Weekday.Friday]: 'Piątek',
  [Weekday.Saturday]: 'Sobota',
  [Weekday.Sunday]: 'Niedziela'
};
