import { inject, Injectable } from '@angular/core';
import { filter } from 'rxjs';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsSegmentService {
  private readonly router: Router = inject(Router);

  private scriptLoaded = false;

  initialize(): void {
    const segmentToken = environment.segmentToken;

    if (this.scriptLoaded) return;

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://cdn.segment.com/analytics.js/v1/${segmentToken}/analytics.min.js`;

    script.onload = () => {
      this.scriptLoaded = true;
      this.trackPageView();
    };

    document.head.appendChild(script);
  }

  private trackEvent(event: string, properties: any): void {
    if ((window as any).analytics) {
      (window as any).analytics.track(event, properties);
    }
  }

  private pageView(name?: string): void {
    if ((window as any).analytics) {
      (window as any).analytics.page(name);
    }
  }

  private trackPageView() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
      if (event && event instanceof RouterEvent && event.url) {
        this.pageView();
      }
    });
  }
}
