import { inject, Injectable, Injector } from '@angular/core';
import { EMPTY, filter, switchMap, take, tap } from 'rxjs';
import { App } from '@capacitor/app';
import { Router } from '@angular/router';
import {
  AnalyticsSegmentService,
  CoordinatesDTO,
  DeepLinkService,
  GEOLOCATION_TOKEN,
  GeolocationProvider,
  GlobalRefreshService,
  IsAuthedService,
  MODAL_TOKEN,
  ModalProvider,
  NAVIGATION_TOKEN,
  NavigationProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
  USER_DATA_TOKEN,
  UserDataProvider
} from '@core';
import {
  NotificationsInitializer,
  PushPermissionsStatusCommandHandler,
  UpdateAlertsLocationCommandHandler
} from '@notifications';
import { AppRoutes } from './app-routes.enum';
import { setReferrerIdUtil } from '@auth';
import { LoadFeatureFlagsCommandHandler } from '@feature-flags';
import { environment } from '../environment/environment';
import { SetFilterCommandHandler } from '../../projects/find-games/src/lib/application/handlers';

@Injectable({ providedIn: 'root' })
export class StartupV1Service {
  private readonly navigationProvider: NavigationProvider = inject(NAVIGATION_TOKEN);
  private readonly loadFeatureFlagsCommandHandler: LoadFeatureFlagsCommandHandler =
    inject(LoadFeatureFlagsCommandHandler);
  private readonly notificationInitializer: NotificationsInitializer = inject(NotificationsInitializer);
  private readonly deepLinkService: DeepLinkService = inject(DeepLinkService);
  private readonly isAuthedService: IsAuthedService = inject(IsAuthedService);
  private readonly globalRefreshService: GlobalRefreshService = inject(GlobalRefreshService);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly router: Router = inject(Router);
  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);
  private readonly updateAlertsLocationCommandHandler: UpdateAlertsLocationCommandHandler = inject(
    UpdateAlertsLocationCommandHandler
  );
  private readonly pushPermissionsStatusCommandHandler: PushPermissionsStatusCommandHandler = inject(
    PushPermissionsStatusCommandHandler
  );
  private readonly injector: Injector = inject(Injector);
  private readonly analyticsSegmentService: AnalyticsSegmentService = inject(AnalyticsSegmentService);
  private readonly setFilterCommandHandler: SetFilterCommandHandler = inject(SetFilterCommandHandler);
  private readonly geolocationProvider: GeolocationProvider = inject(GEOLOCATION_TOKEN);
  private platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);

  private backButtonPressedOnce: boolean = false;

  init(): void {
    if (this.platformProvider.isNativeRun) {
      this.platformProvider.requestPermissions();
    }

    this.navigationProvider.init();
    this.deepLinkService.initializeDeepLinking();
    this.setupAppResumeListener();
    this.initBackButton();
    this.initializeSegmentAnalytics();

    this.isAuthedService.isAuthed$
      .pipe(
        filter((isAuthed: boolean) => isAuthed),
        take(1),
        switchMap(() => this.modalProvider.showLoading$()),
        tap(() => this.notificationInitializer.init()),
        tap(() => this.userDataProvider.setUserData()),
        switchMap(() => this.loadFeatureFlagsCommandHandler.loadFLags()),
        tap(() => this.setupAlerts()),
        switchMap(() => setReferrerIdUtil(this.injector)),
        switchMap(() => this.modalProvider.dismissLoading$()),
        take(1)
      )
      .subscribe();

    this.geolocationProvider
      .getCoordinates()
      .pipe(
        take(1),
        switchMap((coordinates: CoordinatesDTO | null) => {
          if (coordinates)
            return this.setFilterCommandHandler.set({
              maxDistanceMeters: 70000,
              latitude: coordinates?.latitude,
              longitude: coordinates?.longitude
            });

          return EMPTY;
        })
      )
      .subscribe();
  }

  private setupAlerts(): void {
    this.updateAlertsLocationCommandHandler.setLocation().pipe(take(1)).subscribe();

    this.pushPermissionsStatusCommandHandler.setValue().pipe(take(1)).subscribe();
  }

  private setupAppResumeListener(): void {
    App.addListener('appStateChange', (state) => {
      if (state.isActive) {
        this.globalRefreshService.emit();
      }
    });
  }

  private initBackButton(): void {
    App.addListener('backButton', () => {
      if (this.modalProvider.isModalActive()) {
        this.modalProvider.dismissAllStoredModals();
      } else if (this.router.url.includes(AppRoutes.DASHBOARD)) {
        if (this.backButtonPressedOnce) {
          App.exitApp();
        } else {
          this.backButtonPressedOnce = true;
          setTimeout(() => {
            this.backButtonPressedOnce = false;
          }, 500);
        }
      } else {
        this.navigationProvider.back();
      }
    });
  }

  private initializeSegmentAnalytics() {
    if (environment.production) this.analyticsSegmentService.initialize();
  }
}
