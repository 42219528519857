<div
  class="or-head-sm or-color-secondary-2 or-d-flex or-justify-content-center"
>
  Statystyki
</div>

<div class="or-d-flex or-justify-content-evenly or-mt-12-px">
  <div class="or-align-items-center or-d-flex or-flex-col">
    <div class="or-head-30 or-color-primary">{{ userStats.playedGames }}</div>
    <div class="or-head-sm">Rozegrane gierki</div>
  </div>
  <div class="or-align-items-center or-d-flex or-flex-col">
    <div class="or-head-30 or-color-primary">{{ userStats.hostedGames }}</div>
    <div class="or-head-sm">Zorganizowane gierki</div>
  </div>
</div>
