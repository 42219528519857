<div class="or-px-32-px or-pt-32-px or-head-lg or-text-align-center">{{ header }}</div>

<div class="or-px-32-px or-pt-20-px or-pb-16-px">
  <div
    *ngFor="let item of providedData$ | async"
    class="or-p-16-px or-mb-8-px"
    [ngClass]="
        (isSelected(item.value) | async)
          ? 'is-active or-bg-focus or-color-primary'
          : 'is-no-active or-bg-white'
      ">
    <ion-checkbox
      labelPlacement="end"
      justify="start"
      [checked]="(isSelected(item.value) | async)"
      (ionChange)="selectItem(item.value)"
      class="or-w-100-pc"
      ><span
        class="or-text-lg"
        [ngClass]="
          (isSelected(item.value) | async)
            ? ' or-color-primary'
            : 'or-color-black'
        "
        >{{ item.name }}</span
      ></ion-checkbox
    >
  </div>
</div>
<div class="or-d-flex or-justify-content-center">
  <button class="button-primary-style or-h-48-px or-w-60-pc or-head-md or-mb-32-px" (click)="closeModal()">
    {{ buttonText }}
  </button>
</div>
