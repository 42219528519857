import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { ENV_CONFIG, EnvConfig } from '@core';
import { Observable } from 'rxjs';
import { UpdateUserDTO, UserDetailsDTO, UserTrophiesDTO } from '../../application/interfaces';

export class UserService {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  getDetails(userId: string): Observable<UserDetailsDTO> {
    return this.httpClient.get<UserDetailsDTO>(`${this.env.apiUrl}/user-profile/${userId}`);
  }

  updateUser(userData: UpdateUserDTO): Observable<void> {
    return this.httpClient.patch<void>(`${this.env.apiUrl}/users/me`, userData);
  }

  getUserTrophies(userId: string): Observable<UserTrophiesDTO> {
    return this.httpClient.get<UserTrophiesDTO>(`${this.env.apiUrl}/trophies/user/${userId}`);
  }
}
