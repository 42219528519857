import { PaymentType } from '../enums';

export const paymentTypesMapper: Record<PaymentType, string> = {
  [PaymentType.CASH]: 'Gotówka',
  [PaymentType.BLIK]: 'BLIK',
  [PaymentType.TRANSFER]: 'Przelew'
};

export const reversedPaymentTypesMapper: Record<string, PaymentType> = Object.entries(paymentTypesMapper).reduce(
  (acc, [key, value]) => {
    acc[value] = PaymentType[key as keyof typeof PaymentType];
    return acc;
  },
  {} as Record<string, PaymentType>
);
