import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, map, Observable, switchMap, tap } from 'rxjs';
import { GamesListQueryHandler } from '../../application/handlers';
import { provideNearbyGamesService } from '../../infrastructure/http-services';
import {
  MODAL_TOKEN,
  ModalProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
  USER_DATA_TOKEN,
  UserDataProvider,
  UserModel,
  UserRoleEnum
} from '@core';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { ListGameCardComponent } from '../list-game-card/list-game-card.component';
import { GamesListModel } from '../../application/interfaces';
import { IonicModule } from '@ionic/angular';
import { FiltersComponent } from '../filters/filters.component';
import { Wm72hComponent } from '@ui-components';

@Component({
  selector: 'lib-games-list',
  templateUrl: './games-list.component.html',
  styleUrls: ['./games-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgForOf, AsyncPipe, ListGameCardComponent, IonicModule, NgIf, FiltersComponent, NgClass, Wm72hComponent],
  providers: [GamesListQueryHandler, provideNearbyGamesService()]
})
export class GamesListComponent implements OnInit {
  private readonly gamesListQueryHandler: GamesListQueryHandler = inject(GamesListQueryHandler);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);

  private readonly showPrivate$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  readonly isiOS: boolean = this.platformProvider.isiOS;
  readonly isAdmin$: Observable<boolean> = this.userDataProvider.userData$.pipe(
    map((userData: UserModel) => userData.role === UserRoleEnum.ADMIN)
  );

  readonly privateAccess$: Observable<boolean> = combineLatest([this.showPrivate$, this.isAdmin$]).pipe(
    map(([showPrivate, isAdmin]: [boolean, boolean]) => showPrivate && isAdmin)
  );

  readonly games$: Observable<GamesListModel[]> = this.modalProvider.showLoading$().pipe(
    switchMap(() => this.privateAccess$),
    switchMap((showPrivate: boolean) => this.gamesListQueryHandler.getGames(showPrivate)),
    tap(() => this.modalProvider.dismissLoading$())
  );

  ngOnInit(): void {
    if (this.platformProvider.isNativeRun) {
      this.platformProvider.requestPermissions();
    }
  }

  setShowPrivateGames(value: boolean) {
    this.showPrivate$.next(value);
  }
}
