import { HOSTED_GAMES_DTO, HostedGamesDTOPort } from '../../infrastructure/http-service';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomPlayerModel } from '../interfaces';

export class AddPlayerCommandHandler {
  private readonly hostedGamesDTOPort: HostedGamesDTOPort = inject(HOSTED_GAMES_DTO);

  addPlayer(gameId: string, player: CustomPlayerModel): Observable<void> {
    return this.hostedGamesDTOPort.addPlayer(gameId, player);
  }
}
