import {
  CHAT_CHANEL_DTO,
  ChatDtoPort,
} from "../../infrastructure/http-service";
import { inject } from "@angular/core";
import { Observable } from "rxjs";

export class MuteChannelCommandHandler {
  private readonly chatService: ChatDtoPort = inject(CHAT_CHANEL_DTO);

  mute(channelId: string, value: boolean): Observable<void> {
    return this.chatService.muteChannel(channelId, value);
  }
}
