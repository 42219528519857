import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  BottomNavbarComponent,
  GameCardComponent,
  GamesMenuComponent,
  RedirectTypeEnum,
  TitileNavbarComponent
} from '@ui-components';
import { IonicModule } from '@ionic/angular';
import { AppRoutes } from 'src/app/app-routes.enum';
import { HostedGamesRoutes } from '../../hosted-games.routes.enum';
import { GetHostedFutureGamesQueryHandler } from '../../application/handlers';
import { BehaviorSubject, Observable, switchMap, tap } from 'rxjs';
import { provideHostedGamesService } from '../../infrastructure/http-service';
import { Router } from '@angular/router';
import { GameDetailsModel, MODAL_TOKEN, ModalProvider, PLATFORM_TOKEN, PlatformProvider } from '@core';
import { HostedFutureGamesListComponent } from '../hosted-future-games-list/hosted-future-games-list.component';

@Component({
  selector: 'lib-hosted-future-games',
  templateUrl: './hosted-future-games.component.html',
  styleUrls: ['./hosted-future-games.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    BottomNavbarComponent,
    IonicModule,
    TitileNavbarComponent,
    GamesMenuComponent,
    GameCardComponent,
    HostedFutureGamesListComponent
  ],
  providers: [GetHostedFutureGamesQueryHandler, provideHostedGamesService()]
})
export class HostedFutureGamesComponent {
  public redirectType: RedirectTypeEnum = RedirectTypeEnum.HOSTED;

  private readonly getHostedFutureGamesQueryHandler: GetHostedFutureGamesQueryHandler = inject(
    GetHostedFutureGamesQueryHandler
  );
  private readonly router: Router = inject(Router);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  private showPlaceholderSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly isPageLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  readonly isiOS: boolean = this.platformProvider.isiOS;

  readonly games$: Observable<GameDetailsModel[]> = this.modalProvider.showLoading$().pipe(
    switchMap(() => this.getHostedFutureGamesQueryHandler.getGames()),
    tap((games: GameDetailsModel[]) => this.showPlaceholderSubject.next(!games.length)),
    tap(() => this.isPageLoaded$.next(true)),
    tap(() => this.modalProvider.dismissLoading$())
  );

  readonly showPlaceholder$: Observable<boolean> = this.showPlaceholderSubject.asObservable();

  goToFutureGames(): void {
    this.router.navigate([`${AppRoutes.HOST}/${HostedGamesRoutes.FUTURE}`]);
  }

  goToPastGames(): void {
    this.router.navigate([`${AppRoutes.HOST}/${HostedGamesRoutes.PAST}`]);
  }

  goToGameCreator(): void {
    this.router.navigateByUrl(`${AppRoutes.HOST}/${HostedGamesRoutes.CREATE_GAME}`);
  }
}
