import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TitileNavbarComponent } from '@ui-components';
import { VersionInfoComponent } from '../../components';

@Component({
  templateUrl: './developer-settings.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TitileNavbarComponent, VersionInfoComponent]
})
export class DeveloperSettingsPage {}
