import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MessagesRoutes } from "./messages-routes.enum";
import { ChatComponent, ChatsListComponent } from "./components";
import { isChannelLoadedGuard } from "./application/guards";
import { isAuthedGuard, referrerResolver } from "@auth";

const routes: Routes = [
  {
    path: MessagesRoutes.ROOT,
    resolve: { referrerResolver },
    children: [
      {
        path: MessagesRoutes.ROOT,
        component: ChatsListComponent,
        canActivate: [isAuthedGuard],
      },
      {
        path: MessagesRoutes.CHAT,
        component: ChatComponent,
        canActivate: [isChannelLoadedGuard, isAuthedGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MessagesRoutingModule {}
