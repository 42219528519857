<ng-container *ngIf="trophies$ | async as trophies">
  <div *ngIf="trophies?.length" class="or-bg-white border-radius-4 or-p-12-px card-box-shadow">
    <div class="or-d-flex or-justify-content-between">
      <div class="or-head-sm or-color-secondary-2">Osiągnięcia</div>
      <div (click)="showAllTrophies()" class="or-head-sm or-color-primary">Sprawdź wszystkie</div>
    </div>
    <div class="or-mt-12-px">
      <div
        *ngFor="let trophy of trophies; let last = last; let first = first"
        class="or-d-flex or-align-items-center or-mb-12-px"
        [class.or-mb-12-px]="!last || (first && !last)">
        <img [src]="trophy.icon" class="or-d-flex or-align-self-start or-mr-8-px" />
        <div>
          <div class="or-head-sm or-mb-4-px">{{ trophy.name }}</div>
          <div class="or-text-sm or-color-secondary-6">{{ trophy.description }}</div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
