import { NotificationType } from '../enums';

export const NotificationStatusMapper: Record<NotificationType, string> = {
  [NotificationType.GameApplicationApproved]: 'Twoja prośba o gierkę została zaakceptowana!',
  [NotificationType.GameApplicationRejected]: 'Twoja prośba została odrzucona.',
  [NotificationType.GameReminder]: 'Twoja gierka zaczyna się za chwilę!',
  [NotificationType.HostedGameReminder]: 'Gierka którą organizujesz zaczyna się za chwilę!',
  [NotificationType.GameCanceled]: 'Twoja gierka została odwołana',
  [NotificationType.HostedGameNewApplication]: 'Masz nową prośbę o gierkę!',
  [NotificationType.HostedGamePlayerLeft]: 'Gracz opuścił Twoją gierkę.',
  [NotificationType.NewGameByAlert]: 'Nowa gierka w Twojej okolicy!',
  [NotificationType.GameFinished]: 'Gierka zakończona! Zobacz szczegóły płatności.',
  [NotificationType.HostedGameFinished]: 'Twoja gierka została zakończona!',
  [NotificationType.GamePlayerRemovedByHost]: 'Zostałeś usunięty z gierki.',
  [NotificationType.GameInvitationRecived]: 'Dostałeś zaproszenie do gierki!',
  [NotificationType.ChatNewMessage]: 'Dostałeś nową wiadomość!',
  [NotificationType.CustomNotification]: 'Zobacz newsa!',
  [NotificationType.HostedGamePlayerAutoApproved]: 'Gracz został automatycznie zaakceptowany!',
  [NotificationType.TeamMemberAddedByHost]: 'Witaj w grupce!',
  [NotificationType.UserPenaltyReceived]: 'Otrzymałeś kartkę!',
  [NotificationType.GameLineupsCreated]: 'Składy zostały wylosowane! Sprawdź z kim grasz!',
  [NotificationType.NewTrophyReceived]: 'Zdobyłeś nowe osiągnięcie!',
  [NotificationType.NewCommentReceived]: 'Dostałeś nowy komplement!'
};
