<lib-title-navbar title="Twoje gierki"></lib-title-navbar>
<div class="top-container"></div>

<div class="margin-top--24-px">
  <lib-games-menu
    (goToFutureEvent)="goToFutureGames()"
    (goToPastEvent)="goToPastGames()"
    [isActiveFuture]="true"></lib-games-menu>
</div>

<div class="or-d-flex or-justify-content-around or-mt-24-px or-px-16-px or-text-lg or-color-secondary">
  <div
    (click)="changeDataSource('approved')"
    class="or-pb-8-px"
    [ngClass]="{'or-color-black bottom-border-active': isApprovedTabActive}">
    Potwierdzone
  </div>
  <div
    (click)="changeDataSource('waiting')"
    class="or-pb-8-px"
    [ngClass]="{'or-color-black bottom-border-active': !isApprovedTabActive}">
    Oczekujące
  </div>
</div>
<div class="custom-divider or-w-100-pc"></div>

<div [fullHeightScrollableContainer]="246">
  <div *ngFor="let game of games$ | async" class="or-mb-12-px or-mt-16-px">
    <lib-game-card-v2
      [game]="game"
      [redirectType]="gameRedirectType"
      [showShareButton]="true"
      [showHostDetails]="true"></lib-game-card-v2>
  </div>

  <div
    *ngIf="showPlaceholder$ | async"
    class="or-align-items-center or-d-flex or-justify-content-center or-head-sm or-h-100-pc">
    Nie grasz w żadnych gierkach.
  </div>
</div>

<lib-bottom-navbar></lib-bottom-navbar>

<lib-close-ad-banner [containerDirective]="containerDirective"></lib-close-ad-banner>
