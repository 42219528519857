import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AvatarSkeletonComponent } from './avatar-skeleton/avatar-skeleton.component';
import { ListSkeletonComponent } from './list-skeleton/list-skeleton.component';
import { RowSkeletonComponent } from './row-skeleton/row-skeleton.component';
import { NgSpawnDirective } from './ng-spawn.directive';

const skeletons: Type<unknown>[] = [RowSkeletonComponent, ListSkeletonComponent, AvatarSkeletonComponent];

@NgModule({
  imports: [CommonModule, IonicModule, NgSpawnDirective],
  declarations: [...skeletons],
  exports: [...skeletons]
})
export class SkeletonsModule {}
