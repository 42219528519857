import {
  HOSTED_GAMES_DTO,
  HostedGamesDTOPort,
} from "../../infrastructure/http-service";
import { inject } from "@angular/core";
import { Observable } from "rxjs";

export class MarkAsPaidCommandHandler {
  private readonly hostedGamesDTOPort: HostedGamesDTOPort =
    inject(HOSTED_GAMES_DTO);

  markAsPaid(
    gameId: string,
    playerId: string,
    isPaid: boolean
  ): Observable<void> {
    if (!isPaid) return this.hostedGamesDTOPort.markAsPaid(gameId, playerId);

    return this.hostedGamesDTOPort.markAsNotPaid(gameId, playerId);
  }
}
