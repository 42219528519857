import { FeatureFlagsDtoPort } from './feature-flags.dto-port';
import { map, Observable } from 'rxjs';
import { FeatureFlagDto, FeatureFlagsRecord } from '../../application/models';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { inject } from '@angular/core';

export class FeatureFlagsService implements FeatureFlagsDtoPort {
  private readonly firestore: AngularFirestore = inject(AngularFirestore);

  getFlags(): Observable<FeatureFlagsRecord> {
    return this.firestore
      .collection<FeatureFlagDto>('feature-flags')
      .snapshotChanges()
      .pipe(
        map((response) =>
          response.reduce((acc, response) => {
            const data: FeatureFlagDto = response.payload.doc.data() as FeatureFlagDto;
            const id: string = response.payload.doc.id;
            acc[id] = data;
            return acc;
          }, {} as { [id: string]: FeatureFlagDto })
        )
      );
  }
}
