import { inject, Injectable } from '@angular/core';
import { AdMob, AdOptions, BannerAdOptions, BannerAdPluginEvents } from '@capacitor-community/admob';
import { ENV_CONFIG, EnvConfig } from '../env';
import { Platform } from '@ionic/angular';
import { AdsUnitId, PlatformType } from '../enums';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdMobService {
  private readonly env: EnvConfig = inject(ENV_CONFIG);
  private readonly platform: Platform = inject(Platform);

  private readonly bannerEventsSubject: ReplaySubject<BannerAdPluginEvents | null> =
    new ReplaySubject<BannerAdPluginEvents | null>(1);

  readonly bannerEvents$: Observable<BannerAdPluginEvents | null> = this.bannerEventsSubject.asObservable();

  get isNative(): boolean {
    return this.platform.is(PlatformType.HYBRID);
  }

  initialize(): void {
    if (!this.isNative) return;

    AdMob.requestTrackingAuthorization();
    AdMob.initialize({
      initializeForTesting: !this.env.production
    });

    this.registerBannerEvents();
  }

  showBanner(opts: BannerAdOptions): void {
    if (!this.isNative) return;

    const options: BannerAdOptions = {
      ...opts,
      adId: this.env.production ? opts?.adId : AdsUnitId.TestBanner
    };

    AdMob.showBanner(options);
  }

  showInterstital(opts: BannerAdOptions): void {
    if (!this.isNative) return;

    const options: AdOptions = {
      ...opts,
      adId: !this.env.production ? AdsUnitId.TestInterstital : opts.adId
    };

    AdMob.prepareInterstitial(options).then(() => AdMob.showInterstitial());
  }

  showRewardVideo(opts: BannerAdOptions): void {
    if (!this.isNative) return;

    const options: AdOptions = {
      ...opts,
      adId: !this.env.production ? AdsUnitId.TestRewardVideo : opts.adId
    };

    AdMob.prepareRewardVideoAd(options).then(() => AdMob.showRewardVideoAd());
  }

  showRewardInterstitial(opts: AdOptions): void {
    if (!this.isNative) return;

    const options: AdOptions = {
      ...opts,
      adId: !this.env.production ? AdsUnitId.TestRewardInterstitial : opts.adId
    };

    AdMob.prepareRewardInterstitialAd(options).then(() => AdMob.showRewardInterstitialAd());
  }

  resumeBanner(): void {
    if (!this.isNative) return;

    AdMob.resumeBanner();
  }

  removeBanner(): void {
    if (!this.isNative) return;

    AdMob.removeBanner();
  }

  hideBanner(): void {
    if (!this.isNative) return;

    AdMob.hideBanner();
  }

  registerBannerEvents(): void {
    const bannerEvents: BannerAdPluginEvents[] = Object.values(BannerAdPluginEvents);

    bannerEvents.forEach((event) => {
      AdMob.addListener(event as any, () => {
        this.bannerEventsSubject.next(event);
        this.bannerEventsSubject.next(null);
      });
    });
  }
}
