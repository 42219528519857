<div
  (click)="openDetailsModal()"
  class="border-radius-8 or-bg-white or-cursor-pointer or-mx-16-px or-pb-12-px or-pt-4-px or-px-12-px z-index-1 card-box-shadow"
  *ngIf="game">
  <div class="or-mt-8-px or-d-flex or-justify-content-between">
    <div class="or-head-lg">{{ game.name }}</div>
    <div
      *ngIf="game.currentUserParticipationStatus === 'Approved'"
      class="border-radius-4 or-align-content-center or-bg-shade or-p-4-px or-text-sm text-no-wrap">
      Grasz w tej gierce
    </div>
  </div>

  <div class="or-d-flex or-align-items-center or-mt-8-px">
    <img src="assets/icons/clock-icon.svg" class="or-mr-8-px" />

    <div class="or-text-md">{{ game.gameDateStart | dateFormat : game.gameDateEnd }}</div>
  </div>

  <div class="or-d-flex or-align-items-center or-mt-4-px">
    <img src="assets/icons/map-pin-icon.svg" class="or-mr-8-px" />

    <div class="or-text-md">{{ game.location.address }}, {{ game.location.city }}</div>
  </div>

  <div class="or-d-flex or-mt-4-px or-justify-content-between">
    <div class="or-d-flex">
      <div class="or-d-flex or-align-items-center or-mr-20-px">
        <img src="assets/icons/price-icon.svg" class="or-mr-8-px" />

        <div *ngIf="+game.priceAmount" class="or-text-md">{{ +game.priceAmount }} zł</div>
        <div *ngIf="!+game.priceAmount" class="or-text-md">Darmowa</div>
      </div>

      <div class="or-d-flex or-align-items-center">
        <img src="assets/icons/member-icon.svg" class="or-mr-8-px" />

        <div class="or-text-md">
          {{ game.occupiedSlots }}/{{ game.totalSlots }}
          <span *ngIf="(isAdmin$ | async) && game.playerApplications?.length"
            >({{ game.playerApplications.length }})</span
          >
        </div>
      </div>
    </div>
    <div>
      <div
        *ngIf="game.visibility === 'PRIVATE'"
        class="border-radius-4 or-align-content-center or-bg-shade or-p-4-px or-text-sm text-no-wrap negative-margin-top-24-px">
        Ukryta na mapie
      </div>
    </div>
  </div>

  <div class="custom-divider or-mt-8-px or-w-100-pc"></div>

  <div class="or-d-flex or-justify-content-between or-align-items-center or-mt-12-px">
    <div class="or-ml-2-px or-d-flex or-align-items-center">
      <lib-or-avatar
        [avatarUrl]="game.host.thumbnail"
        [avatarSize]="'xs'"
        [initials]="game.host.displayName"></lib-or-avatar>
      <div class="or-head-sm">{{ game.host.displayName }}</div>
      <ng-container *ngIf="hostRating()">
        <img class="or-ml-8-px or-mr-4-px or-size-16-px or-mb-4-px" src="assets/icons/star-fill.svg" />
        <div class="or-head-xs">{{ hostRating() }}</div>
      </ng-container>
    </div>
    <div
      class="or-d-flex or-align-items-center or-px-8-px or-py-4-px border-radius-8"
      [style.background-color]="locationTypeMapper[game.locationType].bgColor">
      <img [src]="locationTypeMapper[game.locationType].icon" />
      <div class="or-ml-4-px or-text-md" [style.color]="locationTypeMapper[game.locationType].textColor">
        {{locationTypeMapper[game.locationType].name}}
      </div>
    </div>
  </div>
</div>
