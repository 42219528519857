import { DateFormatPipe } from '@ui-components';
import { gameLevelMapper } from '@core';
import { CreateGameResponseDTO, CreateGameResponseViewModel } from '../../interfaces';

export const mapToCreateGameViewModelUtil = (
  game: CreateGameResponseDTO,
  dateFormatPipe: DateFormatPipe
): CreateGameResponseViewModel => {
  return {
    gameName: game.name,
    gameId: game.gameId,
    formattedDate: dateFormatPipe.transform(game.gameDateStart, game.gameDateEnd),
    location: game.location,
    level: gameLevelMapper[game.level],
    priceAmount: +game.priceAmount
  };
};
