<lib-title-navbar title="Napisz do nas"></lib-title-navbar>

<div
  [ngClass]="
    isiOS
      ? 'full-height-scrollable-container-ios'
      : 'full-height-scrollable-container'
  ">
  <div class="or-mx-16-px or-d-flex or-flex-col or-align-items-center">
    <img src="assets/contact.svg" class="or-size-20" />

    <div class="or-text-lg or-text-align-center">
      Jeśli masz jakiś problem z aplikacją lub chcesz się podzielić wrażeniami - daj nam znać. Twoja opinia jest dla nas
      bardzo wazna!
    </div>

    <div class="or-mb-12-px or-w-100-pc or-mt-48-px">
      <span class="or-head-sm or-color-secondary-2 or-mb-4-px">Treść wiadomości</span>
      <form [formGroup]="contactForm">
        <ion-textarea
          formControlName="message"
          class="textarea-style outline-none"
          maxlength="500"
          rows="15"
          auto-grow="true"></ion-textarea>
      </form>
    </div>
  </div>
</div>

<div class="or-d-flex">
  <button
    [disabled]="!contactForm.valid"
    (click)="sendMessage()"
    class="button-primary-style or-h-52-px or-head-md or-mb-20-px or-mx-16-px or-w-100-pc"
    [ngClass]="{
      'disabled-button': !contactForm.valid
    }">
    Napisz wiadomość
  </button>
</div>
