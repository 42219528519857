import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { AsyncPipe, NgForOf } from "@angular/common";
import { TrophyTypeEnum } from "../../application/enums";
import { userTrophiesMapper } from "../../application/mappers";
import { BottomNavbarComponent, TitileNavbarComponent } from "@ui-components";
import { PLATFORM_TOKEN, PlatformProvider } from "@core";

@Component({
  selector: "lib-all-trophies",
  templateUrl: "./all-trophies.component.html",
  styleUrls: ["./all-trophies.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, NgForOf, TitileNavbarComponent, BottomNavbarComponent],
})
export class AllTrophiesComponent {
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);

  readonly allTrophies: TrophyTypeEnum[] = Object.values(TrophyTypeEnum);
  readonly trophiesMapper = userTrophiesMapper;

  readonly isiOS: boolean = this.platformProvider.isiOS;
}
