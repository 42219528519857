import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { GamePlayersDTO, MODAL_TOKEN, ModalProvider } from '@core';
import { OrAvatarComponent } from '@ui-components';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { cardReasonMapper } from '../../application/utils';
import { AddCardPayloadDTO, CardReasonModel } from '../../application/interfaces';
import { BehaviorSubject, take } from 'rxjs';
import { CardReason } from '../../application/enums';
import { AddCardCommandHandler } from '../../application/handlers';
import { PenaltiesService } from '../../infrastructure';
import { AddCardSuccessModalComponent } from './add-card-success-modal/add-card-success-modal.component';

@Component({
  selector: 'lib-add-card-modal',
  templateUrl: './add-card-modal.component.html',
  styleUrls: ['./add-card-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [OrAvatarComponent, NgIf, IonicModule, NgForOf, AsyncPipe, NgClass],
  providers: [AddCardCommandHandler, PenaltiesService]
})
export class AddCardModalComponent {
  @Input() gameId!: string;
  @Input() player!: GamePlayersDTO;

  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly addCardCommandHandler: AddCardCommandHandler = inject(AddCardCommandHandler);

  public reasons: CardReasonModel[] = Object.values(cardReasonMapper);

  readonly selectedReason$: BehaviorSubject<CardReason> = new BehaviorSubject<CardReason>(
    null as unknown as CardReason
  );

  closeModal() {
    this.modalProvider.dismissModal$();
  }

  addCard(): void {
    const reason: CardReason = this.selectedReason$.value;
    const payload: AddCardPayloadDTO = {
      gameId: this.gameId,
      userId: this.player.userId,
      reason: this.selectedReason$.value,
      penaltyType: cardReasonMapper[reason].cardType
    };

    this.addCardCommandHandler
      .add(payload)
      .pipe(take(1))
      .subscribe(() => {
        this.modalProvider.dismissModal$();
        this.confirmationModal(payload.reason);
      });
  }

  select(event: CustomEvent): void {
    this.selectedReason$.next(event.detail.value);
  }

  selectBox(value: CardReason): void {
    this.selectedReason$.next(value);
  }

  isSelected(value: CardReason): boolean {
    return this.selectedReason$.value === value;
  }

  private confirmationModal(reason: CardReason): void {
    this.modalProvider
      .showModal$({
        component: AddCardSuccessModalComponent,
        cssClass: 'present-modal',
        componentProps: {
          reason: reason,
          player: this.player
        }
      })
      .subscribe(() => this.modalProvider.dismissAllStoredModals());
  }
}
