import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { IsAuthedService, LocalStorageKey } from '@core';
import { inject } from '@angular/core';
import { map, take } from 'rxjs';
import { TeamInvitationPayload } from '../models';

export const teamInvitationAcceptResolver: ResolveFn<void> = (activatedRoute: ActivatedRouteSnapshot) => {
  const isAuthed: IsAuthedService = inject(IsAuthedService);

  const teamId: string = activatedRoute.params['teamId'];
  const token: string = activatedRoute.params['token']?.includes('?')
    ? activatedRoute.params['token'].split('?')[0]
    : activatedRoute.params['token'];

  isAuthed.isAuthed$
    .pipe(
      take(1),
      map((isAuthed: boolean) => {
        if (isAuthed) return sessionStorage.removeItem(LocalStorageKey.TeamInvitationAccept);

        const data: TeamInvitationPayload = {
          teamId: teamId,
          token: token
        };

        return sessionStorage.setItem(LocalStorageKey.TeamInvitationAccept, JSON.stringify(data));
      })
    )
    .subscribe();
};
