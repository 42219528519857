import { UserService } from '../../infrastructure';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { UpdateUserDTO } from '../interfaces';

export class UpdateUserCommandHandler {
  private readonly userService: UserService = inject(UserService);

  update(userData: UpdateUserDTO): Observable<void> {
    return this.userService.updateUser({
      ...userData,
      phone: userData.phone ? `+48${userData.phone}` : null
    });
  }
}
