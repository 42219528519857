import { filter, first, Observable, of, tap } from 'rxjs';
import { StartupState } from '../startup.state';
import { StartupStep } from '../startup-step';
import { SplashScreen } from '@capacitor/splash-screen';

export class ShowSplashScreenStep extends StartupStep {
  executeStep(state: StartupState): Observable<StartupState> {
    SplashScreen.show({
      autoHide: true
    });

    return of(state);
  }
}
