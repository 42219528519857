import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { SendApplicationResponseDTO } from '../../../application/interfaces';

export const GAME_APPLICATIONS_DTO: InjectionToken<GameApplicationsDTOPort> =
  new InjectionToken<GameApplicationsDTOPort>('GAME_APPLICATIONS_DTO');

export interface GameApplicationsDTOPort {
  sendApplication(gameId: string): Observable<SendApplicationResponseDTO>;
  removeFromGame(gameId: string): Observable<void>;
  removeApplication(gameId: string): Observable<void>;
}
