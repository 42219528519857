import { Routes } from '@angular/router';
import { DashboardRoutes } from './dashboard.routes.enum';
import { DashboardComponent } from './components';
import { loadFuturePlayerGamesResolver } from '@games';

export const DashboardRouting: Routes = [
  {
    path: DashboardRoutes.ROOT,
    component: DashboardComponent,
    resolve: { loadFuturePlayerGamesResolver }
  }
];
