export enum TrophyTypeEnum {
  HOSTED_GAMES_5 = "HOSTED_GAMES_5",
  HOSTED_GAMES_25 = "HOSTED_GAMES_25",
  HOSTED_GAMES_50 = "HOSTED_GAMES_50",
  HOSTED_GAMES_100 = "HOSTED_GAMES_100",

  PLAYER_GAMES_5 = "PLAYER_GAMES_5",
  PLAYER_GAMES_25 = "PLAYER_GAMES_25",
  PLAYER_GAMES_50 = "PLAYER_GAMES_50",
  PLAYER_GAMES_100 = "PLAYER_GAMES_100",

  HOST_RATE_5_50 = "HOST_RATE_5_50",
  HOST_RATE_5_100 = "HOST_RATE_5_100",

  PLAYER_COMMENT_FAIR_PLAY_10 = "PLAYER_COMMENT_FAIR_PLAY_10",
  PLAYER_COMMENT_GOOD_PLAYER_10 = "PLAYER_COMMENT_GOOD_PLAYER_10",
  PLAYER_COMMENT_TEAM_PLAY_10 = "PLAYER_COMMENT_TEAM_PLAY_10",
  PLAYER_COMMENT_HELPFUL_10 = "PLAYER_COMMENT_HELPFUL_10",
  PLAYER_COMMENT_SPRINTER_10 = "PLAYER_COMMENT_SPRINTER_10",

  NO_PENALTY_2025 = "NO_PENALTY_2025",
}
