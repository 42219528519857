import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { GetUserCommentQueryHandler } from '../../application/handlers';
import { Observable } from 'rxjs';
import { GroupedUserCommentModel } from '../../application/interfaces';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { UserCommentsService } from '../../infrastructure';
import { commentMapper } from '../../application/utils';

@Component({
  selector: 'lib-user-comments',
  templateUrl: './user-comments.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, NgForOf, NgIf, NgClass],
  providers: [GetUserCommentQueryHandler, UserCommentsService]
})
export class UserCommentsComponent {
  private readonly getUserCommentQueryHandler: GetUserCommentQueryHandler = inject(GetUserCommentQueryHandler);

  public commentMapper = commentMapper;
  public isExpanded: boolean = false;

  readonly comments$: Observable<GroupedUserCommentModel> = this.getUserCommentQueryHandler.getComment();

  changeVisibility(): void {
    this.isExpanded = !this.isExpanded;
  }
}
