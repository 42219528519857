import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { SessionService } from '../services/session.service';
import { inject } from '@angular/core';

export class TraceSessionInterceptor implements HttpInterceptor {
  private sessionService: SessionService = inject(SessionService);

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const traceSessionId = req.clone({
      headers: req.headers.set('x-trace-session-id', this.sessionService.getUuid())
    });

    return next.handle(traceSessionId);
  }
}
