<ng-container *ngIf="(allowToView$ | async); else notNativeView">
  <lib-title-navbar title="Udostępniona gierka"></lib-title-navbar>

  <div [fullHeightScrollableContainer]="71">
    <ng-container *ngIf="gameDetails$ | async as game">
      <div class="or-p-16-px or-mb-8-px">
        <lib-game-details
          [game]="game"
          [showHostInfo]="true"
          [showNavigateLink]="true"
          (onSendMessage)="sendMessage($event)"></lib-game-details>

        <lib-join-buttons [game]="game" (applicationSent)="applicationSent($event)"></lib-join-buttons>
      </div>
    </ng-container>

    <div #map class="map-container-shared"></div>
  </div>
</ng-container>

<ng-template #notNativeView>
  <ng-container *ngIf="redirectPath$ | async as path">
    <lib-blocked-web-view [path]="path"></lib-blocked-web-view>
  </ng-container>
</ng-template>
