import { TeamsService } from "../../infrastructure";
import { inject } from "@angular/core";

export class GetTeamsQueryHandler {
  private readonly teamsService: TeamsService = inject(TeamsService);

  getTeams() {
    return this.teamsService.getTeams();
  }
}
