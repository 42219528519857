import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { NgClass, NgForOf, NgIf } from "@angular/common";
import { format } from "date-fns";
import { cardReasonMapper, PenaltyDTO } from "@fair-play";

@Component({
  selector: "lib-card-history",
  templateUrl: "./card-history.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgForOf, NgClass],
})
export class CardHistoryComponent {
  @Input({ required: true }) penaltyHistory!: PenaltyDTO[];
  @Input({ required: true }) activePenalty!: PenaltyDTO;

  public cardReasonMapper = cardReasonMapper;

  get penalty(): PenaltyDTO[] {
    if (this.activePenalty) return [this.activePenalty, ...this.penaltyHistory];

    return this.penaltyHistory;
  }

  formatDate(date: Date): string {
    return format(date, "dd.MM.yyyy");
  }
}
