import { NgModule } from '@angular/core';
import { GamesRouting } from './games.routing';
import { RouterModule } from '@angular/router';
import { provideGamesService } from './infrastructure/http-service';
import { GameDetailsQueryHandler, LoadFutureGamesCommandHandler } from './application/handlers';
import { FutureGamesStorage } from './infrastructure/storage';

@NgModule({
  imports: [RouterModule.forChild(GamesRouting)],
  providers: [provideGamesService(), GameDetailsQueryHandler, FutureGamesStorage, LoadFutureGamesCommandHandler]
})
export class GamesModule {}
