import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { TitileNavbarComponent } from "@ui-components";
import { IonicModule } from "@ionic/angular";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { NgClass } from "@angular/common";
import {
  MODAL_TOKEN,
  ModalProvider,
  NAVIGATION_TOKEN,
  NavigationProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
  PresentModalComponent,
} from "@core";
import { SendFeedbackCommandHandler } from "../../application/handlers";
import { provideContactService } from "../../infrastructure";
import { Observable, switchMap } from "rxjs";

@Component({
  selector: "lib-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TitileNavbarComponent, IonicModule, ReactiveFormsModule, NgClass],
  providers: [SendFeedbackCommandHandler, provideContactService()],
})
export class ContactComponent {
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly sendMessageCommandHandler: SendFeedbackCommandHandler =
    inject(SendFeedbackCommandHandler);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly navigationProvider: NavigationProvider =
    inject(NAVIGATION_TOKEN);

  readonly isiOS: boolean = this.platformProvider.isiOS;

  readonly contactForm: FormGroup = new FormGroup({
    message: new FormControl("", [
      Validators.required,
      Validators.maxLength(500),
    ]),
  });

  sendMessage(): void {
    this.sendMessageCommandHandler
      .send(this.contactForm?.value?.message)
      .pipe(switchMap(() => this.successMessage()))
      .subscribe(() => this.navigationProvider.back());
  }

  private successMessage(): Observable<void> {
    return this.modalProvider.showModal$({
      component: PresentModalComponent,
      componentProps: {
        header: "Wiadomość wysłana",
        message: "Dziękujemy za kontakt! Odpowiemy tak szybko jak to możliwe.",
        btnTxt: "Zamknij",
      },
      cssClass: "present-modal",
    });
  }
}
