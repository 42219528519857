import { map, Observable } from 'rxjs';
import { inject } from '@angular/core';
import { DateFormatPipe } from '@ui-components';
import { mapToGameQueryUtil } from './utils/map-to-game-query.util';
import { GameDetailsModel } from '@core';
import { FutureGameDTO, PlayerGameDTO } from '../interfaces';
import { FutureGamesStorage } from '../../infrastructure/storage';

export class GetFutureGamesQueryHandler {
  private readonly futureGamesStorage: FutureGamesStorage = inject(FutureGamesStorage);
  private readonly dateFormatPipe: DateFormatPipe = inject(DateFormatPipe);

  getApproved(): Observable<GameDetailsModel[]> {
    return this.futureGamesStorage.games$.pipe(
      map((games: FutureGameDTO) =>
        games.approved.map((game: PlayerGameDTO) => mapToGameQueryUtil(game, this.dateFormatPipe))
      )
    );
  }

  getWaiting(): Observable<GameDetailsModel[]> {
    return this.futureGamesStorage.games$.pipe(
      map((games: FutureGameDTO) =>
        games.waiting.map((game: PlayerGameDTO) => mapToGameQueryUtil(game, this.dateFormatPipe))
      )
    );
  }

  getInvitations(): Observable<GameDetailsModel[]> {
    return this.futureGamesStorage.games$.pipe(
      map((games: FutureGameDTO) =>
        games.invitations.map((game: PlayerGameDTO) => mapToGameQueryUtil(game, this.dateFormatPipe))
      )
    );
  }
}
