import {
  DeepLinkService,
  DynamicLinkType,
  SocialMetaTagInfo,
  socialMetaTagInfoMapper,
  USER_DATA_TOKEN,
  UserDataProvider,
  UserModel,
} from "@core";
import { inject } from "@angular/core";
import { environment } from "src/environment/environment";
import { AppRoutes } from "src/app/app-routes.enum";
import { TeamsRoutes } from "../../teams-routes.enum";
import { Observable, switchMap } from "rxjs";

export class GenerateInviteUrlCommandHandler {
  private readonly deepLinkService: DeepLinkService = inject(DeepLinkService);
  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);

  generate(teamId: string, token: string): Observable<string> {
    return this.userDataProvider.userData$.pipe(
      switchMap((userData: UserModel) => {
        const socialMetaTagInfo: SocialMetaTagInfo =
          socialMetaTagInfoMapper[DynamicLinkType.TEAM_INVITATION];

        const url: string = `${environment.frontendUrl}/${AppRoutes.TEAMS}/${TeamsRoutes.ACCEPT_INVITATION}/${teamId}/${token}?referrer=${userData.userId}`;

        return this.deepLinkService.generateLink(url, socialMetaTagInfo);
      })
    );
  }
}
