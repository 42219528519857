import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from "@angular/core";
import {
  BottomNavbarComponent,
  OrAvatarComponent,
  TitileNavbarComponent,
} from "@ui-components";
import { Router } from "@angular/router";
import { NgClass, NgForOf, NgIf, SlicePipe } from "@angular/common";
import { AppRoutes } from "src/app/app-routes.enum";
import { TeamsRoutes } from "../../teams-routes.enum";
import { TeamDTO } from "../../application/interfaces";
import {
  ChannelDetailsByReferenceQueryHandler,
  ChannelDetailsDTO,
  ChannelDetailsPayloadDTO,
  ChannelDetailsQueryParam,
  OpenChatCommandHandler,
} from "@messages";
import { ShareTeamModalComponent } from "../share-team-modal/share-team-modal.component";
import { TeamInviteData } from "../../application/models";
import { MODAL_TOKEN, ModalProvider } from "@core";
import { ChannelTypeEnum } from "@messages";
import { switchMap, take } from "rxjs";

@Component({
  selector: "lib-team-card",
  templateUrl: "./team-card.component.html",
  styleUrls: ["./team-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TitileNavbarComponent,
    BottomNavbarComponent,
    NgClass,
    NgForOf,
    OrAvatarComponent,
    SlicePipe,
    NgIf,
  ],
  providers: [OpenChatCommandHandler, ChannelDetailsByReferenceQueryHandler],
})
export class TeamCardComponent {
  @Input({ required: true }) teamDetails!: TeamDTO;
  @Input({ required: true }) showInfoLabel: boolean = false;
  @Input({ required: true }) showShareIcon: boolean = false;

  private readonly router: Router = inject(Router);
  private readonly openChatCommandHandler: OpenChatCommandHandler = inject(
    OpenChatCommandHandler
  );
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly channelDetailsByReferenceQueryHandler: ChannelDetailsByReferenceQueryHandler =
    inject(ChannelDetailsByReferenceQueryHandler);

  public hostInfoText: string =
    "Osoby z Twojej grupki będą automatycznie zapraszane do Twojej gierki.";
  public memberInfoText: string =
    "Dzięki grupce jesteś automatycznie zapraszany do gierki, którą stworzy organizator.";

  goToDetails(): void {
    this.router.navigateByUrl(
      `${AppRoutes.TEAMS}/${this.teamDetails.teamId}/${TeamsRoutes.TEAM_DETAILS}`
    );
  }

  goToChat(event: Event): void {
    event.stopImmediatePropagation();

    const payload: ChannelDetailsPayloadDTO = {
      param: ChannelDetailsQueryParam.TEAM,
      teamId: this.teamDetails.teamId,
    };

    this.channelDetailsByReferenceQueryHandler
      .details(payload)
      .pipe(
        take(1),
        switchMap((details: ChannelDetailsDTO) =>
          this.openChatCommandHandler.open(
            details.channelExternalId,
            ChannelTypeEnum.TEAM
          )
        )
      )
      .subscribe();
  }

  goToAutoApprove(event: Event): void {
    event.stopImmediatePropagation();

    this.router.navigateByUrl(
      `${AppRoutes.TEAMS}/${this.teamDetails.teamId}/${TeamsRoutes.AUTO_APPROVE}`
    );
  }

  share(event: Event): void {
    event.stopImmediatePropagation();

    const payload: TeamInviteData = {
      teamId: this.teamDetails.teamId,
      token: this.teamDetails.invitationToken,
    };

    this.modalProvider.showModal$({
      component: ShareTeamModalComponent,
      componentProps: { teamData: payload },
      cssClass: "modal-auto",
      initialBreakpoint: 1,
      breakpoints: [0, 1],
    });
  }
}
