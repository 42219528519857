import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { PostGameResponse } from '../../../application/models/post-game.dto';

export const GENERATE_POST_DTO: InjectionToken<GeneratePostDtoPort> = new InjectionToken<GeneratePostDtoPort>(
  'GENERATE_POST_DTO'
);

export interface GeneratePostDtoPort {
  generateFutureGames(endDays: number): Observable<PostGameResponse>;
}
