import { NgModule } from '@angular/core';
import { RegisterCommandHandler } from './register.command-handler';
import { LoginCommandHandler } from './login.command-handler';
import { LogoutCommandHandler } from './logout.command-handler';
import { RemoveAccountCommandHandler } from './remove-account.command-handler';
import { AuthWithProviderCommandHandler } from './auth-with-provider.command-handler';

@NgModule({
  providers: [
    RegisterCommandHandler,
    LoginCommandHandler,
    LogoutCommandHandler,
    RemoveAccountCommandHandler,
    AuthWithProviderCommandHandler
  ]
})
export class AuthHandlersModule {}
