import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'lib-or-avatar',
  templateUrl: './or-avatar.component.html',
  styleUrls: ['./or-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [IonicModule, NgClass, NgIf]
})
export class OrAvatarComponent {
  @Input() avatarUrl!: string | undefined | null;
  @Input() initials: string | undefined = '';
  @Input() base64?: string;
  @Input() showWhiteBorder?: boolean = false;
  @Input() rightMargin?: boolean = true;
  @Input({ required: true }) avatarSize!: 'xs' | 'sm' | 'md' | 'lg';

  public imageFallback: boolean = false;

  get getInitials(): string {
    const nameParts: string[] = this.initials ? this.initials?.split(' ') : [];
    const a: string = nameParts[0]?.charAt(0).toUpperCase() ?? '';
    const b: string = nameParts[1]?.charAt(0).toUpperCase() ?? nameParts[0]?.charAt(1).toLowerCase() ?? '';

    return `${a}${b}`;
  }

  get avatarClass(): string {
    return `custom-avatar-${this.avatarSize}`;
  }

  get initialsClass(): string {
    if (this.avatarSize === 'lg') return `${this.avatarClass} or-head-30`;
    if (this.avatarSize === 'md') return `${this.avatarClass} or-head-xl`;
    if (this.avatarSize === 'sm') return `${this.avatarClass} or-head-md`;
    if (this.avatarSize === 'xs') return `${this.avatarClass} or-head-xs`;
    return '';
  }

  onImageError(): void {
    this.imageFallback = true;
  }
}
