import { map, Observable } from 'rxjs';
import { inject } from '@angular/core';
import { NotificationBody, NotificationResponseDTO, NotificationViewModel } from '../interfaces';
import { NOTIFICATIONS_DTO, NotificationsDTOPort } from '../../infrastructure';
import { NotificationStatusMapper } from '../mappers';
import { formattedTimeDifferenceUtil } from '@core';
import { NotificationType } from '../enums';
import { userTrophiesMapper } from '../../../../../profile/src/lib/application/mappers';
import { cardReasonMapper } from '@fair-play';

export class NotificationsListQueryHandler {
  private readonly notificationsDtoPort: NotificationsDTOPort = inject(NOTIFICATIONS_DTO);

  getNotificationsList(): Observable<NotificationViewModel[]> {
    return this.notificationsDtoPort.notificationsList().pipe(
      map((notifications: NotificationResponseDTO) =>
        notifications.notifications.map((notification: NotificationBody) => {
          return {
            title: this.getTitle(notification),
            description: this.getDescription(notification),
            formattedDate: this.getFormattedDate(notification),
            gameName: notification.payload.gameName,
            address: notification.payload.gameAddress,
            avatar: this.getAvatar(notification),
            isRead: notification.isRead,
            id: notification.notificationId,
            type: notification.notificationType,
            createdAt: formattedTimeDifferenceUtil(new Date(notification.createdAt)),
            gameId: notification.payload.gameId,
            displayName: notification.payload.displayName,
            customId: notification.payload.customId,
            customRedirectUrl: notification.payload.customRedirectUrl,
            teamId: notification.payload.teamId,
            teamName: notification.payload.teamName,
            receiverUserId: notification.payload.receiverUserId
          };
        })
      )
    );
  }

  private getTitle(notification: NotificationBody): string {
    if (
      notification.notificationType === NotificationType.CustomNotification ||
      notification.notificationType === NotificationType.UserPenaltyReceived
    )
      return notification.payload.title || NotificationStatusMapper[notification.notificationType];

    return NotificationStatusMapper[notification.notificationType];
  }

  private getDescription(notification: NotificationBody): string {
    if (
      notification.notificationType === NotificationType.CustomNotification ||
      notification.notificationType === NotificationType.TeamMemberAddedByHost ||
      notification.notificationType === NotificationType.UserPenaltyReceived ||
      notification.notificationType === NotificationType.NewTrophyReceived ||
      notification.notificationType === NotificationType.NewCommentReceived
    )
      return notification.payload.body;

    return `${notification.payload.gameName} (${notification.payload.gameAddress})`;
  }

  private getFormattedDate(notification: NotificationBody): string | null {
    if (
      notification.notificationType === NotificationType.CustomNotification ||
      notification.notificationType === NotificationType.TeamMemberAddedByHost ||
      notification.notificationType === NotificationType.UserPenaltyReceived ||
      notification.notificationType === NotificationType.NewTrophyReceived ||
      notification.notificationType === NotificationType.NewCommentReceived
    )
      return null;

    return `${notification.payload.gameDate} | ${notification.payload.gameHours}`;
  }

  private getAvatar(notification: NotificationBody): string {
    if (notification.notificationType === NotificationType.NewTrophyReceived)
      return userTrophiesMapper[notification.payload.trophyType].icon;

    if (notification.notificationType === NotificationType.UserPenaltyReceived)
      return cardReasonMapper[notification.payload.penaltyReason].icon;

    if (notification.notificationType === NotificationType.NewCommentReceived) return 'assets/icons/comment-icon.svg';

    return notification.payload.thumbnailUrl;
  }
}
