import { GAMES_DTO, GamesDTOPort } from '../../infrastructure/http-service';
import { inject } from '@angular/core';
import { DateFormatPipe } from '@ui-components';
import { map, Observable } from 'rxjs';
import { mapToGameQueryUtil } from './utils/map-to-game-query.util';
import { PastGameDTO, PlayerGameDTO } from '../interfaces';
import { GameDetailsModel } from '@core';

export class GetPastGamesQueryHandler {
  private gamesDTOPort: GamesDTOPort = inject(GAMES_DTO);
  private readonly dateFormatPipe: DateFormatPipe = inject(DateFormatPipe);

  getGames(): Observable<Map<string, GameDetailsModel[]>> {
    return this.gamesDTOPort
      .getPastGames()
      .pipe(map((games: PastGameDTO) => this.groupAndMapGamesByMonth(games.games)));
  }

  private groupAndMapGamesByMonth(games: PlayerGameDTO[]): Map<string, GameDetailsModel[]> {
    return games.reduce((groupedGames, game) => {
      const gameDate: Date = new Date(game.gameDateStart);
      const monthYear: string = this.capitalizeFirstLetter(
        gameDate.toLocaleString('pl-PL', {
          month: 'long',
          year: 'numeric'
        })
      );

      const mappedGame: GameDetailsModel = mapToGameQueryUtil(game, this.dateFormatPipe);
      const updatedGroup = (groupedGames.get(monthYear) || []).concat(mappedGame);
      return new Map(groupedGames).set(monthYear, updatedGroup);
    }, new Map<string, GameDetailsModel[]>());
  }

  private capitalizeFirstLetter(string: string): string {
    return string?.charAt(0).toUpperCase() + string.slice(1);
  }
}
