import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { IntroductionEnum } from '../application/enums';

export const INTRODUCTION_DTO: InjectionToken<IntroductionDtoPort> = new InjectionToken<IntroductionDtoPort>(
  'INTRODUCTION_DTO'
);

export interface IntroductionDtoPort {
  isViewed(introId: IntroductionEnum, userId: string): Observable<boolean>;
  markAsViewed(introId: IntroductionEnum, userId: string): Promise<void>;
}
