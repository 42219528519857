import { Observable } from 'rxjs';
import { GAME_APPLICATIONS_DTO, GameApplicationsDTOPort } from '../../infrastructure/http-service';
import { inject } from '@angular/core';

export class RemoveApplicationCommandHandler {
  private readonly gameApplicationsDTOPort: GameApplicationsDTOPort = inject(GAME_APPLICATIONS_DTO);

  remove(gameId: string, gameStatus: string): Observable<void> {
    return gameStatus === 'Approved'
      ? this.gameApplicationsDTOPort.removeFromGame(gameId)
      : this.gameApplicationsDTOPort.removeApplication(gameId);
  }
}
