import { ChangeDetectionStrategy, Component, EventEmitter, inject, Output } from '@angular/core';
import { provideGameApplicationsService, SendApplicationDirective } from '@games';
import { GameCardComponent, RedirectTypeEnum } from '@ui-components';
import { Observable, switchMap, tap } from 'rxjs';
import { GameDetailsModel, LocalRefreshService, MODAL_TOKEN, ModalProvider } from '@core';
import { AsyncPipe, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DeclineInvitationCommandHandler, provideInvitationsService } from '@invitations';
import {
  GetFutureGamesQueryHandler,
  LoadFutureGamesCommandHandler,
  SendApplicationCommandHandler
} from '../../../../../games/src/lib/application/handlers';

@Component({
  selector: 'lib-dashboard-invitations',
  templateUrl: './dashboard-invitations.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, NgIf, NgForOf, IonicModule, GameCardComponent, SendApplicationDirective, NgTemplateOutlet],
  providers: [
    GetFutureGamesQueryHandler,
    SendApplicationCommandHandler,
    provideGameApplicationsService(),
    LocalRefreshService,
    DeclineInvitationCommandHandler,
    provideInvitationsService()
  ]
})
export class DashboardInvitationsComponent {
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly getFutureGamesQueryHandler: GetFutureGamesQueryHandler = inject(GetFutureGamesQueryHandler);
  private readonly declineInvitationCommandHandler: DeclineInvitationCommandHandler = inject(
    DeclineInvitationCommandHandler
  );
  private readonly loadFutureGamesCommandHandler: LoadFutureGamesCommandHandler = inject(LoadFutureGamesCommandHandler);

  @Output() isInvitationsAvailable: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() invitationAccepted: EventEmitter<void> = new EventEmitter<void>();

  public isInvitationsExpanded: boolean = true;
  public gameRedirectType: RedirectTypeEnum = RedirectTypeEnum.GAME;
  public isDisabled: boolean = false;

  readonly invitations$: Observable<GameDetailsModel[]> = this.modalProvider.showLoading$().pipe(
    switchMap(() => this.getFutureGamesQueryHandler.getInvitations()),
    tap((result: GameDetailsModel[]) => this.isInvitationsAvailable.emit(!!result?.length)),
    tap(() => this.modalProvider.dismissLoading$())
  );

  refresh(): void {
    this.loadFutureGamesCommandHandler.load().subscribe();
    this.invitationAccepted.emit();
  }

  stopEventPropagation(event: MouseEvent): void {
    event.stopImmediatePropagation();
  }

  changeInvitationsVisibility(): void {
    this.isInvitationsExpanded = !this.isInvitationsExpanded;
  }

  decline(invitaionId: string): void {
    this.declineInvitationCommandHandler.decline(invitaionId).subscribe(() => this.refresh());
  }

  blockMulticlick() {
    this.isDisabled = true;
  }
}
