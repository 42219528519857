<div class="or-my-32-px or-mx-24-px">
  <div class="or-d-flex or-justify-content-center">
    <span class="or-head-xl or-mb-16-px or-text-align-center">{{ header }}</span>
  </div>
  <div class="or-text-align-center or-mb-20-px">
    <span class="or-text-xl or-text-align-center">{{ message }} </span>
  </div>
  <div class="or-align-items-center or-d-flex or-flex-col">
    <button class="button-primary-style or-h-48-px or-w-90-pc or-head-md or-mb-12-px" (click)="goToGame()">
      <div class="or-align-items-center or-d-flex or-justify-content-center">
        <img src="assets/icons/ball-icon.svg" class="or-mr-12-px" /><span> Przejdź do gierki</span>
      </div>
    </button>

    <button class="button-secondary-style or-h-48-px or-w-90-pc or-head-md or-mb-12-px" (click)="goToInvitations()">
      <div class="or-align-items-center or-d-flex or-justify-content-center">
        <img src="assets/icons/add-player-icon.svg" class="or-mr-12-px" />
        <span>Zaproś graczy</span>
      </div>
    </button>

    <button [shareGame]="game" class="button-secondary-style or-h-48-px or-w-90-pc or-head-md">
      <div class="or-align-items-center or-d-flex or-justify-content-center">
        <img src="assets/icons/share-shade-icon.svg" class="or-mr-12-px" /><span>Udostępnij...</span>
      </div>
    </button>
  </div>
</div>
