import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { take, tap } from 'rxjs';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { environment } from 'src/environment/environment';
import { getNewVersionWebAndroid, getNewVersionWebiOS } from '@update';
import { NgIf } from '@angular/common';

@Component({
  selector: 'lib-blocked-web-view',
  templateUrl: './blocked-web-view.component.html',
  styleUrls: ['./blocked-web-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf]
})
export class BlockedWebViewComponent {
  @Input({ required: true }) path: string = '';

  public isPopupVisible: boolean = false;

  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  redirectToNativeApp(): void {
    this.activatedRoute.queryParamMap
      .pipe(
        take(1),
        tap((paramMap: ParamMap) => {
          const referrer: string = encodeURIComponent(paramMap.get('referrer') || '');
          const referrerPath = referrer ? `?referrer=${referrer}` : '';
          const sharedUrl: string = `${environment.customUrl}/${this.path}${referrerPath}`;
          const defaultUrl: string = `${environment.customUrl}`;

          const url = this.path ? sharedUrl : defaultUrl;
          window.open(url, '_blank');
        }),
        tap(() => (this.isPopupVisible = true))
      )
      .subscribe();
  }

  downloadAndroid(): void {
    getNewVersionWebAndroid();
  }

  downloadiOS(): void {
    getNewVersionWebiOS();
  }
}
