import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { OrAvatarComponent, TitileNavbarComponent } from '@ui-components';
import { Router } from '@angular/router';
import { BehaviorSubject, map, Observable, shareReplay, switchMap, take, tap } from 'rxjs';
import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { NotificationViewModel } from '../../application/interfaces';
import { MODAL_TOKEN, ModalProvider, PLATFORM_TOKEN, PlatformProvider } from '@core';
import { notificationRedirectUtil } from '../../application/utils';
import {
  MarkAsReadNotificationsCommandHandler,
  MarkNotificationAsClickedCommandHandler,
  NotificationsListQueryHandler
} from '../../application/handlers';
import { NotificationType } from '../../application/enums';

@Component({
  selector: 'lib-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TitileNavbarComponent, CommonModule, IonicModule, OrAvatarComponent, NgTemplateOutlet],
  providers: [MarkNotificationAsClickedCommandHandler]
})
export class NotificationsListComponent {
  private readonly notificationsListQueryHandler: NotificationsListQueryHandler = inject(NotificationsListQueryHandler);
  private readonly markAsReadNotificationsCommandHandler: MarkAsReadNotificationsCommandHandler = inject(
    MarkAsReadNotificationsCommandHandler
  );
  private readonly router: Router = inject(Router);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly markNotificationAsClickedCommandHandler: MarkNotificationAsClickedCommandHandler = inject(
    MarkNotificationAsClickedCommandHandler
  );

  readonly isiOS: boolean = this.platformProvider.isiOS;

  readonly specificNotificationImage: NotificationType[] = [
    NotificationType.NewTrophyReceived,
    NotificationType.UserPenaltyReceived
  ];

  readonly isPageLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  readonly notifications$: Observable<NotificationViewModel[]> = this.modalProvider.showLoading$().pipe(
    switchMap(() => this.notificationsListQueryHandler.getNotificationsList()),
    take(1),
    tap(() => this.isPageLoaded$.next(true)),
    tap(() => this.modalProvider.dismissLoading$()),
    shareReplay(1)
  );

  readonly newNotifications$: Observable<NotificationViewModel[]> = this.notifications$.pipe(
    take(1),
    map((notifications: NotificationViewModel[]) =>
      notifications.filter((notification: NotificationViewModel) => !notification.isRead)
    ),
    tap((notifications: NotificationViewModel[]) => {
      const notificationsIds: string[] = notifications.map((notification) => notification.id);
      this.markAsReadNotificationsCommandHandler.markAsRead(notificationsIds).pipe(take(1)).subscribe();
    }),
    shareReplay(1)
  );

  readonly pastNotifications$: Observable<NotificationViewModel[]> = this.notifications$.pipe(
    map((notifications: NotificationViewModel[]) =>
      notifications.filter((notification: NotificationViewModel) => notification.isRead)
    ),
    shareReplay(1)
  );

  redirect(notification: NotificationViewModel): void {
    this.markNotificationAsClickedCommandHandler.markasClicked(notification.id).pipe(take(1)).subscribe();

    notificationRedirectUtil({
      type: notification.type,
      gameId: notification.gameId,
      router: this.router,
      customId: notification.customId,
      customRedirectUrl: notification.customRedirectUrl,
      teamId: notification.teamId,
      receiverUserId: notification.receiverUserId
    });
  }
}
