import { PenaltiesService } from '../../infrastructure';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { AddCardPayloadDTO } from '../interfaces';

export class AddCardCommandHandler {
  private readonly playerProfileService: PenaltiesService = inject(PenaltiesService);

  add(payload: AddCardPayloadDTO): Observable<void> {
    return this.playerProfileService.addCard(payload);
  }
}
