<ng-container *ngIf="savedFilters$ | async as savedFilters">
  <ng-container *ngIf="{areFiltersDefault: areFiltersDefault$ | async} as areFiltersDefault">
    <div class="or-d-flex overflow-x-auto or-pl-8-px or-pr-8-px or-mt-16-px">
      <div
        *ngFor="let day of daysRange$ | async"
        (click)="setDate(day)"
        class="or-head-sm or-mr-4-px or-p-8-px or-d-flex or-justify-content-center or-cursor-pointer text-no-wrap or-min-w-60-px"
        [ngClass]="{
        checked: day.isSelected,
        unchecked: !day.isSelected
      }">
        <div class="or-d-flex or-flex-col or-align-items-center or-justify-content-center">
          <div>{{ day.date }}</div>
          <div class="or-text-transform-capitalize-first-letter">{{ day.dayAbbreviation }}</div>
        </div>
      </div>
    </div>

    <div class="or-px-12-px or-d-flex or-mt-8-px overflow-x-auto">
      <img
        *ngIf="!areFiltersDefault.areFiltersDefault"
        (click)="resetFilters()"
        src="assets/icons/reset.svg"
        class="or-mr-8-px" />

      <div
        *ngIf="showDistanceFilter"
        (click)="openDistanceFilter()"
        class="border-shade border-radius-4 or-d-flex or-mr-12-px or-bg-white or-p-8-px or-text-md text-no-wrap or-align-content-center">
        <div class="or-color-secondary-2 or-mr-4-px">Odległość:</div>
        <div>{{ savedFilters.maxDistanceMeters ? savedFilters.maxDistanceMeters / 1000 + " km" : "dowolna" }}</div>
        <img src="assets/icons/dropdown-icon.svg" class="or-ml-8-px" />
      </div>

      <div
        (click)="openGameLevel()"
        class="border-shade border-radius-4 or-d-flex or-mr-12-px or-bg-white or-p-8-px or-text-md text-no-wrap or-align-content-center">
        <div class="or-color-secondary-2 or-mr-4-px">Poziom:</div>
        <div>{{ getGameLevelName(savedFilters.gameLevel) }}</div>
        <img src="assets/icons/dropdown-icon.svg" class="or-ml-8-px" />
      </div>

      <div
        (click)="openLocationType()"
        class="border-shade border-radius-4 or-d-flex or-bg-white or-p-8-px or-text-md text-no-wrap or-align-content-center">
        <div class="or-color-secondary-2 or-mr-4-px">Typ boiska:</div>
        <div>{{ getLocationTypeName(savedFilters.locationType) }}</div>
        <img src="assets/icons/dropdown-icon.svg" class="or-ml-8-px" />
      </div>
    </div>
  </ng-container>
</ng-container>
