import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { IonicModule } from "@ionic/angular";
import {
  NAVIGATION_TOKEN,
  NavigationProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
} from "@core";
import { NgClass, NgIf } from "@angular/common";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { take, tap } from "rxjs";
import { AppRoutes } from "src/app/app-routes.enum";
import { ChannelDetailsDTO } from "../../application/interfaces";
import { TeamsRoutes } from "../../../../../teams/src/lib/teams-routes.enum";
import { HostedGamesRoutes } from "../../../../../hosted-games/src/lib/hosted-games.routes.enum";
import { GamesRoutes } from "../../../../../games/src/lib/games-routes.enum";
import { ChannelTypeEnum } from "../../application/enums";

@Component({
  selector: "lib-chat-navbar",
  templateUrl: "./chat-navbar.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [IonicModule, NgClass, NgIf],
})
export class ChatNavbarComponent {
  @Output() actionOnBack: EventEmitter<void> = new EventEmitter<void>();
  @Output() onDotsClicked: EventEmitter<void> = new EventEmitter<void>();

  @Input({ required: true }) date: string = "";
  @Input({ required: true }) details!: ChannelDetailsDTO;

  private readonly navigationProvider: NavigationProvider =
    inject(NAVIGATION_TOKEN);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly activatedRouter: ActivatedRoute = inject(ActivatedRoute);
  private readonly router: Router = inject(Router);

  isIOs(): boolean {
    return this.platformProvider.isiOS;
  }

  goBack(): void {
    this.activatedRouter.queryParamMap
      .pipe(
        take(1),
        tap((paramMap: ParamMap) => {
          const type = paramMap.get("type");
          if (!type) return this.navigationProvider.back();

          return this.router.navigateByUrl(
            `${AppRoutes.MESSAGES}?type=${type}`
          );
        }),
        tap(() => this.actionOnBack.emit())
      )
      .subscribe();
  }

  dotsClicked(): void {
    this.onDotsClicked.emit();
  }

  redirect(): void {
    if (
      this.details.channelType === ChannelTypeEnum.TEAM &&
      this.details.teamId
    ) {
      this.router.navigateByUrl(
        `${AppRoutes.TEAMS}/${this.details.teamId}/${TeamsRoutes.TEAM_DETAILS}`
      );
    }

    if (
      this.details.channelType === ChannelTypeEnum.MULTI &&
      this.details.closestGame?.gameId
    ) {
      const hostUrl: string = `${AppRoutes.HOST}/${HostedGamesRoutes.FUTURE}/${this.details.closestGame?.gameId}`;
      const playerUrl: string = `${AppRoutes.GAMES}/${GamesRoutes.DETAILS}/${this.details.closestGame?.gameId}`;
      const url: string = this.details.closestGame?.isHost
        ? hostUrl
        : playerUrl;

      this.router.navigateByUrl(url);
    }
  }
}
