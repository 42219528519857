import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { BehaviorSubject, map, Observable, switchMap } from 'rxjs';
import { DateFormatPipe, GameCardComponent, GameDetailsComponent } from '@ui-components';
import { AdminGameModel, AdminGamesDTO } from '../../application/models';
import { CopyService, CopyValueToClipboardDirective } from '@core';
import { AppRoutes } from 'src/app/app-routes.enum';
import { GamesRoutes } from '@games';
import { Router } from '@angular/router';
import { ADMIN_GAMES_DTO, AdminGamesDtoPort } from '../../infrastructure/http-services';

@Component({
  selector: 'lib-admin-games-list',
  templateUrl: './admin-games-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    NgForOf,
    NgIf,
    GameDetailsComponent,
    GameCardComponent,
    DateFormatPipe,
    CopyValueToClipboardDirective,
    NgClass
  ],
  providers: [CopyService]
})
export class AdminGamesListComponent {
  private readonly adminGamesDtoPort: AdminGamesDtoPort = inject(ADMIN_GAMES_DTO);
  private readonly router: Router = inject(Router);

  readonly gamesPeriod$: BehaviorSubject<number> = new BehaviorSubject<number>(7);

  readonly games$: Observable<AdminGameModel[]> = this.gamesPeriod$.asObservable().pipe(
    switchMap((period) => this.adminGamesDtoPort.getAllGames(period)),
    map((games: AdminGamesDTO) => games.adminGames)
  );

  goToDetails(gameId: string): void {
    this.router.navigate([`${AppRoutes.GAMES}/${GamesRoutes.DETAILS}/${gameId}`]);
  }

  setPeriod(period: number): void {
    this.gamesPeriod$.next(period);
  }
}
