import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UpdateService } from '../../infrastructure/update.service';
import { ActionModalComponent, MODAL_TOKEN, ModalProvider, PLATFORM_TOKEN, PlatformProvider } from '@core';
import { AppRoutes } from 'src/app/app-routes.enum';
import { getNewVersionNative } from '../utils';

export const UpdateGuard = (): Observable<boolean> => {
  const router: Router = inject(Router);
  const updateService: UpdateService = inject(UpdateService);
  const modalToken: ModalProvider = inject(MODAL_TOKEN);
  const platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);

  const openModal = () => {
    router.navigate([`/${AppRoutes.BLOCKED_VERSION}`]);

    return modalToken
      .showModal$({
        component: ActionModalComponent,
        componentProps: {
          header: 'Wymagana aktualizacja',
          message: 'Jeśli chcesz nadal korzystać z aplikacji musisz ją zaktualizować',
          btnOk: 'Pobierz aktualizację',
          btnCancel: 'Anuluj',
          action: () => getNewVersionNative(platformProvider)
        },
        cssClass: 'present-modal'
      })
      .pipe(map(() => false));
  };

  if (platformProvider.isDesktop) return of(true);

  return updateService.isUpdated().pipe(switchMap((isUpdated: boolean) => (!isUpdated ? openModal() : of(true))));
};
