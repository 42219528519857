<div class="ion-page" id="main-content">
  <div [ngClass]="{ 'or-pt-32-px header-bg-color': isIOs() }">
    <div class="header-bg-color or-d-flex or-justify-content-between" style="height: 56px">
      <ion-buttons
        ><ion-icon class="or-w-80-px or-ml-16-px" src="assets/icons/orlikfy-header.svg"></ion-icon
      ></ion-buttons>

      <ion-buttons class="or-pr-12-px">
        <ion-button (click)="addGame()">
          <ion-icon src="assets/add-button.svg"></ion-icon>
        </ion-button>
        <ion-button (click)="openNotifications()">
          <ion-icon src="assets/icons/notifications-icon.svg"></ion-icon>
          <img
            *ngIf="isNewNotifications"
            src="assets/icons/unread-icon.svg"
            class="or-position-fixed or-bottom-16-px or-right-2-px" />
        </ion-button>
      </ion-buttons>
    </div>
  </div>
</div>
