import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
} from "@angular/core";
import { OrAvatarComponent, TitileNavbarComponent } from "@ui-components";
import { BehaviorSubject, map, Observable, switchMap, take, tap } from "rxjs";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { AppRoutes } from "src/app/app-routes.enum";
import { Router } from "@angular/router";
import { ProfileRoutes } from "../../profile.routes.enum";
import {
  ActionModalComponent,
  CoordinatesDTO,
  GEOLOCATION_TOKEN,
  GeolocationProvider,
  GlobalRefreshService,
  MODAL_TOKEN,
  ModalProvider,
  NOTIFICATION_TOKEN,
  NotificationPermissionProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
  PresentModalComponent,
  USER_DATA_TOKEN,
  UserDataProvider,
  UserModel,
  UserRoleEnum,
} from "@core";
import {
  NotificationsRoutes,
  UnregisterTokenCommandHandler,
} from "@notifications";
import { getNewVersionNative, UpdateService } from "@update";
import { LogoutCommandHandler } from "../../../../../auth/src/lib/application/handlers";

@Component({
  selector: "lib-profile-settings",
  templateUrl: "./profile-settings.component.html",
  styleUrls: ["./profile-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TitileNavbarComponent,
    IonicModule,
    OrAvatarComponent,
  ],
  providers: [UnregisterTokenCommandHandler],
})
export class ProfileSettingsComponent {
  private readonly logoutCommandHandler: LogoutCommandHandler =
    inject(LogoutCommandHandler);
  private readonly router: Router = inject(Router);
  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);
  private readonly unregisterTokenCommandHandler: UnregisterTokenCommandHandler =
    inject(UnregisterTokenCommandHandler);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly geolocationProvider: GeolocationProvider =
    inject(GEOLOCATION_TOKEN);
  private readonly updateService: UpdateService = inject(UpdateService);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private notificationPermissionProvider: NotificationPermissionProvider =
    inject(NOTIFICATION_TOKEN);
  private globalRefreshService: GlobalRefreshService =
    inject(GlobalRefreshService);
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  readonly isPageLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  readonly userData$: Observable<UserModel> =
    this.userDataProvider.userData$.pipe(
      tap(() => this.isPageLoaded$.next(true))
    );

  readonly shouldShowAdminPanel$: Observable<boolean> = this.userData$.pipe(
    map((userData: UserModel) => userData.role === UserRoleEnum.ADMIN)
  );

  readonly isNewestVersion$: Observable<boolean> =
    this.updateService.isNewestVersion();

  readonly isNotificationPermissionsEnabled$: Observable<boolean> =
    this.globalRefreshService.refresh$.pipe(
      tap(() => this.cdr.markForCheck()),
      switchMap(() => this.notificationPermissionProvider.isEnabled()),
      tap(() => this.cdr.detectChanges())
    );

  readonly isNativeRun: boolean = this.platformProvider.isNativeRun;

  logout(): void {
    this.unregisterTokenCommandHandler
      .unregister()
      .pipe(
        take(1),
        switchMap(() => this.logoutCommandHandler.logout()),
        switchMap(() => this.router.navigate([`/${AppRoutes.AUTH}`]))
      )
      .subscribe();
  }

  editProfile(): void {
    this.router.navigate([`${AppRoutes.PROFILE}/${ProfileRoutes.EDIT}`]);
  }

  openContact(): void {
    this.router.navigate([`${AppRoutes.PROFILE}/${ProfileRoutes.CONTACT}`]);
  }

  goToAlertsSettings(): void {
    this.geolocationProvider
      .getCoordinates()
      .pipe(
        take(1),
        tap((coordinates: CoordinatesDTO | null) =>
          !!coordinates ? this.openAlerts() : this.alertsDisabledModal()
        )
      )
      .subscribe();
  }

  openInstagram(): void {
    window.open("https://www.instagram.com/orlikfy");
  }

  goToAdminPanel(): void {
    this.router.navigate([AppRoutes.ADMIN_PANEL]);
  }

  updateApp(): void {
    if (this.platformProvider.isDesktop) return;

    this.isNewestVersion$
      .pipe(
        take(1),
        switchMap((version) =>
          version ? this.hasNewestVersion() : this.showUpdateModal()
        )
      )
      .subscribe();
  }

  showUpdateModal(): Observable<void> {
    return this.modalProvider.showModal$({
      component: ActionModalComponent,
      componentProps: {
        header: "Dostępna aktualizacja!",
        message:
          "Nowa wersja aplikacji jest dostępna. Pobierz ją, aby korzystać z nowych funkcji!",
        btnOk: "Pobierz aktualizację",
        btnCancel: "Anuluj",
        action: () => getNewVersionNative(this.platformProvider),
      },
      cssClass: "present-modal",
    });
  }

  hasNewestVersion(): Observable<void> {
    return this.modalProvider.showModal$({
      component: PresentModalComponent,
      componentProps: {
        header: "Brak aktualizacji",
        message: "Masz najnowszą wersję!",
        btnTxt: "Zamknij",
      },
      cssClass: "present-modal",
    });
  }

  private alertsDisabledModal(): void {
    this.modalProvider.showModal$({
      component: PresentModalComponent,
      componentProps: {
        header: "Brak dostępu do lokalizacji",
        message:
          "Włącz lokalizację, aby móc korzystać z powiadomień o gierkach w pobliżu.",
        btnTxt: "Zamknij",
      },
      cssClass: "present-modal",
    });
  }

  private openAlerts(): void {
    this.router.navigate([
      `${AppRoutes.NOTIFICATIONS}/${NotificationsRoutes.ALERTS}`,
    ]);
  }

  openReferral(): void {
    return;
    // this.eventHandler.handleEvent({
    //   eventName: AnalyticsEventName.ReferralOpened,
    // });
    //
    // this.router.navigate([`${AppRoutes.PROFILE}/${ProfileRoutes.REFERRAL}`]);
  }
}
