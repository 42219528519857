import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from "@angular/core";
import {
  GetFilterDaysQueryHandler,
  GetFiltersQueryHandler,
  SetFilterCommandHandler,
} from "../../application/handlers";
import { combineLatest, Observable, of, switchMap, take } from "rxjs";
import { CalendarHeader } from "../../application/interfaces";
import { AsyncPipe, NgClass, NgForOf, NgIf } from "@angular/common";
import { MODAL_TOKEN, ModalProvider } from "@core";
import { SelectableSingleRadioModalComponent } from "@ui-components";

@Component({
  selector: "lib-filters",
  templateUrl: "./filters.component.html",
  styleUrls: ["./filters.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgForOf, AsyncPipe, NgClass, NgIf],
  providers: [
    GetFilterDaysQueryHandler,
    SetFilterCommandHandler,
    GetFiltersQueryHandler,
  ],
})
export class FiltersComponent {
  @Input() showDistanceFilter: boolean = true;

  private readonly getFilterDaysQueryHandler: GetFilterDaysQueryHandler =
    inject(GetFilterDaysQueryHandler);
  private readonly setFilterCommandHandler: SetFilterCommandHandler = inject(
    SetFilterCommandHandler
  );
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly getFiltersQueryHandler: GetFiltersQueryHandler = inject(
    GetFiltersQueryHandler
  );

  readonly availableDistance$ = of([
    {
      name: "do 2 km",
      value: 2000,
    },
    {
      name: "do 5 km",
      value: 5000,
    },
    {
      name: "do 25 km",
      value: 25000,
    },
    {
      name: "do 70 km",
      value: 70000,
    },
    {
      name: "dowolna",
      value: null,
    },
  ]);

  readonly daysRange$: Observable<CalendarHeader[]> =
    this.getFilterDaysQueryHandler.filterDays();
  readonly savedFilters$ = this.getFiltersQueryHandler.filters();

  setDate(data: CalendarHeader) {
    if (data.isSelected) {
      this.setFilterCommandHandler.set({ specificDate: undefined }).subscribe();
    } else {
      this.setFilterCommandHandler
        .set({ specificDate: data.value })
        .subscribe();
    }
  }

  openDistanceModal(): void {
    combineLatest([this.availableDistance$, this.savedFilters$])
      .pipe(
        take(1),
        switchMap(([availableDistance, filters]) =>
          this.modalProvider.showModal$<number>({
            component: SelectableSingleRadioModalComponent,
            cssClass: "present-modal",
            backdropDismiss: false,
            componentProps: {
              buttonText: "Wybierz",
              header: "Odległość",
              providedData: availableDistance,
              selectedData: filters.maxDistanceMeters ?? null,
            },
          })
        ),
        switchMap((distance: number) =>
          this.setFilterCommandHandler.set({
            maxDistanceMeters: distance,
          })
        )
      )
      .subscribe();
  }
}
