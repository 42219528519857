import { inject } from '@angular/core';
import { INVITATIONS_DTO, InvitationsDtoPort } from '../../infrastructure/http-service';
import { Observable, switchMap } from 'rxjs';
import { InvitePlayersRequestDTO } from '../interfaces';
import { ActivatedRoute, Params } from '@angular/router';

export class InvitePlayersCommandHandler {
  private readonly invitationsDtoPort: InvitationsDtoPort = inject(INVITATIONS_DTO);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  sendInvitations(players: InvitePlayersRequestDTO): Observable<void> {
    return this.activatedRoute.params.pipe(
      switchMap((params: Params) => this.invitationsDtoPort.invite(params['id'], players))
    );
  }
}
