import { FiltersStorage } from '../../infrastructure/storages';
import { inject } from '@angular/core';
import { map, Observable } from 'rxjs';
import { FiltersValues } from '../models';
import { Router } from '@angular/router';

export class AreFiltersDefaultQueryHandler {
  private readonly filtersStorage: FiltersStorage = inject(FiltersStorage);
  private readonly router: Router = inject(Router);

  areDefault(): Observable<boolean> {
    return this.filtersStorage.filters$.pipe(
      map((filters: FiltersValues) => {
        const areCoordinatesAvailable: boolean = !!filters?.longitude && !!filters?.latitude;

        return (
          !filters.gameLevel?.length &&
          !filters.specificDate &&
          !filters.locationType?.length &&
          this.checkLocation(filters.maxDistanceMeters, areCoordinatesAvailable)
        );
      })
    );
  }

  private checkLocation(selectedDistance: number, areCoordinatesAvailable: boolean | null): boolean {
    if (this.router.url.includes('map')) return true;
    if (areCoordinatesAvailable && selectedDistance === 70000) return true;

    return !areCoordinatesAvailable;
  }

  private areArraysEqual(arr1: unknown[], arr2: unknown[]): boolean {
    if (arr1?.length !== arr2?.length) return false;

    return arr1?.every((item: unknown) => arr2?.includes(item));
  }
}
