<ng-container *ngIf="invitations$ | async as invitations">
  <ng-container *ngIf="invitations?.length">
    <div
      (click)="changeInvitationsVisibility()"
      class="or-d-flex or-justify-content-between or-align-items-center or-px-24-px or-mt-20-px"
    >
      <div class="or-head-md">Zaproszenia</div>
      <img
        [src]="
          isInvitationsExpanded
            ? 'assets/icons/chevron-up.svg'
            : 'assets/icons/chevron-down-primary.svg'
        "
      />
    </div>
    <ng-container *ngIf="isInvitationsExpanded">
      <div *ngFor="let game of invitations" class="or-mb-12-px or-mt-12-px">
        <ion-chip
          class="or-ml-32-px z-index-2 or-head-xs chips-margin-bottom--20-px or-mt-0-px invited"
          >Zaproszenie</ion-chip
        >

        <lib-game-card
          [game]="game"
          [showMoreDetails]="true"
          [showShareButton]="true"
          [redirectType]="gameRedirectType"
          [adjustToChip]="true"
          ><ng-container
            *ngTemplateOutlet="invitationsButtons; context: { $implicit: game }"
          ></ng-container>
        </lib-game-card>
      </div> </ng-container
  ></ng-container>
</ng-container>

<ng-template #invitationsButtons let-game>
  <div
    (click)="stopEventPropagation($event)"
    class="or-d-flex or-justify-content-center or-mt-16-px or-mb-8-px"
  >
    <button
      class="button-primary-style or-h-24-px or-head-xs or-w-112-px or-mr-12-px"
      [sendApplication]="game.gameId"
      (applicationSent)="refresh()"
      (onBlockMulticlick)="blockMulticlick()"
      [disabled]="isDisabled"
    >
      Gram!
    </button>
    <button
      (click)="decline(game.gameInvitationId)"
      class="button-secondary-style or-h-24-px or-head-xs or-w-112-px"
    >
      Nie gram
    </button>
  </div>
</ng-template>
