import { FiltersStorage } from "../../infrastructure/storages";
import { inject } from "@angular/core";
import { FiltersValues } from "../models";
import { Observable } from "rxjs";

export class SetFilterCommandHandler {
  private readonly filtersStorage: FiltersStorage = inject(FiltersStorage);

  set(data: Partial<FiltersValues>): Observable<void> {
    return this.filtersStorage.setFilters(data);
  }
}
