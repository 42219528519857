import { NOTIFICATIONS_DTO, NotificationsDTOPort } from "../../infrastructure";
import { inject } from "@angular/core";
import { Observable } from "rxjs";

export class MarkAsReadNotificationsCommandHandler {
  private readonly notificationsDtoPort: NotificationsDTOPort =
    inject(NOTIFICATIONS_DTO);

  markAsRead(ids: string[]): Observable<void> {
    return this.notificationsDtoPort.markAsRead(ids);
  }
}
