import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import {
  BottomNavbarComponent,
  OrAvatarComponent,
  TitileNavbarComponent,
} from "@ui-components";
import {
  LocalRefreshService,
  MODAL_TOKEN,
  ModalProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
} from "@core";
import { ActivatedRoute, Params } from "@angular/router";
import {
  AsyncPipe,
  NgClass,
  NgForOf,
  NgIf,
  NgTemplateOutlet,
} from "@angular/common";
import { IonicModule } from "@ionic/angular";
import {
  BehaviorSubject,
  combineLatest,
  map,
  Observable,
  switchMap,
  take,
  tap,
} from "rxjs";
import {
  AddTeamMemberCommandHandler,
  GetTeamCandidatesQueryHandler,
} from "../../application/handlers";
import {
  TeamCandidatesDTO,
  TeamPlayersViewModel,
} from "../../application/interfaces";
import { TeamInviteData } from "../../application/models";
import { ShareTeamModalComponent } from "../share-team-modal/share-team-modal.component";

@Component({
  selector: "lib-team-invite-players",
  templateUrl: "./team-invite-players.component.html",
  styleUrls: ["./team-invite-players.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TitileNavbarComponent,
    BottomNavbarComponent,
    NgClass,
    AsyncPipe,
    IonicModule,
    NgIf,
    NgTemplateOutlet,
    NgForOf,
    OrAvatarComponent,
  ],
  providers: [
    GetTeamCandidatesQueryHandler,
    LocalRefreshService,
    AddTeamMemberCommandHandler,
  ],
})
export class TeamInvitePlayersComponent {
  private readonly getTeamCandidatesQueryHandler: GetTeamCandidatesQueryHandler =
    inject(GetTeamCandidatesQueryHandler);
  private readonly addTeamMemberCommandHandler: AddTeamMemberCommandHandler =
    inject(AddTeamMemberCommandHandler);
  private readonly localRefreshService: LocalRefreshService =
    inject(LocalRefreshService);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  private readonly selectedUsers: BehaviorSubject<string[]> =
    new BehaviorSubject<string[]>([]);

  readonly selectedUsers$: Observable<string[]> =
    this.selectedUsers.asObservable();

  readonly candidates$: Observable<TeamPlayersViewModel[]> =
    this.localRefreshService.refresh$.pipe(
      switchMap(() => this.modalProvider.showLoading$()),
      switchMap(() => this.teamData$),
      switchMap((teamData: TeamInviteData) =>
        this.getTeamCandidatesQueryHandler.candidates(teamData.teamId)
      ),
      map((candidates: TeamCandidatesDTO[]) =>
        candidates.map((candidate: TeamCandidatesDTO) => ({
          ...candidate,
          isSelected: false,
        }))
      ),
      tap(() => this.modalProvider.dismissLoading$())
    );

  readonly teamData$: Observable<TeamInviteData> =
    this.activatedRoute.params.pipe(
      map((params: Params) => ({
        teamId: params["id"],
        token: params["token"],
      }))
    );

  readonly isiOS: boolean = this.platformProvider.isiOS;

  selectAll(event: CustomEvent) {
    const eventValue: boolean = event.detail.checked;

    this.candidates$
      .pipe(
        take(1),
        map((players) => {
          const payload = eventValue
            ? players.map((player) => player.userId)
            : [];

          this.selectedUsers.next(payload);
        })
      )
      .subscribe();
  }

  selectPlayer(userId: string): void {
    const selectedUsers: string[] = this.selectedUsers.value;

    if (selectedUsers.includes(userId))
      return this.selectedUsers.next(
        selectedUsers.filter((id: string) => id !== userId)
      );
    return this.selectedUsers.next([...selectedUsers, userId]);
  }

  isSelected(memberId: string): boolean {
    return this.selectedUsers.value.some((players) =>
      players.includes(memberId)
    );
  }

  inviteUsers(): void {
    combineLatest([this.teamData$, this.selectedUsers$])
      .pipe(
        take(1),
        switchMap(([teamData, selectedUsers]: [TeamInviteData, string[]]) =>
          this.addTeamMemberCommandHandler.add(teamData.teamId, selectedUsers)
        )
      )
      .subscribe(() => {
        this.localRefreshService.emit();
        this.selectedUsers.next([]);
      });
  }

  inviteViaLink(): void {
    this.teamData$
      .pipe(
        take(1),
        switchMap((teamData: TeamInviteData) =>
          this.modalProvider.showModal$({
            component: ShareTeamModalComponent,
            componentProps: { teamData: teamData },
            cssClass: "modal-auto",
            initialBreakpoint: 1,
            breakpoints: [0, 1],
          })
        )
      )
      .subscribe();
  }
}
