import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Output,
} from "@angular/core";
import { GameCardComponent } from "@ui-components";
import { Observable, switchMap, tap } from "rxjs";
import { MODAL_TOKEN, ModalProvider } from "@core";
import { AsyncPipe, NgForOf, NgIf } from "@angular/common";
import {
  GetTeamsQueryHandler,
  TeamCardComponent,
  TeamDTO,
  TeamsService,
} from "@teams";
import { TeamRole } from "../../../../../teams/src/lib/application/enums";

@Component({
  selector: "lib-dashboard-teams",
  templateUrl: "./dashboard-teams.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, NgIf, NgForOf, GameCardComponent, TeamCardComponent],
  providers: [GetTeamsQueryHandler, TeamsService],
})
export class DashboardTeamsComponent {
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly getTeamsQueryHandler: GetTeamsQueryHandler =
    inject(GetTeamsQueryHandler);

  @Output() isTeamsAvailable: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() canCreateTeam: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isTeamsExpanded: boolean = true;

  readonly teams$: Observable<TeamDTO[]> = this.modalProvider
    .showLoading$()
    .pipe(
      switchMap(() => this.getTeamsQueryHandler.getTeams()),
      tap((teams: TeamDTO[]) => this.isTeamsAvailable.emit(!!teams?.length)),
      tap((teams: TeamDTO[]) => {
        const result: TeamDTO[] = teams.filter(
          (team: TeamDTO) => team.memberRole === TeamRole.Host
        );

        this.canCreateTeam.emit(!result?.length);
      }),
      tap(() => this.modalProvider.dismissLoading$())
    );

  stopEventPropagation(event: MouseEvent): void {
    event.stopImmediatePropagation();
  }

  changeTeamsVisibility() {
    this.isTeamsExpanded = !this.isTeamsExpanded;
  }
}
