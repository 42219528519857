import { UserCommentsService } from "../../infrastructure";
import { inject } from "@angular/core";
import { Observable, switchMap } from "rxjs";
import { UserCommentDTO } from "../interfaces";
import { ActivatedRoute, Params } from "@angular/router";

export class GetUserCommentQueryHandler {
  private readonly userCommentsService: UserCommentsService =
    inject(UserCommentsService);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  getComment(): Observable<UserCommentDTO> {
    return this.activatedRoute.params.pipe(
      switchMap((params: Params) => {
        const userId: string = params["id"];

        return this.userCommentsService.getComment(userId);
      })
    );
  }
}
