import { ChangeDetectionStrategy, Component, inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { BottomNavbarComponent, CloseAdBannerComponent, HomeNavbarComponent } from '@ui-components';
import { map, Observable, take, tap } from 'rxjs';
import { NotificationsListQueryHandler, NotificationViewModel } from '@notifications';
import { AsyncPipe, NgIf } from '@angular/common';
import { AppRoutes } from 'src/app/app-routes.enum';
import { HostedGamesRoutes } from '@hosted-games';
import { Router } from '@angular/router';
import { DashboardNavbarComponent } from '../dashboard-navbar/dashboard-navbar.component';
import {
  ADS_TOKEN,
  AdsProvider,
  AdsUnitId,
  FullHeightScrollableContainerDirective,
  PLATFORM_TOKEN,
  PlatformProvider
} from '@core';
import { DashboardGamesListComponent } from '../dashboard-games-list/dashboard-games-list.component';
import { DashboardInvitationsComponent } from '../dashboard-invitations/dashboard-invitations.component';
import { DashboardEmptyStateComponent } from '../dashboard-empty-state/dashboard-empty-state.component';
import { DashboardTeamsComponent } from '../dashboard-teams/dashboard-teams.component';
import { FeatureFlag, FeatureFlagHandler } from '@feature-flags';
import { BannerAdSize } from '@capacitor-community/admob/dist/esm/banner/banner-ad-size.enum';
import { BannerAdPosition } from '@capacitor-community/admob/dist/esm/banner/banner-ad-position.enum';
import { CreateTeamComponent } from '../create-team/create-team.component';
import { RedCardIndicatorComponent } from '@fair-play';

@Component({
  selector: 'lib-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    HomeNavbarComponent,
    AsyncPipe,
    BottomNavbarComponent,
    DashboardNavbarComponent,
    DashboardGamesListComponent,
    NgIf,
    DashboardInvitationsComponent,
    DashboardEmptyStateComponent,
    DashboardTeamsComponent,
    FullHeightScrollableContainerDirective,
    CloseAdBannerComponent,
    CreateTeamComponent,
    RedCardIndicatorComponent
  ],
  providers: [NotificationsListQueryHandler]
})
export class DashboardComponent implements OnInit {
  @ViewChildren(DashboardGamesListComponent)
  dashboardGamesListComponent!: QueryList<DashboardGamesListComponent>;
  @ViewChild(FullHeightScrollableContainerDirective)
  containerDirective!: FullHeightScrollableContainerDirective;

  private readonly notificationsListQueryHandler: NotificationsListQueryHandler = inject(NotificationsListQueryHandler);
  private readonly router: Router = inject(Router);
  private readonly adsProvider: AdsProvider = inject(ADS_TOKEN);
  private readonly featureFlagHandler: FeatureFlagHandler = inject(FeatureFlagHandler);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);

  public isGameAvailable: boolean = true;
  public isInvitationsAvailable: boolean = true;
  public isTeamsAvailable: boolean = true;

  get isEmptyStateVisible(): boolean {
    return !this.isGameAvailable && !this.isInvitationsAvailable && !this.isTeamsAvailable;
  }

  readonly isNewNotifications$: Observable<boolean> = this.notificationsListQueryHandler.getNotificationsList().pipe(
    map((notifications: NotificationViewModel[]) =>
      notifications.filter((notification: NotificationViewModel) => !notification.isRead)
    ),
    map((notifications: NotificationViewModel[]) => !!notifications?.length)
  );

  ngOnInit(): void {
    this.initBottomBanner();
  }

  goToGameCreator(): void {
    this.router.navigateByUrl(`${AppRoutes.HOST}/${HostedGamesRoutes.CREATE_GAME}`);
  }

  setIsInvitationsAvailable(event: boolean): void {
    this.isInvitationsAvailable = event;
  }

  setIsGameAvailable(event: boolean): void {
    this.isGameAvailable = event;
  }

  setIsTeamsAvailable(event: boolean): void {
    this.isTeamsAvailable = event;
  }

  invitationAccepted(): void {
    this.dashboardGamesListComponent?.forEach((child: DashboardGamesListComponent) => child?.refresh());
  }

  private initBottomBanner(): void {
    const bottomMargin: number = this.platformProvider.isiOS ? 50 : 70;

    this.featureFlagHandler
      .isEnabled$(FeatureFlag.AD_MOB)
      .pipe(
        take(1),
        tap((isEnabled: boolean) => {
          if (isEnabled)
            return this.adsProvider.showBanner({
              adSize: BannerAdSize.BANNER,
              position: BannerAdPosition.BOTTOM_CENTER,
              adId: AdsUnitId.BannerV1,
              margin: bottomMargin
            });

          return;
        })
      )
      .subscribe();
  }
}
