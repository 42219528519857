import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from "@angular/core";
import {
  CopyService,
  CopyValueToClipboardDirective,
  formattedTimeDifferenceUtil,
  GameDetailsModel,
  MODAL_TOKEN,
  ModalProvider,
  NavigateDirective,
  RedirectToUserProfileDirective,
  USER_DATA_TOKEN,
  UserDataProvider,
  UserModel,
} from "@core";
import { IonicModule } from "@ionic/angular";
import { OrAvatarComponent } from "../or-avatar/or-avatar.component";
import { AsyncPipe, NgClass, NgIf } from "@angular/common";
import { ShareGameDirective } from "../share-game";
import { map, Observable, switchMap, take } from "rxjs";
import { VisibilityEnum } from "../../../../hosted-games/src/lib/application/enums";
import { Router } from "@angular/router";
import { AppRoutes } from "src/app/app-routes.enum";
import {
  ChannelDetailsQueryParam,
  ChannelTypeEnum,
} from "../../../../messages/src/lib/application/enums";
import {
  ChannelDetailsByReferenceQueryHandler,
  OpenChatCommandHandler,
} from "../../../../messages/src/lib/application/handlers";
import {
  ChannelDetailsDTO,
  ChannelDetailsPayloadDTO,
} from "../../../../messages/src/lib/application/interfaces";
import { HostedGamesRoutes } from "../../../../hosted-games/src/lib/hosted-games.routes.enum";

@Component({
  selector: "lib-game-details",
  templateUrl: "./game-details.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ShareGameDirective,
    IonicModule,
    OrAvatarComponent,
    CopyValueToClipboardDirective,
    NgIf,
    NavigateDirective,
    NgClass,
    AsyncPipe,
    RedirectToUserProfileDirective,
    RedirectToUserProfileDirective,
  ],
  providers: [
    CopyService,
    OpenChatCommandHandler,
    ChannelDetailsByReferenceQueryHandler,
  ],
})
export class GameDetailsComponent {
  @Input() game!: GameDetailsModel;
  @Input() showHostInfo: boolean = false;
  @Input() showPhoneNumber: boolean = true;
  @Input() showMessage: boolean = true;
  @Input() showNavigateLink: boolean = false;
  @Input() showGameTypeDetails: boolean = false;
  @Input() adminAccess: boolean = false;
  @Input() showGoToChat: boolean = false;
  @Output() onSendMessage: EventEmitter<string> = new EventEmitter<string>();

  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);
  private readonly router: Router = inject(Router);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly openChatCommandHandler: OpenChatCommandHandler = inject(
    OpenChatCommandHandler
  );
  private readonly channelDetailsByReferenceQueryHandler: ChannelDetailsByReferenceQueryHandler =
    inject(ChannelDetailsByReferenceQueryHandler);

  readonly isMyGame$: Observable<boolean> =
    this.userDataProvider.userData$.pipe(
      map((userData: UserModel) => userData.userId === this.game.host?.hostId)
    );

  get gameType(): string {
    return this.game.isRecurring ? "Powtarzalna" : "Pojedyncza";
  }

  get gameVisibilityLabel(): string {
    return this.game.visibility === VisibilityEnum.PRIVATE
      ? "Ukryta"
      : "Widoczna";
  }

  hostRating(): string | null {
    if (!this.game.host) return null;
    return this.game.host?.rating.hostRating;
  }

  sendMessage(userId: string | undefined, event: Event): void {
    event.stopImmediatePropagation();
    this.onSendMessage.emit(userId);
  }

  showAdminDetails(): void {
    this.modalProvider.dismissAllStoredModals();
    this.router.navigateByUrl(
      `${AppRoutes.HOST}/${HostedGamesRoutes.FUTURE}/${this.game.gameId}`
    );
  }

  calculatelastActive(date: string | undefined): string {
    const adjustedDate: Date | null = date ? new Date(date) : null;
    return formattedTimeDifferenceUtil(adjustedDate).toLowerCase();
  }

  goToChat(event: MouseEvent, gameId: string) {
    event.stopImmediatePropagation();

    const payload: ChannelDetailsPayloadDTO = {
      param: ChannelDetailsQueryParam.GAME,
      gameId: gameId,
    };

    this.channelDetailsByReferenceQueryHandler
      .details(payload)
      .pipe(
        take(1),
        switchMap((details: ChannelDetailsDTO) =>
          this.openChatCommandHandler.open(
            details.channelExternalId,
            ChannelTypeEnum.MULTI
          )
        )
      )
      .subscribe();
  }
}
