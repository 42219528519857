import { AuthDtoPort } from './auth.dto-port';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { inject, Injectable } from '@angular/core';
import { CredentialsDTO } from '../../application/models';
import { EmailAuthProvider, GoogleAuthProvider, OAuthProvider } from '@angular/fire/auth';
import { HttpClient } from '@angular/common/http';
import { ENV_CONFIG, EnvConfig, PLATFORM_TOKEN, PlatformProvider, UserDTO } from '@core';
import { Observable, switchMap, tap } from 'rxjs';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { SignInWithApple, SignInWithAppleOptions, SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';

@Injectable()
export class AuthService implements AuthDtoPort {
  private readonly authClient: AngularFireAuth = inject(AngularFireAuth);
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);

  login(credentials: CredentialsDTO) {
    return this.authClient.signInWithEmailAndPassword(credentials.email, credentials.password);
  }

  register(credentials: CredentialsDTO) {
    return this.authClient.createUserWithEmailAndPassword(credentials.email, credentials.password);
  }

  async logout() {
    if (this.platformProvider.isNativeRun) {
      await GoogleAuth.initialize();
      await GoogleAuth.signOut();
    }

    localStorage.clear();

    await this.authClient.signOut();
  }

  removeAccount(): any {
    return this.httpClient.delete<UserDTO>(`${this.env.apiUrl}/users/me`).pipe(
      switchMap(() => this.authClient.currentUser),
      tap((user) => user?.delete())
    );
  }

  reauthenticate(password: string): any {
    return this.authClient.currentUser.then((user) => {
      const credential = EmailAuthProvider.credential(user?.email ?? '', password);
      return user?.reauthenticateWithCredential(credential);
    });
  }

  async authWithGoogle(): Promise<void> {
    if (!this.platformProvider.isNativeRun) {
      await this.authClient.signInWithPopup(new GoogleAuthProvider());
    }

    if (this.platformProvider.isNativeRun) {
      await GoogleAuth.initialize();

      const user = await GoogleAuth.signIn();
      const credential = GoogleAuthProvider.credential(user.authentication.idToken);

      await this.authClient.signInWithCredential(credential);
    }
  }

  async authWithApple(): Promise<void> {
    const options: SignInWithAppleOptions = {
      clientId: 'com.orlikfy.auth',
      redirectURI: 'https://app.orlikfy.pl',
      scopes: 'email',
      state: '12345'
    };

    GoogleAuth.initialize();

    return SignInWithApple.authorize(options).then(async (result: SignInWithAppleResponse) => {
      const provider: OAuthProvider = new OAuthProvider('apple.com');
      const credentials = provider.credential({
        idToken: result.response.identityToken
      });

      await this.authClient.signInWithCredential(credentials);
    });
  }

  setReferrer(referrerId: string): Observable<void> {
    return this.httpClient.post<void>(`${this.env.apiUrl}/users/referral`, {
      referralId: referrerId
    });
  }
}
