import { inject } from '@angular/core';
import { TeamsService } from '../../infrastructure';

export class GetTeamDetailsQueryHandler {
  private readonly teamsService: TeamsService = inject(TeamsService);

  teamDetails(teamId: string) {
    return this.teamsService.getTeamDetails(teamId);
  }
}
