import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { GetArrivalBarInfoQueryHandler } from "../../application/handlers";
import { provideArrivalBarService } from "../../infrastructure/http-services";
import { AsyncPipe, NgIf } from "@angular/common";
import { Observable } from "rxjs";
import { ArrivalBarModel } from "../../application/models";
import { Router } from "@angular/router";
import { AppRoutes } from "src/app/app-routes.enum";
import { HostedGamesRoutes } from "@hosted-games";
import { IonicModule } from "@ionic/angular";
import { GamesRoutes } from "@games";

@Component({
  selector: "lib-arrival-bar",
  templateUrl: "./arrival-bar.component.html",
  styleUrls: ["./arrival-bar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, AsyncPipe, IonicModule],
  providers: [GetArrivalBarInfoQueryHandler, provideArrivalBarService()],
})
export class ArrivalBarComponent {
  public showFullItem: boolean = true;

  private readonly getArrivalBarInfoQueryHandler: GetArrivalBarInfoQueryHandler =
    inject(GetArrivalBarInfoQueryHandler);
  private readonly router: Router = inject(Router);

  readonly arrivalBarInfo$: Observable<ArrivalBarModel> =
    this.getArrivalBarInfoQueryHandler.getArrivalInfo();

  redirectToGame(gameId: string, isHost: boolean): Promise<boolean> {
    if (isHost) {
      return this.router.navigateByUrl(
        `${AppRoutes.HOST}/${HostedGamesRoutes.FUTURE}/${gameId}`
      );
    }
    return this.router.navigateByUrl(
      `${AppRoutes.GAMES}/${GamesRoutes.DETAILS}/${gameId}`
    );
  }

  changeVisibility() {
    this.showFullItem = !this.showFullItem;
  }
}
