import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { BottomNavbarComponent, TitileNavbarComponent } from "@ui-components";
import { CustomNotificationQueryHandler } from "../../application/handlers";
import { CustomNotificationDTO } from "../../application/interfaces";
import { provideCustomNotificationService } from "../../infrastructure/";
import { Observable } from "rxjs";
import { AsyncPipe, NgForOf, NgIf } from "@angular/common";
import { PLATFORM_TOKEN, PlatformProvider } from "@core";
import { RouterLink } from "@angular/router";

@Component({
  selector: "lib-custom-notification",
  templateUrl: "./custom-notification.component.html",
  styleUrls: ["./custom-notification.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TitileNavbarComponent,
    AsyncPipe,
    NgIf,
    BottomNavbarComponent,
    NgForOf,
    RouterLink,
  ],
  providers: [
    CustomNotificationQueryHandler,
    provideCustomNotificationService(),
  ],
})
export class CustomNotificationComponent {
  private readonly customNotificationQueryHandler: CustomNotificationQueryHandler =
    inject(CustomNotificationQueryHandler);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);

  readonly isiOS: boolean = this.platformProvider.isiOS;

  readonly data$: Observable<CustomNotificationDTO> =
    this.customNotificationQueryHandler.getData();
}
