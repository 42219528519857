import { Observable, of } from 'rxjs';
import { StartupState } from '../startup.state';
import { inject } from '@angular/core';
import { StartupStep } from '../startup-step';
import { App } from '@capacitor/app';
import { AppRoutes } from '../../app-routes.enum';
import { GlobalRefreshService, MODAL_TOKEN, ModalProvider, NAVIGATION_TOKEN, NavigationProvider } from '@core';
import { Router } from '@angular/router';

export class AppNativeEventsStep extends StartupStep {
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly router: Router = inject(Router);
  private readonly navigationProvider: NavigationProvider = inject(NAVIGATION_TOKEN);
  private readonly globalRefreshService: GlobalRefreshService = inject(GlobalRefreshService);

  private backButtonPressedOnce: boolean = false;

  executeStep(state: StartupState): Observable<StartupState> {
    this.setupAppResumeListener();
    this.handleBackButton();

    return of(state);
  }

  private setupAppResumeListener(): void {
    App.addListener('appStateChange', (state) => {
      if (state.isActive) {
        this.globalRefreshService.emit();
      }
    });
  }

  private handleBackButton() {
    App.addListener('backButton', () => {
      if (this.modalProvider.isModalActive()) {
        this.modalProvider.dismissAllStoredModals();
      } else if (this.router.url.includes(AppRoutes.DASHBOARD)) {
        if (this.backButtonPressedOnce) {
          App.exitApp();
        } else {
          this.backButtonPressedOnce = true;
          setTimeout(() => {
            this.backButtonPressedOnce = false;
          }, 500);
        }
      } else {
        this.navigationProvider.back();
      }
    });
  }
}
