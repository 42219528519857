import { LineupsPlayerModel } from '../interfaces';
import { PreferredPosition } from '@core';

export const shuffleLineupsPosition = (players: LineupsPlayerModel[]): LineupsPlayerModel[] => {
  const teams: LineupsPlayerModel[][] = [[], []];

  const goalkeepers = players.filter(
    (player) => player.playerPreferences?.preferredPosition === PreferredPosition.GOALKEEPER
  );
  const defenders = players.filter((player) => player.playerPreferences?.preferredPosition === PreferredPosition.BACK);
  const midfielders = players.filter(
    (player) => player.playerPreferences?.preferredPosition === PreferredPosition.MIDFIELDER
  );
  const strikers = players.filter(
    (player) => player.playerPreferences?.preferredPosition === PreferredPosition.STRIKER
  );
  const randomPlayers = players.filter((player) => !player.playerPreferences?.preferredPosition);

  goalkeepers.forEach((goalkeeper, index) => {
    const teamIndex = index % 2;
    teams[teamIndex].push(goalkeeper);
  });

  const distributePlayersWithSizeBalance = (playersGroup: LineupsPlayerModel[]) => {
    playersGroup.forEach((player) => {
      const teamIndex = teams[0].length <= teams[1].length ? 0 : 1;
      teams[teamIndex].push(player);
    });
  };

  distributePlayersWithSizeBalance(defenders);
  distributePlayersWithSizeBalance(midfielders);
  distributePlayersWithSizeBalance(strikers);

  randomPlayers.forEach((player) => {
    const teamIndex = Math.floor(Math.random() * 2); // Losowy wybór drużyny
    teams[teamIndex].push(player);
  });

  return teams.flat();
};
