import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { OrAvatarComponent, TitileNavbarComponent } from '@ui-components';
import { BehaviorSubject, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { SetReferrerCommandHandler, UpdateUserCommandHandler } from '../../application/handlers';
import { IonicModule } from '@ionic/angular';
import { Router } from '@angular/router';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AppRoutes } from 'src/app/app-routes.enum';
import {
  availableLevels,
  CacheKey,
  FullHeightScrollableContainerDirective,
  GameLevel,
  gameLevelMapper,
  MODAL_TOKEN,
  ModalProvider,
  PLATFORM_TOKEN,
  PlatformProvider,
  PreferredPosition,
  preferredPositionMapper,
  PresentModalComponent,
  ProfileType,
  USER_DATA_TOKEN,
  UserDataProvider,
  UserModel
} from '@core';
import { RemoveAccountCommandHandler } from '../../../../../auth/src/lib/application/handlers';
import { ProfileRoutes } from '../../profile.routes.enum';

interface Base64imageModel {
  readonly isAvailable: boolean;
  readonly base64: string;
}

@Component({
  selector: 'lib-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TitileNavbarComponent,
    IonicModule,
    ReactiveFormsModule,
    OrAvatarComponent,
    FullHeightScrollableContainerDirective
  ],
  providers: [UpdateUserCommandHandler, SetReferrerCommandHandler]
})
export class EditProfileComponent implements OnInit, OnDestroy {
  public isRemoveAccountMode: boolean = false;

  readonly fileExtensions: string = '.png, .jpg, .jpeg';

  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);
  private readonly updateUserCommandHandler: UpdateUserCommandHandler = inject(UpdateUserCommandHandler);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly router: Router = inject(Router);
  private readonly removeAccountCommandHandler: RemoveAccountCommandHandler = inject(RemoveAccountCommandHandler);
  private readonly setReferrerCommandHandler: SetReferrerCommandHandler = inject(SetReferrerCommandHandler);

  private base64imageSubject$: BehaviorSubject<Base64imageModel> = new BehaviorSubject<Base64imageModel>({
    isAvailable: false,
    base64: ''
  });

  readonly isiOS: boolean = this.platformProvider.isiOS;

  readonly profileForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.maxLength(15)]),
    lastName: new FormControl('', [Validators.maxLength(15)]),
    phone: new FormControl('', [Validators.minLength(9), Validators.maxLength(9), Validators.pattern('^[0-9]*$')]),
    avatar: new FormControl(),
    preferredLevel: new FormControl('', [Validators.required]),
    preferredPosition: new FormControl('', [Validators.required]),
    profileType: new FormControl('', [Validators.required]),
    referrerToken: new FormControl()
  });

  readonly removeAccountForm: FormGroup = new FormGroup({
    password: new FormControl()
  });

  public preferredLevel: GameLevel[] = availableLevels;
  public gameLevelMapper: Record<GameLevel, string> = gameLevelMapper;
  public profileType: typeof ProfileType = ProfileType;

  public preferredPosition: PreferredPosition[] = Object.values(PreferredPosition);
  public preferredPositionMapper: Record<PreferredPosition, string> = preferredPositionMapper;

  readonly userData$: Observable<UserModel> = this.userDataProvider.userData$;

  readonly base64image$: Observable<Base64imageModel> = this.base64imageSubject$.asObservable();

  readonly allowToCancel$: Observable<boolean> = this.userData$.pipe(
    map(
      (userData: UserModel) =>
        !!userData.name &&
        !!userData.playerPreferences?.preferredLevel &&
        !!userData.playerPreferences?.preferredPosition &&
        !!userData.profileType
    )
  );

  readonly isHidden$: Observable<boolean> = this.userData$.pipe(
    map((userData) => {
      const isAppleEmail = userData.email.includes('apple');

      return this.isiOS && isAppleEmail && !userData.name;
    })
  );

  get isNameOverAvailableLength() {
    return this.profileForm.get('name')?.value?.length > 15;
  }

  get isLastNameOverAvailableLength() {
    return this.profileForm.get('lastName')?.value?.length > 15;
  }

  get profileTypeSelectedValue(): ProfileType {
    return this.profileForm.get('profileType')?.value;
  }

  ngOnInit(): void {
    this.userData$
      .pipe(
        take(1),
        tap((userData: UserModel) =>
          this.profileForm.patchValue({
            name: userData?.name,
            lastName: userData?.surname,
            phone: userData?.phone,
            preferredLevel: userData?.playerPreferences?.preferredLevel,
            preferredPosition: userData?.playerPreferences?.preferredPosition,
            profileType: userData?.profileType
          })
        )
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem(CacheKey.RedirectAfterCompleteProfileUrl);
  }

  saveData(): void {
    if (this.isRemoveAccountMode) return;

    if (this.profileForm.valid) {
      this.updateUserCommandHandler
        .update({
          name: this.profileForm.get('name')?.value,
          surname: this.profileForm.get('lastName')?.value,
          phone: this.profileForm.get('phone')?.value,
          thumbnail: this.profileForm.get('avatar')?.value,
          preferredLevel: this.profileForm.get('preferredLevel')?.value,
          preferredPosition: this.profileForm.get('preferredPosition')?.value,
          profileType: this.profileForm.get('profileType')?.value
        })
        .pipe(
          take(1),
          switchMap(() => {
            const referrerId: string = this.profileForm.get('referrerToken')?.value;
            if (!!referrerId) return this.setReferrerCommandHandler.setReferrer(referrerId);

            return of(void 0);
          }),
          take(1),
          tap(() => this.userDataProvider.setUserData())
        )
        .subscribe({
          next: () => this.redirectAfterSuccess()
        });
    }
  }

  goToSettings(): void {
    this.allowToCancel$
      .pipe(
        take(1),
        tap((allowToCancel: boolean) => {
          if (!allowToCancel) return;

          this.router.navigate([`${AppRoutes.PROFILE}/${ProfileRoutes.SETTINGS}`]);
        })
      )
      .subscribe();
  }

  redirectAfterSuccess(): void {
    const redirectAfterCompleteProfile = sessionStorage.getItem(CacheKey.RedirectAfterCompleteProfileUrl);

    if (!!redirectAfterCompleteProfile) {
      this.router.navigate([redirectAfterCompleteProfile]);
      return;
    }

    this.userData$
      .pipe(
        take(1),
        tap((userData: UserModel) => this.router.navigate([`${AppRoutes.PROFILE}/${userData.userId}`]))
      )
      .subscribe();
  }

  isFieldInvalid(fieldName: string): boolean {
    const control = this.profileForm.get(fieldName);
    return control ? control.touched && control.invalid : false;
  }

  changeAvatar(event: Event): void {
    const inputElement: HTMLInputElement = event.target as HTMLInputElement;
    const file: File | null = inputElement.files ? inputElement.files[0] : null;

    if (!file) return;

    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const imageBase64: string = reader.result as string;

      const estimatedSize: number = (imageBase64.replace(/[\s=]/g, '').length * 3) / 4;

      if (estimatedSize < 2000000) {
        this.base64imageSubject$.next({
          isAvailable: true,
          base64: imageBase64
        });
        this.profileForm.patchValue({ avatar: imageBase64 });
      } else {
        this.imageSizeError();
      }
    };
  }

  private imageSizeError() {
    this.profileForm.patchValue({ avatar: null });
    this.base64imageSubject$.next({ isAvailable: false, base64: '' });

    this.modalProvider.showModal$({
      component: PresentModalComponent,
      componentProps: {
        header: 'Błąd',
        message: 'Avatar jest zbyt duży. Maksymalny rozmiar to 2MB.',
        btnTxt: 'Zamknij'
      },
      cssClass: 'present-modal'
    });
  }

  removeAcoountMode(): void {
    this.isRemoveAccountMode = true;
  }

  deleteAccount(): void {
    this.removeAccountCommandHandler
      .removeAccount(this.removeAccountForm.get('password')?.value)
      .pipe(take(1))
      .subscribe(() => this.router.navigate([AppRoutes.AUTH]));
  }
}
