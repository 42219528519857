import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { ENV_CONFIG, EnvConfig } from '@core';
import { Observable } from 'rxjs';

export class ReferrerHttpService {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  setReferrer(referralToken: string): Observable<void> {
    return this.httpClient.post<void>(`${this.env.apiUrl}/users/referral`, {
      referralToken: referralToken
    });
  }
}
