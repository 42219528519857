import { UserService } from "../../infrastructure";
import { inject } from "@angular/core";
import { map, Observable, switchMap, take } from "rxjs";
import { UserTrophiesDTO, UserTrophyDTO, UserTrophyModel } from "../interfaces";
import { userTrophiesMapper } from "../mappers";
import { ActivatedRoute, Params } from "@angular/router";

export class GetUserTrophiesQueryHandler {
  private readonly userService: UserService = inject(UserService);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  getTrophies(): Observable<UserTrophyModel[]> {
    return this.activatedRoute.params.pipe(
      take(1),
      switchMap((params: Params) =>
        this.userService.getUserTrophies(params["id"])
      ),
      map((trophies: UserTrophiesDTO) =>
        trophies.trophies.map((trophy: UserTrophyDTO) => {
          return {
            name: userTrophiesMapper[trophy.trophyType]?.name,
            description: userTrophiesMapper[trophy.trophyType]?.description,
            icon: userTrophiesMapper[trophy.trophyType]?.icon,
          };
        })
      )
    );
  }
}
