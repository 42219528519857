import { SingleRadioItemModel } from '@ui-components';

export const availableFilterDistanceConst: SingleRadioItemModel<number>[] = [
  {
    name: 'do 2 km',
    value: 2000
  },
  {
    name: 'do 5 km',
    value: 5000
  },
  {
    name: 'do 25 km',
    value: 25000
  },
  {
    name: 'do 70 km',
    value: 70000
  },
  {
    name: 'dowolna',
    value: 0
  }
];
