import { AUTH_DTO, AuthDtoPort } from "../../infrastructure/http-services";
import { inject } from "@angular/core";
import { CredentialsDTO } from "../models";
import { from, Observable, of, switchMap, tap } from "rxjs";
import { USER_DATA_TOKEN, UserDataProvider } from "@core";

export class LoginCommandHandler {
  private readonly authDtoPort: AuthDtoPort = inject(AUTH_DTO);
  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);

  login(credentials: CredentialsDTO): Observable<void> {
    return from(this.authDtoPort.login(credentials)).pipe(
      tap(() => this.userDataProvider.setUserData()),
      switchMap(() => of(void 0))
    );
  }
}
