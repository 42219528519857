import { inject, Injectable } from '@angular/core';
import { environment } from 'src/environment/environment';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { ENV_CONFIG, EnvConfig } from '../env';
import { DeepLinkDTO, DeepLinkResponseInterface, SocialMetaTagInfo } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class DeepLinkService {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  generateLink(url: string, socialMetaTagInfo: SocialMetaTagInfo): Observable<string> {
    const payload: DeepLinkDTO = {
      payload: {
        dynamicLinkInfo: {
          domainUriPrefix: environment.deepLinkUriPrefix,
          link: url,
          androidInfo: {
            androidPackageName: environment.packageName,
            androidFallbackLink: url
          },
          socialMetaTagInfo: socialMetaTagInfo
        },
        suffix: {
          option: 'SHORT'
        }
      },
      apiKey: environment.firebase.apiKey
    };

    return this.httpClient
      .post<DeepLinkResponseInterface>(`${this.env.apiUrl}/short-link`, payload)
      .pipe(map((response: DeepLinkResponseInterface) => response.shortLink));
  }
}
