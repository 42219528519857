import { CreateTeamDTO, TeamDTO } from '../interfaces';
import { TeamsService } from '../../infrastructure';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

export class UpdateTeamCommandHandler {
  private readonly teamsService: TeamsService = inject(TeamsService);

  update(teamId: string, payload: CreateTeamDTO): Observable<TeamDTO> {
    return this.teamsService.updateTeam(teamId, payload);
  }
}
