import { inject } from '@angular/core';
import { debounceTime, map, Observable, switchMap } from 'rxjs';
import { NearbyGamesService } from '../../infrastructure/http-services';
import { FiltersStorage } from '../../infrastructure/storages';
import { FiltersValues } from '../models';
import {
  capitalizeFirstLetter,
  formatDateToString,
  GameDetailsModel,
  GameDTO,
  mapGameDTOToGameDetailsUtil
} from '@core';
import { parseFiltersData } from '../utils';
import { GamesListModel } from '../interfaces';
import { parseISO } from 'date-fns';

export class GamesListQueryHandler {
  private nearbyGamesService: NearbyGamesService = inject(NearbyGamesService);
  private filtersStorage: FiltersStorage = inject(FiltersStorage);

  getGames(showPrivate: boolean): Observable<GamesListModel[]> {
    return this.filtersStorage.filters$.pipe(
      debounceTime(300),
      map((filters: FiltersValues) => parseFiltersData(filters)),
      switchMap((filters: string) => this.nearbyGamesService.getGamesList(filters, showPrivate)),
      map((games: { games: GameDTO[] }) => {
        const groupedGames: Record<string, GameDetailsModel[]> = this.groupByDate(games.games);

        return Object.keys(groupedGames)
          .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
          .map((date: string) => ({
            date: this.formatDate(date),
            games: groupedGames[date]
          }));
      })
    );
  }

  private groupByDate(games: GameDTO[]): Record<string, GameDetailsModel[]> {
    return games.reduce((acc, game) => {
      const date: string = formatDateToString(game.gameDateStart, 'yyyy-MM-dd');
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(mapGameDTOToGameDetailsUtil(game));
      return acc;
    }, {} as Record<string, GameDetailsModel[]>);
  }

  formatDate(dateString: string): string {
    const date: Date = parseISO(dateString);
    const formattedDate: string = formatDateToString(date, 'EEEE, dd.MM.yyyy');

    return capitalizeFirstLetter(formattedDate);
  }
}
