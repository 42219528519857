<div class="or-p-16-px or-pt-40-px or-bg-shade">
  <div class="or-head-xs or-color-secondary-2 or-mb-12-px">Zarządzaj gierką</div>

  <ng-container *ngIf="!isPlayerInGame">
    <div
      [sendApplication]="game.gameId"
      [showModal]="false"
      class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-mb-8-px or-d-flex or-p-8-px or-h-56-px or-max-h-28-px">
      <img src="assets/icons/field.svg" class="or-mr-8-px or-size-32-px" />
      <div class="or-text-md">Dołącz do gierki jako gracz</div>
    </div>
  </ng-container>

  <div
    (click)="addPlayer()"
    class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-mb-8-px or-d-flex or-p-8-px or-h-56-px or-max-h-28-px">
    <img src="assets/add-button-shade.svg" class="or-mr-8-px or-size-32-px" />
    <div class="or-text-md">Dodaj gracza ręcznie</div>
  </div>

  <div
    (click)="changeVisibility()"
    class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-mb-8-px or-d-flex or-p-8-px or-h-56-px or-max-h-28-px">
    <img src="assets/icons/map-pin-2-icon.svg" class="or-mr-8-px or-size-32-px" />
    <div>
      <div class="or-text-md">{{ visibilityLabel.header }}</div>
      <div class="or-text-sm or-color-secondary-2">{{ visibilityLabel.text }}</div>
    </div>
  </div>

  <div
    (click)="goToInvitations()"
    class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-mb-8-px or-d-flex or-p-8-px or-h-56-px or-max-h-28-px">
    <img src="assets/icons/notifications-grey.svg" class="or-mr-8-px or-size-32-px" />
    <div class="or-text-md">Zaproś dawnych graczy</div>
  </div>

  <div
    (click)="goToLineups()"
    class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-mb-8-px or-d-flex or-p-8-px or-h-56-px or-max-h-28-px">
    <img src="assets/icons/randomize-shade.svg" class="or-mr-8-px or-size-32-px" />
    <div class="or-text-md">Losuj składy</div>
  </div>

  <div
    (click)="editGame()"
    class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-mb-8-px or-d-flex or-p-8-px or-h-56-px or-max-h-28-px">
    <img src="assets/icons/edit-2-border.svg" class="or-mr-8-px or-size-32-px" />
    <div class="or-text-md">Edytuj gierkę</div>
  </div>

  <div
    (click)="removeGame()"
    class="or-cursor-pointer border-radius-4 border-shade or-align-items-center or-bg-white or-d-flex or-p-8-px or-h-56-px or-max-h-28-px">
    <img src="assets/icons/trash-icon.svg" class="or-mr-8-px" />
    <div class="or-text-md or-color-red">Usuń gierkę</div>
  </div>
</div>
