import { TeamsService } from '../../infrastructure';
import { inject } from '@angular/core';
import { UpdateTeamMemberPayloadDTO } from '../interfaces';

export class UpdateTeamMemberCommandHandler {
  private readonly teamsService: TeamsService = inject(TeamsService);

  update(teamId: string, memberId: string, payload: UpdateTeamMemberPayloadDTO) {
    return this.teamsService.updateMember(teamId, memberId, payload);
  }
}
