import { Component, inject, OnInit } from '@angular/core';
import { StartupStepService } from './startup/startup-steps.service';

@Component({
  selector: 'app-component',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private readonly startupStepService: StartupStepService = inject(StartupStepService);

  ngOnInit(): void {
    this.startupStepService.init();
  }
}
