import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { Observable } from 'rxjs';
import { GetUserTrophiesQueryHandler } from '../../application/handlers';
import { UserTrophyModel } from '../../application/interfaces';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/app-routes.enum';
import { ProfileRoutes } from '../../profile.routes.enum';

@Component({
  selector: 'lib-trophies',
  templateUrl: './trophies.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, NgIf, NgForOf, NgClass],
  providers: [GetUserTrophiesQueryHandler]
})
export class TrophiesComponent {
  private readonly getUserTrophiesQueryHandler: GetUserTrophiesQueryHandler = inject(GetUserTrophiesQueryHandler);
  private readonly router: Router = inject(Router);

  readonly trophies$: Observable<UserTrophyModel[]> = this.getUserTrophiesQueryHandler.getTrophies();

  showAllTrophies(): void {
    this.router.navigateByUrl(`${AppRoutes.PROFILE}/${ProfileRoutes.ALL_TROPHIES}`);
  }
}
