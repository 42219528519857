<ion-list>
  <ion-row *ngSpawn="elements" class="or-d-flex or-align-items-center or-justify-content-center">
    <ion-row
      class="list-skeleton-item or-b-radius-8-px or-bg-shade or-d-flex or-align-items-center or-justify-content-center">
      <ion-col size="3" class="or-d-flex or-align-self-center or-justify-content-center">
        <ion-row>
          <or-avatar-skeleton class="or-b-radius-144-px-i"></or-avatar-skeleton>
        </ion-row>
      </ion-col>
      <ion-col class="list-skeleton-text">
        <ion-row>
          <or-row-skeleton> </or-row-skeleton>
        </ion-row>
        <ion-row class="or-mt-12-px">
          <or-row-skeleton> </or-row-skeleton>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-row>
</ion-list>
