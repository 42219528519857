import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { Router } from "@angular/router";
import { AppRoutes } from "src/app/app-routes.enum";
import { NgClass, NgIf } from "@angular/common";
import { PLATFORM_TOKEN, PlatformProvider } from "@core";

@Component({
  selector: "lib-home-navbar",
  templateUrl: "./home-navbar.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [IonicModule, NgIf, NgClass],
})
export class HomeNavbarComponent {
  @Output() onAddGameOpened: EventEmitter<void> = new EventEmitter<void>();
  @Input() isNewNotifications: boolean = false;
  @Input() showFiltersIcon: boolean = true;

  private readonly router: Router = inject(Router);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);

  isIOs(): boolean {
    return this.platformProvider.isiOS;
  }

  openNotifications(): void {
    this.router.navigate([AppRoutes.NOTIFICATIONS]);
  }

  addGame(): void {
    this.onAddGameOpened.emit();
  }
}
