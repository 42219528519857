import { AlertsSettingsDTO } from '../interfaces';
import { map, Observable, switchMap } from 'rxjs';
import { inject } from '@angular/core';
import { NotificationsAlertsService } from '../../infrastructure';
import { CoordinatesDTO, GEOLOCATION_TOKEN, GeolocationProvider } from '@core';

export class UpdateAlertsSettingsCommandHandler {
  private notificationsAlertsService: NotificationsAlertsService = inject(NotificationsAlertsService);
  private geolocationProvider: GeolocationProvider = inject(GEOLOCATION_TOKEN);

  switchAlerts(value: boolean): Observable<void> {
    return this.notificationsAlertsService.switchAlerts(value);
  }

  updateAlertsSettings(settings: AlertsSettingsDTO): Observable<void> {
    return this.geolocationProvider.getCoordinates().pipe(
      map((coordinates: CoordinatesDTO | null) => {
        const distance: number = settings.maxDistanceMeters === 71 ? 0 : settings.maxDistanceMeters;

        return {
          ...settings,
          maxDistanceMeters: distance * 1000,
          coordinates: coordinates
        };
      }),
      switchMap((result: AlertsSettingsDTO) => this.notificationsAlertsService.updateAlerts(result))
    );
  }
}
